import React, { useEffect, useState } from "react";
import support from './support1.jpg'
import { Link } from "react-router-dom";
import { basicAuth } from "../../../actions/auth/basicAuth";
import axios from "axios";

const SuppoortSystem=()=>{
  const [data,setData]=useState([])

    const getSupportSystem = async (e) => {
        const customerId=localStorage.getItem('customerId')
        const headers = {
            "ngrok-skip-browser-warning": "skip-browser-warning",
            Authorization: basicAuth, // Ensure proper formatting
        };
        const baseUrl = 'https://newhousemanager.webbulldesign.com/';
        try {
            const response = await axios.get(`${baseUrl}getAllTicketByCustomerId/${customerId}`, { headers: headers });
      setData(response?.data)
        } catch (error) {
            console.error('Error fetching banners:', error.response ? error.response.data : error.message); // Improved error logging
            // Optionally handle the error further if neededP
        }
    };
    useEffect(()=>{
        getSupportSystem()
    },[])
    console.log(data,'scnbshcjsjhcbscj')
    return(
        <>
        <div className="" style={{display:'flex',justifyContent:'end',alignItems:'end',marginRight:'10px',margin:'10px'}}>
          <Link to='/addticket'>
            <button style={{background:'#f56358',color:'white',padding:'10px'}}>
                Add Ticket
            </button>
            </Link>
        </div>
        <div className="">
            <div className=""style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
             <div className="" style={{width:'100%'}}>
                <img  src={support} style={{height:'80vh',width:'100%'}}/>
                </div>
                <h3 style={{marginTop:'10px'}}>
                    
                How can we help you?

                </h3>
                <p>
                To buy furniture online or to buy wooden furniture for the house has always been one of the most crucial decisions of a new life:


                </p>

                <div className="" style={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
                <div className="" style={{display:'flex',justifyContent:'center',padding:'10px'}}>
                    <h4>
                        Ticket Raised
                    </h4>
                </div>
                {
                    Array.isArray(data) && data?.map((item,index)=>{
                        return(
                            <>
                               <div className="p-10" style={{padding:'10px'}}>
                        <h5>
                         {index+1} {item?.issueType}
                        </h5>
                        <h6 style={{background:'beige',padding:'10px'}}>
                           {item?.discriptionOfIssue}
                        </h6>
                    </div>
                            
                            </>
                        )
                    })
                }
                 
                </div>
            </div>
        </div>
        
        </>
    )
}

export default SuppoortSystem