import {
    GET_CUSTOMER_BY_ID_REQUEST,
    GET_CUSTOMER_BY_ID_SUCCESS,
    GET_CUSTOMER_BY_ID_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const fetchCustomerByIdRequest = () => {
    return {
        type: GET_CUSTOMER_BY_ID_REQUEST,
    };
};
export const fetchCustomerByIdSuccess = (Data) => {
    return {
        type: GET_CUSTOMER_BY_ID_SUCCESS,
        payload: Data,
    };
};

export const fetchCustomerByIdFailure = (Error) => {
    return {
        type: GET_CUSTOMER_BY_ID_FAILURE,
        payload: Error,
    };
};

export const fetchCustomerById = () => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    const customerId = localStorage.getItem("customerId")
    return (dispatch) => {
        dispatch(fetchCustomerByIdRequest());
        axios
            .get(url + `getCustomerById/${customerId}`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchCustomerByIdSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchCustomerByIdFailure(error));
            });
    };
};
