

import {
    getInvoiceReq,
    getInvoiceSuccess,
   getInvoiceFail 
} from "../../../actions/types";
const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};

export const getInvoice = (state = initialState, action) => {
    switch (action.type) {
        case getInvoiceReq:
            return {
                ...state,
                loading: true,
            };
        case getInvoiceSuccess:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "successfully added"
            };
        case getInvoiceFail:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
            
            
        default:
            return state;
    }
};
