import {
 getInvoiceReq,
 getInvoiceSuccess,
getInvoiceFail 
} from "../../types";




import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";

export const GET_Invoice_REq = () => ({
    type: getInvoiceReq,
  });

export const GET_Invoice_Succees = (data) => {
    return {
        type: getInvoiceSuccess,
        payload:data
    };
};
export const GET_Invoice_RFail = (Data) => {
    return {
        type: getInvoiceFail,
        payload: Data,
    };
};



export const getInVOICEUCCESS = () => {
    const headers = {
        Accept: "application/json",
        'Authorization': basicAuth,
    };
    const id=localStorage.getItem('customerId')
 // Assuming pdfFile is the PDF file object

    return (dispatch) => {
        dispatch(GET_Invoice_REq());
        axios
            .get(url + `getAllDeliveredOrdersByCustomerId/${id}`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(GET_Invoice_Succees(response.data));
            })
            .catch(function (error) {
                dispatch(GET_Invoice_RFail(error));
            });
    };
};


