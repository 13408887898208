import React,{useEffect} from 'react'

function Shipping_policy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div style={{marginTop:'60px'}}>
             <h2>Delivery</h2>
        <ul>
            <li><strong>Shipping and Delivery:</strong> The Company provides shipping and delivery to areas it services, with no charge unless otherwise notified.</li>
            <li><strong>Address Verification:</strong> Shipping addresses and pin codes will be verified for serviceability before payment is processed. If an order cannot be serviced, you may provide an alternative address within the serviceable area.</li>
            <li><strong> Delivery Timeline:</strong> The Company will attempt to deliver Products within 2 to 7 Days . You will be updated via call, SMS, or email about your order status. For inquiries, contact customercare@RENTYFURNITURE.com. Delivery may be delayed due to factors beyond the Company’s control.</li>
            <li><strong>Delivery Attempts:</strong> The Company will make up to two delivery attempts. If delivery cannot be completed after these attempts, the Company reserves the right to cancel the order.</li>
        </ul>
        </div>
    )
}

export default Shipping_policy