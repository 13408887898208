import {
    GET_ALL_MAIN_CATEGORY_REQUEST,
    GET_ALL_MAIN_CATEGORY_SUCCESS,
    GET_ALL_MAIN_CATEGORY_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";
export const fetchAllMainCategoryRequest = () => {
    return {
        type: GET_ALL_MAIN_CATEGORY_REQUEST,
    };
};
export const fetchAllMainCategorySuccess = (Data) => {
    return {
        type: GET_ALL_MAIN_CATEGORY_SUCCESS,
        payload: Data,
    };
};

export const fetchAllMainCategoryFailure = (Error) => {
    return {
        type: GET_ALL_MAIN_CATEGORY_FAILURE,
        payload: Error,
    };
};

export const fetchAllMainCategory = () => {
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchAllMainCategoryRequest);
        axios
            .get(url + `getAllMainCategory`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchAllMainCategorySuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchAllMainCategoryFailure(error));
            });
    };
};
