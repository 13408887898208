
import {
    GET_CENTER_BANNER_REQUEST,
    GET_CENTER_BANNER_SUCCESS,
    GET_CENTER_BANNER_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const fetchCenterBannerRequest = () => {
    return {
        type: GET_CENTER_BANNER_REQUEST,
    };
};
export const fetchCenterBannerSuccess = (Data) => {

    return {
        type: GET_CENTER_BANNER_SUCCESS,
        payload: Data,
    };
};

export const fetchCenterBannerFailure = (Error) => {
    return {
        type: GET_CENTER_BANNER_FAILURE,
        payload: Error,
    };
};

export const fetchCenterBanner = (data) => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchCenterBannerRequest);
        axios
            .get(url + `getAllBanners`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchCenterBannerSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchCenterBannerFailure(error));
            });
    };
};
