import {
    GET_ALL_CITY_REQUEST,
    GET_ALL_CITY_SUCCESS,
    GET_ALL_CITY_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const fetchAllCityRequest = () => {
    return {
        type: GET_ALL_CITY_REQUEST,
    };
};
export const fetchAllCitySuccess = (Data) => {
    return {
        type: GET_ALL_CITY_SUCCESS,
        payload: Data,
    };
};

export const fetchAllCityFailure = (Error) => {
    return {
        type: GET_ALL_CITY_FAILURE,
        payload: Error,
    };
};

export const fetchAllCity = (data) => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
        "ngrok-skip-browser-warning" : "skip-browser-warning",

    };
    return (dispatch) => {
        dispatch(fetchAllCityRequest);
        axios
            .get(url + `getAllCity`, {
                headers: headers,
            })
            .then(function (response) {

                dispatch(fetchAllCitySuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchAllCityFailure(error));
            });
    };
};
