import {
   SET_Duration
} from "../../actions/types";


const initialState = {
    duration: '',
    tenure:'',
    updatedPrice:''
  };
  
  export const  Durationreducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_Duration:
        return {
          ...state,
          duration: action.payload,
          tenure:action.tenure,
          updatedPrice:action.updatedPrice
        };
      default:
        return state;
    }
  };