import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Slider from 'react-slick'
import { ArrowForward } from '@mui/icons-material'
import { fetchProductsHomeByFilter } from '../../actions/products/getProductsByFilter'
import { fetchMostOrderProductsByFilter } from '../../actions/products/getMostOrderproducts'
import outofstock from './outofstock.png'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../style/css/home.css"

const RelatableProduct = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const cityId = localStorage.getItem('cityId')
  const relatableProducts = useSelector((state) => state?.GetRelatableProductsReducer?.data)

  useEffect(() => {
    dispatch(fetchProductsHomeByFilter(cityId))
    dispatch(fetchMostOrderProductsByFilter())
  }, [dispatch, cityId])

  const handleProductDetail = (item) => {
    const formattedProductName = item?.productTitle?.replace(/\s+/g, '-')
    navigate(`/product-details/${formattedProductName}`)
  }

  const mydata = (days) => {
    const currentDate = new Date()
    const futureDate = new Date(currentDate.getTime() + (days * 24 * 60 * 60 * 1000))
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
    return futureDate.toLocaleDateString('en-GB', options)
  }

  const calculateDiscount = (eighteenMonth, perMonth) => {
    const discountPercentage = ((perMonth - eighteenMonth) / perMonth) * 100
    return discountPercentage.toFixed(2)
  }

  const settings = {
    dots: false,
    infinite: relatableProducts?.length >= 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    centerPadding: "20px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  const duplicatedOrderData = Array.isArray(relatableProducts) && relatableProducts?.length >= 4
  ? [...relatableProducts, ...relatableProducts] // Duplicate the array
  : relatableProducts; // Keep original data if length is 4 or less

  return (
    <>
    {
      <style>
        {
          `
          @media(max-width:768px){
          .kk{
          display:none;
          }
          }
          
          `
        }
      </style>
    }
    <div
    className='kk'
    style={{ 
      maxWidth: "1200px", 
      margin: "0 auto", 
      padding: "0 15px"
    }}>
      <h2 style={{ 
        textAlign: 'center', 
        margin: '40px 0', 
        fontSize: '24px',
        fontWeight: '600',
        color: '#333'
      }}>
        {duplicatedOrderData?.length > 0 ? "Our Relatable Products" : ""}
      </h2>

      <div style={{ position: 'relative' }}>
        <Slider {...settings}>
          {Array.isArray(relatableProducts) && relatableProducts
            .filter(item => item.status !== false)
            .map((item) => (
              <div key={item.productId} style={{ padding: '10px' }}>
                <div 
                  style={{
                    background: '#fff',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    cursor: 'pointer',
                    height: '100%',
                    position: 'relative'
                  }}
                  onClick={() => handleProductDetail(item)}
                >
                  {item?.inStockQuantity === 0 && (
                    <img 
                      src={outofstock} 
                      alt="Out of stock"
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        width: '80px',
                        zIndex: 2
                      }}
                    />
                  )}
                  
                  <div style={{ position: 'relative', paddingTop: '100%' }}>
                    <img
                      src={item?.productImagesPath?.[0]}
                      alt={item?.productTitle}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        padding: '20px'
                      }}
                    />
                    {item?.stripText && (
                      <span style={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '10px',
                        background: item?.stripColor || '#ff4444',
                        color: '#fff',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        fontSize: '12px'
                      }}>
                        {item?.stripText}
                      </span>
                    )}
                  </div>

                  <div style={{ padding: '15px' }}>
                    <h3 style={{
                      fontSize: '16px',
                      fontWeight: '500',
                      marginBottom: '10px',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      height: '48px'
                    }}>
                      {item?.productTitle}
                    </h3>

                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px',
                      justifyContent:'space-between'
                    }}>
                      <span style={{
                        background: '#ffebee',
                        color: '#ff4444',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        fontSize: '14px'
                      }}>
                        -{calculateDiscount(item?.twelveMonth, item?.perMonth)}% off
                      </span>
                       <div style={{
                        fontSize: '14px',
                        color: '#666',
                        textDecoration: 'line-through'
                      }}>
                        ₹{item?.perMonth}/mo
                      </div>
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                    <span style={{
                        fontSize: '14px',
                        color: '#666'
                      }}>
                        Get it by {mydata(item.availabeDay)}
                      </span>
                    </div>

                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderTop: '1px solid #eee',
                      paddingTop: '10px'
                    }}>
                        <div style={{ marginBottom: '10px' }}>
                      <div style={{
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#333'
                      }}>
                        ₹{item?.twelveMonth}/Month
                      </div>
                      </div>

                     
                    
                      <ArrowForward style={{ 
                        fontSize: '20px',
                        color: '#666'
                      }} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </div>
    </>
  )
}

export default RelatableProduct