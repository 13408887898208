import {
    GetProductListingRequest,
    GetProductListingRequestSuccess,
    GetProductListingRequesFail
} from "../../actions/types";

const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};
export const GetProductListingredcuer = (state = initialState, action) => {
    switch (action.type) {
        case GetProductListingRequest:
            return {
                ...state,
                loading: true,
            };
        case GetProductListingRequestSuccess:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "successfully fatched"
            };
        case GetProductListingRequesFail:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
        default:
            return state;
    }
};
