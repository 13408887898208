import React, { useState, useEffect } from "react";
import "../../style/css/header.css";
//API Method
import logom from "./logo.png";
import { fetchAllCity } from "../../actions/city/getAllCity";
import {
  fetchProductsByFilter,
  fetchProductsHomeByFilter,
} from "../../actions/products/getProductsByFilter";
//Redux
import { connect } from "react-redux";
//Logo and Icons
import Logo from "../../style/images/logo.png";
import { BiUser } from "react-icons/bi";
import { BiCurrentLocation } from "react-icons/bi";
import { SlLike } from "react-icons/si";
import { BiLike } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { LiaSearchSolid } from "react-icons/lia";
import { FaHome } from "react-icons/fa";
import { BiSolidTimer } from "react-icons/bi";
import { ImProfile } from "react-icons/im";
import { TbFilterMinus } from "react-icons/tb";
//Bottom Bar
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderIcon from "@mui/icons-material/Folder";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SwipeableTemporaryDrawer from "../location/location";
import SelectLocation from "../location/selectLocation";
import { fetchCartProducts, fetchCartProductsBybrowserId } from "../../actions/products/cart/getCartProducts";
//Links
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../../style/css/location.css";
import { fetchProductsBySearch } from "../../actions/products/getProductsBySearch";

import { GetAllPincodeByCityIdAction } from "../../actions/products/getAllPincodeByPincodeId";
// import component 👇
import Drawer from "react-modern-drawer";
import wishlist from "./heart.png";

import { useSelector, useDispatch } from "react-redux";
//import styles 👇
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CallMadeIcon from "@mui/icons-material/CallMade";

import "react-modern-drawer/dist/index.css";
import Cartpng from "../assets/carts (1).png";
import search from "./search.png";
import l66666cation from "./gps.png";

import { HomeMax, HomeMaxSharp } from "@mui/icons-material";
import { basicAuth } from "../../actions/auth/basicAuth";
import axios from "axios";
import { fetchProductById } from "../../actions/products/getProductById";
import { fetchGetWishlist } from "../../actions/products/wishList/getWishList";
function Header(props) {
  const dispatch = useDispatch();
  const pincodeBycityId = useSelector(
    (state) => state?.GetAllPincodeByCityIDREDCUER?.data
  );
  console.log(pincodeBycityId, "pincodeBycityId");

  const cartData = useSelector((state) => state?.getCartProducts?.data);
  const cart = cartData?.cartitemList?.length;
  useEffect(() => {
    dispatch(fetchCartProducts());
  }, [dispatch]);
  const {
    onOpenDrawer,
    fetchAllCityData,
    handleFetchAllCity,
    handleFetchProductsByFilter,
    message,
    ProductFilterData,
    error,
    setCityPincodeData,
    userSelectedPincode,
  } = props;

  const myStyle = {};

  const { data } = useSelector((state) => state.getProductsSearch);
  console.log(data, "filterDatafilterDatafilterData");
  const [filterSuggestion, setFilteruggestionProducts] = useState();
  console.log(filterSuggestion,'filterSuggestionfilterSuggestion')

  const isAuthenticated = localStorage.getItem("login");
  const isMobile = window.innerWidth <= 768;
  const { keyword } = useParams();
  const [value, setValue] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    handleFetchAllCity();
  }, [dispatch]);
  //Location selector
  const [selectedPincode, setSelectedPincode] = useState();
  const [selectedCity, setSelectedCity] = useState({
    cityName: "",
    pincode: "",
  });
  const [pincode, setPinCode] = useState("");
  const cat = localStorage.getItem("cat");

  const [filterData, setFilterData] = useState({
    categoryName: cat,
    subCategoryNames: [],
    minPrice: 0,
    maxPrice: 0,
    keyword: "",
    pincode: "",
  });
  useEffect(()=>{
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
    categoryName:cat
    }));
  },[cat])
  const [newPincode, setNewPincode] = useState();
  const [msgPincode, setPincodemg] = useState();

  const filteredCities = fetchAllCityData;

  // const HandleGetPincodeBycityId = () => {
  //     if (!selectedPincode) {
  //         setPincodemg('Please enter a Valid  pincode');
  //         return;
  //     }
  //     console.log(pincodeBycityId,'pincodeBycityIdpincodeBycityId')

  //     if (pincodeBycityId && selectedPincode) {
  //         const selectedPincodeInt = parseInt(selectedPincode, 10);
  //         const filtered = pincodeBycityId.filter(pin => pin.pinCode === selectedPincodeInt);

  //         if (selectedCity && selectedCity.pincode === selectedPincodeInt) {
  //             // If the searched pincode is already selected
  //             setPincodemg('This Pincode already selected');
  //         } else if (filtered.length === 0) {
  //             // If no matching pincode found
  //             setPincodemg('Pincode is not serviceable');
  //         } else {
  //             // Valid pincode found, reset message and update the data
  //             setPincodemg(''); // Clear the message
  //             toggleDrawer();
  //             const firstMatchedPin = filtered[0]; // Get the first matched pincode
  //             const updatedSelectedCity = {
  //                 cityName: firstMatchedPin.city.cityName,
  //                 pincode: firstMatchedPin.pinCode
  //             };
  //             setSelectedCity(updatedSelectedCity);
  //             setPinCode(firstMatchedPin.pinCode);
  //             setCityPincodeData(updatedSelectedCity);
  //         }
  //     } else {
  //         console.log('Data or selected pincode is missing');
  //     }
  // };

  const HandleGetPincodeBycityId = async (e) => {
    e.preventDefault();
    try {
      if(!selectedPincode){
        return setPincodemg("Please Enter a Valid Pincode");
    }
      const headers = {
        "ngrok-skip-browser-warning": "skip-browser-warning",
        Authorization: basicAuth, // Ensure proper formatting
      };

      const baseUrl = "https://newhousemanager.webbulldesign.com/";
      const response = await axios.get(
        `${baseUrl}getCityByPin/${selectedPincode}`,
        { headers }
      );
      console.log(response, "responseresponseresponseresponse");
      if (response == "Service Not Available  at this pin!") {
        return setPincodemg("Pincode is not serviceable");
      } else {
        const item = {
          cityName: response?.data?.cityName,
          pincode: response?.data?.pincode,
          cityId: response?.data?.cityId,
        };
        handleCItySelect(item);
        setPicodeHeader(selectedPincode);
        localStorage.setItem("cityPincode", selectedPincode);
        setPincodemg("");
      }
    } catch (error) {
      setPincodemg("Pincode is not serviceable");

      console.error(
        "Error creating new ticket:",
        error.response ? error.response.data : error.message
      );
    }
  };

  console.log(filteredCities, "filteredCities");

  const handleSearchProduct = () => {
    if (!filterData.keyword || filterData.keyword.trim() === "") {
      // Show an alert if the keyword is empty
      return; setInputkey(null);

      // Optional: Dispatch an action if needed
      // dispatch(yourActionCreator());
      // Redirect to a different route or do nothing
    }
    handleFetchProductsByFilter(filterData);

    // Proceed with fetching products and navigating
    navigate(`/SearchProduct/${filterData.keyword}`);
    setShowSuggestions(false)
    setInputkey(null);

  };

  // useEffect(() => {
  //     handleFetchProductsByFilter(filterData, keyword)

    
  // }, [filterData,dispatch]);

  useEffect(() => {
    localStorage.setItem("pincode", filterData.pincode);
  }, [filterData.pincode]);

  useEffect(() => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      keyword: keyword ? keyword : "",
      pincode: userSelectedPincode?.pincode || 201301,
    }));
  }, [userSelectedPincode]);

  useEffect(() => {
    const cameFIRST = localStorage.getItem("addddcarrrr");

    if (!cameFIRST) {
      localStorage.setItem("addddcarrrr", "true");
      toggleDrawer();
    }
  }, []);

  const handleCItySelect = async (item) => {
    console.log(item, "itemitem.pincodeitem.pincode");
    
    const updatedSelectedCity = {
        cityName: item.cityName,
        pincode: item.pincode,
    };

    // Dispatch actions
    dispatch(GetAllPincodeByCityIdAction(item?.cityId));
    dispatch(fetchProductsHomeByFilter(item?.cityId));

    // Set local state and local storage
    setSelectedCity(updatedSelectedCity);
    setPinCode(item.pincode);
    localStorage.setItem("cityId", item?.cityId);
    localStorage.setItem("cityPincode", item?.pincode);

    // Update filter data
    setFilterData((prevFilterData) => {
        const newFilterData = {
            ...prevFilterData,
            keyword: keyword || '',
            pincode: item.pincode,
        };

        // Call fetch with the updated filter data and keyword
        handleFetchProductsByFilter(newFilterData, keyword);
        return newFilterData;
    });

    // Toggle the drawer
    toggleDrawer();

    // Set city pincode data
    setCityPincodeData(updatedSelectedCity);
  dispatch(fetchProductById())
  dispatch(fetchCartProducts())
  dispatch(fetchGetWishlist())
  dispatch(fetchCartProductsBybrowserId())


};


  const [inputkey, setInputkey] = useState(null);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchProduct();
    }
    setInputkey(null);
  };


// Function to fetch suggestions
const handleFetchSuggestions = async () => {
  if(!inputkey){
    return setShowSuggestions(false)
  }
  try {
    if (inputkey.length < 3) {
      // Skip API call if input is less than 3 characters
      return;
    }
     setShowSuggestions(true)

    const headers = {
      "ngrok-skip-browser-warning": "skip-browser-warning",
      Authorization: basicAuth, // Ensure proper formatting
    };

    const baseUrl = 'https://newhousemanager.webbulldesign.com/';
    const query = inputkey; // Use the current input as query

    const response = await axios.get(`${baseUrl}search/lookahead`, {
      params: { q: query }, // Send 'q' as a query parameter
      headers: headers, // Optional headers if needed
    });
  setFilteruggestionProducts(response?.data?.data?.autoComplete)
  
    console.log('API Response:', response.data); // Log the response data
  } catch (error) {
    console.error('Error fetching suggestions:', error.response ? error.response.data : error.message);
  }
};
// Debounce function
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
// Debounced version of the fetch function to avoid API calls on every keystroke
const debouncedFetchSuggestions = debounce(handleFetchSuggestions, 300);

// Effect hook to trigger the fetch when inputkey changes
useEffect(() => {
  console.log(inputkey,'inputkeyinputkeyinputkey')
 
  debouncedFetchSuggestions();
}, [inputkey])

   
  const [myPincode, setPicodeHeader] = useState();

  const handlepuincodePres = async (e) => {
    console.log(selectedPincode,'selectedPincodeselectedPincode')
    if (e.key === "Enter") {
      e.preventDefault();
      if(!selectedPincode){
        return setPincodemg("Enter Valid Pincode");
    }
    try {
      const headers = {
        "ngrok-skip-browser-warning": "skip-browser-warning",
        Authorization: basicAuth, // Ensure proper formatting
      };

      const baseUrl = "https://newhousemanager.webbulldesign.com/";
      const response = await axios.get(
        `${baseUrl}getCityByPin/${selectedPincode}`,
        { headers }
      );
      console.log(response, "responseresponseresponseresponse");
      if (response == "Service Not Available  at this pin!") {
        return setPincodemg("Pincode is not serviceable");
      } else {
        const item = {
          cityName: response?.data?.cityName,
          pincode: response?.data?.pincode,
          cityId: response?.data?.cityId,
        };
        handleCItySelect(item);
        setPicodeHeader(selectedPincode);
        localStorage.setItem("cityPincode", selectedPincode);
        setPincodemg("");
      }
    } catch (error) {
      setPincodemg("Pincode is not serviceable");

      console.error(
        "Error creating new ticket:",
        error.response ? error.response.data : error.message
      );
    }
    }
    setInputkey(null);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      keyword: inputValue,
    }));
    setInputkey(inputValue.trim() === "" ? null : inputValue);
  };

  // useEffect(() => {
  //   // Check if inputKey is not null and has at least 3 characters
  //   if (inputkey && inputkey.length >= 3) {
  //     const delayDebounceFn = setTimeout(() => {
  //       dispatch(fetchProductsBySearch(inputkey, filterData?.pincode));
  //     }, 300); // Optional: Add a debounce delay

  //     return () => clearTimeout(delayDebounceFn); // Cleanup on unmount
  //   }
  // }, [inputkey, filterData?.pincode]);

  useEffect(() => {
    if (Array.isArray(data)) {
      const filteredSuggestions = data.filter((item) => {
        const searchTerm = inputkey?.toLowerCase();

        // Check if the item has a `status` of `true` and if any of the category names or product title contains the input key
        return (
          item.status !== false && // Filter out items with status `false`
          (item.productTitle.toLowerCase().includes(searchTerm) ||
            item.mainCategoryName.toLowerCase().includes(searchTerm) ||
            item.subCategoryName.toLowerCase().includes(searchTerm) ||
            item.superCategoryName.toLowerCase().includes(searchTerm))
        );
      });
      // setFilteruggestionProducts(filteredSuggestions);
    } else {
      setFilteruggestionProducts([]);
    }
  }, [inputkey, data]);

  console.log(data, "datataytcfasytcfasytc");

  const handleSuggestProducts = (item) => {
    console.log(item, "itemmm");

    navigate(`/product-details/${item}`);
  };

  const location = useLocation();
  useEffect(() => {
    const links = document.querySelectorAll(".item");
    links.forEach((link) => {
      if (link.pathname === location.pathname) {
        link.classList.add("active");
      } else {
        link.classList.remove("active");
      }
    });
  }, [location]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleInputFocus = () => {
    setShowSuggestions(true);
  };

  const handleInputBlur = () => {
    setShowSuggestions(false);
  };
  const handlenavigate = (e) => {
    e.preventDefault();
    if (isAuthenticated) {
      navigate("/whishlist");
    } else {
      navigate("/login");
    }
  };


  const [cityId, setCityId] = useState(null);

  useEffect(() => {
    // Check if cityId exists in localStorage
    const storedCityId = localStorage.getItem('cityId');

    if (!storedCityId) {
      // If cityId exists in localStorage, use that
// If not, set the default cityId to 1
localStorage.setItem('cityId', '1'); // Default to cityId 1
setCityId(1);    }
 else {
      
    }
  }, []);

  return (
    <>
      <style>
        {`
                    .css-1s0tc6b img {
    position: absolute;
    top: 0px;
    width: 31px;
}


              
                .item.active p{
                    color:black;
                    font-weight:700;
                }
                    .mobileheader{
                    display:none;
                    }

                    @media(max-width:768px){
                  .mobileheader{
                    display:block;
                    }     
                    .desktoheader{
                    display:none;
                    }               
                    }
                `}
      </style>
      {/* mobile header */}
      <div className="mobileheader">
        <div class="MuiBox-root css-clbtza" bis_skin_checked="1">
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Search Products</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="MuiInputBase-root MuiInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth css-1ie9fvn">
                <input
                  placeholder="What are you searching for?"
                  type="text"
                  class="MuiInputBase-input MuiInput-input css-mnn31"
                  value={filterData.keyword}
                  onKeyPress={handleKeyPress}
                  onChange={handleInputChange}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                <img
                  src={search}
                  style={{ width: "25px", height: "25px" }}
                  onClick={handleSearchProduct}
                />
              </Button>
            </Modal.Footer>
          </Modal>

          <div class="MuiBox-root css-11t353r" bis_skin_checked="1">
            <div class="MuiBox-root css-1r9aykm" bis_skin_checked="1">
              <div
                class="MuiBox-root css-cnjuyp"
                style={{ paddingTop: "0px" }}
                bis_skin_checked="1"
              >
                <Link to="/">
                  <img
                    alt=""
                    style={{
                      height: "auto",
                      width: "100%",
                      visibility: "visible",
                    }}
                    data-srcset={logom}
                    sizes=""
                    srcset={logom}
                    class="loaded"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div
            class="MuiBox-root css-i0vy0s"
            id="global-header"
            bis_skin_checked="1"
          >
            <div class="MuiBox-root css-fa5g5n" bis_skin_checked="1">
              <div class="MuiBox-root css-1kz6cvi" bis_skin_checked="1">
                <div
                  style={{
                    alignItems: "center",
                    flexDirection: "row",
                    display: "flex",
                  }}
                  bis_skin_checked="1"
                >
                  <div
                    class="MuiCollapse-root MuiCollapse-horizontal MuiCollapse-entered css-1cijpyl"
                    style={{ minWidth: "0px" }}
                    bis_skin_checked="1"
                  >
                    <div
                      class="MuiCollapse-wrapper MuiCollapse-horizontal css-164swfl"
                      bis_skin_checked="1"
                    >
                      <div
                        class="MuiCollapse-wrapperInner MuiCollapse-horizontal css-1mziwkx"
                        bis_skin_checked="1"
                      >
                        <div
                          class="MuiBox-root css-70qvj9"
                          bis_skin_checked="1"
                          onClick={toggleDrawer}
                        >
                          <div
                            class="MuiBox-root css-1ly73or"
                            bis_skin_checked="1"
                          >
                            <img
                              src={l66666cation}
                              style={{ width: "24px", height: "24px" }}
                            />
                          </div>
                          <div class="MuiBox-root css-0" bis_skin_checked="1">
                            <p class="MuiTypography-root MuiTypography-body1 css-rae83j">
                              Delivery to{" "}
                              {localStorage.getItem("cityPincode") || 201301}
                            </p>
                            <p class="MuiTypography-root MuiTypography-body1 css-12rb58m"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="MuiBox-root css-xflhna" bis_skin_checked="1">
                <div
                  style={{
                    alignItems: "center",
                    flexDirection: "row",
                    display: "flex",
                  }}
                  bis_skin_checked="1"
                >
                  <div
                    class="MuiCollapse-root MuiCollapse-horizontal MuiCollapse-entered css-1cijpyl"
                    style={{ minWidth: "0px" }}
                    bis_skin_checked="1"
                  >
                    <div
                      class="MuiCollapse-wrapper MuiCollapse-horizontal css-164swfl"
                      bis_skin_checked="1"
                    >
                      <div
                        class="MuiCollapse-wrapperInner MuiCollapse-horizontal css-1mziwkx"
                        bis_skin_checked="1"
                      >
                        <div
                          class="MuiBox-root css-1ay9vb9"
                          bis_skin_checked="1"
                        >
                          <Link to="/searchpage">
                            <img
                              src={search}
                              style={{ width: "25px", height: "25px" }}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="MuiCollapse-root MuiCollapse-horizontal MuiCollapse-entered css-1cijpyl"
                    style={{ minWidth: "0px" }}
                    bis_skin_checked="1"
                  >
                    <div
                      class="MuiCollapse-wrapper MuiCollapse-horizontal css-164swfl"
                      bis_skin_checked="1"
                    >
                      <div
                        class="MuiCollapse-wrapperInner MuiCollapse-horizontal css-1mziwkx"
                        bis_skin_checked="1"
                      >
                        <div
                          class="MuiBox-root css-dvot97"
                          bis_skin_checked="1"
                        >
                          <button onClick={handlenavigate}>
                            <img
                              src={wishlist}
                              height={"25px"}
                              width={"25px"}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="MuiCollapse-root MuiCollapse-horizontal MuiCollapse-entered css-1cijpyl"
                    style={{ minWidth: "0px" }}
                    bis_skin_checked="1"
                  >
                    <div
                      class="MuiCollapse-wrapper MuiCollapse-horizontal css-164swfl"
                      bis_skin_checked="1"
                    >
                      <div
                        class="MuiCollapse-wrapperInner MuiCollapse-horizontal css-1mziwkx"
                        bis_skin_checked="1"
                      >
                        <div
                          class="MuiBox-root css-79elbk"
                          bis_skin_checked="1"
                        >
                          <Link to="/cart">
                            <img
                              src={Cartpng}
                              style={{ width: "22px", height: "22px" }}
                            />
                          </Link>
                          <div
                            class="MuiBox-root css-1yjllpn"
                            bis_skin_checked="1"
                          >
                            {cart}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="MuiBox-root css-8atqhb bobobokobk homeheader "
          bis_skin_checked="1"
        >
          <div
            class="MuiBox-root css-na2bnx containerww headerssss "
            bis_skin_checked="1"
          >
            <NavLink
              to="/product-listing"
              style={{
                textDecoration: "none",
                position: "relative",
                top: "7px",
              }}
              className="item"
            >
              <div class="MuiBox-root css-1fs6avd" bis_skin_checked="1">
                <div class="MuiBox-root css-1kjzbsf" bis_skin_checked="1">
                  <BiSolidTimer
                    style={{
                      height: "25px",
                      width: "25px",
                      color: "rgb(245, 99, 88)",
                    }}
                  />
                </div>
                <p
                  class="MuiTypography-root MuiTypography-body1 css-c2nj2v"
                  className="item"
                  style={{ color: "rgb(245, 99, 88)" }}
                >
                  Rents
                </p>
              </div>
            </NavLink>

            <NavLink
              to="/"
              style={{
                textDecoration: "none",
                position: "relative",
                top: "7px",
              }}
              className="item"
            >
              <div class="MuiBox-root css-1fs6avd" bis_skin_checked="1">
                <div class="MuiBox-root css-1kjzbsf" bis_skin_checked="1">
                  {/* <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.58421 10.9554C5.57906 10.9554 5.57417 10.9562 5.56902 10.9562C4.23426 10.948 3.15084 9.86016 3.15084 8.5236V0.175098H0V8.5236C0 10.0958 0.655663 11.5158 1.70508 12.5315C0.655663 13.5474 0 14.9674 0 16.5396V24.175H3.15084V16.5396C3.15084 15.2028 4.23426 14.1153 5.56902 14.1068C5.57417 14.1068 5.57906 14.1076 5.58421 14.1076H19.1878V14.106V10.957V10.9554H5.58421Z" fill="#069BAA"></path><path d="M17.6484 0.175031H7.87036C6.6762 0.175031 6.34399 0.366374 6.34399 1.56053V6.40177C6.34399 7.59592 6.54641 7.79834 7.74056 7.79834H17.5186C18.7127 7.79834 19.1876 7.59592 19.1876 6.40177V1.56053C19.1876 0.366374 18.8425 0.175031 17.6484 0.175031Z" fill="#069BAA">
                                            </path>
                                        </svg> */}
                  <FaHome
                    className="item"
                    style={{
                      height: "20px",
                      width: "20px",
                      color: "rgb(245, 99, 88)",
                    }}
                  />
                </div>
                <p
                  class=""
                  className="item"
                  style={{ color: "rgb(245, 99, 88)" }}
                >
                  Home
                </p>
              </div>
            </NavLink>
            <NavLink
              to="/dashboard/profile"
              style={{
                textDecoration: "none",
                position: "relative",
                top: "7px",
              }}
              className="item"
            >
              <div class="MuiBox-root css-1fs6avd" bis_skin_checked="1">
                <div class="MuiBox-root css-1kjzbsf" bis_skin_checked="1">
                  {/* <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g filter="url(#filter0_d_166_4531)">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M31.9994 25.2563C31.9994 26.0089 31.6906 26.694 31.1939 27.1909L31.1921 27.1924L31.1939 27.1943C30.6969 27.6913 30.0102 28 29.2557 28H10.7437C9.98921 28 9.30256 27.6913 8.80554 27.1943C8.78637 27.1751 8.7685 27.1554 8.75146 27.1349C8.28657 26.6431 8 25.9812 8 25.2563V6.74383C8 5.99107 8.30888 5.30608 8.80554 4.80924L8.8073 4.80767L8.80554 4.80581C9.30256 4.3087 9.98921 4 10.7437 4H29.2557C30.0102 4 30.6969 4.3087 31.1939 4.80581C31.2131 4.82498 31.2309 4.84461 31.248 4.86507C31.7129 5.35691 31.9994 6.01884 31.9994 6.74383V25.2563Z" fill="#999999">
                                                </path>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.2215 23.4237V6.74386C30.2215 6.49451 30.1262 6.26591 29.9711 6.09452L29.9372 6.06248C29.762 5.88712 29.5202 5.77795 29.2557 5.77795H10.7437C10.4792 5.77795 10.2374 5.88712 10.0621 6.06248L10.0603 6.06091C9.88632 6.23572 9.77789 6.47766 9.77789 6.74386V23.4237C10.6028 22.2978 11.6965 21.3204 12.986 20.5509C14.2035 19.8244 15.5969 19.2816 17.1022 18.9729C16.7882 18.7688 16.4963 18.5326 16.2321 18.2685L16.2305 18.2666L16.2287 18.2651C15.2628 17.2982 14.6662 15.9661 14.6662 14.4978C14.6662 13.0293 15.2628 11.6975 16.2287 10.7303L16.2305 10.7288L16.2321 10.7269C17.1989 9.7611 18.5312 9.16454 19.9997 9.16454C21.4682 9.16454 22.8002 9.7611 23.7673 10.7269L23.7689 10.7288L23.7707 10.7303C24.7366 11.6975 25.3332 13.0293 25.3332 14.4978C25.3332 15.9661 24.7366 17.2982 23.7707 18.2651L23.7689 18.2666L23.7673 18.2685C23.503 18.5326 23.2115 18.7688 22.8972 18.9729C24.4027 19.2816 25.7959 19.8244 27.0134 20.5509C28.3029 21.3204 29.3966 22.2978 30.2215 23.4237ZM22.5141 11.9802C21.8734 11.3393 20.9839 10.9425 19.9997 10.9425C19.0155 10.9425 18.1259 11.3393 17.4853 11.9802L17.4819 11.9836C16.841 12.6243 16.4441 13.5135 16.4441 14.4978C16.4441 15.482 16.841 16.3711 17.4819 17.0119L17.4853 17.0153C18.1259 17.6562 19.0155 18.053 19.9997 18.053C20.9839 18.053 21.8734 17.6562 22.5141 17.0153L22.5175 17.0119C23.1584 16.3711 23.5553 15.482 23.5553 14.4978C23.5553 13.5135 23.1584 12.6243 22.5175 11.9836L22.5141 11.9802ZM10.3008 26.1129C10.4338 26.1825 10.5847 26.2221 10.7437 26.2221H29.2557C29.4147 26.2221 29.5656 26.1825 29.6986 26.1129C29.0651 24.487 27.7888 23.0773 26.1037 22.0716C24.4062 21.0587 22.2949 20.4593 19.9997 20.4593C17.7045 20.4593 15.5932 21.0587 13.8957 22.0716C12.2106 23.0773 10.9343 24.487 10.3008 26.1129Z" fill="#FFFFFF">
                                                </path>
                                            </g>
                                            <defs>
                                                <filter id="filter0_d_166_4531" x="0" y="0" width="39.9995" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix">
                                                </feFlood>
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
                                                    <feOffset dy="4"></feOffset>
                                                    <feGaussianBlur stdDeviation="4"></feGaussianBlur>
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0.2 0"></feColorMatrix>
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_166_4531"></feBlend>
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_166_4531" result="shape"></feBlend>
                                                </filter>
                                            </defs>
                                        </svg> */}
                  <BiUser
                    style={{
                      height: "20px",
                      width: "20px",
                      color: "rgb(245, 99, 88)",
                    }}
                  />
                </div>
                <p
                  class="MuiTypography-root MuiTypography-body1 css-c2nj2v"
                  className="item"
                  style={{ color: "rgb(245, 99, 88)" }}
                >
                  Profile
                </p>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      {/* screenn */}
      <div className="header-container desktoheader">
        <noscript>
          <img width={1} height={1} style={{ display: "none" }} src={logom} />
        </noscript>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-5LDD26Q"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
        <div id="__next">
          <div class="MuiBox-root css-1uovz60"></div>
          <div class="MuiBox-root css-0" id="main">
            <div class="MuiBox-root css-0">
              <div class="MuiBox-root css-1uin9l0">
                <div class="MuiBox-root css-12h61o9" id="header-content">
                  <div class="MuiBox-root css-1t5c0b2">
                    <div class="cursor-pointer MuiBox-root css-9e50nb">
                      <div
                        class="MuiBox-root css-1om2adh"
                        style={{ paddingTop: "10.638297872340425%" }}
                      >
                        <Link to="/">
                          <img
                            src={logom}
                            alt=""
                            data-srcset={logom}
                            sizes=""
                            class="lazy loaded"
                            style={{
                              height: "auto",
                              width: "100%",
                              visibility: "visible",
                              position: "relative",
                              top: "-30px",
                            }}
                            srcset={logom}
                          />
                        </Link>
                      </div>
                    </div>
                    <div class="MuiBox-root css-1lirfxe">
                      <div class="MuiBox-root css-15ro776">
                        <div
                          class="hoverable  MuiBox-root css-4lkcnh"
                          icons="[object Object]"
                          id="header-location-pin"
                          onClick={toggleDrawer}
                        >
                          <img
                            src={l66666cation}
                            style={{ width: "24px", height: "24px" }}
                          />
                          <div class="MuiBox-root css-1gdh6jb">
                            <p class="MuiTypography-root MuiTypography-Small_Regular css-xm1owp">
                              Delivery to
                            </p>
                            <p class="MuiTypography-root MuiTypography-P_SemiBold css-4s9voa">
                              {localStorage.getItem("cityPincode") || 201301}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="MuiBox-root css-1eem22k">
                    <div class="MuiBox-root css-120gsus">
                      <div class="MuiBox-root css-ku4lgg">
                        <div class="MuiInputBase-root MuiInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth css-1ie9fvn">
                          <input
                            placeholder="What are you searching for?"
                            type="text"
                            class="MuiInputBase-input MuiInput-input css-mnn31"
                            value={filterData.keyword}
                            onFocus={handleInputFocus} // Show suggestions when input is focused
                            // Hide suggestions when input loses focus

                            onKeyPress={handleKeyPress}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div
                          class="MuiBox-root css-8zy5qf"
                          style={{ position: "relative", top: "-5px" }}
                        >
                          <img
                            src={search}
                            style={{ width: "25px", height: "25px" }}
                            onClick={handleSearchProduct}
                          />
                        </div>
                      </div>
                      {showSuggestions &&
                        filterSuggestion &&
                        filterSuggestion.length > 0 && (
                          <div class="suggestion-box">
                            <ul class="suggestion-list">
                              {filterSuggestion &&
                                filterSuggestion.map((item) => {
                                  if (!item || !item.Label) {
                                    console.error(
                                      "Invalid item structure:",
                                      item
                                    );
                                    return null; // Skip rendering this item
                                  }
                                  return (
                                    <>
                                      <li
                                        key={item.id}
                                        className="d-flex "
                                        style={{ gap: "8px" }}
                                        onClick={() =>
                                          handleSuggestProducts(item?.Label)
                                        }
                                      >
                                        <CallMadeIcon
                                          style={{
                                            color: "black",
                                            fontSize: "20px",
                                          }}
                                          color="rgb(245, 99, 88)"
                                      
                                        />
                                        {item.Label}
                                      </li>
                                    </>
                                  );
                                })}
                            </ul>
                          </div>
                        )}
                    </div>
                    <div class="MuiBox-root css-120gsus">
                      <div class="popover_wrapper MuiBox-root css-0">
                        <div
                          class="popover_content popover_right MuiBox-root css-0"
                          id="mouse-over-popover"
                        >
                          <div class="MuiBox-root css-1bhdxsm">
                            <div class="MuiBox-root css-16vcgef">
                              <p class="MuiTypography-root MuiTypography-body1 css-1awhple">
                                Hello!{" "}
                              </p>
                              <p style={{ color: "black" }}>
                                {localStorage.getItem("customername")}
                              </p>
                              {isAuthenticated ? (
                                <>
                                  <Link to="/dashboard/profile">
                                    <p class="MuiTypography-root MuiTypography-body1 cursor-pointer css-1gndsm0">
                                      Dashboard
                                    </p>
                                  </Link>
                                  <Link to="/dashboard/all_request">
                                    <p class="MuiTypography-root MuiTypography-body1 cursor-pointer css-1gndsm0">
                                      Your Orders
                                    </p>
                                  </Link>
                                </>
                              ) : (
                                <Link to="/login">
                                  <p
                                    class="MuiTypography-root MuiTypography-body1 cursor-pointer css-1gndsm0"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Login
                                  </p>
                                </Link>
                              )}

                              {/* <Link to="/registration">
                                                                    <p
                                                                        class="MuiTypography-root MuiTypography-body1 cursor-pointer css-1gndsm0">
                                                                        Registration</p>
                                                                </Link> */}

                              <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                              <div class="MuiBox-root css-1yuhvjn"></div>
                            </div>
                          </div>
                        </div>
                        <div class="popover_title MuiBox-root css-1la3wyy">
                          <div class="MuiBox-root css-0">
                            <div
                              class="hoverable  MuiBox-root css-0"
                              icons="[object Object]"
                            >
                              <FaUser />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="MuiBox-root css-120gsus">
                      <div
                        class="hoverable move-up-transition MuiBox-root css-0"
                        icons="[object Object]"
                      >
                        {/* <Link to="/whishlist" > */}
                        <button onClick={handlenavigate}>
                          <img src={wishlist} height={"25px"} width={"25px"} />
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                    <div class="MuiBox-root css-0">
                      <div
                        class="MuiCollapse-root MuiCollapse-horizontal MuiCollapse-entered css-1cijpyl"
                        style={{ minWidth: "0px" }}
                        bis_skin_checked="1"
                      >
                        <div
                          class="MuiCollapse-wrapper MuiCollapse-horizontal css-164swfl"
                          bis_skin_checked="1"
                        >
                          <div
                            class="MuiCollapse-wrapperInner MuiCollapse-horizontal css-1mziwkx"
                            bis_skin_checked="1"
                          >
                            <div
                              class="MuiBox-root css-79elbk"
                              bis_skin_checked="1"
                            >
                              <Link to="/cart">
                                <img src={Cartpng} style={{ width: "20px" }} />
                              </Link>
                              <div
                                class="MuiBox-root css-1yjllpn"
                                bis_skin_checked="1"
                              >
                                {cart}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* /  Location Selection  / */}
      {isMobile ? (
        <>
          <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction={"bottom"}
            className="bla bla bla"
            style={{ height: "60vh" }}
          >
            <div>
              <div
                class="MuiPaper-root MuiPaper-elevation MuiPaper-elevation16 MuiDrawer-paper MuiDrawer-paperAnchorBottom css-fy2uxz"
                tabindex="-1"
                bis_skin_checked="1"
                style={{
                  transform: "none",
                  transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                }}
              >
                <div class="MuiBox-root css-wie1fk" bis_skin_checked="1">
                  <div class="MuiBox-root css-163n7ae" bis_skin_checked="1">
                    <div class="MuiBox-root css-khle4t" bis_skin_checked="1">
                      <p class="MuiTypography-root MuiTypography-body1 css-m64942">
                        Select Delivery Location
                      </p>
                      <div class="MuiBox-root css-0" bis_skin_checked="1">
                        <svg
                          width="24"
                          height="24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setIsOpen(false)}
                        >
                          <path
                            d="M18 6 6 18M6 6l12 12"
                            stroke="#555"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <style>
                      {`
                                                .inputagforr{
                                                border:1px solid black !important;
                                                }
                                                ::placeholder{
                                                text-align:center !important;
                                                }
                                                `}
                    </style>
                    <div
                      class="MuiBox-root css-14zwodr"
                      bis_skin_checked="1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <div class="MuiBox-root css-j1akof" bis_skin_checked="1">
                        <div
                          class="MuiFormControl-root MuiTextField-root css-uw3g51"
                          bis_skin_checked="1"
                        >
                          <div
                            class="MuiInputBase-root MuiInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-10iatzg"
                            bis_skin_checked="1"
                          >
                            <input
                              aria-invalid="false"
                              autocomplete="off"
                              id=":r1:"
                              name="mobile"
                              placeholder="Enter your pincode"
                              type="tel"
                              minlength="6"
                              maxlength="6"
                              color="#FFFFFF"
                              className="MuiInputBase-input MuiInput-input css-mnn31 inputagforr"
                              value={selectedPincode}
                              onKeyDown={handlepuincodePres}
                              onChange={(e) =>
                                setSelectedPincode(e.target.value)
                              }
                              style={{
                                borderRadius: "30px",
                                border: "1px solid black",
                                padding: "9px",
                                fontSize: "16px", // Set font size to 16px or larger
                            }}
                        
                            />
                          </div>
                        </div>

                        <div
                          class="MuiBox-root css-bx8o6j"
                          bis_skin_checked="1"
                          role="button"
                          onClick={HandleGetPincodeBycityId}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 12H19"
                              stroke="#069BAA"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M12 5L19 12L12 19"
                              stroke="#069BAA"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <p
                        class="MuiTypography-root MuiTypography-Small_Medium css-z77hf"
                        style={{ color: "red" }}
                      >
                        {msgPincode}
                      </p>
                    </div>
                    <p
                      class="MuiTypography-root MuiTypography-body1 css-1j5np21"
                      style={{ width: "100%" }}
                    >
                      Currently selected pincode :
                      <span class="MuiTypography-root MuiTypography-body1 css-1vbw26">
                        {localStorage.getItem("cityPincode") || 201301}
                      </span>
                    </p>
                    
                    <p class="" style={{margin:"auto"}}>Or select your city</p>
                    <div class="MuiBox-root css-u78fzv p-2" style={{margin:'auto'}} bis_skin_checked="1">
                  {Array.isArray(filteredCities) &&
                  filteredCities.length !== 0 ? (
                    filteredCities.map((item, index) =>
                      // Check if the city status is true
                      item.status ? (
                        <div
                          key={index}
                          className={`cursor-pointer MuiBox-root css-14shhfv ${
                            selectedCity?.cityName === item.cityName
                              ? "selected-city"
                              : ""
                          }`}
                          style={{
                            border:
                              selectedCity?.cityName === item.cityName
                                ? "2px solid #069BAA"
                                : "",
                            boxShadow:
                              selectedCity?.cityName === item.cityName
                                ? "0 0 10px rgba(6, 155, 170, 0.5)"
                                : "",
                            borderRadius:
                              selectedCity?.cityName === item.cityName
                                ? "20px"
                                : "",
                          }}
                          onClick={() => {
                            handleCItySelect(item);
                          }}
                        >
                          <div
                            class="MuiBox-root css-1om2adh"
                            bis_skin_checked="1"
                            style={{ paddingTop: "80%" }}
                          >
                            <img
                              src={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                              alt=""
                              data-srcset={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                              sizes=""
                              class="lazy loaded"
                              style={{
                                height: "auto",
                                width: "100%",
                                visibility: "visible",
                              }}
                              srcset={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                            />
                          </div>
                          <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l pt-3">
                            {item.cityName}
                          </p>
                        </div>
                      ) : null
                    )
                  ) : (
                    <p>There is no city present</p>
                  )}
                </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </>
      ) : (
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction={"left"}
          className="bla bla bla"
          style={{
            width: "",
            height: "100%",
            overflowY: "auto",
            padding: "20px",
          }}
        >
          <div>
            <div
              class="MuiBox-root css-1rp0757"
              id="location-selector"
              bis_skin_checked="1"
            >
              <div class="MuiBox-root css-uzd8m2" bis_skin_checked="1">
                <p class="MuiTypography-root MuiTypography-H10A_Regular css-mqetcj">
                  Select Delivery Location
                </p>
                <div
                  class="cursor-pointer MuiBox-root css-0"
                  bis_skin_checked="1"
                >
                    <svg
                          width="24"
                          height="24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setIsOpen(false)}
                        >
                          <path
                            d="M18 6 6 18M6 6l12 12"
                            stroke="#555"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                </div>
              </div>
              <div class="MuiBox-root css-naaosy" bis_skin_checked="1">
                <form style={{ width: "100%" }}>
                  <style>
                    {`
                                                .inputagforr{
                                                border:1px solid black !important;
                                                }
                                                `}
                  </style>
                  <div
                    class="MuiFormControl-root MuiTextField-root css-113fqis"
                    bis_skin_checked="1"
                  >
                    <div
                      class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd css-a46wc5"
                      bis_skin_checked="1"
                    >
                      <input
                        aria-invalid="false"
                        id=":r2:"
                        placeholder="Enter your pincode"
                        type="tel"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        minlength="5"
                        maxlength="6"
                        class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-1uvydh2 inputagforr"
                        value={selectedPincode}
                        onKeyPress={handlepuincodePres}
                        onChange={(e) => setSelectedPincode(e.target.value)}
                        style={{ borderRadius: "30px", padding: "9px" }}
                      />
                      <div
                        class="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-outlined MuiInputAdornment-sizeMedium css-1nvf7g0"
                        onClick={HandleGetPincodeBycityId}
                        bis_skin_checked="1"
                      >
                        <button
                          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-lia39s"
                          tabindex="0"
                          type="button"
                        >
                          <div class="MuiBox-root css-0" bis_skin_checked="1">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5 12.9453H19"
                                stroke="#069BAA"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="right-arrow-icon"
                              ></path>
                              <path
                                d="M12 5.94531L19 12.9453L12 19.9453"
                                stroke="#069BAA"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="right-arrow-icon"
                              ></path>
                            </svg>
                          </div>
                          <span class="MuiTouchRipple-root css-w0pj6f"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <p
                  class="MuiTypography-root MuiTypography-Small_Medium css-z77hf"
                  style={{ color: "red" }}
                >
                  {msgPincode}
                </p>
                <div class="MuiBox-root css-b8l48z" bis_skin_checked="1">
                  <p class="MuiTypography-root MuiTypography-Small_Medium css-z77hf">
                    Currently selected pincode :{localStorage.getItem("cityPincode") || 201301}

                  </p>
                 
                </div>
              </div>
              <div class="MuiBox-root css-0" bis_skin_checked="1">
                <div class="MuiBox-root css-1pa9coj" bis_skin_checked="1">
                  <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                  <p class="MuiTypography-root MuiTypography-H11A_Medium css-1s17izv">
                    Or select your city
                  </p>
                  <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                </div>
                <div class="MuiBox-root css-u78fzv p-2" bis_skin_checked="1">
                  {Array.isArray(filteredCities) &&
                  filteredCities.length !== 0 ? (
                    filteredCities.map((item, index) =>
                      // Check if the city status is true
                      item.status ? (
                        <div
                          key={index}
                          className={`cursor-pointer MuiBox-root css-14shhfv ${
                            selectedCity?.cityName === item.cityName
                              ? "selected-city"
                              : ""
                          }`}
                          style={{
                            border:
                              selectedCity?.cityName === item.cityName
                                ? "2px solid #069BAA"
                                : "",
                            boxShadow:
                              selectedCity?.cityName === item.cityName
                                ? "0 0 10px rgba(6, 155, 170, 0.5)"
                                : "",
                            borderRadius:
                              selectedCity?.cityName === item.cityName
                                ? "20px"
                                : "",
                          }}
                          onClick={() => {
                            handleCItySelect(item);
                          }}
                        >
                          <div
                            class="MuiBox-root css-1om2adh"
                            bis_skin_checked="1"
                            style={{ paddingTop: "80%" }}
                          >
                            <img
                              src={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                              alt=""
                              data-srcset={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                              sizes=""
                              class="lazy loaded"
                              style={{
                                height: "auto",
                                width: "100%",
                                visibility: "visible",
                              }}
                              srcset={
                                item.cityImagesPhotoPath.startsWith("https")
                                  ? item?.cityImagesPhotoPath
                                  : item?.cityImagesPhoto
                              }
                            />
                          </div>
                          <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l pt-3">
                            {item.cityName}
                          </p>
                        </div>
                      ) : null
                    )
                  ) : (
                    <p>There is no city present</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    fetchAllCityData: state.getAllCity.data,
    message: state.getProductsByFilter.message,
    error: state.getProductsByFilter.error,
    userSelectedPincode: state.setCityPincode.data,
    ProductFilterData: state.getProductsByFilter.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleFetchAllCity: () => dispatch(fetchAllCity()),

    handleFetchProductsByFilter: (data) =>
      dispatch(fetchProductsByFilter(data)),
    setCityPincodeData: (data) =>
      dispatch({ type: "SET_CITY_PINCODE_DATA", payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
