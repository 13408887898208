import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Home from './components/homepage/home';
import PrivacyAndPolicy from './components/privacyAndPolicy/privacyAndPolicy';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import ProductListing from './components/product/productListing';
import TestingPage from './components/testingPage';
import ProductDetail from './components/product/productDetail';
import CartSection from './components/product/cartSection/cartSection';
import Wishlist from './components/product/wishlist/wishlist';
import Main from './components/customer/dashboard/main';
import Privacy_Policy from './components/privacy_policy/Privacy_Policy';
import Login from './components/auth/login';
import Rental_Terms_And_Conditions from './components/rentalTermsAndConditions/Rental_Terms_And_Conditions';
import About_Us from './components/aboutus/about_us';
import Document_Requred from './components/document_required/Document_Requred';
import Cancellation_return from './components/cancellation_return/Cancellation_return';
import FAQ from './components/faq/FAQ';
import Blogs from './components/blogs/Blogs';
import Contact_Us from './components/contact_us/Contact_Us';
import Registration from './components/auth/registration';
import Shipping_policy from './components/shipping_policy/shipping_policy';
import Location from './components/location/location';
import ForMobileTandQ from './components/forMobile/forMobileTandQ';
import PrivateRoute from "./utils/privateRoutes"
import OrderReciept from './components/product/cartSection/orderReciept';
import Addresss from './components/product/cartSection/address';
import { PrimeReactProvider } from 'primereact/api';
import PdfModal from './components/customer/dashboard/pdfmodal';
import ScrollToTop from './scrolltop';

//Dashboard
// searchproducts
import SearchProduct from './components/product/searchproducts';
import ProcessedToPay from './components/product/cartSection/processedtopay';

import MyOrderSummary from './components/product/cartSection/ordersummary'
import NewKyc from './components/product/cartSection/kyc';
import CaptureCurrentURL from './components/auth/captureurl';
import ThankuPage from './components/aboutus/thanksupage';
import KycUnderProcess from './components/aboutus/kycunderprocess';
import RentAgreement from './components/product/cartSection/rentagreement';
import RentAgreementByOrderId from './components/product/cartSection/rentAgreemnetbyorderid';
import Otp from './components/auth/otp';
import OerifyOtp from './components/auth/verifyotpregister';
import TermsAndCondtion from './components/privacyAndPolicy/terms';
import AccountDeletpage from './actions/pages/mynewpage1';
import PrivacyPolicynew from './actions/pages/newprivacypolicy';
import SuppoortSystem from './components/product/cartSection/supportSystem';
import AddTicket from './components/product/cartSection/addticket';
import SearchPageHeader from './components/product/searchPage';
import NewRentAgrement from './components/product/cartSection/newRentagremnet';
import NoInternetPage from './nointernet';


function App() {
  const [isOffline, setIsOffline] = useState(false);
  const navigate=useNavigate()
  const isMobile = window.innerWidth <= 768;
  const [isLoggedIn, setLoggedIn] = useState(true);
  const loginCheck = localStorage.getItem("login")

//   const handleOffline = () => {
//     setIsOffline(true);
//     navigate('/no-internet'); // Redirect to 'No Internet' page
// };

// Function to handle online status
const handleOnline = () => {
    setIsOffline(false);
};

// useEffect(() => {
//     // Check initial network status
//     if (!navigator.onLine) {
//         handleOffline(); // If initially offline, show "No Internet" page
//     }

//     // Add event listeners for network status changes
//     window.addEventListener('offline', handleOffline);
//     window.addEventListener('online', handleOnline);

//     // Cleanup listeners on component unmount
//     return () => {
//         window.removeEventListener('offline', handleOffline);
//         window.removeEventListener('online', handleOnline);
//     };
// }, []);

if (isOffline) {
    return null; // Optionally show a loading or offline message
}


  const Dashboard = () => {
    const isAuthenticated = localStorage.getItem('login');

    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }

    return (
      <>

      <CaptureCurrentURL/>
        <Header />
        <Main />
        <Footer />
      </>
    );
  };


  return (
    <>
    
      {/* <Main /> */}
      <Routes>
        {/* <Route
          path="/dashboard/*"
          element={
            <>
              <a />
              <Main />
              <Footer />
            </>
          }
        /> */}
        <Route path="/no-internet/*" element={<NoInternetPage />} />

        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/termsuse/*" element={<TermsAndCondtion />} />

        <Route path="/Otp/*" element={<Otp />} />
        <Route path="/verifyotp/*" element={<OerifyOtp />} />
        <Route path="/thankuPage/*" element={<ThankuPage />} />
        <Route path="/kycunderProcess/*" element={<KycUnderProcess />} />
        <Route path="/RentAgreement/*" element={<RentAgreement/>} />
        <Route path="/SupportSystem" element={<SuppoortSystem/>} />
        <Route path="/addticket/:id" element={<AddTicket/>} />
        <Route path="/searchpage/*" element={ <SearchPageHeader/>} />
        <Route path="/aggrement/:id" element={ <NewRentAgrement/>} />



        <Route path="/RentAgreement/:id/*" element={<>
      <CaptureCurrentURL/>
          <RentAgreementByOrderId/>
          
          </>} />
        <Route path="/pdfview" element={<PdfModal />} />
        <Route path="/kyc" element={<>
      <CaptureCurrentURL/>

    <Header/>
    
    <NewKyc />
     </>} />



        {/* <Route
          path="/profile"
          element={loginCheck ? (
            <>
              <Header />
              <Main />
              <Footer />
            </>
          ) : (
            <Navigate to="/login" replace={true} />
          )}
        /> */}

        <Route
          exact
          path="/"
          element={
            <>
      <CaptureCurrentURL/>

              <Header />
              <Home />
              <Footer />
            </>
          }
        />
        <Route
          exact
          path="/shipping_policy"
          element={
            <>
      <CaptureCurrentURL/>

              <Header />
              <Shipping_policy />
              <Footer />
            </>
          }
        />


        {isMobile ?
          <Route
          path="/product-listing"
            element={
              <>
      <CaptureCurrentURL/>

                <Header />

                <ProductListing />
                <Footer />
              </>
            }
          />
          : <Route
            path="/product-listing/:keyword?"
            element={
              <>
      <CaptureCurrentURL/>

                <Header />
                <ProductListing />
                <Footer />
              </>
            }
          />
        }
        <Route
          path="/SearchProduct/:keyword?"
          element={
            <>
      <CaptureCurrentURL/>

              <Header />
              <SearchProduct />
              <Footer />
            </>
          }
        />

        <Route
          path="/login"
          element={
            <>
      <CaptureCurrentURL/>

              <Header />
              <Login />
            </>
          }
        />
        <Route
          path="/location"
          element={
            <>

              <Location />
            </>
          }
        />
        {isMobile ?
          <Route
            path="/t-q-screen"
            element={
              <>
      <CaptureCurrentURL/>

                <Header />

                <Header />
                <ForMobileTandQ />
              </>
            }
          />
          : ""}


        <Route
          path="/about-us"
          element={
            <>
      <CaptureCurrentURL/>

              <Header />
              <About_Us />
              <Footer />
            </>
          }
        />
        <Route
          path="/contact-us"
          element={
            <>
              <Header />
              <Contact_Us />
              <Footer />
            </>
          }
        />

        <Route
          path="/Cancellation_return"
          element={
            <>
              <Header />
              <Cancellation_return />
              <Footer />
            </>
          }
        />
        <Route
          path="/FAQ"
          element={
            <>
              <Header />
              <FAQ />
              <Footer />
            </>
          }
        />
        <Route
          path="/registration"
          element={
            <>
              <Header />
              <Registration />
            </>
          }
        />

        <Route
          path="/blogs"
          element={
            <>
              <Header />
              <Blogs />
              <Footer />
            </>
          }
        />
        <Route
          path="/document-required"
          element={
            <>
              <Header />
              <Document_Requred />
              <Footer />
            </>
          }
        />
        <Route
          path="/rental_terms_and_conditions"
          element={
            <>
              <Header />
              <Rental_Terms_And_Conditions />
              <Footer />
            </>
          }
        />
        <Route
          path="/Privacy_Policy"
          element={
            <>
                      <CaptureCurrentURL/>

              <Header />
              <Privacy_Policy />
              <Footer />
            </>}
        />

        <Route
          path="/testing-page"
          element={<TestingPage />}
        />
        <Route
          path="/product-details/:id"
          element={
            <>
              {isMobile ?
                <>
                      <CaptureCurrentURL/>
                  <Header />
                  <ProductDetail />
                  <Footer />
                </>
                :
                <>
      <CaptureCurrentURL/>

                  <Header />
                  <PrimeReactProvider>
                    <ProductDetail />
                    </PrimeReactProvider>
                  <Footer />
                </>
              }
            </>
          }
        />
        <Route
          path="/orderReciept"
          element={
            <>
      <CaptureCurrentURL/>

              <OrderReciept />

            </>
          }
        />



        <Route path="whishlist"
          element={
            <>
              {isMobile ?
                <>
      <CaptureCurrentURL/>

                  <Header />
                  <Wishlist />
                </>
                :
                <>
                  <Header />
                  <Wishlist />
                </>
              }
            </>
          }
        />
        


<Route
          path="/cart"
          element={
            <>
              {isMobile ?
                <>
      <CaptureCurrentURL/>

                  <Header />

                  <CartSection />
                </>
                :
                <>
                      <CaptureCurrentURL/>

                  <Header />
                  <CartSection />
                  {/* <Footer /> */}
                </>
              }
            
            </>
          }

        // element={
        //   <PrivateRoute
        //     element={
        //       <>
        //         {isMobile ? (
        //           <>
        //             <CartSection />
        //           </>
        //         ) : (
        //           <>
        //             <Header />
        //             <CartSection />
        //             {/* <Footer /> */}
        //           </>
        //         )}
        //       </>
        //     }
        //   />
        // }
        />
        {/* <Route render={() => <Navigate to="/" />} /> */}
        {/* <Route
          path="*"
          element={<Navigate to="/" replace />}
        /> */}
        <Route path='/pro' element={<ProcessedToPay/>}></Route>
        <Route path='/Addresss' element={
          <>
                      <CaptureCurrentURL/>
                      <Addresss/>
          
          </>
           }></Route>
        <Route path='/MyOrderSummary' element={<MyOrderSummary/> }></Route>
        <Route path='/accountdeletionpolicy' element={<AccountDeletpage/> }></Route>
        <Route path='/privacy_policies' element={<PrivacyPolicynew/> }></Route>


        
        
      </Routes>
    </>
  );
}

export default App;
