import {
    UpdateProfleReq,
    UpdateProfleSucesss,
    UpdateProflefail,
} from "../../actions/types";

const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};
export const updateUserProfile = (state = initialState, action) => {
    switch (action.type) {
        case UpdateProfleReq:
            return {
                ...state,
                loading: true,
                error: "",
                message: ""
            };
        case UpdateProfleSucesss:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: action.payload
            };
        case UpdateProflefail:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
        default:
            return state;
    }
};
