import {
    GET_ALL_BLOGS_REQUEST,
    GET_ALL_BLOGS_SUCCESS,
    GET_FAQ_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const fetchGetAllBlogsRequest = () => {
    return {
        type: GET_ALL_BLOGS_REQUEST,
    };
};
export const fetchGetAllBlogsSuccess = (Data) => {
    return {
        type: GET_ALL_BLOGS_SUCCESS,
        payload: Data,
    };
};

export const fetchGetAllBlogsFailure = (Error) => {
    return {
        type: GET_FAQ_FAILURE,
        payload: Error,
    };
};

export const fetchGetAllBlogs = (id) => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchGetAllBlogsRequest);
        axios
            .get(url + `getAllBlog`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchGetAllBlogsSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchGetAllBlogsFailure(error));
            });
    };
};
