
import {
    getReieptDataReq,
    getReieptDatasuccess,
    getReieptDataFail,
} from "../../types";




import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";

export const GET_Reciet_REq = () => ({
    type: getReieptDataReq,
  });

export const GET_Reciet_Success = (data) => {
    return {
        type: getReieptDatasuccess,
        payload:data
    };
};
export const GET_Reciet_Fail = (Data) => {
    return {
        type: getReieptDataFail,
        payload: Data,
    };
};



export const getRecieptsUCCESS = () => {
    const headers = {
        Accept: "application/json",
        'Authorization': basicAuth,
    };
    const id=localStorage.getItem('customerId')
 // Assuming pdfFile is the PDF file object

    return (dispatch) => {
        dispatch(GET_Reciet_REq());
        axios
            .get(url + `getAllPaymentsForSuccessfulOrdersByCustomerId/${id}`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(GET_Reciet_Success(response.data));
            })
            .catch(function (error) {
                dispatch(GET_Reciet_Fail(error));
            });
    };
};


