

import {
    getAllCanPlanReq,
    getAllCanPlanSucess,
    getAllCanPlanFail 
   } from "../../types";
   
   


import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";

export const GET_Cancel_REq = () => ({
    type: getAllCanPlanReq,
  });

export const GET_Cancel_Sucxcess = (data) => {
    return {
        type: getAllCanPlanSucess,
        payload:data
    };
};
export const GET_Cancel_Fail = (Data) => {
    return {
        type: getAllCanPlanFail,
        payload: Data,
    };
};


export const GETALLCANCELpLNS = () => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    const id=localStorage.getItem('customerId')
 // Assuming pdfFile is the PDF file object

    return (dispatch) => {
        dispatch(GET_Cancel_REq());
        axios
            .get(url + `getAllNonActiveRentalPlanByCustomerId/${id}`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(GET_Cancel_Sucxcess(response.data));
            })
            .catch(function (error) {
                dispatch(GET_Cancel_Fail(error));
            });
    };
};


