import {
    addPaymentRequest,
    addPaymentSuccess,
    addPaymentFail,
} from "../../actions/types";

const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};
export const AddpaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case addPaymentRequest:
            return {
                ...state,
                loading: true,
            };
        case addPaymentSuccess:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "payment addded"
            };
        case addPaymentFail:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
        default:
            return state;
    }
};