import React, { useState, useEffect } from "react";
//API Methods
import { newRegistrationUser } from "../../actions/auth/registration";
//Validation
import {
  emailValidate,
  stringValidate,
  numberValidate,
  contactNumberValidation,
  postalCodeValidation,
  passwordValidate,
} from "../../utils/validation";
import { connect, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import "../../style/css/registration.scss";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { BiMailSend } from "react-icons/bi";
import { sendLoginOtp } from "../../actions/auth/login";
import { useSelector } from "react-redux";
import { ArrowBack } from "@mui/icons-material";
function Registration(props) {
  const navigate = useNavigate();
  const { handleNewRegistrationUser, message, error } = props;
  const [otp, setOtp] = useState("");
  
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
    panNumber: "",
    aadharNumber: "",
    uploadPan: "",
    uploadAadhar: "",
    referralCode: "",
  });
  useEffect(() => {
    const number = localStorage.getItem("number");
    if (number) {
      setUserData((pre) => ({
        ...pre,
        mobile: number,
      }));
    }
  }, []);
  const [userDataError, setUserDataError] = useState({
    name: null,
    email: null,
    password: null,
    mobile: null,
    panNumber: null,
    aadharNumber: null,
    uploadPan: null,
    uploadAadhar: null,
  });

  const handleValidation = () => {
    setUserDataError({
      ...userDataError,
      name: stringValidate(userData.name, "Name"),
      email: emailValidate(userData.email, "Email"),
      mobile: contactNumberValidation(userData.mobile, "Mobile Number"),
      // uploadPan: stringValidate(userData.uploadPan, "Pan Card Image"),
      // uploadAadhar: stringValidate(userData.uploadAadhar, "Aadhar Card Image")
    });
  };
  const dispatch = useDispatch();
  const handleRegister = () => {
    localStorage.setItem("number", userData.mobile);
    handleValidation();
    if (
      stringValidate(userData.name, "Name") === null &&
      emailValidate(userData.email, "Email") === null &&
      contactNumberValidation(userData.mobile, "Mobile Number") === null
    ) {
      // stringValidate(userData.uploadPan, "Pan Card Image") === null &&
      handleNewRegistrationUser(userData);
    }
  };
  const contactNumberValidation = (mobile, fieldName) => {
    if (!mobile) {
      return `${fieldName} is required`;
    }

    const regex = /^\d{10}$/; // Regex to ensure exactly 10 digits
    if (!regex.test(mobile)) {
      return `${fieldName} must be exactly 10 digits long`;
    }

    return null; // No errors if validation passes
  };

  //Handle Check User Created And Failed
  const [otpget, setetitp] = useState(false);
  const OtpData = localStorage.getItem("Otp");

  useEffect(() => {
    if (localStorage.getItem("message") === "NewCustomerCreatedSuccessfully") {
      Swal.fire({
        title: message,
        text: "User Registered Successfully",
        icon: "success",
      });
      localStorage.removeItem("message");

      navigate("/verifyotp",{ state: userData });
    }
    if (localStorage.getItem("message") === "NewCustomerCreatedFailed") {
      Swal.fire({
        title: message,
        text: "",
        icon: "error",
      });
      localStorage.removeItem("message");
    }
  }, [message, error]);
  const isMobile = window.innerWidth <= 768;
  const handleVerifyOtp = async () => {
    try {
      if (OtpData === otp) {
        await navigatess();
        Swal.fire({
          title: "OTP Verify Success",
          text: "Your OTP Has been successfully verified",
          icon: "success",
        });
      } else {
        Swal.fire({
          title: "OTP Verification Failed",
          text: "Please enter the correct OTP",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error occurred during OTP verification:", error);
      // Handle the error appropriately, such as displaying an error message to the user
    }
  };
  const navigatess = () => {
    navigate("/login");
  };
  const data = useSelector((state) => state.getAllCity.data);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  return (
    <>
      {isMobile ? (
        <>
          <style>
            {`
                       .button{
                        display:none;
                    
                    }
                        @media(max-width:767px){
                        .bobobokobk{
                        display:none;
                        }
                        .mobileheader{
                        display:none;
                        }
                         .button{
                        display:block;
                    
                    }
                        }
                        `}
          </style>
          <div
            className="button"
            style={{
              background: "rgb(245, 99, 88)",
              width: "41px",
              padding: "9px",
              margin: "10px",
              borderRadius: "100%",
            }}
          >
            <Link to="/" style={{ color: "white" }}>
              <ArrowBack color="white"></ArrowBack>
            </Link>
          </div>
          <div className="body_color">
            <div class="box-form_1" style={{ marginTop: "70px" }}>
              <div class="left">
                <div class="overlay">
                  {/* <h1>House Manager</h1> */}
                  <p></p>
                  <span></span>
                </div>
              </div>
              <div class="right">
                <h5
                  style={{
                    boxShadow: "",
                    fontWeight: 1000,
                    marginRight: "",
                    marginTop: "30px",
                    lineHeight: "25px",
                    fontSize: "28px",
                  }}
                >
                  Create An Account
                </h5>
                <form style={{ marginTop: "60px" }}>
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter Name"
                      value={userData.name}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          name: e.target.value,
                        })
                      }
                      onBlur={() =>
                        setUserDataError({
                          ...userDataError,
                          name: stringValidate(userData.name, "Name"),
                        })
                      }
                    />
                    <p
                      className="profile-input-errors"
                      style={{ color: "red" }}
                    >
                      {userDataError.name}
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Enter Mobile Number"
                      value={userData.mobile}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          mobile: e.target.value,
                        })
                      }
                      onBlur={() =>
                        setUserDataError({
                          ...userDataError,
                          mobile: contactNumberValidation(
                            userData.mobile,
                            "Mobile Number"
                          ),
                        })
                      }
                    />
                    <p
                      className="profile-input-errors"
                      style={{ color: "red" }}
                    >
                      {userDataError.mobile}
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Enter Email ID"
                      value={userData.email}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          email: e.target.value,
                        })
                      }
                      onBlur={() =>
                        setUserDataError({
                          ...userDataError,
                          email: stringValidate(userData.email, "Email"),
                        })
                      }
                    />
                    <p
                      className="profile-input-errors"
                      style={{ color: "red" }}
                    >
                      {userDataError.email}
                    </p>
                  </div>

                  <div class="form-group">
                    <select
                      className="border"
                      style={{ border: "1px solid black" }}
                    >
                      <option>Select City</option>

                      {Array.isArray(data) &&
                        data
                          ?.filter((item) => item?.status == true)
                          ?.map((item) => {
                            return (
                              <>
                                <option name="city">{item?.cityName}</option>
                              </>
                            );
                          })}
                    </select>
                  </div>

                  <div class="form-group">
                    <input
                      type="text"
                      value={userData.referralCode}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          referralCode: e.target.value,
                        })
                      }
                      class="form-control"
                      name="referralCode"
                      id="exampleInputPassword1"
                      placeholder="Referral code (if any)"
                    />
                  </div>
                </form>
                {/* <br /> */}
                <br />
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "baseline",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      width: "10%",
                      marginTop: "0px",
                      position: "relative",
                      top: "",
                    }}
                  ></input>
                  <p>
                    By creating an account on Renty Furniture, you agree to our{" "}
                    <Link to="/termsuse"> Terms of Use </Link>.You consent to
                    receive communications from us via WhatsApp, SMS, or phone
                    call, and you accept our cookie policy.
                  </p>
                </div>
                <div
                  class="remember-me--forget-password "
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button onClick={handleRegister}>Send OTP</button>
                </div>
                <br />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {!otpget && (
            <>
              <div className="body_color">
                <div class="box-form_1" style={{ marginTop: "70px" }}>
                  <div class="left">
                    <div class="overlay">
                      <p></p>
                      <span></span>
                    </div>
                  </div>
                  <div class="right">
                    <p
                      style={{
                        boxShadow: "",
                        fontWeight: 1000,
                        marginRight: "100px",
                        marginTop: "30px",
                        fontSize: "30px",
                        color: "black",
                      }}
                    >
                      Create An Account
                    </p>
                    <form style={{ marginTop: "60px" }}>
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter Name"
                          value={userData.name}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              name: e.target.value,
                            })
                          }
                          onBlur={() =>
                            setUserDataError({
                              ...userDataError,
                              name: stringValidate(userData.name, "Name"),
                            })
                          }
                        />
                        <p
                          className="profile-input-errors"
                          style={{ color: "red" }}
                        >
                          {userDataError.name}
                        </p>
                      </div>
                      <div class="form-group">
                        <input
                          type="number"
                          class="form-control"
                          id="exampleInputPassword1"
                          placeholder="Enter Mobile Number"
                          value={userData.mobile}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              mobile: e.target.value,
                            })
                          }
                          onBlur={() =>
                            setUserDataError({
                              ...userDataError,
                              mobile: contactNumberValidation(
                                userData.mobile,
                                "Mobile Number"
                              ),
                            })
                          }
                        />
                        <p
                          className="profile-input-errors"
                          style={{ color: "red" }}
                        >
                          {userDataError.mobile}
                        </p>
                      </div>
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputPassword1"
                          placeholder="Enter Email ID"
                          value={userData.email}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              email: e.target.value,
                            })
                          }
                          onBlur={() =>
                            setUserDataError({
                              ...userDataError,
                              email: stringValidate(userData.email, "Email"),
                            })
                          }
                        />
                        <p
                          className="profile-input-errors"
                          style={{ color: "red" }}
                        >
                          {userDataError.email}
                        </p>
                      </div>
                      <style>
                        {`
                                                        
                                                        .skjb{
                                                        border:1px solid black !important;
                                                        
                                                        }
                                                        .border{
                                                        border:2px solid black !important;
                                                        }
                                                        `}
                      </style>

                      <div class="form-group skjb">
                        <select
                          className="border"
                          style={{ border: "1px solid black" }}
                        >
                          <option>Select City</option>

                          {Array.isArray(data) &&
                            data?.map((item) => {
                              return (
                                <>
                                  <option>{item?.cityName}</option>
                                </>
                              );
                            })}
                        </select>
                      </div>

                      <div class="form-group">
                        <input
                          type="email"
                          value={userData.referralCode}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              referralCode: e.target.value,
                            })
                          }
                          class="form-control"
                          id="exampleInputPassword1"
                          placeholder="Referral code (if any)"
                          // value={userData.email}
                          // onChange={(e) => setUserData({
                          //     ...userData,
                          //     email: e.target.value
                          // })}
                          // onBlur={() =>
                          //     setUserDataError({
                          //         ...userDataError,
                          //         email: stringValidate(
                          //             userData.email,
                          //             "Email"
                          //         ),
                          //     })
                          // }
                        />
                      </div>
                    </form>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <input
                        checked={isCheckboxChecked}
                        onChange={handleCheckboxChange}
                        type="checkbox"
                        style={{
                          width: "10%",
                          position: "relative",
                          top: "-8px",
                        }}
                      ></input>

                      <p style={{ position: "relative", top: "8px" }}>
                        By creating an account on Renty Furniture, you agree to
                        our <Link to="/termsuse"> Terms of Use</Link>. You
                        consent to receive communications from us via WhatsApp,
                        SMS, or phone call, and you accept our cookie policy.
                      </p>
                    </div>
                    {/* <br /> */}
                    <br />
                    <div class="remember-me--forget-password">
                      <p style={{ color: "black" }}>
                        Already Have An Account ?{" "}
                        <Link to="/login" style={{ color: "red" }}>
                          {" "}
                          Login{" "}
                        </Link>
                      </p>
                      <button
                        disabled={!isCheckboxChecked}
                        onClick={handleRegister}
                      >
                        Send OTP
                      </button>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </>
          )}

          {otpget && (
            <>
              <div className="body_color">
                <div class="box-form" style={{ marginTop: "70px" }}>
                  <div class="left">
                    <div class="overlay">
                      <p></p>
                      <span></span>
                    </div>
                  </div>
                  <div class="right">
                    <h5
                      style={{
                        boxShadow: "10px 5px 5px gray",
                        fontWeight: 1000,
                        marginTop: "40px",
                      }}
                    >
                      OTP
                    </h5>
                    <div class="inputs">
                      <br />
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Your OTP Here"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      <br />
                    </div>
                    <br />
                    <div class="remember-me--forget-password"></div>
                    <br />
                    <button onClick={handleVerifyOtp}>Send</button>
                    <p>
                      Don't have an account?{" "}
                      <Link to="/registration">Create Your Account</Link> it
                      takes less than a minute
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    message: state.newUserRegistration.message,
    error: state.newUserRegistration.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleNewRegistrationUser: (userData) =>
      dispatch(newRegistrationUser(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
