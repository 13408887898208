import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../../../style/css/sidebar.scss';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { persistor } from '../../../store/createStore';
import { fetchCartProducts } from '../../../actions/products/cart/getCartProducts';
import { clearProdict } from '../../../actions/products/cart/getCartProducts';
import { Drawer, ButtonToolbar, Button, IconButton, Placeholder } from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import AngleLeftIcon from '@rsuite/icons/legacy/AngleLeft';
import AngleDownIcon from '@rsuite/icons/legacy/AngleDown';
import AngleUpIcon from '@rsuite/icons/legacy/AngleUp';

import 'rsuite/dist/rsuite.min.css';
import { Menu, ThumbDown } from '@mui/icons-material';
import { FaHamburger } from 'react-icons/fa';
import Swal from 'sweetalert2';
function Sidebar() {
  const isMobile = window.innerWidth <= 768;
  const navigate = useNavigate()
  function clearPersistedState() {
    localStorage.removeItem('persist:root'); // Adjust the key according to your persistConfig
  }
  const dispatch = useDispatch()


  const handleLogout = async () => {
    await localStorage.clear();

    dispatch(clearProdict())
    Swal.fire({
      title: 'Logout',
      text: 'Logout Successfully',
      icon: 'success',
  });
    window.location.reload();
  clearPersistedState();

  }

  // Call the function wherever needed to purge persisted state

  const location = useLocation();
  useEffect(() => {
    const links = document.querySelectorAll('.item');
    links.forEach(link => {
      if (link.pathname === location.pathname) {
        link.classList.add('active');
      } else {
        link.classList.remove('active');
      }
    });
  }, [location]);


  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleOpen = key => {
    setOpen(true);
    setPlacement(key);
  };
  return (
    <>
      <style>
        {
          `
          .ooskokosk{
          display:none;}
        .item li{
        display:flex;
        }
        .item.active li{
          color:black;
             display:flex;
          font-weight:500;
          
        }
            .item li{
          border-bottom :2px solid whitesmoke !important;
        }
     

          @media(max-width:768px){
          .ooskokosksss{
          display:none;
          }
               .ooskokosk{
          display:block;}
        .ppppp{
        display:none;
        }
        .container-fluid{
        margin-top:unset !important;
                margin-bottom: 50px !important;

        }
       .rs-drawer-body {
    height: 100%;
    overflow: auto;
    padding: 30px 17px !important;
    /* position: relative; */
}
        `
        }
      </style>

      <aside className="ooskokosksss" style={{ background: 'rgb(245, 99, 88)', borderRadius: '10px', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;', padding: '40px' }}>
        <h1 style={{ fontSize: '40px', fontWeight: '600', color: '#fff',display:'flex',justifyContent:'center' }}>Menu</h1>
        <hr style={{ height: ' 2px', border: 'none', background: '#fff' }}></hr>
        <ul className="list-unstyled" style={{ padding: '10px',display:'flex',flexDirection:'column',alignItems:'' }}>
          <NavLink className='item' to="/dashboard/profile" style={{ textDecoration: 'none' }}>
            <li>My Profile</li>
          </NavLink>
          <NavLink className='item' to="/dashboard/all_request" style={{ textDecoration: 'none' }}>
            <li>All Orders</li>
          </NavLink>
          <NavLink className='item' to="/dashboard/kycverified" style={{ textDecoration: 'none' }}>
            <li>Kyc Verified</li>
          </NavLink>
          <NavLink className='item' to="/dashboard/invoice" style={{ textDecoration: 'none' }}>
            <li>Invoice</li>
          </NavLink>
          <NavLink className='item' to="/dashboard/active_subscription_items" style={{ textDecoration: 'none' }}>
            <li>Active Subscription </li>
          </NavLink>
          <NavLink className='item' to="/dashboard/close_subscriptions_items" style={{ textDecoration: 'none' }}>
            <li>Closed Subscription </li>
          </NavLink>
          <NavLink className='item' to="/dashboard/reciepts" style={{ textDecoration: 'none' }}>
            <li>Receipts</li>
          </NavLink>
          <NavLink style={{ textDecoration: 'none' }} >
            <button onClick={handleLogout}>Logout</button>
          </NavLink>
        </ul>
      </aside>

      <div className='ooskokosk' style={{ background: 'rgb(245, 99, 88)', padding: '10px' }}>
        <ul style={{
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          margin: 0,
          padding: 0,
          gap:'7px',
          listStyle: 'none',
        }}>
          <NavLink className='item' to="/dashboard/profile" style={{ textDecoration: 'none' }}>
            <li style={{ padding: '10px',whiteSpace:'nowrap' }}>My Profile</li>
          </NavLink>
          <NavLink className='item' to="/dashboard/all_request" style={{ textDecoration: 'none' }}>
            <li style={{ padding: '10px',whiteSpace:'nowrap' }}>All Orders</li>
          </NavLink>
          <NavLink className='item' to="/dashboard/kycverified" style={{ textDecoration: 'none' }}>
            <li style={{ padding: '10px' ,whiteSpace:'nowrap'}}>Kyc Verified</li>
          </NavLink>
          <NavLink className='item' to="/dashboard/invoice" style={{ textDecoration: 'none' }}>
            <li style={{ padding: '10px' ,whiteSpace:'nowrap'}}>Invoice</li>
          </NavLink>
          <NavLink className='item' to="/dashboard/active_subscription_items" style={{ textDecoration: 'none' }}>
            <li style={{ padding: '10px' ,whiteSpace:'nowrap'}}>Active Subscription</li>
          </NavLink>
          <NavLink className='item' to="/dashboard/close_subscriptions_items" style={{ textDecoration: 'none' }}>
            <li style={{ padding: '10px' ,whiteSpace:'nowrap'}}>Closed Subscription</li>
          </NavLink>
          <NavLink className='item' to="/dashboard/reciepts" style={{ textDecoration: 'none' }}>
            <li style={{ padding: '10px' ,whiteSpace:'nowrap'}}>Receipts</li>
          </NavLink>
          {/* <NavLink className='item' to="/rental_terms_and_conditions" style={{ textDecoration: 'none' }}>
            <li style={{ padding: '10px',whiteSpace:'nowrap' }}>Rent Agreement </li>
          </NavLink> */}
          <NavLink style={{ textDecoration: 'none',display:'flex',justifyContent:'center'}}>
            <button onClick={handleLogout} style={{ padding: '10px', border: 'none', background: 'transparent', color: 'white', cursor: 'pointer' }}>
              Logout
            </button>
          </NavLink>
        </ul>
      </div>





    </>
  );
}

export default Sidebar;
