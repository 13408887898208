import React, { useEffect,useState } from 'react'
import { fetchCustomerById } from "../../../actions/customer/getCustomerById"
//Redux
import { connect } from "react-redux";
import { UpdateMyprofile } from '../../../actions/auth/upateProfile';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateCartItemsBybrowserIdCustomerIdAction } from '../../../actions/products/cart/updateCartItemBybandCId';
import { Button } from '@mui/material';
import Swal from 'sweetalert2';
function Profile(props) {
    const { getCustomerByIdData, handleFetchCustomerById } = props
    
   
    const [data, setuserData] = useState({
        name: "",
        email: "",
        mobile:""
    })
  

    
    
    const dispatch = useDispatch()

    const handle=(e)=>{
         e.preventDefault()
         Swal.fire({
            title: 'Profile',
            text: 'Profile Updated Successfully',
            icon: 'success',
        });
     dispatch(UpdateMyprofile(data))    

    }
  
    useEffect(() => {
        handleFetchCustomerById()
    }, [])
    useEffect(()=>{
 setuserData({
    ...data,
      name: getCustomerByIdData?.name,
        mobile: getCustomerByIdData?.mobile,
        email:getCustomerByIdData?.email

 })
    },[getCustomerByIdData])
    const customerId=localStorage.getItem('customerId')
    useEffect(()=>{
        dispatch(UpdateCartItemsBybrowserIdCustomerIdAction())
  },[customerId])

  const handleChange=(e)=>{
    const {name,value}=e.target
    setuserData({
        ...data,
        [name]:value
    })

  }
    return (

        <main>
        <style>
            {
                `
                input.form-control {
    border: 1px solid rgb(176, 179, 185) !important;
}
                `
            }
        </style>
            <div className="container mt-2" style={{marginBottom:'40px'}}>
                <div class="col-lg-5 col-md-4 col-sm-6 col-12" bis_skin_checked="1">
                    <h4>My Profile  <hr /></h4>
                    <form method="post" action="#" style={{ marginTop: "12px" }}>
                        <div class="form-group mb-4" bis_skin_checked="1">
                   
                            Name
                            <input
                                type="text"
                                style={{border:' 1px solid #B0B3B9'}}
                                name="name"
                                onChange={handleChange}
                                className="form-control" // Note: Use className instead of class for class attribute in JSX
                                value={data?.name}
                                placeholder="Enter name"
                                required
                            />

                        </div>
                        <div class="form-group mb-4" bis_skin_checked="1">
                            Mobile No.
                            <input
                                style={{border:' 1px solid #B0B3B9'}}
                                onChange={handleChange}

                                type="number"
                                name="mobile"
                                class="form-control"
                                pattern="[6-9]{1}[0-9]{9}" title="Phone number with 6-9 and remaing 9 digit with 0-9"
                                placeholder="Enter mobile number"
                                value={data?.mobile}

                                />
                        </div>
                        <div class="form-group mb-4" bis_skin_checked="1">
                            Email ID
                            <input

                                style={{border:' 1px solid #B0B3B9'}}
                                type="email"
                                name="email"
                                class="form-control"
                                value={data?.email}
                                onChange={handleChange}
                                placeholder="Enter email id"
                                required=""
                            />
                        </div>
                      

                        {/* <div class="text-center" bis_skin_checked="1" style={{marginBottom:'50px' }}>
                            <input type="submit" onClick={handle} name="signup" class="btn btn-custom font-14 py-2 px-4 text-uppercase mt-1" value="Update Profile" style={{ background: "rgb(245, 99, 88)", color: "#fff", float: "left"}} />
                        </div> */}
                    </form>
                </div>
            </div>
        </main>

    )
}
const mapStateToProps = (state, ownProps) => {
    return {
        getCustomerByIdData: state.getCustomerById.data,
        message: state.getCustomerById.message,
        error: state.getCustomerById.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleFetchCustomerById: () => dispatch(fetchCustomerById()),
        // handleFetchProductsByFilter: (data) => dispatch(fetchProductsByFilter(data))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);

// getCustomerById
