import {
    GET_SUB_CATEGORY_DROPDOWN_BY_ID_REQUEST,
    GET_SUB_CATEGORY_DROPDOWN_BY_ID_SUCCESS,
    GET_SUB_CATEGORY_DROPDOWN_BY_ID_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";
export const fetchAllSubCategoryDropdownRequest = () => {
    return {
        type: GET_SUB_CATEGORY_DROPDOWN_BY_ID_REQUEST,
    };
};
export const fetchAllSubCategoryDropdownSuccess = (Data) => {
    return {
        type: GET_SUB_CATEGORY_DROPDOWN_BY_ID_SUCCESS,
        payload: Data,
    };
};
export const fetchAllSubCategoryDropdownFailure = (Error) => {
    return {
        type: GET_SUB_CATEGORY_DROPDOWN_BY_ID_FAILURE,
        payload: Error,
    };
};

export const fetchAllSubCategoryDropdown = (id) => {
    const headers = {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchAllSubCategoryDropdownRequest);
        axios
            .get(url + `getAllSubcategoryByMainCategoryId/${id}`, {
                headers: headers,
            })
            .then(function (response) {
                console.log(response,'responseresponseresponse')
            const fetchedOptions = response.data.map(option => {
                    return { value: option.subCategoryId, label: option.subCategoryName,status:option.status};
                });
                dispatch(fetchAllSubCategoryDropdownSuccess(fetchedOptions));
            })
            .catch(function (error) {
                dispatch(fetchAllSubCategoryDropdownFailure(error));
            });
    };
};
