import React,{useEffect} from 'react'
import "../../style/css/privacyAndPolicy.css"
function TermsAndCondtion() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
      <>
      
      <div class="container">
        <h1>Terms of Use – RENTYFURNITURE</h1>
        
        <p>This website is owned and operated by Day Dream Future Reality Infratech Pvt Ltd (the "Company"), a company duly registered under the Companies Act, 1956, with its registered address at 17018 Pine Tower, Mahagun Mywoods, Greater Noida West, Noida 201301, U.P., India. By using our website, you agree to comply with and be bound by the following terms, conditions, and policies ("Terms of Use"). Please review these Terms of Use carefully. If you do not agree to these terms, you should not use the website, review information, or subscribe to any furniture, soft furnishings, or home décor ("Transaction") from this website.</p>
        
        <p><strong>"We," "Our," "Us," "RENTYFURNITURE," or "Service Provider"</strong> refers to Day Dream Future Reality Infratech Pvt Ltd and its successors and assignees. <strong>"Website" or "Site"</strong> refers to <a href="http://www.RENTYFURNITURE.com">www.RENTYFURNITURE.com</a> and any mobile applications ("App") developed by Day Dream Future Reality Infratech Pvt Ltd. <strong>"You," "Your," or "Yourself"</strong> refers to the Prospect, Customer, or their representative, and any other user accessing the Site.</p>

        <h2>GENERAL</h2>
        <p>By using <a href="http://www.RENTYFURNITURE.com">http://www.RENTYFURNITURE.com</a> (the "Website" or "App"), you agree to the terms and conditions outlined in these Terms of Use. This agreement constitutes the entire and exclusive agreement between you and us, overriding all previous or contemporaneous agreements, representations, warranties, and understandings regarding the Website, its content, and any products or services provided through it.</p>
        
        <p>These Terms of Use may be updated periodically without specific notice to you. The most recent version will be posted on the Website, and you should review these Terms of Use before using the Website each time.</p>
        
        <p>Your use of the Website and its services is governed by these Terms of Use and any applicable policies referenced herein. If you make a transaction on the Website, you will be subject to the relevant policies applicable to that transaction. By using the Website, you are entering into a contract with the Company, and these terms and policies constitute your binding obligations with us.</p>
        
        <p>For the purposes of these Terms of Use, <strong>"You" or "User"</strong> refers to any individual or entity who has agreed to become a subscriber on the Website by providing information via computer systems. While browsing the Website does not require registration, completing the registration process is mandatory to subscribe to our offerings.</p>
        
        <p>When you use the Website or communicate with us through emails or other forms of data, you consent to electronic communication. We may communicate with you via email or other electronic means.</p>
        
        <p>These Terms of Use apply solely to the Website and the App.</p>

        <h2>LEGAL NOTICE</h2>
        <p>All textual, graphical, and other content on this Website, unless otherwise stated by the Company, is the property of the Company. The content, organization, graphics, design, compilation, magnetic translation, digital conversion, and related aspects of the Website are protected by applicable copyrights, trademarks, and other proprietary rights, including but not limited to intellectual property rights. Copying, redistributing, using, or publishing any part of the Website, except as expressly permitted hereunder, is strictly prohibited. Viewing the Website does not grant you ownership rights to any content, document, or other materials.</p>
        
        <p>You may view, copy, print, and use content from the Website solely for your personal, non-commercial use, provided that:</p>
        <ul>
            <li>The content is used for informational purposes only.</li>
            <li>No text, graphics, or other content is modified in any way.</li>
            <li>Graphics are not used, copied, or distributed separately from accompanying text.</li>
            <li>The content is not used in a manner that is offensive or disparages the Company, its products, employees, or management.</li>
        </ul>
        
        <p>Nothing in this statement implies or grants any license or right to use any patent, copyright, trademark, or other intellectual property of the Company or any third party, except as expressly provided herein.</p>
        
        <p>We reserve the right, at our sole discretion, to edit or delete any documents, information, or other content on the Website. Trademarks mentioned on the Website may be unregistered trademarks under common law or registered trademarks of the Company under Indian Law.</p>

        <h2>USE OF WEBSITE</h2>
        <p>You shall not use any "deep-link," "page-scrape," "robot," "spider," or other automatic devices, programs, algorithms, or methodologies, or any similar or equivalent manual processes to access, acquire, copy, or monitor any part of the Website or its Content. Additionally, you shall not reproduce or circumvent the navigational structure or presentation of the Website or any Content to obtain or attempt to obtain any materials, documents, or information through means not intentionally made available through the Website. We reserve the right to block any such activities.</p>
        
        <p>You shall not probe, scan, or test the vulnerability of the Website or any network connected to it, nor breach any security or authentication measures. You may not reverse lookup, trace, or seek to trace any information on other users or visitors of the Website, including accounts not owned by you, to its source. You also agree not to exploit the Website, services, or information to reveal any information, including personal identification or details, other than your own as provided by the Website.</p>
        
        <p>You agree not to disparage or defame the Company, nor encourage others to do so. "Disparage" includes comments or statements made in any medium that could adversely affect the Company's business conduct, plans, prospects, or reputation. You will not publish, post, or release any material, make speeches, give interviews, or make public statements about the Company, its operations, clients, employees, products, or services without prior written consent from the Company.</p>
        
        <p>You may not forge headers or manipulate identifiers to disguise the origin of any message or transmission sent to us through the Website. Impersonation or misrepresentation of any individual or entity is also prohibited.</p>
        
        <p>You may not use the Website or its content for any unlawful purpose or in violation of these Terms of Use, nor solicit or perform any illegal activity or any activity that infringes the Company’s rights.</p>
        
        <p>We partner with Microsoft Clarity and Microsoft Advertising to analyze your interactions with our website through behavioral metrics, heatmaps, and session replays to enhance and market our products/services. We use first and third-party cookies and other tracking technologies to gather data on product/service popularity and online activity. This data is also used for site optimization, fraud detection, and advertising purposes. For more information on how Microsoft collects and uses your data, please refer to the <a href="https://privacy.microsoft.com/en-us">Microsoft Privacy Statement</a>.</p>
        
        <p>Additionally, we use Moengage to understand how you interact with our apps and website to improve our products and advertising. By using our site, you consent to the collection and analysis of this behavioral data by Moengage. For further details, please visit the <a href="https://www.moengage.com/privacy-policy/">Moengage Privacy Policy</a>.</p>

        <h2>SECURITY POLICY</h2>
        <p>At RENTYFURNITURE, we are committed to leveraging the latest technologies and best practices to maintain a highly secure environment for our customers. However, we recognize that no technology is infallible, and therefore, we disclaim any liability for defects or errors in our network and systems. We continuously monitor and assess our technology and design for potential vulnerabilities. If you identify a security issue on our Website or App, please contact us at <a href="mailto:security@RENTYFURNITURE.com">security@RENTYFURNITURE.com</a>. We value your input and will work diligently to address any issues promptly. We also acknowledge and appreciate the contributions of security researchers who help us enhance the security of RENTYFURNITURE.</p>

        <h2>DISCLAIMER AND LIMITS</h2>
        <p>We make reasonable efforts to ensure the accuracy and integrity of the information and materials provided on this Website, but we are not responsible for misprints, outdated information, technical or pricing inaccuracies, typographical errors, or other mistakes that may appear. All information and materials are subject to change without notice. The information and services are provided "as is" and "as available," with all warranties, express or implied, disclaimed. This includes, but is not limited to, disclaiming any implied warranties of merchantability or fitness for a particular purpose. The information and services may contain bugs, errors, or other limitations, and we, along with our affiliated parties, will not be liable for any damages arising from your use of or inability to use this Website or its content. This includes direct, indirect, special, incidental, or consequential damages, such as those related to loss of business or profits.</p>
        
        <p>The exclusion of damages described above is a fundamental element of the agreement between us and you. No advice or information obtained from us through this Website, whether oral or written, will create any warranty, representation, or guarantee not expressly stated in these terms of use. We also disclaim any responsibility or liability for damages caused by viruses contained within any electronic files or documents.</p>
        
        <p>Our maximum liability under any circumstances will be limited to the purchase price you paid for any products, services, or information.</p>
        
        <p>We consider the protection of your privacy a high priority. Your personal information is valuable to us, and we handle it in accordance with the Information Technology Act, 2000, and its Rules. Our Privacy Policy is available at <a href="/Privacy_Policy">https://www.RENTYFURNITURE.com/privacy-policy</a>. If you do not agree with how we process or use your information as outlined in our Privacy Policy, please refrain from using our Website.</p>
        
        <p>The Company may collect personal data when you register with RENTYFURNITURE or browse the Website. This data may be used to build a customer profile and includes:</p>
        <ul>
            <li>Identifiers such as Name, Alias, Postal Address, Username, Password, Email Address, Aadhaar number (UID), Permanent Account Number (PAN)</li>
            <li>Characteristics of protected classifications under law such as age and gender</li>
            <li>Commercial information such as records of personal property</li>
        </ul>
        
        <p>References to any third-party products, services, or offerings by trade name, trademark, or manufacturer do not imply endorsement or recommendation by the Company.</p>

        <h2>PRODUCT DESCRIPTION</h2>
        <p>We do not guarantee that the product descriptions or other content on this Website is accurate, complete, reliable, current, or error-free, and we assume no liability in this regard. RENTYFURNITURE makes no representations or warranties about the accuracy of the images displayed on the Website; these images do not reflect the actual quality of the products. Users should not infer product quality based solely on the images. Please note that prices displayed on our Website or app are subject to change without prior notice. Technical issues, typographical errors, or updates to product information may cause price variations. We reserve the right to adjust prices at any time based on our business needs.</p>
        
        <p>The terms and conditions related to delivery, payment, and other matters will be governed by the Subscription Plan selected by the Customer. Any feedback or data submitted by users, such as ideas, comments, suggestions, or questions about Company products or services or the Website content, will be considered non-confidential. The Company has no obligation regarding such information and may freely reproduce, use, disclose, and distribute it without limitation. Additionally, the Company may use any ideas, concepts, know-how, or techniques contained in such feedback for any purpose, including developing, manufacturing, and marketing new products.</p>
        
        <p>We reserve the right, and you authorize us, to use and assign all information regarding your use of the Website and any information you provide in accordance with our Privacy Policy.</p>

        <h2>PAYMENTS</h2>
        <p>You represent and warrant that, if you are making payments to us:</p>
        <ul>
            <li>Any credit, debit, net banking, check, or other payment information you provide is accurate and complete.</li>
            <li>Charges incurred will be honored by your credit card, debit card, or banking company.</li>
            <li>You will pay the charges at the posted prices, including any applicable taxes. You also agree to comply with our <a href="/rental_terms_and_conditions">PAYMENT AND REFUND POLICY</a>.</li>
        </ul>
        
        <p>RENTYFURNITURE is not responsible for any loss or damage arising directly or indirectly from:</p>
        <ul>
            <li>Lack of authorization for transactions.</li>
            <li>Exceeding the limit agreed upon with your bank.</li>
            <li>Payment issues or declines for any reason.</li>
        </ul>
        
        <p>All payments must be made in Indian Rupees, as acceptable in the Republic of India. We do not facilitate payments in any other currency. By making a payment, you authorize RENTYFURNITURE and/or its service providers to collect, process, facilitate, and remit payments. You understand and agree that the payment facility provided by RENTYFURNITURE is not a banking or financial service but a facilitator offering electronic, automated online payment, cash on delivery, and collection services using existing banking infrastructure and credit card payment gateway networks. RENTYFURNITURE and its service providers are not acting as trustees or fiduciaries with respect to your payments.</p>
        
        <p>Refunds, if applicable, will be issued via a link provided by the Company, which can be redeemed through any payment platform of your choice, as permitted. For electronic payments, refunds will be processed using NEFT, RTGS, or other approved online banking/electronic funds transfer systems, in compliance with our <a href="#payment-refund-policy">PAYMENT AND REFUND POLICY</a>. You may initiate a chargeback request according to the terms of your debit or credit card scheme or bank account. Please note that chargeback requests are processed by your card provider or bank, not by us, and we bear no liability for this process. We reserve the right to review your account and payment history in case of a chargeback request to detect fraud. If we suspect fraudulent activity, we may terminate, block, or suspend your account or subscription immediately.</p>
        
        <p>For your convenience, you may save your credit, debit, ATM, or POS card details for future payments. We use 128-bit encryption technology and PCI-DSS certified environments to ensure the security of your payment information. This information will be used to facilitate future payments only with your permission.</p>
        
        <p>You can choose to authorize automatic payments. By doing so, you irrevocably authorize us to instruct your bank or financial institution to handle such transactions as requested on the site. You agree to be bound by the terms and conditions of your bank or financial institution, with our terms prevailing in case of conflict.</p>
        
        <p>RENTYFURNITURE will not be liable for any damages, claims, or losses resulting from processing or failure to process a payment transaction, or any delay beyond our control. You agree that we are not liable for any unauthorized use of your credit card or other payment methods by third parties in connection with the site. You waive the right to dispute any payment made into your account and will bear all associated costs.</p>

        <h2>No Cost EMI Terms and Conditions</h2>
        <ol>
            <li><strong>Eligibility:</strong> No Cost EMI purchases can only be made using credit or debit cards issued by select banks, through the No Cost EMI payment option on the Payment Page. This method is not available for payments made via net banking or other payment methods introduced by RENTYFURNITURE.</li>
            <li><strong>Availability:</strong> No Cost EMI is applicable only for select tenures determined by RENTYFURNITURE, and is available based on the credit and debit card issuing banks' terms, which may change from time to time.</li>
            <li><strong>Discount and Payment:</strong> Customers opting for the No Cost EMI method will receive an upfront discount equivalent to the interest charged by the bank. This ensures that the total EMI amount equals the total invoice amount of the No Cost EMI eligible products at the time of purchase. For Bank Credit Card payments, the offer applies to subscription plans selected in a single checkout where the total sale price (net of applicable discounts) is Rs. 5000 or more.
                <br/><strong>Example:</strong>
                <ul>
                    <li>Cart Value: INR 40,000</li>
                    <li>No Cost EMI Tenure: 6 Months</li>
                    <li>Rate of Interest: 15% p.a.</li>
                    <li>Upfront Discount: INR 1750</li>
                    <li>Payment by Customer: INR 38,250</li>
                    <li>Invoice Value: INR 38.250 (after discount)</li>
                </ul>
                <strong>Bank Statement Example:</strong>
                <ul>
                    <li>EMI 1: INR 6,666 (Interest: INR 500, Principal: INR 6,166)</li>
                    <li>EMI 2: INR 6,666 (Interest: INR 416, Principal: INR 6,250)</li>
                    <li>EMI 3: INR 6,666 (Interest: INR 334, Principal: INR 6,334)</li>
                    <li>EMI 4: INR 6,666 (Interest: INR 250, Principal: INR 6,418)</li>
                    <li>EMI 5: INR 6,666 (Interest: INR 166, Principal: INR 6,500)</li>
                    <li>EMI 6: INR 6,666 (Interest: INR 84, Principal: INR 6,584)</li>
                    <li>Total: INR 40,000 (Interest: INR 1750, Principal: INR 38,250)</li>
                </ul>
            </li>
            <li><strong>Charges:</strong> RENTYFURNITURE does not charge for the No Cost EMI payment method. However, any additional charges such as GST or other taxes applied to the purchase will be levied by the bank issuing the credit or debit card. The bank's policies on EMI facilities, including any additional charges or cancellation fees, apply.</li>
            <li><strong>Bank Discretion:</strong> The extension of the EMI facility is at the discretion of the issuing bank. RENTYFURNITURE is not liable if the bank refuses to process the transaction.</li>
            <li><strong>Invoice and EMI Terms:</strong> The invoice will reflect the net value of the product purchased. The EMI will proceed according to the plan selected during checkout. Any delay or failure in EMI payments may incur additional interest or penalties by the bank, which will be the customer’s responsibility. RENTYFURNITURE is not liable for any additional charges.</li>
            <li><strong>Processing Fees:</strong> RENTYFURNITURE will not charge a processing fee for EMI options. Banks may impose processing or cancellation fees as per their policies, which are non-refundable even in case of order return or cancellation.</li>
            <li><strong>Cancellation and Refunds:</strong> The No Cost EMI program will continue as is even if cancellations or returns occur. Customers are responsible for managing the No Cost EMI program with their credit card issuer. The program is available only in India and requires the cardholder to be at least 18 years old.</li>
            <li><strong>Order Cancellation:</strong> RENTYFURNITURE reserves the right to cancel orders in cases of fraudulent activity, including duplicate accounts or multiple orders. In such cases, the order will not be reinstated. The decision of RENTYFURNITURE will be final and binding.</li>
            <li><strong>Bank Services:</strong> RENTYFURNITURE does not warrant or represent the services offered by any bank, including the EMI facility. By availing of this offer, customers agree that RENTYFURNITURE is not liable for any issues related to bank services or benefits.</li>
            <li><strong>Flexi-Cancellation Pass:</strong> If a customer purchases a Flexi-Cancellation Pass, they can cancel their subscription during the tenure and receive a refund of the outstanding principal amount post-termination. Any NC-EMI charges incurred after the subscription ends will not be covered by the company. Amount to be refunded will be the principal outstanding at termination or the amount eligible for refund assuming no NC-EMI, minus interest charged after termination. Subscribers should settle the loan with their banker to avoid future interest or other charges.</li>
        </ol>

        <h2>CONTACT INFORMATION</h2>
        <p>If you have any questions regarding these Terms of Use or need to contact us for any reason, please reach out to us at:</p>
        <p>Email: <a href="mailto:support@RENTYFURNITURE.com">support@RENTYFURNITURE.com</a></p>
        <p>Address: 17018 Pine Tower, Mahagun Mywoods, Greater Noida West, Noida 201301, U.P., India</p>
    </div>

      </>
    )
}

export default TermsAndCondtion