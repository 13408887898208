
import {
    REGISTRATION_NEW_USER_REQUEST,
    REGISTRATION_NEW_USER_SUCCESS,
    REGISTRATION_NEW_USER_FAILURE,
} from "../types";
import axios from "axios";
import Swal from "sweetalert2";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const newRegistrationUserRequest = () => {
    return {
        type: REGISTRATION_NEW_USER_REQUEST,
    };
};
export const newRegistrationUserSuccess = (Data) => {

    return {
        type: REGISTRATION_NEW_USER_SUCCESS,
        payload: Data,
    };
};

export const newRegistrationUserFailure = (Error) => {
    return {
        type: REGISTRATION_NEW_USER_FAILURE,
        payload: Error,
    };
};
export const newRegistrationUser = (userData) => {
    localStorage.setItem('registernumber',userData?.mobile)
    console.log(userData)
    const headers = {
        Accept: "Application/json",
        "Content-Type": "multipart/formdata",
        'Authorization': basicAuth,
    };
    const formData = new FormData()
    const data = {
        "name": userData.name,
        "email": userData.email,
        "mobile": userData.mobile,
        "panNumber": userData.panNumber,
        "aadharNumber": userData.aadharNumber,
        "referralCode":userData?.referralCode||null
    }

    const fullData = JSON.stringify(data)
    formData.append("data", fullData)
    formData.append("uploadPan", userData.uploadPan)
    formData.append("uploadAadhar", userData.uploadAadhar)

    return (dispatch) => {
        dispatch(newRegistrationUserRequest());
        axios
            .post(url + `addCustomer`, formData, {
                headers: headers
            })
            .then(function (response) {
                dispatch(newRegistrationUserSuccess(response?.data));
                if(response.data==='Customer mobile no Already Exist!'){
                    return Swal.fire({
                        title: 'Already Exist',
                        text: "Customer mobile no Already Exist!",
                        icon: "error",
                      });
                }else if(response.data==='User Email Already Available!'){
                    return Swal.fire({
                        title: 'Already Exist',
                        text: "User Email Already Available!",
                        icon: "error",
                      });
                }
                console.log(response,'skskcks')
                if(response?.data!=='Referral Code is invalid'){
                    localStorage.setItem("message", "NewCustomerCreatedSuccessfully")
                }else{
                localStorage.setItem("message", "NewCustomerCreatedFailed")
                }
            })
            .catch(function (error) {
                console.log(error,'hiiiii')
                localStorage.setItem("message", "NewCustomerCreatedFailed")
                return Swal.fire({
                    title: 'Account Already Exists',
                    text: "This mobile number or email already exists.",
                    icon: "error",
                  });
                dispatch(newRegistrationUserFailure(error?.response?.data));
            });
    };
};

