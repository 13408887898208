import {
    UpdateAddessByCustimerRequestID,
    UpdateAddessByCustimerSuccessID,
    UpdateAddessByCustimerFailureID,
} from "../../actions/types";


const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};
export const UpdateAddresByCustomerID = (state = initialState, action) => {
    switch (action.type) {
        case UpdateAddessByCustimerRequestID:
            return {
                ...state,
                loading: true,
            };
        case UpdateAddessByCustimerSuccessID:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "successfully updated"
            };
        case UpdateAddessByCustimerFailureID:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
        default:
            return state;
    }
};