
import {
    getAlladressBycustomerIdTRequets,
    getAlladressBycustomerIdTSuccess,
    getAlladressBycustomerIdTFail,
} from "../types";

import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const fetchCustomreAddressByIdRequestFun = () => {
    return {
        type: getAlladressBycustomerIdTRequets,
    };
};
export const fetchCustomreAddressByIdSuccessFun = (Data) => {
    return {
        type: getAlladressBycustomerIdTSuccess,
        payload: Data,
    };
};

export const fetchCustomreAddressByIdFailureFun = (Error) => {
    return {
        type: getAlladressBycustomerIdTFail,
        payload: Error,
    };
};



export const GetallAddeessByCustomerId = () => {
    const headers = {
        "Content-Type": "Application/json",
        'Authorization': basicAuth,
    };
    const cutsomer = localStorage.getItem('customerId')
    return (dispatch) => {
        dispatch(fetchCustomreAddressByIdRequestFun());
        axios
            .get(url + `getAllAddressByCustomerId/${cutsomer}`,
                {
                    headers: headers,
                })
            .then(function (response) {
                localStorage.setItem("message", "getFilterByProductSuccessfully")
                
                dispatch(fetchCustomreAddressByIdSuccessFun(response.data));
            })
            .catch(function (error) {
                localStorage.setItem("message", "getFilterByProductFailed")
                dispatch(fetchCustomreAddressByIdFailureFun(error));
            });
    };
};
