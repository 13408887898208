

import React, { useEffect } from 'react'
import Table from 'react-bootstrap/Table';

import { useDispatch, useSelector } from 'react-redux'
import { getRecieptsUCCESS } from '../../../actions/products/orders/getReciept';
import { Link, useNavigate } from 'react-router-dom';
function Reciepts() {
  const navigate=useNavigate()
  const data = useSelector((state) => state?.getReciptsbSuccefull?.data)
  console.log(data,'datadatadatadatadata')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getRecieptsUCCESS())
  }, [dispatch])

  const handleurl = async (urlss) => {
    console.log(urlss,'uruururuurur')
    localStorage.setItem('url',urlss)
    window.open('/pdfview', '', '');
   
};
const handleAgrement = (orderId, file) => {
  navigate(`/aggrement/${orderId}`, { state: file });
}

  return (
    <div className='' striped bordered hover style={{height:'73vh',overflow:'auto'}}>
      <h1>Receipts</h1>
    <Table>
    <thead>
    <tr>
        <th>Date</th>
        <th>Order Id</th>
        <th>Rent Agreement</th>
        <th>Payable Amount</th>
    </tr>
    <style>
        {`
          th, td {
            text-align: center;
            white-space: nowrap; /* Prevents text from wrapping */
            overflow: hidden; /* Ensures content doesn't overflow */
            text-overflow: ellipsis; /* Adds ellipsis (...) for overflowed text */
          }
          td {
            max-width: 150px; /* Adjust as necessary */
            width: 150px; /* Set a fixed width */
          }
        `}
    </style>
</thead>
<tbody>
    {Array.isArray(data) ? (
        data.map((item, index) => (
            <tr key={index}>
                <td style={{ whiteSpace: 'nowrap' }}>{item.createdDate}</td>
                <td>{item.orderId}</td>
                <td style={{ cursor: 'pointer',color:'#0d6efd' }} onClick={()=>handleAgrement(item.orderId,item?.rentAgreementFilePath)}>
                  <Link  _blank>
                    Agreement
                    </Link>
                </td>
                <td>{item.payableAmount}</td>
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan="4">No data available</td>
        </tr>
    )}
</tbody>

  </Table>
  </div>
  )
}

export default Reciepts