import {
    ADD_TO_CART_PRODUCTS_REQUEST,
    ADD_TO_CART_PRODUCTS_SUCCESS,
    ADD_TO_CART_PRODUCTS_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";
import { fetchCartProducts } from "./getCartProducts"
export const addToCartProductRequest = () => {
    return {
        type: ADD_TO_CART_PRODUCTS_REQUEST,
    };
};
export const addToCartProductSuccess = (Data) => {
    return {
        type: ADD_TO_CART_PRODUCTS_SUCCESS,
        payload: Data,
    };
};

export const addToCartProductFailure = (Error) => {
    return {
        type: ADD_TO_CART_PRODUCTS_FAILURE,
        payload: Error,
    };
};


export const addToCartProduct = (data) => {
    console.log(data,'mycartddat')
    const custumerid = localStorage.getItem("customerId")
    const headers = {
        Accept: "Application/json",
        'Authorization': basicAuth,
    };
    
    localStorage.setItem('browserId',data?.browserId)
    return (dispatch) => {
        dispatch(addToCartProductRequest());
        axios
            .post(url + `addCartItem`, {
                "quantity": 1,
                "duration": data?.duration||1,
                "cartPaymentStatus": false,
                "custumerid": custumerid||null,
                "passProduct": {
                    "productId": data?.productId
                },
                "deleted": false,
                "cartValue":data?.cartValue||1,
                "browserId":data?.browserId||null
            }, {
                headers: headers,
            })
            .then(function (response) {
                localStorage.setItem("message", "addToCartProductsuccuss")
                dispatch(addToCartProductSuccess(response.data));
                dispatch(fetchCartProducts())
            })
            .catch(function (error) {
                localStorage.setItem("message", "addToCartProductFailed")
                dispatch(addToCartProductFailure(error));
            });
    };
};


export const RemoveToCartProduct = (data) => {
    console.log(data,'mycartddat')
    const custumerid = localStorage.getItem("customerId")
    const headers = {
        Accept: "Application/json",
        'Authorization': basicAuth,
    };
    const currentQuantity = data?.quantity;
    const parsedQuantity = parseInt(currentQuantity);
    const validQuantity = isNaN(parsedQuantity) || parsedQuantity < 1 ? 1 : parsedQuantity;
    
    return (dispatch) => {
        dispatch(addToCartProductRequest);
        axios
            .post(url + `addCartItem`, {
                "quantity": -1,
                "duration": data.duration || data.duration === "" || data.duration === null ? 1 : data.duration,
                "cartPaymentStatus": false,
                "custumerid": custumerid,
                "passProduct": {
                    "productId": data?.productId
                },
                "deleted": false
            }, {
                headers: headers,
            })
            .then(function (response) {
                localStorage.setItem("message", "addToCartProductsuccuss")
                dispatch(addToCartProductSuccess(response));
                dispatch(fetchCartProducts())
            })
            .catch(function (error) {
                localStorage.setItem("message", "addToCartProductFailed")
                dispatch(addToCartProductFailure(error));
            });
    };
};
