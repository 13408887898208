
import {
    addPaymentRequest,
    addPaymentSuccess,
    addPaymentFail,
} from "../types"
import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";


export const addPaymentRquest = () => {
    return {
        type: addPaymentRequest,
    };
};
export const addPaymentSuccessfUN = (Data) => {
    return {
        type: addPaymentSuccess,
        payload: Data,
    };
};

export const addPaymentFailfUN = (Error) => {
    return {
        type: addPaymentFail,
        payload: Error,
    };
};


export const AddpaymentAction = (data) => {
    console.log(data,'paymentdata')
    const headers = {
        "Content-Type": "application/json", // Set Content-Type to indicate JSON data
        'Authorization': basicAuth,
    };
    const data2=JSON.stringify(
        {
            "paymentMode":data?.paymentMode ,
            "transactionId":data?.transactionId,
            "paymentType": data?.paymentType,
            "createdDate": data?.createdDate,
            "paymentStatus":data?.paymentStatus,
            "payableAmount":data?.payableAmount

        }
    )
    
    return (dispatch) => {
        dispatch(addPaymentRquest());
        axios
            .post(url + `addPayment`,data2 , {
                headers: headers,
            })
            .then(function (response) {
            localStorage.setItem('PAYMENTid',response?.data?.paymentId)
                dispatch(addPaymentSuccessfUN(response.data));
            })
            .catch(function (error) {
                // localStorage.setItem("message", "searchProductFailed")
                dispatch(addPaymentFailfUN(error));
            });
    };
};




