import {GetRelatableProductsBySubCityIdReq,GetRelatableProductsBySubCityIdSucc,GetRelatableProductsBySubCityIdFail} from "../types";



import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const GetRlatblereq = () => {
    return {
        type: GetRelatableProductsBySubCityIdReq,
    };
};
export const GetRlatbleSucc = (Data) => {
    return {
        type: GetRelatableProductsBySubCityIdSucc,
        payload: Data,
    };
};

export const fail = (Error) => {
    return {
        type: GetRelatableProductsBySubCityIdFail,
        payload: Error,
    };
};


export const getRelatableproducts = (cityId,subId) => {
    const headers = {
        "Content-Type": "Application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(GetRlatblereq());
        axios
            .get(url +`getAllProductBySubCategoryIdAndCityId/${subId}/${cityId}`,{
                headers: headers,
            })
            .then(function (response) {
                localStorage.setItem("message", "getFilterByProductSuccessfully")
                dispatch(GetRlatbleSucc(response.data));
            })
            .catch(function (error) {
                localStorage.setItem("message", "getFilterByProductFailed")
                dispatch(fail(error));
            });
    };
};
