import {
    GET_ALL_ORDERS_BY_CUSTOMER_ID_REQUEST,
    GET_ALL_ORDERS_BY_CUSTOMER_ID_SUCCESS,
    GET_ALL_ORDERS_BY_CUSTOMER_ID_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";
export const fetchAllOrdersByCustomerIdRequest = () => {
    return {
        type: GET_ALL_ORDERS_BY_CUSTOMER_ID_REQUEST,
    };
};
export const fetchAllOrdersByCustomerIdSuccess = (Data) => {
    return {
        type: GET_ALL_ORDERS_BY_CUSTOMER_ID_SUCCESS,
        payload: Data,
    };
};

export const fetchAllOrdersByCustomerIdFailure = (Error) => {
    return {
        type: GET_ALL_ORDERS_BY_CUSTOMER_ID_FAILURE,
        payload: Error,
    };
};
export const fetchAllOrdersByCustomerId = (data) => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    const customerId=localStorage.getItem('customerId')
    return (dispatch) => {
        dispatch(fetchAllOrdersByCustomerIdRequest());
        axios
            .get(url + `getAllOrderByCustomerId/${customerId}`, {
                headers: headers,
            })
            .then(function (response) {

                dispatch(fetchAllOrdersByCustomerIdSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchAllOrdersByCustomerIdFailure(error));
            });
    };
};
