import React from 'react'
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
function SelectLocation(props) {
    const { isOpen, toggleDrawer } = props
    const handleOverlayClick = () => {
        // Close the drawer when the overlay is clicked
        alert("Called")
        toggleDrawer();
    };
    return (
        <div>
            <Drawer open={isOpen} onClose={toggleDrawer} onOverlayClick={handleOverlayClick} direction='left' className='bla bla bla'>
                <div>
                    <div class="MuiBox-root css-1rp0757" id="location-selector" bis_skin_checked="1">
                        <div class="MuiBox-root css-uzd8m2" bis_skin_checked="1"><p class="MuiTypography-root MuiTypography-H10A_Regular css-mqetcj">Select Delivery Location</p>
                            <div class="cursor-pointer MuiBox-root css-0" bis_skin_checked="1"><svg width="24" height="24" viewBox="3 3 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 8 8 24M16 16l8 8M8 8l8 8" stroke="#555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                            </div>
                        </div>
                        <div class="MuiBox-root css-naaosy" bis_skin_checked="1">
                            <form style={{ width: "100%" }}>
                                <div class="MuiFormControl-root MuiTextField-root css-113fqis" bis_skin_checked="1">
                                    <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd css-a46wc5" bis_skin_checked="1">
                                        <input aria-invalid="false" id=":r2:" placeholder="Enter your pincode" type="tel" inputmode="numeric" pattern="[0-9]*" minlength="5" maxlength="6" class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-1uvydh2" value="" />
                                        <div class="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-outlined MuiInputAdornment-sizeMedium css-1nvf7g0" bis_skin_checked="1">
                                            <button class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-lia39s" tabindex="0" type="button">
                                                <div class="MuiBox-root css-0" bis_skin_checked="1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5 12.9453H19" stroke="#069BAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="right-arrow-icon"></path>
                                                    <path d="M12 5.94531L19 12.9453L12 19.9453" stroke="#069BAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="right-arrow-icon"></path>
                                                </svg>
                                                </div>
                                                <span class="MuiTouchRipple-root css-w0pj6f"></span>
                                            </button>
                                        </div>
                                        <fieldset aria-hidden="true" class="MuiOutlinedInput-notchedOutline css-igs3ac">
                                            <legend class="css-ihdtdm"><span class="notranslate">&ZeroWidthSpace;</span>
                                            </legend>
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                            <div class="MuiBox-root css-b8l48z" bis_skin_checked="1">
                                <p class="MuiTypography-root MuiTypography-Small_Medium css-z77hf">Currently selected pincode : </p>
                                <p class="MuiTypography-root MuiTypography-Small_SemiBold css-81x7e">411052</p>
                            </div>
                        </div>
                        <div class="MuiBox-root css-0" bis_skin_checked="1">
                            <div class="MuiBox-root css-1pa9coj" bis_skin_checked="1">
                                <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                                <p class="MuiTypography-root MuiTypography-H11A_Medium css-1s17izv">Or select your city</p>
                                <hr class="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />

                            </div>
                            <div class="MuiBox-root css-u78fzv" bis_skin_checked="1">
                                <div class="cursor-pointer MuiBox-root css-14shhfv" bis_skin_checked="1">
                                    <div class="MuiBox-root css-1om2adh" bis_skin_checked="1" style={{ paddingTop: "80%" }}>
                                        <img src="https://assets.furlenco.com/image/upload/c_scale,e_blur:75,f_auto,q_0.5,w_20/v1/s3-furlenco-images/evolve_2_0/bengaluru-city-image.png" alt="" data-srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/bengaluru-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/bengaluru-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/bengaluru-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/bengaluru-city-image.png 4x" sizes="" class="lazy loaded" style={{ height: "auto", width: "100%", visibility: "visible" }} srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/bengaluru-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/bengaluru-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/bengaluru-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/bengaluru-city-image.png 4x" />
                                    </div>
                                    <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l">Bengaluru</p>
                                </div>
                                <div class="cursor-pointer MuiBox-root css-14shhfv" bis_skin_checked="1">
                                    <div class="MuiBox-root css-1om2adh" bis_skin_checked="1" style={{ paddingTop: "80%" }}>
                                        <img src="https://assets.furlenco.com/image/upload/c_scale,e_blur:75,f_auto,q_0.5,w_20/v1/s3-furlenco-images/evolve_2_0/mumbai-city-image.png" alt="" data-srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/mumbai-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/mumbai-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/mumbai-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/mumbai-city-image.png 4x" sizes="" class="lazy loaded" style={{ height: "auto", width: "100%", visibility: "visible" }} srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/mumbai-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/mumbai-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/mumbai-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/mumbai-city-image.png 4x" />
                                    </div>
                                    <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l">Mumbai</p>
                                </div>
                                <div class="cursor-pointer MuiBox-root css-14shhfv" bis_skin_checked="1">
                                    <div class="MuiBox-root css-1om2adh" bis_skin_checked="1" style={{ paddingTop: "80%" }}>
                                        <img src="https://assets.furlenco.com/image/upload/c_scale,e_blur:75,f_auto,q_0.5,w_20/v1/s3-furlenco-images/evolve_2_0/pune-city-image.png" alt="" data-srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/pune-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/pune-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/pune-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/pune-city-image.png 4x" sizes="" class="lazy loaded" style={{ height: "auto", width: "100%", visibility: "visible" }} srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/pune-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/pune-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/pune-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/pune-city-image.png 4x" />
                                    </div>
                                    <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l">Pune</p>
                                </div>
                                <div class="cursor-pointer MuiBox-root css-14shhfv" bis_skin_checked="1">
                                    <div class="MuiBox-root css-1om2adh" bis_skin_checked="1" style={{ paddingTop: "80%" }}>
                                        <img src="https://assets.furlenco.com/image/upload/c_scale,e_blur:75,f_auto,q_0.5,w_20/v1/s3-furlenco-images/evolve_2_0/delhi-city-image.png" alt="" data-srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/delhi-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/delhi-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/delhi-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/delhi-city-image.png 4x" sizes="" class="lazy loaded" style={{ height: "auto", width: "100%", visibility: "visible" }} srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/delhi-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/delhi-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/delhi-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/delhi-city-image.png 4x" />
                                    </div>
                                    <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l">Delhi</p>
                                </div>
                                <div class="cursor-pointer MuiBox-root css-14shhfv" bis_skin_checked="1">
                                    <div class="MuiBox-root css-1om2adh" bis_skin_checked="1" style={{ paddingTop: "80%" }}>
                                        <img src="https://assets.furlenco.com/image/upload/c_scale,e_blur:75,f_auto,q_0.5,w_20/v1/s3-furlenco-images/evolve_2_0/gurugram-city-image.png" alt="" data-srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/gurugram-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/gurugram-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/gurugram-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/gurugram-city-image.png 4x" sizes="" class="lazy loaded" style={{ height: "auto", width: "100%", visibility: "visible" }} srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/gurugram-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/gurugram-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/gurugram-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/gurugram-city-image.png 4x" />
                                    </div>
                                    <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l">Gurugram</p>
                                </div>
                                <div class="cursor-pointer MuiBox-root css-14shhfv" bis_skin_checked="1">
                                    <div class="MuiBox-root css-1om2adh" bis_skin_checked="1" style={{ paddingTop: "80%" }}>
                                        <img src="https://assets.furlenco.com/image/upload/c_scale,e_blur:75,f_auto,q_0.5,w_20/v1/s3-furlenco-images/evolve_2_0/noida-city-image.png" alt="" data-srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/noida-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/noida-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/noida-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/noida-city-image.png 4x" sizes="" class="lazy loaded" style={{ height: "auto", width: "100%", visibility: "visible" }} srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/noida-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/noida-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/noida-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/noida-city-image.png 4x" />
                                    </div>
                                    <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l">Noida</p>
                                </div>
                                <div class="cursor-pointer MuiBox-root css-14shhfv" bis_skin_checked="1">
                                    <div class="MuiBox-root css-1om2adh" bis_skin_checked="1" style={{ paddingTop: "80%" }}>
                                        <img src="https://assets.furlenco.com/image/upload/c_scale,e_blur:75,f_auto,q_0.5,w_20/v1/s3-furlenco-images/evolve_2_0/nashik-city-image.png" alt="" data-srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/nashik-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/nashik-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/nashik-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/nashik-city-image.png 4x" sizes="" class="lazy" style={{ height: "auto", width: "100%", visibility: "visible" }} srcset="https://assets.furlenco.com/image/upload/dpr_1.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/nashik-city-image.png 1x, https://assets.furlenco.com/image/upload/dpr_2.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/nashik-city-image.png 2x, https://assets.furlenco.com/image/upload/dpr_3.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/nashik-city-image.png 3x, https://assets.furlenco.com/image/upload/dpr_4.0,f_auto,q_auto/v1/s3-furlenco-images/evolve_2_0/nashik-city-image.png 4x" />
                                    </div>
                                    <p class="MuiTypography-root MuiTypography-P_Medium css-1f9bf0l">Nashik</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default SelectLocation