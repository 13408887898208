import React, { useState } from 'react'
import { Drawer, ButtonToolbar, Button, IconButton, Placeholder } from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import AngleLeftIcon from '@rsuite/icons/legacy/AngleLeft';
import AngleDownIcon from '@rsuite/icons/legacy/AngleDown';
import AngleUpIcon from '@rsuite/icons/legacy/AngleUp';
import { useSelector } from 'react-redux';
import 'rsuite/dist/rsuite.min.css';
import { ArrowForward, CloudCircle, LockClock, ThumbDown } from '@mui/icons-material';
import { GETDISCOUNTbyDiscountId, clearDiscountData } from '../../../actions/products/orders/getDiscountById';
import { useDispatch } from 'react-redux';
import discount from '../../assets/discount.png'
import axios from 'axios';
import { basicAuth } from '../../../actions/auth/basicAuth';
import Swal from 'sweetalert2';
const OfferAndDiscount = () => {
    const datattata = useSelector((state) => state?.getCartProducts?.data)
    console.log(datattata?.rentGrandTotal, 'datattatadatattata')
    const coupendatata = useSelector((state) => state?.GetAlldiscountCopenReducer?.data)
    const coupentDiscountPrice = useSelector((state) => state?.GetdiscountByIdReducer?.data)
    console.log(coupentDiscountPrice, 'coupentDiscountPrice')
    const [newcoupen, setCoupen] = useState('')

    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const isMobile = window.innerWidth <= 768;

    const handleOpen = key => {
        setOpen(true);
        setPlacement(key);
    };

    const handleRemoveCoupon = () => {
        localStorage.removeItem('coupen')
        localStorage.removeItem('discountprice')

        dispatch(clearDiscountData())
    }
    const [error,setError]=useState()
    const [consoleError,SetConsoleWERRROR]=useState()


    const ApplyCoupeb = (e) => {
        if(newcoupen==''){
            return setError('Please Enter Valid Coupen Code')
        }
        const coupen = coupendatata?.find(item => item?.couponCode === newcoupen)

        if (coupen?.minimumCartValue > datattata?.rentGrandTotal) {
            return Swal.fire({
                title: 'Coupen Code',
                text: 'You Cant Apply This coupen ',
                icon: 'error',
            }); 
        }
        else {
           


            const customerId = localStorage.getItem('customerId')
            var headers = {
                Accept: "application/json",
                'Authorization': basicAuth,
            };
            var baseUrl = "https://newhousemanager.webbulldesign.com/";

            axios
                .get(`${baseUrl}applyDiscountByUserId/${customerId}/${coupen?.discountId}`, { headers: headers })
                .then(async (response) => {
                    if (response.status === 200) {
                        const rentGrandTotal = Number(datattata.rentGrandTotal);
                        const discountPercentage = Number(coupen.discountPercentage);
            
                        // Validate inputs
                        if (isNaN(rentGrandTotal) || isNaN(discountPercentage)) {
                            return console.error('Invalid data: rentGrandTotal and discountPercentage must be numbers.');
                        }
            
                        if (discountPercentage === 0) {
                            return console.error('Invalid discount percentage: cannot be zero.');
                        }
            
                        // Calculate the percentage value
                        const percentageValue = rentGrandTotal * (discountPercentage/100);
            
            
            
                        // Validate the result
                        if (isNaN(percentageValue) || percentageValue < 0) {
                            return console.error('Invalid calculation result: percentageValue must be a non-negative number.');
                        }
            
                        if (coupen.maxDiscountPrice < percentageValue) {
                            localStorage.setItem('discountprice', coupen.maxDiscountPrice)
                        } else {
                            localStorage.setItem('discountprice', percentageValue)
            
            
                        }
                        localStorage.setItem('coupen', coupen?.couponCode)
                        dispatch(GETDISCOUNTbyDiscountId(coupen?.couponCode))
                        Swal.fire({
                            title: 'Coupon',
                            text: 'Coupon Applied successfully ',
                            icon: 'success',
                        });
                        SetConsoleWERRROR("")
                        setOpen(false)
                        // Handle successful response
                    }
                })
                .catch((error) => {
                    if(error?.response?.data=='Coupon already used.'){
                     return   Swal.fire({
                            title: 'Coupon',
                            text: 'The Coupon has already been used. ',
                            icon: 'error',
                        });
                    }
                    SetConsoleWERRROR('Please Enter Valid Coupon Code')
                  
                });
        }
    }
    const coupenCodeAavailable = localStorage.getItem('coupen')
    // const  = (Coupen) => {

    // }

    const handleCoupen = (coupen) => {
        console.log(coupen, 'sasksks')
        if (coupen.minimumCartValue > datattata?.rentGrandTotal) {
            return   Swal.fire({
                title: 'Coupon Code',
                text: "You can't Apply this Coupon Code",
                icon: 'error',
            });
        }
        else {
           


            const customerId = localStorage.getItem('customerId')
            var headers = {
                Accept: "application/json",
                'Authorization': basicAuth,
            };
            var baseUrl = "https://newhousemanager.webbulldesign.com/";

            axios
                .get(`${baseUrl}applyDiscountByUserId/${customerId}/${coupen?.discountId}`, { headers: headers })
                .then(async (response) => {
                    console.log(response,'slslsl')
                    if(response.data==='Coupon is expired'){
                        return  Swal.fire({
                            title: 'Coupon Expired',
                            text: 'Coupon is Expired Please use valid Coupen ',
                            icon: 'error',
                        }); 
                        
                    }
                    if (response.status === 200) {
                        const rentGrandTotal = Number(datattata.rentGrandTotal);
                        const discountPercentage = Number(coupen.discountPercentage);
            
                        // Validate inputs
                        if (isNaN(rentGrandTotal) || isNaN(discountPercentage)) {
                            return console.error('Invalid data: rentGrandTotal and discountPercentage must be numbers.');
                        }
            
                        if (discountPercentage === 0) {
                            return console.error('Invalid discount percentage: cannot be zero.');
                        }
            
                        // Calculate the percentage value
                        const percentageValue = rentGrandTotal * (discountPercentage/100);
                        console.log(percentageValue,'percentageValuepercentageValuepercentageValue')
            
            
            
                        // Validate the result
                        if (isNaN(percentageValue) || percentageValue < 0) {
                            return console.error('Invalid calculation result: percentageValue must be a non-negative number.');
                        }
            
                        if (coupen.maxDiscountPrice < percentageValue) {
                            localStorage.setItem('discountprice', coupen.maxDiscountPrice)
                        } else {
                            localStorage.setItem('discountprice', percentageValue)
            
                        }
                        localStorage.setItem('coupen', coupen?.couponCode)
                        dispatch(GETDISCOUNTbyDiscountId(coupen?.couponCode))
                        Swal.fire({
                            title: 'Coupon',
                            text: 'Coupon Applied successfully ',
                            icon: 'success',
                        });
                        setOpen(false)
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Coupon ',
                        text: 'The Coupon has already beed used.',
                        icon: 'error',
                    }); 
                });
        }

    };

    return (
        <div>
            <style>
                {
                    `
                    div#responsiveDiv {
    border: 2px solid whitesmoke !important;
    margin-bottom:5px !important;
}
         .MuiTypography-body1 {
    line-height: 28px;
}
            .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium.css-azjc9l{
            display:flex !Important;
            }
#input-offer-code{
border:1px solid black !important;
}
            `
                }
            </style>

            <>
                {
                    isMobile ? (
                        <>
                            <div onClick={() => handleOpen('bottom')}>
                                {
                                    coupenCodeAavailable ? (
                                        <button onClick={handleRemoveCoupon}>Remove <ArrowForward /> </button>
                                    ) : (
                                        <span>
                                            {Array.isArray(coupendatata) && coupendatata?.filter(item => item.couponStatus === true).length} Available 
                                            <svg width="24" style={{ position: 'relative', left: '5px' }} height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="24" height="24" rx="12" fill="red"></rect>
                                                <path d="M8.5 12h7M12 8.5l3.5 3.5-3.5 3.5" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </span>

                                    )
                                }                        </div>
                        </>
                    ) : <div onClick={() => handleOpen('right')}>
                        {
                            coupenCodeAavailable ? (
                                <button onClick={handleRemoveCoupon}>Remove <ArrowForward /> </button>
                            ) : (
                                <span>{Array.isArray(coupendatata) && coupendatata?.filter(item => item.couponStatus === true).length} Available <ArrowForward/>
                                </span>
                            )
                        }                        </div>
                }

            </>

            <Drawer placement={placement} open={open} onClose={() => setOpen(false)}>
                <Drawer.Header style={{position:'static',marginTop:'40px'}}>
                    <Drawer.Title style={{ fontWeight: '700' }}> Offers & Discounts </Drawer.Title>
                    <Drawer.Actions>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>

                    </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body style={{ padding: "40px" }}>
                    <div style={{ width: "80%", margin: "AUTO" }} class="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-outlined MuiInputAdornment-sizeMedium css-azjc9l">
                        <input onChange={(e) => setCoupen(e.target.value)} id="input-offer-code" style={{ width: '80%', border: "1px solid grey", paddingBottom: '10px', border: "1px solid black" }} placeholder="Enter Coupon Code" type="text" class="" />
                        <button style={{ position: 'relative', top: '', left: '4px', backgroundColor: 'red', borderRadius: '6px', color: 'white', padding: '8px', paddingLeft: '10px', paddingRight: '10px' }} onClick={ApplyCoupeb} class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1tzsp8a" tabindex="0" type="button">APPLY</button>
                    </div>
 <p style={{color:'red',display:'flex',justifyContent:'center',marginTop:'10px',fontSize:'15px'}}>{error||consoleError}</p>
                    <fieldset aria-hidden="true" class="MuiOutlinedInput-notchedOutline css-igs3ac">
                        <legend class="css-ihdtdm">
                            <span class="notranslate"></span>
                        </legend></fieldset>
                    {
                        console.log(coupendatata, 'coupendatatacoupendatata')
                    }
                    {
                        Array.isArray(coupendatata) && coupendatata?.filter((item) => item.couponStatus === true).map((item) => {
                            return (
                                <>
                                    <style>
                                        {

                                            `
                                        {
    
                                     #responsiveDiv {
            background: url(https://assets.furlenco.com/s3-furlenco-images/grogu/OfferIcon.svg) no-repeat;
            width: 70%;
            margin: auto;
        }
          div#responsiveDiv {
    border: 3px solid whitesmoke !important;
    margin-bottom: 9px;
}
       
        @media (max-width: 600px) {
            #responsiveDiv {
                width: 100% !Important;
            }

        }
                                        }
                                        `
                                        }
                                    </style>
                                    <div id="responsiveDiv" className='NKKKKK' style={{ background: 'url(https://assets.furlenco.com/s3-furlenco-images/grogu/OfferIcon.svg)', backgroundRepeat: 'no-repeat', margin: "auto" ,width:'88%',border:'1px solid black'}}>
                                        <div class="MuiBox-root css-1n4o34b mt-5" >

                                            <div class="MuiBox-root css-14hsy4n"><p class="MuiTypography-root MuiTypography-body1 css-9mr42z" style={{ fontSize: '12px', display: 'flex', justifyContent: 'center', gap: '4px', color: 'white', fontWeight: '700', marginBottom: '0px', padding: '5px', background: 'rgb(254, 175, 168)', borderRadius: '6px', width: "50%" }}>
                                                <p>Minimum Cost :₹{item?.minimumCartValue}</p>
                                                <path d="M18.0716 4.92941C16.1828 3.04037 13.6714 2 11 2C8.32864 2 5.81724 3.04037 3.92845 4.92941C2.04 6.81809 1 9.32918 1 12C1 14.6708 2.04 17.1819 3.92845 19.0706C5.81724 20.9596 8.32864 22 11 22C13.6714 22 16.1828 20.9596 18.0716 19.0706C19.96 17.1819 21 14.6708 21 12C21 9.32918 19.96 6.81809 18.0716 4.92941Z" fill="#940924"></path>                                                   </p></div>
                                           <div style={{display:'flex',justifyContent:'space-between'}}>
                                            <div style={{ fontSize: '15px' }} class="MuiBox-root css-1s1gfiq">
                                                <img src={discount} style={{ height: '30px', width: '30px' }} />

                                                <div class="MuiBox-root css-58lsyh"><p class="MuiTypography-root MuiTypography-body1 css-elwuio">
                                                    {item.tittle} 
                                                    {/* {item?.discountPercentage}% off */}
                                                  </p>
                                                    <div class="" style={{fontSize:'12px',width:'100%',lineHeight:''}}>
                                                        {item?.description}
                                                    </div>
                                                    <div class="MuiBox-root css-18fapr5">
                                                        Expire Date {item?.expirationDate}
                                                    </div>
                                                    <p class="MuiTypography-root MuiTypography-body1 css-1frsiv7" > <p style={{ fontSize: '10px' }}> Use Coupon code:{item?.couponCode}</p>
                                                    </p></div>
                                               
                                            </div>
                                            <div className='button'>
                                                    <button className='' style={{ background: "red", padding: "10px", borderRadius: "4px", color: "white" }} onClick={() => handleCoupen(item)}>Apply</button>
                                                </div>
</div>

                                        </div>
<style>
                                                        {
                                                            `
            p.MuiTypography-root.MuiTypography-body1.css-1frsiv7 {
    font-size: ;
}
            
            `
                                                        }
                                                    </style>

                                    </div>
                                </>
                            )
                        })
                    }
                </Drawer.Body>
            </Drawer>
        </div>
    );
};

export default OfferAndDiscount