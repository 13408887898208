import {
    Post_Order_Request,
    Post_Order_Success,
    Post_Order_Failure,
    CLEAR_ADD_PRODUCT_ORDER 
} from "../../types";
import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";

export const clearAddProductOrderAction = () => ({
    type: CLEAR_ADD_PRODUCT_ORDER,
  });

export const AddorderRequest = () => {
    return {
        type: Post_Order_Request,
    };
};
export const AddorderSuccess = (Data) => {
    return {
        type: Post_Order_Success,
        payload: Data,
    };
};

export const AddOrderFailure = (Error) => {
    return {
        type: Post_Order_Failure,
        payload: Error,
    };
};

export const AddNewOrder = (data, pdfFile,pdfFileRent) => {
    const customerId = localStorage.getItem('customerId');
    const addressId = localStorage.getItem('addressIsssd');
   const PAYMENTid=localStorage.getItem('PAYMENTid')
   const neworderId=localStorage.getItem('neworderId')
   const coupen=localStorage.getItem('coupen')
   const gstInvoiceNumber=localStorage.getItem('gstInvoiceNumber')

   
    const headers = {
        Accept: "application/json",
        'Authorization': basicAuth,
    };
    const formData = new FormData();
    const data2 = {
        "customerId": customerId,
        ...(addressId ? { selectAddress: { addressId: addressId } } : {}), // Only include selectAddress if addressId exists
        "source": "Online",
        "deliveryDate": data?.createdDate,
        "passPaymentEntity": {
            "paymentId": PAYMENTid,
        },
        "orderId":neworderId,
        "orderComment": "This is a sample order comment",
        "action": true,
        "discountAndOffers": coupen ? { "couponCode": coupen } : null,
        "gstInvoiceNumber":gstInvoiceNumber||''
    };
    const fullData = JSON.stringify(data2);
    formData.append("rentAgreement", pdfFileRent); // Assuming pdfFile is the PDF file object
    formData.append("data", fullData);
    formData.append("file", pdfFile); // Assuming pdfFile is the PDF file object
    return (dispatch) => {
        dispatch(AddorderRequest());
        axios
            .post(url + `addOrder`, formData, {
                headers: headers,
            })
            .then(function (response) {
                localStorage.setItem('orderid',response?.data.order?.orderId)
                dispatch(AddorderSuccess(response));
            })
            .catch(function (error) {
                dispatch(AddOrderFailure(error));
            });
    };
};


