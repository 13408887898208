import {
    UpdateCartItemsBybrowserIdCustomerIdReq,
    UpdateCartItemsBybrowserIdCustomerIdSucesss,
    UpdateCartItemsBybrowserIdCustomerIdFail,
} from "../../types";
import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";
export const UpdateCartItemsBybrowserIdCustomerIdReqFun = () => {
    return {
        type: UpdateCartItemsBybrowserIdCustomerIdReq,
    };
};
export const UpdateCartItemsBybrowserIdCustomerIdSucesssfUN = (Data) => {
    return {
        type: UpdateCartItemsBybrowserIdCustomerIdSucesss,
        payload: Data,
    };
};

export const UpdateCartItemsBybrowserIdCustomerIdFailFUN = (Error) => {
    return {
        type: UpdateCartItemsBybrowserIdCustomerIdFail,
        payload: Error,
    };
};

export const UpdateCartItemsBybrowserIdCustomerIdAction = (data) => {
    const custumerid = localStorage.getItem("customerId")
    const browserID = localStorage.getItem("browserUniqueId")
    const headers = {
        Accept: "Application/json",
        'Authorization': basicAuth,
    };
    localStorage.setItem('browserId',data?.browserId)
    return (dispatch) => {
        dispatch(UpdateCartItemsBybrowserIdCustomerIdReqFun());
        axios
            .put(url + `updateCartItemByBrowser/${browserID}/${custumerid}`, {
            }, {
                headers: headers,
            })
            .then(function (response) {
                console.log(response,'responseresponse')
                dispatch(UpdateCartItemsBybrowserIdCustomerIdSucesssfUN(response.data));
            })
            .catch(function (error) {
                localStorage.setItem("message", "addToCartProductFailed")
                dispatch(UpdateCartItemsBybrowserIdCustomerIdFailFUN(error));
            });
    };
};
