import {
    GET_Discount_PRODUCTBY_REQUEST,
    GET_Discount_PRODUCTBY_SUCCESS,
    GET_Discount_PRODUCTBY_FAILURE,
} from "../../actions/types";



const initialState = {
    loading: true,
    data: [],
    error: "",
    message: ""
};
export const getProductsbyDiscountIDhReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_Discount_PRODUCTBY_REQUEST:
            return {
                ...state,
                loading: true,
                error: "",
                message: ""
            };
        case GET_Discount_PRODUCTBY_SUCCESS:
            return{
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: action.payload
            };
        case GET_Discount_PRODUCTBY_FAILURE:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: action.payload
            };
        default:
            return state;
    }
};
