import React, { useEffect, useRef, useState } from 'react';
import { TbH3 } from 'react-icons/tb'
import html2pdf from 'html2pdf.js';

import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCartProducts } from '../../../actions/products/cart/getCartProducts';
import { fetchCustomerById } from '../../../actions/customer/getCustomerById';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { url } from '../../../actions/auth/baseUrl';
import { basicAuth } from '../../../actions/auth/basicAuth';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { GETADDRSSSBYADDRESSID } from '../../../actions/products/getAdressByAdressID';

const NewRentAgrement = () => {
  const adddresByAdresssId = useSelector((state) => state?.GETADDRESSBYADDRESSID?.data)
  console.log(adddresByAdresssId,'adddresByAdresssId')

 const navigate=useNavigate()
 const location = useLocation();
 const file = location.state; // Access the passed state
 const contentRef = useRef();

  const handleDownload = () => {
    const element = contentRef.current;

    const options = {
      margin: 1,
      filename: 'document.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    // Convert to PDF
    html2pdf()
      .from(element)
      .set(options)
      .save();
  };
  const userId=localStorage.getItem('customerId')
  useEffect(()=>{
 if(!userId){
  navigate('/login')
 }
  },[])
  const getTenureFromDuration = (duration) => {
    switch (duration) {
      case 1:
        return "1 Month";
      case 3:
        return "3 Month";
      case 6:
        return "6 Month";
      case 9:
        return "9 Month";
      case 12:
        return "12 Month";
      case 18:
        return "18 Month";
      default:
        return null;
    }
  }
  const extractNumberFromString = (str) => {
    const match = str.match(/\d+/); // Match one or more digits
    return match ? parseInt(match[0], 10) : 0; // Convert matched string to integer, default to 0 if no match
  };
  
  const addrentcost = (data) => {
    if (!data || !data.priceBreakUp || !Array.isArray(data.priceBreakUp)) {
      console.log('Invalid data or data.priceBreakUp is not an array');
      return;
    }

    // Calculate total rental cost
    let total = data.priceBreakUp?.reduce((acc, item) => {
      // Ensure item.baserentalCost and item.selectedTenure exist
      let rentalCost = parseFloat(item.baserentalCost);
      let selectedTenure = parseFloat(item.selectedTenure); // Assuming selectedTenure is a numeric value (like 12)

      if (!isNaN(rentalCost) && !isNaN(selectedTenure)) {
        let multipliedCost = rentalCost * selectedTenure;
        return acc + multipliedCost;
      } else {
        return acc;
      }
    }, 0);

    return total;
  };
  
  const addSecurityAmount = (data) => {
    if (!data || !data.priceBreakUp || !Array.isArray(data.priceBreakUp)) {
      console.log('Invalid data or data.priceBreakUp is not an array');
      return;
    }

    // Calculate total security amount
    let total = data?.priceBreakUp?.reduce((acc, item) => {
      // Ensure item.securityAmount exists and is numeric before adding
      let amount = parseFloat(item.securityAmount);
      if (!isNaN(amount)) {
        return acc + amount;
      } else {
        return acc;
      }
    }, 0);

    // Log the total security amount
    return total
  };

  const [orderId, setOrderData] = useState()
  useEffect(() => {
    const orderId = localStorage.getItem('neworderId')
    setOrderData(orderId)
  }, [orderId])


  const pdfRef = useRef(null);
  const OrderData = useSelector((state) => state?.getCartProducts?.data)
  console.log(OrderData,'OrderDataOrderData')
  const CustomerData = useSelector((state) => state?.getCustomerById?.data)
  const generateOderByid = useSelector((state) => state?.generateOderByid?.data)
  console.log(CustomerData, 'generateOderByidgenerateOderByid')


  console.log(OrderData, 'OrderDataOrderDataOrderData')
  const { priceBreakUp } = OrderData
  const { cartitemList } = OrderData
  const { passProduct } = cartitemList ? cartitemList[0] : []
  const gstttttt = localStorage.getItem('gstInvoiceNumber')

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCartProducts())
    dispatch(fetchCustomerById())
    dispatch((GETADDRSSSBYADDRESSID))

  }, [dispatch])

  // const calculateSecurityAmount = () => {
  //   // Check if priceBreakUp is an array and not empty
  //   if (!Array.isArray(priceBreakUp) || priceBreakUp.length === 0) {
  //     console.error('Invalid priceBreakUp array');
  //     return 0; // Return 0 if priceBreakUp is invalid
  //   }
  //   // Check if all items in priceBreakUp have the securityAmount property
  //   const isValidPriceBreakUp = priceBreakUp.every(item => typeof item === 'object' && 'securityAmount' in item);
  //   if (!isValidPriceBreakUp) {
  //     console.error('Invalid priceBreakUp objects');
  //     return 0; // Return 0 if priceBreakUp objects are invalid
  //   }

  //   // Calculate the total security amount
  //   return priceBreakUp.reduce((total, item) => {
  //     return total + parseFloat(item.securityAmount);
  //   }, 0);
  // };


  const generatePDF = () => {
    return new Promise((resolve, reject) => {
      const input = document.getElementById('pdf-container');
      if (!input) {
        reject('PDF container not found');
        return; // Ensure to return here to exit the function
      }

      html2pdf().from(input).toPdf().output('blob').then((pdfBlob) => {
        resolve(pdfBlob); // Resolve the promise with the PDF blob
      }).catch((error) => {
        reject(error);
      });
    });
  };

  const [orderIdData, setOrderIdData] = useState()

  const { id } = useParams()
  const fetchOurBestRatingProducts = () => {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      'Authorization': basicAuth,
    };
    axios.get(`${url}getOrderById/${id}`, {
      headers: headers,
    })
      .then(function (response) {
        // Handle successful response here
        console.log(response.data, 'skjsressjsjsjjs')
        setOrderIdData(response.data); // Example: Logging response data
      })
      .catch(function (error) {
        // Handle error here
        console.error('Error fetching data:', error);
      });
  };
  const [otherdata,setotherdata]=useState()

  console.log(otherdata,'otherdataotherdataotherdata')
  const [mydata,setDATA]=useState()
  const getOrderSummaryByOrderId = () => {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      'Authorization': basicAuth,
    };
    axios.get(`${url}getOrderSummryByOrderId/${id}`, {
      headers: headers,
    })
      .then(function (response) {
        // Handle successful response here
        console.log(response.data, 'skjsressjsjsjjs')
          setotherdata(response.data)
        const {priceBreakUp}=response.data; // Example: Logging response data
        setDATA(priceBreakUp)
      })
      .catch(function (error) {
        // Handle error here
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchOurBestRatingProducts()
    getOrderSummaryByOrderId()
  }, [])
 console.log(orderIdData,'orderIdDataorderIdData')
  const date = new Date(otherdata?.order?.createdDate);
  const options = { day: '2-digit', month: 'long', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-GB', options);
  

  const addressLines = orderIdData?.customerAddress.split('\n');


  const [valid, setChecked] = useState(false)


  const checkdaily = (e) => {
    setChecked(e.target.checked)

  }

  const [loadingss, setLoading] = useState(false)

  const handleClick = (e) => {
    if (!valid) {
      return alert('Please Check the Rental Condition')
    }
    e.preventDefault()

    console.log('alert');
    var headers = {
      Accept: "application/json",
      'Authorization': basicAuth,

    };
    const data = {
      orderId: id
    }

    var baseUrl = "https://newhousemanager.webbulldesign.com/";
    setLoading(true)
    axios
      .post(`${baseUrl}sendOtpForOrderDeliveryByOrderId/`, { orderId: id }, { headers: headers })
      .then(async (response) => {
        if (response.status === 200) {

          alert('Your OTP is Sent. Please Check Your Email');
          setLoading(false)
          // Handle successful response
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log(error);
      });
  };

  const calculateRentyCover = () => {
    // Extract tenure from selectedTenure string (e.g., "12 month(s)")
    const match = priceBreakUp?.map(item=>item.selectedTenure.match(/\d+/)) // Match one or more digits
    const tenure = match ? parseInt(match[0], 10) : 0; // Convert matched string to integer, default to 0 if no match
    
    if (tenure > 0) { // Ensure tenure is a positive number
      // Map over priceBreakUp and calculate rent cover divided by tenure
      const totalRentyCover = priceBreakUp.reduce((accumulator, item) => {
        if (item?.rentyCare && tenure > 0) {
          const rentCoverPerMonth = item.rentyCare / tenure;
          return accumulator + rentCoverPerMonth;
        }
        return accumulator;
      }, 0);
  
      return totalRentyCover;
    }
  
    // Return 0 if tenure is invalid or not greater than 0
    return 0;
  };
  const coupentDiscountPrice = useSelector((state) => state?.GetdiscountByIdReducer?.data)

const hhhhh=calculateRentyCover()
console.log(hhhhh,'calculateRentyCovercalculateRentyCover')
const [localvlaue,setsknckjsnc]=useState()
const lsls=localStorage.getItem('discountprice')

useEffect(()=>{
const value=  localStorage.getItem('discountprice')
    setsknckjsnc(value)

},[lsls])


const initialTotal = OrderData?.rentGrandTotal ?? 0;

// Determine the discount to apply
const discount = localvlaue != null ? localvlaue : (isNaN(coupentDiscountPrice?.maxDiscountPrice) ? 0 : coupentDiscountPrice?.maxDiscountPrice);

// Calculate the total after applying the discount
const total = initialTotal - discount;
let newtem=0
const adddsumb=(values)=>{
  console.log(values,'valuesvaluesvalues')
  newtem=newtem+values
 }
  return (
    <>

      <style>
        {
          `
        td,th{
        font-size:12px;
        }
        table {
  border-collapse: collapse;
}
 th {
  background: #ccc;
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
}

tr:nth-child(even) {
  background: #efefef;
}

tr:hover {
  background: #d1d1d1;
}
p {
    margin-top: 0;
    font-size: 14px;
    font-weight: 400;
}
    div#pdf-container {
    border: 1px solid black !important;
    width: 100%;
    margin: auto;
}
    .newwidth{
    overflow:scroll;
    }

        `
        }
      </style>
      <button >
        <Link to={file}>
        Download as PDF
        </Link>
        </button>

      <div className='newwidth' id="pdf-container"   ref={contentRef}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h3 style={{ color: 'grey',textAlign:'center' }}>
            Renty Furniture Rental Agreement

          </h3>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px',textAlign:'center' }}>
          <p>
            This Subscription Agreement ("Agreement") is made and deemed executed on the date  {formattedDate} ("Subscription Date") by the Company at
            Noida .
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h6 style={{ color: '' }}>
            By and Between
          </h6>
        </div>
        <div style={{ display: 'flex', justifyContent: '', marginTop: '30px', width: '80%', margin: 'auto' ,textAlign:'center' }}>
          <p>
            Day Dream Future Reality Infratech Pvt. Ltd. <strong> (Brand Name - Renty Furniture)  CIN U70200UP2018PTC102785. ("Company").  </strong>

          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', color: 'grey' }}>
          <h6>
            AND
          </h6>
        </div>


        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', width: '80%', margin: 'auto' }}>
          <p>
            The individuals identified as {CustomerData?.name}  ("Subscriber"). The Company and the
            Subscriber shall individually be referred to as 'Party' and collectively as 'Parties'.
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
          <p style={{textAlign:'center' }}>
            Be aware that the first party is renting out some household items to the Subscriber. The details of which are given below are types.

          </p>
        </div>


        <div>

        </div>
        
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '70px', width: '80%', margin: 'auto' }}>
          <p>
            Whereas The Company is in the business of offering Rentals with respect to home furniture, office furniture, soft furnishings and fitness
            equipments on subscription basis.The Subscriber has approached the Company to subscribe to the products and services offered by the Company, and the Company has agreed to provide the same to the Subscriber.The Parties hereto wish to enter into this Agreement in order to
            record their mutual understanding.

          </p>
        </div>

        <div style={{ width: '80%', margin: 'auto' }}>
          <h6 style={{ color: 'grey' }}>
            1. Definition
          </h6>
          <p>
            In this Agreement, unless the context otherwise requires, the following capitalized words and expressions shall bear the
            meaning ascribed to them here-in-below:
            "Rental" means the product and services offered by the company as part of a subscription programme.
            "Service" means and includes delivery and installation of furniture, furnishing and fitness equipment. "Initial Subscription Period"
            means the period of 3 months from Subscription Date or such period as amended from time to time on the basis of customer request.
            "Security Deposit" means the amount deposited with the Company as per the rental furniture provided by the company
            under the Subscription Programme.
            "Subscriber" means any person who subscribes for the Rental.
            "Subscription" means the non-exclusive and non-transferable permission by the Company to experience the solution for
            personal consumption to the Subscriber.
            "Subscription Fee" means monthly fee charged by the Company towards Subscription of a Rental by the Subscriber.
            "Subscription Period" shall mean the period for which the Subscriber has taken the Subscription including the Initial
            Subscription Period.
            "Subscription Program Documents" shall mean and include this Agreement, invoice and any other document as may be provided
            by the Company.
          </p>

        </div>



        <div style={{ width: '80%', margin: 'auto' }}>
          <h6 style={{ color: 'grey' }}>2. Subscription of Rental</h6>
          <p>
            In this Agreement, unless the context otherwise requires, the following capitalized words and expressions shall bear the meaning ascribed to them here-in-below:
          </p>
          <p>
            <strong>"Rental"</strong> means the product and services offered by the company as part of a subscription programme.
          </p>
          <p>
            <strong>"Service"</strong> means and includes delivery and installation of furniture, furnishing and fitness equipment. "Initial Subscription Period" means the period of 3 months from Subscription Date or such period as amended from time to time on the basis of customer request.
          </p>
          <p>
            <strong>"Security Deposit"</strong> means the amount deposited with the Company as per the rental furniture provided by the company under the Subscription Programme.
          </p>
          <p>
            <strong>"Subscriber"</strong> means any person who subscribes for the Rental.
          </p>
          <p>
            <strong>"Subscription"</strong> means the non-exclusive and non-transferable permission by the Company to experience the solution for personal consumption to the Subscriber.
          </p>
          <p>
            <strong>"Subscription Fee"</strong> means monthly fee charged by the Company towards Subscription of a Rental by the Subscriber.
          </p>
          <p>
            <strong>"Subscription Period"</strong> shall mean the period for which the Subscriber has taken the Subscription including the Initial Subscription Period.
          </p>
          <p>
            <strong>"Subscription Program Documents"</strong> shall mean and include this Agreement, invoice and any other document as may be provided by the Company.
          </p>
          <h6 style={{ color: 'grey' }}>3. Subscription Fees</h6>
          <p>
            Any Person can subscribe to the Rental for a Subscription Fee as per the prevailing Subscription Programme. Subscription Fees shall be paid within 5 days of due date as per subscription programme. The Subscription Fee is exclusive of taxes. GST, as applicable, will be charged additionally.
          </p>
          <p>
            Delayed payment of Subscription Fee beyond due date shall attract a penal interest of 10% of due amount per month from the date of default till the time the Subscription Fee is paid.
          </p>
          <p>
            Non-payment of Subscription Fee within 2 (Two) weeks of due date could result in termination of this Agreement, removal of the Rental or part thereof and other legal procedures/remedies that the Company may at its sole discretion, decide to proceed with. The Company shall levy a charge of Rs. 400/- for each cheque return/bounce or ECS/Standing Instructions dishonor.
          </p>
          <h6 style={{ color: 'grey' }}>4. Security Deposit</h6>
          <p>
            Every Subscriber shall deposit the prescribed refundable, interest-free Security Deposit as indicated in the prevailing Subscription Programme for the Rental prior to the Subscription Date.
          </p>
          <p>
            The Security Deposit shall be refunded after the Company has taken possession of all the products delivered as part of the Rental or part thereof and issued a Pick Up docket.
          </p>
          <p>
            Within 7 (seven) working days from the date of issue of Pick Up dockets, the Company shall process for refund of Security Deposit, subject to deduction of damages, unpaid Subscription Fees and any other deductions as applicable. Security deposit does not include any monthly subscription fee. It is simply a security deposit which takes care of the damages (if any) and any default in payments.
          </p>
          <p>
            Company reserves the right to charge additional security deposit; or ask for additional documents in case of a high-value order. Refund amount shall be transferred to account or card from where initial deposit was received. In case subscriber wish to get amount refunded to some other bank account, canceled cheque copy of account needs to be shared with the company before or at the time of pickup.
          </p>
          <h6 style={{ color: 'grey' }}>5. Subscription Date, Use and Obligations</h6>
          <p>
            The Company shall install/deliver the Rental or part thereof within 7 (Seven) days or any other mutually agreed period at the delivery address provided by the Subscriber from the receipt of the Security Deposit and a delivery receipt be issued by the Company. The date of delivery receipt shall be the Subscription Date.
          </p>
          <p>
            The Rental or part thereof shall be used by the Subscriber for personal purposes or where applicable, for the purposes of its executives and his/her family members, servants and guests, without in any way creating right / title interest in the Rental or part thereof except as mentioned in this Agreement.
          </p>
          <p>
            The Company shall repair or exchange the items in the Rental if the Subscriber faces any technical problems within 7 (seven) days of the receipt of such complaint in writing.
          </p>
          <p>
            On or before expiry of Initial Subscription Period, Subscriber might request for an extension of subscription. The Subscription can be renewed at mutually agreed terms by the Parties.
          </p>
          <h6 style={{ color: 'grey' }}>6. Delivery and Pickup</h6>
          <p>
            The Subscriber or his/her representative has to be present at the agreed date and time. If a 'mutually scheduled' delivery or pickup needs to be rescheduled due to non-availability of the Subscriber, additional logistics costs incurred shall be charged to the Subscriber. Any delay in pick-up of products due to unavailability of Subscriber may attract additional charges depending upon delay duration.
          </p>
          <p>
            Though we conduct detailed quality checks before delivery, the Subscriber is expected to check if there are any damages at the time of delivery and report the same to the representative of the Company and photos shall be captured of the same. Photos of the Subscriber will be taken with the items delivered for Company's records. Subscriber is expected to allow Company's representatives to take the required photographs. Subscriber shall ensure the entry of delivery vehicles inside the premises. Additionally, subscriber shall arrange for the permission to use the elevator. In case an elevator isn't available at the delivery location, Subscriber shall inform the Company about the same before the scheduled delivery date. The Company shall charge for the labor involved for picking up and conveying the items via stairs. The charges shall be discussed with the Subscriber while scheduling the delivery or at the time of delivery.
          </p>
          <h6 style={{ color: 'grey' }}>7. Maintenance</h6>
          <p>
            Maintenance of electrical appliances and fitness equipment shall be taken care of by the Company for the entire tenure of the contract. This does not cover damages or breakdowns due to mishandling.
          </p>
          <h6 style={{ color: 'grey' }}>8. Inspection Rights</h6>
          <p>
            The Company or its designated representative(s) shall have the right to visit the Premise and inspect the various components constituting the Rental or part thereof by providing a 24 hours' notice, either through phone or email, to the Subscriber.
          </p>
          <p>
            The Subscriber shall provide reasonable access to their Premise to enable the Company or its designated representatives to carry out the inspection and/or service and maintenance to the Rental or part thereof.
          </p>
          <h6 style={{ color: 'grey' }}>9. Termination</h6>
          <p>
            Subject to this Agreement, this Agreement can be terminated during the Subscription Period (but only after the expiry of Initial Subscription Period) in the following manner:
          </p>
          <p>
            Subscriber may terminate the Subscription after giving a 30 days written notice/email with the payment of applicable charges. Following charges will be applicable for early termination: Termination will depend upon the time period Subscribe takes the product. For example if Subscriber have taken product for 12 months but returned in 8 months then plan for rental will be calculated upon the 6 month plan. Means the lower month plan of using the product.
          </p>
          <p>
            The Company shall terminate the Subscription after giving a 10 days written notice/email to the Subscriber upon the Subscriber defaulting on the payment of Subscription Fees for one (1) consecutive months and has not paid till the expiry of the 10 days' notice period. The Company may terminate the Subscription with immediate effect if:
          </p>
          <ul>
            <li>The Subscriber has been found to transfer or license the Subscription in its own name</li>
            <li>The Subscriber has transferred, assigned or leased the Subscription without Company's approval</li>
            <li>The Subscriber has transferred the products to some other location without taking prior consent from the company</li>
            <li>The Subscriber does not give reasonable access to their Premise to enable the Company or its designated representatives to carry out the inspection and/or service and maintenance to the Rental or part thereof</li>
          </ul>
          <h6 style={{ color: 'grey' }}>10. Effect of Termination</h6>
          <p>
            On Termination of the Subscription, The Company shall refund the Security Deposit to the Subscriber after deducting due amount, charges, or damages, if any, in accordance with Clause 4 above. The Subscriber shall return the items in the same condition as taken (normal wear and tear accepted) and Company shall remove the items from the premises of the Subscriber at its own cost.
          </p>
          <p>
            The Subscriber shall not terminate this Agreement in the absence of default by the Company in performance of its obligations under this Agreement.
          </p>
        </div>
        <div style={{ width: '80%', margin: 'auto' }}>
          <h6 style={{ color: 'grey' }}>11. Duty of Subscriber</h6>
          <p>
            The Subscriber shall not, use or attempt to use the Rental or any part thereof or permit any person to provide Rental or any part thereof to any third party by way of trade or otherwise.
          </p>
          <p>
            The charges shall be exempted for normal wear and tear. However, if any item in the home Furniture or Home Decor component of the Rental is broken, torn, stained, or is damaged during the Subscription Period, the Subscriber shall be responsible for compensating the Company in this regard.
          </p>
          <p>
            During the Subscription Period, the Subscriber is solely responsible for the component of the Rental which must remain at the Premises and shall not be moved to another location without the prior written consent of the Company. Relocation of the Rental or part thereof or reinstallation of the Rental shall be as per the prevailing Subscription Programme.
          </p>
          <p>
            Subscriber shall take reasonable good care of the Rental or part thereof and not sell, sub-hire, assign, convey, transfer or create any rights in relation to the Rental or part thereof to another person.
          </p>
          <p>
            Subscriber may lease the house furnished with Rental only after the written approval of the Company.
          </p>
          <p>
            Further, the Subscriber shall not alter, modify, re-design, re-colour, re-polish, dismantle, re-assemble, change any of the Rental or part thereof, without prior written permission of the Company.
          </p>
          <h6 style={{ color: 'grey' }}>12. Intellectual Property Rights</h6>
          <p>
            The Intellectual Property Rights ("IPR") including trademarks, copyright, design and any other intellectual property in the Rental belongs to the Company. Rental and any other related materials / service via it and IPR therein may not be copied, distributed, published, licensed, used or reproduced in any way.
          </p>
          <p>
            Subscriber shall do all reasonable endeavors to safeguard IPR of the Company in the Rental and perform no act which violates the IPR of the Company and report promptly to the Company if any third party violates or claims IPR in the Rental in knowledge of the Subscriber and co-operate in any enforcement or other protective action taken by the Company.
          </p>
          <p>
            Subscriber shall not, and shall ensure that any other Person shall not use any reverse engineering, decompilation or disassembly techniques or similar methods to determine any design, concepts, construction method or other aspects of the Rental, or part thereof for any purpose Subscriber shall not make or attempt to make any alterations, modification, additions or enhancements through any means to the Rental or permit the whole or any part of the Rental to be combined with or become incorporated in any other program or Rental.
          </p>
          <h6 style={{ color: 'grey' }}>13. Confidentiality</h6>
          <p>
            Subscriber shall keep confidential the terms and conditions of this Agreement and all information disclosed by the Company to the Subscriber in relation to or in connection to this Agreement including the intellectual property rights in the Rental.
          </p>
          <h6 style={{ color: 'grey' }}>14. Disclaimer and Hold Harmless</h6>
          <p>
            The Subscriber agrees that it is the Subscriber's responsibility to always read the label and/or user manual before using the Rental. THE COMPANY DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR PARTICULAR PURPOSE WITH RESPECT TO THE SOLUTION OFFERED.
          </p>
          <p>
            In no event shall the Company or its owners, affiliates, employees, contractors, officers, or agents be liable for any damages including, without limitation, incidental and consequential damages, personal injury/wrongful death, lost profits, or damages resulting from the Rental, whether based on warranty, contract, tort, or any other legal theory. The Company shall not be liable for any damages, compensation, etc. incurred by the Subscriber or any third Party because of Rental.
          </p>
          <p>
            The Liability of the Company arising under or in relation to this Agreement shall not be more than one month Subscription Fees.
          </p>
          <h6 style={{ color: 'grey' }}>15. Breach of terms</h6>
          <p>
            In the event of breach of any of the covenants and conditions to be observed and performed by the Subscriber hereunder, the Company may at their option terminate this Agreement by giving the Subscriber a prior ten (10) days' notice in writing, specifying the breach complained thereof and requiring its remedy and this Agreement would stand terminated on the expiry of said notice period, unless the Subscriber would have remedied or repaired the said breach before the expiry of the said notice period. Further, the Company shall retain its right to claim damages and/or deduct the damages from the Security Deposit or encash the undated cheques.
          </p>
          <h6 style={{ color: 'grey' }}>16. Indemnity</h6>
          <p>
            Subscriber shall always indemnify, defend and hold harmless the Company from any third party claims including but not limited to by the landlord/owner of the Premises of trespass, damage to the premises, claim of criminal or civil nature or any loss, damage, attorney fees incurred in course/arising out of or in connection with this Agreement.
          </p>
          <p>
            The Subscriber agrees to hold the Company indemnified and harmless from any and all claims, demands, rights, lawsuits, causes of action, obligations, controversies, debts, costs, expenses (including but not limited to attorneys' fees), damages, judgments, losses and liabilities of whatever kind or nature, fixed or contingent, in law, equity or otherwise, whether known or unknown, whether or not apparent or concealed arising out the Rental.
          </p>
          <h6 style={{ color: 'grey' }}>17. Force Majeure</h6>
          <p>
            Upon the occurrence of any of the following events, including but not limited to fire, accident, riots, flood, earthquake, storm, terrorist activities, war, Acts of God, any governmental or municipal action (beyond the control of the Parties), prohibition or restriction, which in any way results in making the Rental or part thereof unfit, the Company shall have the right to terminate this Agreement forthwith and claim asset or value of the asset as defined by the company in books of account.
          </p>
          <h6 style={{ color: 'grey' }}>18. Statutory Action</h6>
          <p>
            If the Subscriber is dispossessed from the usage of Rental or part thereof as a result of any legal proceeding or action against the Company in respect to the Rental or part thereof for the breach by the Company of any law, regulation, rules, bye-laws in force in India, the Agreement shall stand terminated from the date of dispossession of the Subscriber.
          </p>
          <h6 style={{ color: 'grey' }}>19. Dispute Resolution and Arbitration</h6>
          <p>
            In the event of a dispute or difference of any nature whatsoever between the Parties, the same shall be, as far as possible, resolved through negotiations and in the event of failure of dispute resolution by negotiations, the dispute shall be referred to Arbitration. Either Party to this agreement can refer the dispute for resolution to a sole arbitrator or in case of disagreement in the appointment of the arbitrator, then to three arbitrators, of which each Party shall nominate one and the third arbitrator shall be appointed by the said two arbitrators. The decision of the Arbitral Tribunal shall be final and binding on both the parties. The venue of arbitration shall be Gurgaon and the Arbitration proceedings shall be conducted in accordance with provisions of the Arbitration and Conciliation Act, 1996 or any subsequent modifications thereto. The proceeding shall be in English. Each party shall bear and pay its own costs and expenses in connection with the arbitration proceedings unless the arbitrators direct otherwise.
          </p>
          <h6 style={{ color: 'grey' }}>20. Miscellaneous</h6>
          <p>
            <strong>Notice:</strong> Any notice to be served on the other Party shall be sent to the address mentioned in this Agreement or as updated by the Parties from time to time through pre-paid recorded delivery or through email and shall be deemed to have been received by the addressee within 48 hours of posting.
          </p>
          <p>
            <strong>Assignment:</strong> The Company may assign any of its rights under this Agreement to any person or entity without the prior written consent of the Subscriber. The Subscriber may assign any of its rights under this Agreement to any person or entity only upon receipt of prior written consent of the Company.
          </p>
          <p>
            <strong>Amendments:</strong> No change, modification, or termination of any of the terms, provisions, or conditions of this Agreement shall be effective unless made in writing and signed or initiated by all signatories to this Agreement.
          </p>
          <p>
            <strong>Survival:</strong> Termination of this Agreement shall not affect those provisions hereof that by their nature are intended to survive such termination including Clause 4, 9, 11, 12, 13, 14, 15, 17, 18, 19.
          </p>
          <p>
            <strong>Governing Law and Jurisdiction:</strong>  This Agreement shall be governed and construed in accordance with the laws of India in relation to any legal
            action or proceedings to enforce this Agreement. The Parties irrevocably submit to the exclusive jurisdiction of any competent courts situated at
            Gurgaon and waive any objection to such proceedings on grounds of venue or on the grounds that the proceedings have been brought in an
            inconvenient forum. Severability: If any paragraph, sub-paragraph, or provision of this Agreement, or the application of such paragraph, sub
            paragraph, or provision, is held invalid or excessively broad by a court of competent jurisdiction, the remainder of this Agreement, and the
            application of such paragraph, sub-paragraph, or provision to Persons, or circumstances other than those with respect to which it is held invalid
            shall not be affected. Entire Agreement: This Agreement along invoice and other subscription program documents constitutes the entire agreement
            between the Parties with respect to the matters contained herein and supersedes any and all prior and contemporaneous agreements, negotiations,
            correspondence, undertakings and communications of the parties, oral or written, with respect to the subject matter of this Agreement. The
            Subscriber shall inform at least ten (10) days before the expiry of Initial Subscription Period to the Company about his/her discontinuance of the

            Subscription upon expiry of Initial Subscription Period. If no such information is provided, the Rental will be automatically extended for life time
            without any further action/information from the Subscriber.

          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h6 style={{ color: 'grey' }}>Force Majeure</h6>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', width: '80%', margin: 'auto', flexDirection: 'column' }}>
          <h6> Damage shall be defined as follows:
          </h6>
          <p> 1-Minor scratches (below 1 mm in width and depth, and 2cm in length) on wooden furniture shall be ignored as they are considered 'normal
            wear and tear'.</p>
          <p>2-Minor chips and breakage in timber (below 5 mm in width, 1 mm in depth and 1cm in length) shall be ignored, while those above the
            said dimensions shall be charged for.  </p>
          <p>3-Any damage which is a result of raw material or manufacturing defects shall not be chargeable.</p>
          <p> 4-Any damage that results in the product being unusable shall result in the value of the product being charged to the subscriber. 5-Any damage
            that results in the replaceable part of the product being unusable shall result in the value of the part being charged to the subscriber. 6-Tear in
            upholstery shall result in charge towards replacement of upholstery. Opening up of a stitched joint shall not be chargeable. 7-Stains on upholstery
            which are not removable via dry cleaning shall result in a charge for upholstery replacement. </p>
        </div>






        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h6 style={{ color: 'grey' }}>RENT PAYMENT SCHEDULE</h6>
        </div>
        <table style={{ width: "90%", margin: 'auto',border:'1px solid black' }} border={0} cellSpacing={0} cellPadding={0} >


  <div style={{ width: '100%', overflowX: 'auto', margin: 'auto' }}>
  <table style={{ width: "90%", margin: 'auto', border: '1px solid black' }} border={0} cellSpacing={0} cellPadding={0}>
    <thead>
      <tr>
        <th className="no">S No.</th>
        <th className="unit">Item</th>
        <th className="unit">Rent Month</th>
        <th className="unit">Cover</th>
        <th className="qty">Tenure</th>
        <th className="qty">Qty</th>
        <th className="total">TOTAL</th>
      </tr>
    </thead>

    <tbody>
      {mydata?.map((priceBreak, index) => {
        return (
          <tr key={index}>
            <td className="no">{index + 1}</td>
            <td className="unit">
              <h3 style={{ fontSize: '12px', borderBottom: '1px solid black' }}>
                {priceBreak?.productName}
                <br />
                <span style={{ fontSize: '9px', color: '#000' }}>
                  (Security Amount - Rs.{priceBreak?.securityAmount})
                </span>
              </h3>
            </td>
            <td className="unit"> Rs. {priceBreak && Math.floor( (priceBreak?.baserentalCost) * (priceBreak?.quantity))} </td>
            <td className="unit"> Rs.  {(priceBreak?.rentyCare / extractNumberFromString(priceBreak.selectedTenure) / priceBreak.quantity).toFixed(2)} </td>
            <td className="unit">{priceBreak.selectedTenure}</td>
            <td className="qty"> {priceBreak?.quantity}</td>
            <td className=''> Rs.
              {Math.floor(
                (Number(priceBreak?.baserentalCost) * (priceBreak?.quantity) || 0) *
                (Number(extractNumberFromString(priceBreak?.selectedTenure)) || 0) +
                (Number(priceBreak?.rentyCare) || 0)
              )}
            </td>
            <span className='hidden'>
            {adddsumb(Math.floor(Number((priceBreak?.baserentalCost) * (priceBreak?.quantity)) * (extractNumberFromString(priceBreak?.selectedTenure) || 0)) + Math.floor(priceBreak?.rentyCare))}
            </span>
          </tr>
        );
      })}
    </tbody>
  </table>
</div>


</table>

        <main style={{ width: "", marginTop: '7px',width:'94%',margin:'auto' }}>

<div style={{ display: 'flex', justifyContent: 'end', flexDirection: 'column', alignItems: 'end', borderBottom: '' }}>

  <p> Total Tenure  Amount   ₹ {Math.floor(newtem)}</p>
  <p>Total Installation Charges: ₹ {otherdata?.deliveryAndInstallationCharge}</p>
<style>
{
`
.borderrrr{

border-bottom:1px solid black !important;
}
p{
margin-bottom:unset !important;
}
`
}
</style>
  <p className='borderrrr' style={{borderBottom:'1px solid black'}}>GST 18%   ₹ {otherdata?.gst}</p>
  <p className='' style={{borderBottom:'1px solid black !important'}}>Sub Total ₹
  
    {

    (Number(newtem||0) + 
     Number(otherdata?.deliveryAndInstallationCharge||0) + 
     Number(otherdata?.gst || 0) // Use || 0 to avoid NaN
    ).toFixed(2)
}</p>

  <p> Total Security Amount   ₹ {otherdata?.totalSecurityAmount}</p>
  <p>Discount {otherdata?.couponDiscount?otherdata?.couponDiscount:"N/A"}</p>
  <p>RentGrandTotal: ₹ {otherdata?.totalCost  }</p>

</div>


</main>

        <div className='phoneagreeent' style={{ width: '', marginLeft: '', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center',margin:'auto',width:'95%'}}>
        
          <div className='' style={{marginTop:'20px'}}>
          <div className='' >
            <h6>Information Date :- {formattedDate} </h6>
          </div>
       
            <h6>
              Name :- {CustomerData?.name}    
            </h6>
        <h6>    PanNumber {CustomerData?.panNumber}</h6>
        <h6>    Aadhar No. {CustomerData?.aadharNumber}</h6>
        <h6 style={{display:'flex'}}>         Address: {addressLines?.map((line, index) => (
          <li  style={{display:'flex'}}>
            {line}
          </li>
        ))}
        </h6>
         <h6>   Email ID: {CustomerData?.email} {CustomerData?.phoneNumber}</h6>
            
           
          </div>
        </div>
      </div>




    </>
  )
}

export default NewRentAgrement


export const generatePDFRent = () => {
  return new Promise((resolve, reject) => {
    const input = document.getElementById('pdf-container');
    if (!input) {
      reject('PDF container not found');
      return; // Ensure to return here to exit the function
    }

    html2pdf().from(input).toPdf().output('blob').then((pdfBlob) => {
      resolve(pdfBlob); // Resolve the promise with the PDF blob
    }).catch((error) => {
      reject(error);
    });
  });
};
