import {GET_PRODUCTBY_SEARCH_REQUEST,GET_PRODUCTBY_SEARCH_SUCCESS,GET_PRODUCTBY_SEARCH_FAILURE} from "../types";

import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const fetchProductsBySearchRequest = () => {
    return {
        type: GET_PRODUCTBY_SEARCH_REQUEST,
    };
};
export const fetchProductsBySearchSuccess = (Data) => {
    return {
        type: GET_PRODUCTBY_SEARCH_SUCCESS,
        payload: Data,
    };
};

export const fetchProductsBySearchFailure = (Error) => {
    return {
        type: GET_PRODUCTBY_SEARCH_FAILURE,
        payload: Error,
    };
};


export const fetchProductsBySearch = (keyword,pincode) => {
    console.log(pincode,'pincodepincodepincodepincodepincodepincodepincodepincode')
    const headers = {
        "Content-Type": "Application/json",
        'Authorization': basicAuth,
    };

    //Dynamic Object creatingprod
    return (dispatch) => {
        dispatch(fetchProductsBySearchRequest());
        axios
            .post(url +`search`, {
              keyword:keyword,
              pincode:localStorage.getItem('cityPincode')
            }, 
            {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchProductsBySearchSuccess(response.data));
            })
            .catch(function (error) {
                localStorage.setItem("message", "getFilterByProductFailed")
                dispatch(fetchProductsBySearchFailure(error));
            });
    };
};
