import {
    setAdddressId
  } from "../types"

  
  export const SETADDRESSID = (addresssId) => {
    console.log(addresssId,'setAdddressIdsetAdddressIdsetAdddressIdsetAdddressId')

    return {
      type: setAdddressId,
      payload: addresssId,

    };
  };