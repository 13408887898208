import {
    createOrderIdRequest,
    createOrderIdSuceess,
    createOrderIdFail,
} from "../../../actions/types";
const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};

export const generateOderByid = (state = initialState, action) => {
    switch (action.type) {
        case createOrderIdRequest:
            return {
                ...state,
                loading: true,
            };
        case createOrderIdSuceess:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "successfully added"
            };
        case createOrderIdFail:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
            
            
        default:
            return state;
    }
};
