import React,{useEffect, useLayoutEffect} from 'react'
import { useLocation } from 'react-router-dom';

const  About_Us=()=> {
  const handleScrollToTop = () => {
    alert('Scroll to top initiated!');
    console.log('Current scroll position:', window.scrollY);

    const scrollStep = -window.scrollY / (500 / 15); // Adjust for smoothness
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(1000, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  };

      
    return (
        <>
           <button
      onClick={handleScrollToTop}
      style={{
        padding: '10px 20px',
        fontSize: '16px',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#007BFF',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
    >
      Scroll to Top
    </button>
        <div id='main-container' style={{width:'100%',height:"100%",display:'flex',justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
            <div style={{ padding: "20px"}}>
                <h2>About Us</h2>
               <p>
                Rentyfurniture is a furniture rental website that provides an online platform for users to
rent furniture for their homes, offices, or events on a temporary basis. This service offers a
convenient alternative to purchasing furniture outright, allowing users to enjoy high-quality
furnishings without a long-term commitment.
<br></br>
<br></br>

The website features a diverse range of furniture styles, from modern to traditional. Users can
browse the extensive inventory and select items that match their needs and preferences. The
rental process is user-friendly, involving the selection of desired furniture, rental duration,
and any additional services such as delivery and assembly.
<br></br>
<br></br>


A key advantage of using Rentyfurniture.comis the flexibility it provides. Rentals can be
arranged for periods ranging from a few days to several months, making it ideal for
individuals moving to a new city or needing temporary furnishings.
<br></br>
<br></br>


In addition, the website offers delivery and assembly services, saving users time and effort,
especially if they lack the tools or expertise for furniture assembly. Payment options are
varied, including credit cards and financing plans, which helps users manage their budgets
without large upfront costs.
<br></br>

From an environmental perspective, renting furniture through Rentyfurniture.comsupports
sustainability. It reduces waste and promotes a circular economy by enabling the reuse and
recycling of furniture.
<br></br>

Overall, Rentyfurniture.compresents a practical, cost-effective solution for those seeking
quality furnishings without the commitment of buying. With easy online access, flexible
rental terms, and added services, it’s becoming an increasingly popular choice for both
individuals and businesses.
                </p>
            </div>
        </div>
        </>
    )
}

export default About_Us