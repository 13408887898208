// CaptureCurrentURL.js
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function CaptureCurrentURL() {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== '/login') {
      localStorage.setItem('redirectAfterLogin', location.pathname);
    }
  }, [location.pathname]);

  return null;
}

export default CaptureCurrentURL;
