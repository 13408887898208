import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from '@redux-devtools/extension';
import { rootReducer } from "../reducers/combineReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // You can choose between local storage or session storage

const persistConfig = {
    key: 'root', // the key for storing the data in storage
    storage, // the storage engine to use
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export let store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
);


export let persistor = persistStore(store);

// import { createStore, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import { composeWithDevTools } from '@redux-devtools/extension';
// import { rootReducer } from "../reducers/combineReducer";

// export const store = createStore(
//     rootReducer,
//     composeWithDevTools(applyMiddleware(thunk))
// );
