import React, { useEffect } from 'react'
import { fetchGetAllBlogs } from "../../actions/pages/blogs"
import "../../style/css/blogs.scss"
//Redux
import blogig from './blog.jpg'
import { connect } from "react-redux";
function Blogs(props) {
    const { handleGetAllBlogs } = props
    useEffect(() => {
        window.scrollTo(0, 0);
        handleGetAllBlogs()
    }, []);
    return (
      <>
      <div style={{width:'100%',height:"100%",display:'flex',justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
   
   <img src={blogig}/>
      </div>
      
      </>
    )
}
const mapStateToProps = (state, ownProps) => {
    return {
        mainCategoryData: state.getAllMainCategory.data,
        loading: state.getAllMainCategory.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handleGetAllBlogs: () => dispatch(fetchGetAllBlogs())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Blogs);
