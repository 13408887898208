import {
    RenewOrderReq,
    RenewOrderSuccesss,
    RenewOrderFails,
} from "../types"


import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
import Swal from "sweetalert2";

export const RenewOrderRequestfunction = () => {
    return {
        type: RenewOrderReq,
    };
};
export const RenewOrderscuessFunction = (Data) => {
    return {
        type: RenewOrderSuccesss,
        payload: Data,
    };
};

export const RenewOrderFailFunction = (Error) => {
    return {
        type: RenewOrderFails,
        payload: Error,
    };
};


export const RenewOrderAction = (id) => {
    const custumerid = localStorage.getItem("addressId")
    const headers = {
        Accept: "Application/json",
        'Authorization': basicAuth,
    };
   
    return (dispatch) => {
        dispatch(RenewOrderRequestfunction());
        axios
            .put(url + `renewExistingPlanByPlanId/${id}`,{}, {
                headers: headers,
            })
            .then(function (response) {
                localStorage.setItem("message", "addToCartProductsuccuss")
    Swal.fire({
        title: 'Renewal Product',
        text: response?.data,
    });
 
                dispatch(RenewOrderscuessFunction(response.data));
            })
            .catch(function (error) {
                Swal.fire({
                    title: 'Renewal Product',
                    text: 'Product is out of stock',
                    icon:'error'
                });
                localStorage.setItem("message", "addToCartProductFailed")
                dispatch(RenewOrderFailFunction(error));
            });
    };
};


