import {
    GET_CART_PRODUCTS_REQUEST,
    GET_CART_PRODUCTS_SUCCESS,
    GET_CART_PRODUCTS_FAILURE,
    ClearCartProduct
} from "../../types";
import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";
export const fetchCartProductsRequest = () => {
    return {
        type: GET_CART_PRODUCTS_REQUEST,
    };
};
export const fetchCartProductsSuccess = (Data) => {
    return {
        type: GET_CART_PRODUCTS_SUCCESS,
        payload: Data,
    };
};

export const fetchCartProductsFailure = (Error) => {
    return {
        type: GET_CART_PRODUCTS_FAILURE,
        payload: Error,
    };
};
export const clearProdict=()=>{
    return{
        type: ClearCartProduct,
    }
}

export const fetchCartProducts = () => {
    const customerId = localStorage.getItem("customerId");
    const cityId = localStorage.getItem("cityId");

    const headers = {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        'Authorization': basicAuth,
        "ngrok-skip-browser-warning" : "skip-browser-warning",

    };

    return (dispatch) => {
        dispatch(fetchCartProductsRequest());
        
        if (customerId !== undefined && customerId !== null) {
            axios
                .get(url + `getAllCartItemByCustomerId/${customerId}?cityId=${cityId}`, {
                    headers: headers,
                })
                .then(function (response) {
                    dispatch(fetchCartProductsSuccess(response.data));
                })
                .catch(function (error) {
                    dispatch(fetchCartProductsFailure(error));
                });
        } else {
            console.error("customerId is not present");
        }
    };
};

export const fetchCartProductsBybrowserId = () => {
    const browserId = localStorage.getItem('browserUniqueId');
    const cityId = localStorage.getItem('cityId');

    const customerId = localStorage.getItem('customerId'); // Get the customer ID
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchCartProductsRequest());
        if (!customerId && browserId !== undefined && browserId !== null) {
            axios
                .get(url + `getAllCartItemByBrowserId/${browserId}?cityId=${cityId}`, {
                    headers: headers,
                })
                .then(function (response) {
                    dispatch(fetchCartProductsSuccess(response.data));
                })
                .catch(function (error) {
                    dispatch(fetchCartProductsFailure(error));
                });
        } else {
            console.error("customerId is present or browserId is not available");
        }
    };
};
