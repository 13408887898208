import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

export default function SwipeableLeftDrawer() {
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleLeftDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, left: open });
    };

    const leftList = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleLeftDrawer(false)}
            onKeyDown={toggleLeftDrawer(false)}
        >
            <h1>Delhi</h1>
            <h1>Noida</h1>
            <h1>Ghazibad</h1>
            <h1>Gurgaon</h1>
        </Box>
    );

    return (
        <div>
            <Button onClick={toggleLeftDrawer(true)}>Open Left Drawer</Button>
            <SwipeableDrawer
                anchor="left"
                open={state.left}
                onClose={toggleLeftDrawer(false)}
                onOpen={toggleLeftDrawer(true)}
            >
                {leftList}
            </SwipeableDrawer>
        </div>
    );
}
