import React, { useEffect, useState } from "react";
import support from './Complaint.png'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { basicAuth } from "../../../actions/auth/basicAuth";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerById } from "../../../actions/customer/getCustomerById";
import Swal from 'sweetalert2';

const AddTicket = () => {
    const useSdatata = useSelector((state) => state?.getCustomerById?.data)
    console.log(useSdatata,'cjncs')
    const [file, setFile] = useState('')
    const [file2, setFnewile] = useState('')

    const [data, setData] = useState({
        issue_type: "",
        description: "",
        "productName":"",


    })
    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }
    const { id } = useParams();
    console.log(id); 
     const navigate = useNavigate()



    const [errorMessages, setErrorMessages] = useState({
      productName: "",
        issue_type: "",
        description: "",
    });


    const validateAddressData = () => {
      let errors = {};
    
      // Check required fields in `data`
    
      if (!data?.issue_type) {
        errors.issue_type = "Issue Type is required.";
      }
   
      if (!data?.description) {
        errors.description = "Description is required.";
      }
      if (!data?.productName) {
        errors.productName = "Product Name is required.";
      }
      if(!file){
        errors.file="File is required"
      }
    
      // Check for any errors
      if (Object.keys(errors).length > 0) {
        // If there are errors, set them in the state and return `false`
        setErrorMessages(errors);
        return false;
      } else {
        // Clear errors if validation passes
        setErrorMessages({});
        return true;
      }
    };
    
    
    console.log(id,'hiid')
    

      const handleclick = async (e) => {
        console.log(id,'hiid')
        e.preventDefault();
      
        // Run validation first
        if (!validateAddressData()) {
          return; // Prevent API call if validation fails
        }
      
      
        // Proceed with the API call if validation passes
        try {
          const headers = {
            "ngrok-skip-browser-warning": "skip-browser-warning",
            Authorization: basicAuth, // Ensure proper formatting
          };
          const customerId = localStorage.getItem('customerId');
          
          const scs = {
            "ticketId": "",
            "orderId": id,
            "customerId": customerId,
            "customerName": useSdatata?.name,
            "customerEmail": useSdatata?.email,
            "serviceType": data?.servicetype,
            "issueType": data?.issue_type,
            "discriptionOfIssue": data?.description,
            "productName": data?.productName,
            "customerContactNumber": useSdatata?.mobile,
            "cityPincode": useSdatata?.address?.nameOfCity,
            "address": `${useSdatata?.address?.street}, ${useSdatata?.address?.stateName}`,
            "createdDate": null,
            "updatedDate": null,
            "isIssueResolved": false,
          };
      
          const formData = new FormData();
          formData.append('file', file);
          formData.append('data', JSON.stringify(scs));
      
          const baseUrl = 'https://newhousemanager.webbulldesign.com/';
          const response = await axios.post(`${baseUrl}createNewTicket`, formData, { headers });
          
           Swal.fire({
            title: 'Ticket Raised',
            text: 'Ticket Raised successfully',
            icon: 'success',
        });          navigate('/');
          
        } catch (error) {
          console.error('Error creating new ticket:', error.response ? error.response.data : error.message);
        }
      };
      
   
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchCustomerById())
    }, [dispatch])
    return (
        <>

            <style>
                {
                    `
                    label {
    display: block;
    position: relative;
    margin-left: 0px !important;
}
                .form-content{
                line-heigth:47px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                padding:20px;
                }
                .form-field{
                display:flex;
                flex-direction:column;
                margin-top:5px;
                }
                .form-field input,select ,textarea{
                border:1px solid black !important;
                }
                `
                }
            </style>

            <div className="" style={{width:'80%',margin:'auto',marginTop:'40px'}}>
                <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: '', flexDirection: 'row',gap:'20px' }}>
                    <div className="" style={{ width: '100%', display: 'flex', justifyContent: '', flexDirection: 'column' }}>
                        <img src={support} style={{ height: '100%', width: '100%', margin: 'auto' }} />
                    </div>


                    <div class="form-content" style={{width:'100%'}}>
                        <h3 style={{ marginTop: '10px' }}>

                            Submit Request Here

                        </h3>

                      
                        <div class="form-field">
                            <label for="issue_type">Issue Type</label>
                            <select id="issue_type" value={data?.issue_type} class="input-field" name="issue_type" required="" onChange={handleChange} >
                                <option value="" disabled="" selected="" >Select Issue Type</option>
                                <option value="KYC registration pending/rejected">KYC registration pending/rejected</option>
                                <option value="Delay in delivery or Incomplete order delivered">Delay in delivery or Incomplete order delivered</option>
                                <option value="Product delivered but Installation not done">Product delivered but Installation not done</option>
                                <option value="Issue with Appliances - Washing Machine / Refrigerator/TV/Microwave / WaterPurifier">Issue with Appliances - Washing Machine / Refrigerator/TV/Microwave / WaterPurifier</option>
                                <option value="Issues with furniture - Bed / Wardrobe/Couch/Study / Dining etc">Issues with furniture - Bed / Wardrobe/Couch/Study / Dining etc</option>
                                <option value="Unable to create and schedule pickup from the app">Unable to create and schedule pickup from the app</option>
                                <option value="Pickup Activity Cancelled/Delayed">Pickup Activity Cancelled/Delayed</option>
                                <option value="Incomplete Pickup done all products not picked">Incomplete Pickup done all products not picked</option>
                                <option value="Unable to reschedule order from the app">Unable to reschedule order from the app</option>
                                <option value="Order rescheduled without information">Order rescheduled without information</option>
                                <option value="Unable to renew my subscription - Renewal">Unable to renew my subscription - Renewal</option>
                                <option value="Invoice clarification">Invoice clarification</option>
                                <option value="Refund related">Refund related</option>
                                <option value="Want to buy out the rented furniture">Want to buy out the rented furniture</option>
                                <option value="Value added services - FCash / Flexi benefit pass / Furlenco care/">Value added services - FCash / Flexi benefit pass / Furlenco care/</option>
                            </select>
                {errorMessages.issue_type && <span style={{ color: 'red' }}>{errorMessages.issue_type}</span>}

                        </div>
                        <div class="form-field">
                            <label for="description">Product Name</label>
                            <input onChange={handleChange} value={data?.productName} id="description" name="productName" rows="6" placeholder="Product Name"></input>
                            {errorMessages.productName && <span style={{ color: 'red' }}>{errorMessages.productName}</span>}
                            </div>
                        <div class="form-field">
                            <label for="description">Description</label>
                            <textarea onChange={handleChange} value={data?.description} id="description" name="description" rows="6" placeholder="Please provide a detailed description of the issue you are facing"></textarea>
                            {errorMessages.description && <span style={{ color: 'red' }}>{errorMessages.description}</span>}
                        </div>
                       
                        <div class="form-field">
                            <label for="file-upload">Attachments</label>
                                <input type="file" id="file-upload" onChange={(e) => setFile(e.target.files[0])} class="upload-input" name="file"  multiple="" />
                                <div class="custom-file-input-text"><span>Add files&nbsp;</span>or Drop files here</div>
                                <p style={{color:'red'}}> {errorMessages?.file}</p>
                             
                             
                        </div>
                        <div class="form-field">
                            <div id="previewArea"></div>
                        </div>
                        <div class="btn">
                            <button onClick={handleclick} style={{ background: '#f56358', color: 'white', padding: '10px' }}>
                                Submit
                            </button>                </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AddTicket