import {
    GET_BEST_RATING_PRODUCTS_REQUEST,
    GET_BEST_RATING_PRODUCTS_SUCCESS,
    GET_BEST_RATING_PRODUCTS_FAILURE,
} from "../../actions/types";

const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};
export const getBestRatingProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BEST_RATING_PRODUCTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_BEST_RATING_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "successfully fatched"
            };
        case GET_BEST_RATING_PRODUCTS_FAILURE:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
        default:
            return state;
    }
};
