import {
    REGISTRATION_NEW_USER_REQUEST,
    REGISTRATION_NEW_USER_SUCCESS,
    REGISTRATION_NEW_USER_FAILURE,
} from "../../actions/types";

const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};
export const newUserRegistrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTRATION_NEW_USER_REQUEST:
            return {
                ...state,
                loading: true,
                error: "",
                message: ""
            };
        case REGISTRATION_NEW_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: action.payload
            };
       
        default:
            return state;
    }
};
