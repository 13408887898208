import React, { useEffect, useRef, useState } from "react";
import html2pdf from "html2pdf.js";

import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartProducts } from "../../../actions/products/cart/getCartProducts";
import { fetchCustomerById } from "../../../actions/customer/getCustomerById";
import Rlogo from "./logo.png";
import sign from "./sign.png";
import { basicAuth } from "../../../actions/auth/basicAuth";

const OrderReciept = () => {
  const getTenureFromDuration = (duration) => {
    switch (duration) {
      case 1:
        return "1 Month";
      case 3:
        return "3 Month";
      case 6:
        return "6 Month";
      case 9:
        return "9 Month";
      case 12:
        return "12 Month";
      case 18:
        return "18 Month";
      default:
        return null;
    }
  };

  const [orderId, setOrderData] = useState();
  useEffect(() => {
    const orderId = localStorage.getItem("neworderId");
    setOrderData(orderId);
  }, [orderId]);
  const pdfRef = useRef(null);
  const OrderData = useSelector((state) => state?.getCartProducts?.data);
  const CustomerData = useSelector((state) => state?.getCustomerById?.data);
  const generateOderByid = useSelector(
    (state) => state?.generateOderByid?.data
  );
  console.log(generateOderByid, "generateOderByidgenerateOderByid");
  const lsls = localStorage.getItem("discountprice");
  const [localvlaue, setsknckjsnc] = useState();
  useEffect(() => {
    const value = localStorage.getItem("discountprice");
    setsknckjsnc(value);
  }, [lsls]);

  console.log(OrderData, "OrderDataOrderDataOrderData");
  const { priceBreakUp } = OrderData;
  const gstttttt = localStorage.getItem("gstInvoiceNumber");
  const ORDERID = localStorage.getItem("neworderId");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCartProducts());
    dispatch(fetchCustomerById());
  }, [dispatch]);

  const calculateSecurityAmount = () => {
    // Check if priceBreakUp is an array and not empty
    if (!Array.isArray(priceBreakUp) || priceBreakUp.length === 0) {
      console.error("Invalid priceBreakUp array");
      return 0; // Return 0 if priceBreakUp is invalid
    }
    // Check if all items in priceBreakUp have the securityAmount property
    const isValidPriceBreakUp = priceBreakUp.every(
      (item) => typeof item === "object" && "securityAmount" in item
    );
    if (!isValidPriceBreakUp) {
      console.error("Invalid priceBreakUp objects");
      return 0; // Return 0 if priceBreakUp objects are invalid
    }

    // Calculate the total security amount
    return priceBreakUp?.reduce((total, item) => {
      return total + parseFloat(item.securityAmount);
    }, 0);
  };

  const calculateTotalRent = (priceBreakUp) => {
    let totalSum = 0;

    const addNumber = (number) => {
      const parsedNumber = parseFloat(number);
      if (!isNaN(parsedNumber)) {
        totalSum += parsedNumber;
      } else {
        console.log(`${number} is not a valid number`);
      }
    };

    priceBreakUp?.forEach((priceBreak) =>
      addNumber(priceBreak.totaltenureCost)
    );
    const jjjjj = Math.floor(totalSum);
    return jjjjj;
  };

  // Usage
  const totalRent = calculateTotalRent(OrderData?.priceBreakUp);
  console.log(`Total RENT - Rs.${totalRent}`);

  const formatGstAmountWithoutDecimals = (priceBreakUp) => {
    let totalSum = 0;

    priceBreakUp.forEach((item) => {
      const tenureCost =
        Number(item?.baserentalCost ?? 0) *
        extractNumberFromString(item?.selectedTenure);
      const gstAmount = tenureCost * 0.18; // Assuming GST is 18%
      totalSum += gstAmount;
    });

    return totalSum.toFixed(2); // Return formatted GST amount with two decimal places
  };

  // Usage
  const { cartitemList } = OrderData;
  const { passProduct } = cartitemList ? cartitemList[0] : [];

  const date = new Date();
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);
  console.log(formattedDate); // Output: 03 July 2024
  const adddresByAdresssId = useSelector(
    (state) => state?.GETADDRESSBYADDRESSID?.data
  );
  
  const extractNumberFromString = (str) => {
    const match = str.match(/\d+/); // Match one or more digits
    return match ? parseInt(match[0], 10) : 0; // Convert matched string to integer, default to 0 if no match
  };

  const coupentDiscountPrice = useSelector(
    (state) => state?.GetdiscountByIdReducer?.data
  );

  const initialTotal = OrderData?.rentGrandTotal ?? 0;

  // Determine the discount to apply
  const discount =
    localvlaue != null
      ? localvlaue
      : isNaN(coupentDiscountPrice?.maxDiscountPrice)
      ? 0
      : coupentDiscountPrice?.maxDiscountPrice;

  // Calculate the total after applying the discount
  const total = initialTotal - discount;

  // Ensure the total is floored correctly

  const addSecurityAmount = (data) => {
    if (!data || !data.priceBreakUp || !Array.isArray(data.priceBreakUp)) {
      console.log("Invalid data or data.priceBreakUp is not an array");
      return;
    }

    // Calculate total security amount
    let total = data?.priceBreakUp?.reduce((acc, item) => {
      // Ensure item.securityAmount exists and is numeric before adding
      let amount = parseFloat(item.securityAmount);
      if (!isNaN(amount)) {
        return acc + amount;
      } else {
        return acc;
      }
    }, 0);

    // Log the total security amount
    return total;
  };

  const addrentcost = (data) => {
    if (!data || !data.priceBreakUp || !Array.isArray(data.priceBreakUp)) {
      console.log("Invalid data or data.priceBreakUp is not an array");
      return;
    }

    // Calculate total rental cost
    let total = data.priceBreakUp?.reduce((acc, item) => {
      // Ensure item.baserentalCost and item.selectedTenure exist
      let rentalCost = parseFloat(item.baserentalCost);
      let selectedTenure = parseFloat(item.selectedTenure); // Assuming selectedTenure is a numeric value (like 12)

      if (!isNaN(rentalCost) && !isNaN(selectedTenure)) {
        let multipliedCost = rentalCost * selectedTenure;
        return acc + multipliedCost;
      } else {
        return acc;
      }
    }, 0);

    return total + data.priceBreakUp.rentyCare;
  };

  const calculateRentyCover = () => {
    const totalRentyCover = priceBreakUp?.reduce((accumulator, item) => {
      if (item?.rentyCare) {
        // Convert string to number
        const rentCoverPerMonth = parseFloat(item.rentyCare);
        if (!isNaN(rentCoverPerMonth)) {
          // Ensure conversion was successful
          return accumulator + rentCoverPerMonth;
        }
      }
      return accumulator;
    }, 0);

    // Format the totalRentyCover to two decimal places
    const formattedTotal = totalRentyCover?.toFixed(2);
    return formattedTotal;
  };

  // Example usage

  const totalRentyCover = calculateRentyCover();
  console.log(totalRentyCover); // Outputs: 2426.16

  const hhhhh = calculateRentyCover();
  console.log(hhhhh, "calculateRentyCovercalculateRentyCover");
  let newtem = 0;
  const adddsumb = (values) => {
    newtem = newtem + values;
    console.log(values, "oldvalies");
    console.log(newtem, "sjhcjhscbhjsc");
  };
  const addressssss = localStorage.getItem("customerAddeess");

  return (
    <>
      <style>
        {`
  
  @font-face {
    font-family: SourceSansPro;
  }
  
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }
  

  a {
    color: #000;
    text-decoration: none;
  }
  
  p{
  margin-bottom:0px !important;
  }
  header {
    padding: 10px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #AAAAAA;
  }
  
  #logo {
    float: left;
    margin-top: 8px;
  }
  
  
  #company {
    float: right;
    text-align: right;
  }
  
  
  #details {
    margin-bottom: 50px;
  }
  
  #client {
    padding-left: 6px;
    border-left: 6px solid #f23360;
    float: left;
  }
  
  #client .to {
    color: #777777;
  }
  
  h2.name {
    font-size: 1.4em;
    font-weight: normal;
    margin: 0;
  }
  
  #invoice {
    float: right;
    text-align: right;
  }
  table tfoot tr td:first-child {
    border: none;
  }
  
  #thanks{
    font-size: 2em;
    margin-bottom: 50px;
  }
  
  #notices{
    padding-left: 6px;
    border-left: 6px solid #000;  
  }
  
  #notices .notice {
    font-size: 1.2em;
  }
  
  footer {
    color: #777777;
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #AAAAAA;
    padding: 8px 0;
    text-align: center;
  }
  
  
  h2.name1 {
      margin: 0px;
  }
  
  thead {
      font-size: 18px;
      line-height: 38px;
      font-weight: bold;
      text-align: center;
  }
  .newwidth {
    width: 95%;
    margin: auto;
    border:1px solid black !important;
    // visibility: hidden;

}
    tr,td,th{
    padding:10px;
    
border:1px solid black !important;    
    }
   h1 {
      margin: 0px;
  }
      .gst{
      
      border-bottom:1px solid black !important;
      }
      
     p {
    font-size: 11px;
    color: grey;
    padding: 0px;
    margin: 1px;
}
    p{
    margin-bottom:2px;
    }

      h2{
      font-size:12px;
      
      }
       h3{
      font-size:14px;
      }
      ul li{
    font-size: 11px;
      }
    th{
    font-size: 13px;

    }
    p{
    margin-bottom:0px;
    }

    p+p{
    margin-bottom:0px !important;
    margin-top:0px !important;

    }
    td{
    font-size: 13px;
    
    }
    @media(max-width:768px){
      canvas.react-pdf__Page__canvas{
  width:422px !important;
  }
  
  }
  @media print and (color) {
     * {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
     }
  }
        `}
      </style>
      <div
        className="newwidth"
        id="pdf-container"
        ref={pdfRef}
        style={{ border: "1px solid black !important", padding: "10px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "end",
            width: "100%",
            justifyContent: "end",
          }}
        >
          <img src={Rlogo} style={{ width: "50px" }} />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <h2 style={{ fontSize: "15px" }}>
            {" "}
            DAY DREAM FUTURE REALITY INFRATECH PRIVATE LIMITED
          </h2>
          <p style={{ fontSize: "13px" }}>
            {" "}
            Rentyfurniture.com, T1/718 Assotech Business cresstia Sec135 Noida
            -201301 GSTIN - 09AAGCD6293D1ZI | PAN - AAGCD6293D | CIN-
            UP0200UP2018PTC102785 www.rentyfurniture.com|
            support@rentyfurniture.com| Phone No. 9999436043
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            marginTop: "5px",
          }}
        >
          <h3 style={{ color: "grey" }}>ORIGINAL TAX INVOICE</h3>
        </div>

        <div>
          <p style={{ paddingBottom: "0px" }}> Invoice No : -{ORDERID}</p>
          <p> Invoice Date :{formattedDate} </p>
          <p> Name : {CustomerData?.name}</p>
          <p>
            {" "}
            Address :
           {
             adddresByAdresssId?.name
             ? `${adddresByAdresssId.name}, ${adddresByAdresssId.stateName}, ${adddresByAdresssId.street}, ${adddresByAdresssId?.selectCity?.cityName}, ${adddresByAdresssId.postCode}`
             : addressssss
           }
          </p>
          <p> GSTIN : {gstttttt}</p>
          <p> Place To Supply : {CustomerData?.address?.nameOfCity}</p>
          <p> Service : Furniture & Appliances rental</p>
        </div>

        <main style={{ width: "100%", marginTop: "7px" }}>
          <table
            style={{ width: "100%", margin: "auto" }}
            border={0}
            cellSpacing={0}
            cellPadding={0}
          >
            <thead>
              <tr>
                <th className="no">S No.</th>
                <th className="unit">Item</th>
                <th className="unit">Rent Month </th>
                <th className="unit">Cover </th>

                <th className="qty">Tenure</th>

                <th className="qty">Qty</th>

                <th className="total">TOTAL</th>
              </tr>
            </thead>

            <tbody>
              {OrderData?.cartitemList?.map((cartItem, index) => {
                const priceBreak = OrderData?.priceBreakUp[index];

                return (
                  <tr>
                    <td className="no">{index + 1}</td>
                    <td className="unit">
                      <h3
                        style={{
                          fontSize: "12px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        {cartItem?.passProduct?.productTitle}
                        <br />
                        <span style={{ fontSize: "9px", color: "#000" }}>
                          (Security Amount - Rs.{priceBreak?.securityAmount})
                        </span>
                      </h3>
                    </td>
                    <td className="unit">
                      {" "}
                      Rs. {priceBreak && priceBreak?.baserentalCost}{" "}
                    </td>
                    <td className="unit">
                      {" "}
                      Rs.{" "}
                      {(
                        priceBreak?.rentyCare /
                        extractNumberFromString(priceBreak.selectedTenure) /
                        priceBreak.quantity
                      ).toFixed(2)}{" "}
                    </td>

                    <td className="unit">
                      {getTenureFromDuration(cartItem.duration)}
                    </td>

                    <td className="qty"> {cartItem?.quantity}</td>
                    <td className="">
                      {" "}
                      Rs.
                      {Math.floor(
                        Number(priceBreak?.baserentalCost) *
                          (extractNumberFromString(
                            priceBreak?.selectedTenure
                          ) || 0)
                      ) +
                        Math.floor(priceBreak?.rentyCare / priceBreak.quantity)}
                    </td>

                    <span className="hidden">
                      {" "}
                      {adddsumb(
                        Math.floor(
                          Number(priceBreak?.baserentalCost) *
                            (extractNumberFromString(
                              priceBreak?.selectedTenure
                            ) || 0)
                        ) +
                          Math.floor(
                            priceBreak?.rentyCare / priceBreak.quantity
                          )
                      )}
                    </span>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              flexDirection: "column",
              alignItems: "end",
              borderBottom: "",
            }}
          >
            <p> Total Tenure Amount ₹ {newtem}</p>
            <p>
              Total Installation Charges: ₹{" "}
              {OrderData?.deliveryAndInstallationCharge}
            </p>
            <style>
              {`
        .borderrrr{
        
        border-bottom:1px solid black !important;
        }
        
        `}
            </style>
            <p
              className="borderrrr"
              style={{ borderBottom: "1px solid black" }}
            >
              GST 18% (Rent ) ₹ {OrderData?.gst}
            </p>
            <p
              className=""
              style={{ borderBottom: "1px solid black !important" }}
            >
              Sub Total ₹
              {(
                Number(newtem) +
                Number(OrderData?.deliveryAndInstallationCharge) +
                Number(OrderData?.gst || 0)
              ) // Use || 0 to avoid NaN
                .toFixed(2)}
            </p>

            <p> Total Security Amount ₹ {addSecurityAmount(OrderData)}</p>
            <p>
              Discount{" "}
              {Math.floor(localvlaue) ||
              Math.floor(coupentDiscountPrice?.discountPrice)
                ? ""
                : ""}{" "}
              {Math.floor(localvlaue)}
            </p>

            <p>
              RentGrandTotal: ₹{" "}
              {(parseFloat(OrderData?.rentGrandTotal) || 0) -
                Math.floor(parseFloat(localvlaue) || 0) ||
                Math.floor(
                  parseFloat(coupentDiscountPrice?.discountPrice) || 0
                ).toFixed(2)}
            </p>
          </div>

          <div>
            <h6> Terms & Condition : </h6>
            <ul>
              <li style={{ color: "green" }}>
                {" "}
                Payment to be made as per due date. Please ignore, if already
                paid
              </li>
              <li style={{ color: "green" }}>
                {" "}
                Interest at the rate of 18% p.a shall be charged on non-payment.
              </li>
              <li style={{ color: "green" }}>
                {" "}
                Every month 7th i.e due date. After the Due date penalty 10% of
                rent & interest will be chargeable
              </li>
            </ul>
          </div>

          <div
            id="notices"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              border: "1px solid black !important",
              flexDirection: "column",
            }}
          >
            <img src={sign} style={{ height: "200px", width: "300px" }} />
          </div>
        </main>
      </div>
      <button onClick={generatePDF}></button>
    </>
  );
};

export default OrderReciept;

export const generatePDF = () => {
  return new Promise((resolve, reject) => {
    const input = document.getElementById("pdf-container");
    if (!input) {
      reject("PDF container not found");
      return; // Ensure to return here to exit the function
    }

    html2pdf()
      .from(input)
      .toPdf()
      .output("blob")
      .then((pdfBlob) => {
        resolve(pdfBlob); // Resolve the promise with the PDF blob
      })
      .catch((error) => {
        reject(error);
      });
  });
};
