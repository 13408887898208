import {
    SendOtpAdgharRequest,
    SendOtpAdgharSuccesss,
    SendOtpAdgharFail,
} from "../../types";
import axios from "axios";

import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";
import Swal from "sweetalert2";
export const SendOtpAdgharRequestFun = () => {
    return {
        type: SendOtpAdgharRequest,
    };
};
export const SendOtpAdgharSuccessFun = (Data) => {
    return {
        type: SendOtpAdgharSuccesss,
        payload: Data,
    };
};

export const SendOtpAdgharFailFun = (Error) => {
    return {
        type: SendOtpAdgharFail,
        payload: Error,
    };
};

export const SendOtpAdhar = (adharnumber) => {
    const sign = localStorage.getItem('signature');
    const data={
        aadharNumber:adharnumber
    }

    const headers = {
        'Authorization': basicAuth,
        'Accept': 'application/json', // Ensure the server knows you accept JSON responses
        'Content-Type': 'application/json', // Specify the content type of the request body
    };

    return (dispatch) => {
        dispatch(SendOtpAdgharRequestFun());
        const customerID=localStorage.getItem('customerId')

    

        axios.post(url + `sendAadharOtpByCustomerId/${customerID}`, data, { headers })
            .then(response => {
                console.log(response,'responseresponse')
                if(response?.data?.message=="Invalid Aadhaar Card"){
                    Swal.fire({
                        title: "Invalid Aadhar",
                        text: "Invalid Aadhar Number",
                        icon: "error",
                      });
                }else if(response?.data?.message=='Aadhar number is already used'){
                    Swal.fire({
                        title: "Aadhar number is already used",
                        text: "Aadhar number is already used",
                        icon: "error",
                      });  
                }
                else if(response?.data?.message=="OTP sent successfully"){
                    Swal.fire({
                        title: "OTP SENT",
                        text: "Your OTP sent please check ",
                        icon: "success",
                      });  
                }
                console.log(response, 'responseresponse');
                localStorage.setItem('ref',response.data?.ref_id)
                dispatch(SendOtpAdgharSuccessFun(response.data));
            })
            .catch(error => {
                console.log(error,'hiiiiierror')
                if(error?.response?.data=="Aadhar number is already used"){
                    Swal.fire({
                        title: "Aadhar number is already used",
                        text: "Aadhar number is already used",
                        icon: "error",
                      });  
                }
                    
                localStorage.setItem("message", "addToCartProductFailed");
                dispatch(SendOtpAdgharFailFun(error));
            });
    };
};