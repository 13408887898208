import React, { useState, useEffect } from 'react'
import { Link, useNavigate,useLocation } from 'react-router-dom'
//Redux
import { connect, useSelector } from "react-redux";
//API Methods
import { newRegistrationUser } from '../../actions/auth/registration';
import { sendLoginOtp } from "../../actions/auth/login"
import { fetchCustomerByNumber } from "../../actions/customer/getCustomerByNumber"
import "../../style/css/login.css"
import axios from "axios";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { UpdateCartItemsBybrowserIdCustomerIdAction } from '../../actions/products/cart/updateCartItemBybandCId';
import { useDispatch } from 'react-redux';

import { Spinner } from 'react-bootstrap';
import { basicAuth } from '../../actions/auth/basicAuth';
import { url } from '../../actions/auth/baseUrl';
import localStorage from 'redux-persist/es/storage';
function OerifyOtp(props) {

    const navigate = useNavigate()
    const dispatch=useDispatch()
    const backgroundStyle = {
        backgroundImage: `url('path-to-your-background-image.jpg')`, // Replace 'path-to-your-background-image.jpg' with the actual path to your image file
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    };
    const userData=useSelector((state)=>state?.getCustomerByNumber?.data)
    const loaded=useSelector((state)=>state?.getCustomerByNumber?.loading)

    console.log(userData,'userDatattatata')

    const location = useLocation();

    
    const useData = location.state;
    console.log(useData,'useDatauseDatauseDatauseDatauseData')

    const {
        handleSendLoginOtp,
        message,
        error,
        handleFetchCustomerByNumber,
        customerMessage,
        customerError,
        handleNewRegistrationUser
    } = props
    const [number, setNumber] = useState("")
     const [yesOtp,setYesOtp]=useState(false)
     const [email,setemail]=useState('')
    
    const [otpverify, setOtpVerify] = useState(false);
    const [phone, setPhone] = useState("");
    const [hasFilled, setHasFilled] = useState(false);
    const [otp, setOtp] = useState("");
    const handleLogin = () => {
      setTimeout(()=>{
        if (localStorage.getItem("message") === "CustomerNotExist") {

            localStorage.removeItem("message");
            Swal.fire({
                title: 'Customer Is Not Exist',
                text: 'Please Signup Your Account To Continue',
                icon: 'error',
            });

            navigate("/registration")
        }
      },[2000])
        handleFetchCustomerByNumber(useData)
        console.log(useData,'useDatauseDatauseDatauseDatauseData')
        
    }
const newemial=localStorage.getItem('registernumber')
  
    const handleotp = async () => {
        const number=await newemial.then(item=>item)
        console.log(number,'newnumber')
  const newdata = {

    "phone":number,
    "otp": otp
  };

  const headers = {
    "Content-Type": "application/json",
    'Authorization': basicAuth,
  };

  const customerId = localStorage.getItem('customerId');

  try {
    const response = await axios.post(`${url}verifyOtpAndCreateCustomer`, newdata, { headers });
    // Handle the response as needed
    console.log('OTP verified and customer created:', response.data);
    if(response.status==200){
        Swal.fire({
            title: 'OTP Verify successfully',
            text: 'Your OTP Has been successfully verified',
            icon: 'success',
        });
const redirectTo =await localStorage.getItem('redirectAfterLogin') || '/';
    console.log(redirectTo)

        localStorage.removeItem("Otp")
        localStorage.setItem('login', "true");
        console.log(response.data,'custtiomerrrrrrrrrr')
        localStorage.setItem('customerId',response.data?.customerId)
        localStorage.setItem('customername',response.data?.name)
        dispatch(UpdateCartItemsBybrowserIdCustomerIdAction())
        navigate(redirectTo);

    } else {
        Swal.fire({
            title: 'Invalid OTP',
            text: 'The OTP you entered is incorrect. Please check the OTP and try again.',
            icon: 'error',
        });
    }


    // Perform any further actions, such as navigating to another page or updating the state
  } catch (error) {
        Swal.fire({
            title: 'Invalid OTP',
            text: 'The OTP you entered is incorrect. Please check the OTP and try again.',
            icon: 'error',
        });

    // Handle the error as needed, such as displaying an error message to the user
  }
};



    
 
    const OtpData = localStorage.getItem("Otp")
    //Handle Check Customer Is exit or not
    useEffect(() => {
       
        if (localStorage.getItem("message") == "CustomerExist") {
            localStorage.removeItem("message");
            handleSendLoginOtp(number)
        }
        if (localStorage.getItem("message") === "CustomerNotExist") {
            localStorage.removeItem("message");
            Swal.fire({
                title: 'Customer Is Not Exist',
                text: 'Please Signup',
                icon: 'error',
            });
            navigate("/registration")
        }
    }, [customerMessage, customerError])



    const handleRegister = async() => {
        const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
        localStorage.setItem("number", userData.mobile);
        const numbjer=localStorage.getItem('number')
          handleNewRegistrationUser(useData);
          await Swal.fire({
            title: "OTP Resent",
            text: "Please Check OTP Sent",
            icon: "success",
          });
      };
    //CHeck Otp Sent Successfully or not
    // useEffect(() => {

    //     if (localStorage.getItem("message") === "OtpSentSuccessfully") {


    //         localStorage.removeItem("message");
    //         setHasFilled(true);

    //     }
    //     if (localStorage.getItem("message") === "OtpFailedToSend") {

    //         Swal.fire({
    //             title: 'Something Went Wrong!!',
    //             text: 'Please enter right otp',
    //             icon: 'error',
    //         });
    //         localStorage.removeItem("message");
    //     }
    // }, [message, error])
 
    //Handle Verify Otp


    const handleVerifyOtp = () => {
        if (OtpData === otp) {
            const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
            
            Swal.fire({
                title: 'Otp Verify Success',
                text: 'Your Otp Has been successfully verified',
                icon: 'success',
            });
            localStorage.removeItem("Otp")
            localStorage.setItem('login', "true");
            localStorage.setItem('customerId',userData?.customerId)
            navigate(redirectTo);

        }
        else {
            Swal.fire({
                title: 'Otp Verification Failed',
                text: 'Please enter right otp',
                icon: 'error',
            });
        }
    }

        return (
            <div className='body_color'>
                 <style>
                    {
                        `
                        @media(max-width:767px){
                        .bobobokobk{
                        display:none;
                        }
                        }
                        `
                    }
                </style>
                <div class="box-form" style={{ marginTop: "70px" }}>
                    <div class="left">
                        <div class="overlay" >
                            <p>
                            </p>
                            <span>
                            </span>
                        </div>
                    </div>
                    <div class="right">
                        <h5 style={{ boxShadow: "10px 5px 5px gray", fontWeight: 1000, marginTop: "40px" }}>OTP</h5>
                        <div class="inputs">
                        <br></br>
                        <span style={{fontSize:'12px',marginTop:'15px'}}> We have send OTP to your number</span>
                            <input type="text" placeholder="Enter Your OTP Here"  onChange={(e) => setOtp(e.target.value)} />
                        </div>
                        <div style={{display:'flex',justifyContent:'end',width:'100%',alignItems:'end',marginTop:'3px',cursor:'pointer'}}>
                            <p onClick={handleRegister} style={{color:'red'}}>Resend OTP</p>
                        </div>
                        
                        <div class="" style={{display:'flex',gap:'4px',flexWrap:'wrap',justifyContent:'',marginTop:'',padding:'0px'}}>

                            
                            

                            <button  style={{margin:'auto'}} onClick={handleotp}>Verify</button>
                           
                           </div>
                    </div>
                </div>
            </div>
        );
}
const mapStateToProps = (state, ownProps) => {
    return {
        fetchAllCityData: state.getAllCity.data,
        message: state.sendOtpSms.message,
        error: state.sendOtpSms.error,
        customerMessage: state.getCustomerByNumber.message,
        customerError: state.getCustomerByNumber.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleSendLoginOtp: (number) => dispatch(sendLoginOtp(number)),
        handleFetchCustomerByNumber: (number) => dispatch(fetchCustomerByNumber(number)),
        handleNewRegistrationUser: (userData) =>  dispatch(newRegistrationUser(userData)),
    };
      
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OerifyOtp);
