import React from 'react'
import { Drawer, ButtonToolbar, Button, IconButton, Placeholder } from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import AngleLeftIcon from '@rsuite/icons/legacy/AngleLeft';
import AngleDownIcon from '@rsuite/icons/legacy/AngleDown';
import AngleUpIcon from '@rsuite/icons/legacy/AngleUp';
import wallet from './wallet.png'


import 'rsuite/dist/rsuite.min.css';
import { ThumbDown } from '@mui/icons-material';
const SeamLesss = () => {
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const isMobile = window.innerWidth <= 768;

    const handleOpen = key => {
        setOpen(true);
        setPlacement(key);
    };
    return (
        <div>
            <style>
                {

                    `
                    p{
                    font-family:Recline !important;
                    }
                      .content {
            font-size: 0.9em; /* Adjust font size here */
            margin: 20px;
        }
        h2 {
            font-size: 1.1em;
            color: #333;
        }
        ul {
            list-style-type: disc;
            margin-left: 20px;
        }
        li {
            margin-bottom: 10px;
        }
            @media(max-width:768px){
            }
                    
                    `
                }
            </style>





            


            {
                isMobile ? (
                    <>
                   <div onClick={() => handleOpen('bottom')} class="MuiBox-root css-ygagt" style={{background:'aliceblue',gap:'10px',borderRadius:'12px'}}>
                    <div class="MuiBox-root css-om8xn2">
                        <img src={wallet} height={'30px'} width={'31px'}/>
                        </div><div class="MuiBox-root css-0"><p class="MuiTypography-root MuiTypography-body1 css-1mnj09s">Seamless Delivery Guarented!</p><p class="MuiTypography-root MuiTypography-body1 css-ucxyi3">To ensure a seamless process, kindly complete your <span>KYC verification</span> after placing your order</p></div><div class="MuiBox-root css-j14fc5"><button class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-rrlqo" tabindex="0" type="button"><svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16" fill="#fff"></rect><path d="M11.332 16h9.333M16 11.333 20.667 16 16 20.667" stroke="#222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg><span class="MuiTouchRipple-root css-w0pj6f">
                    </span></button></div></div>
                       
                    </>
                ) : (
                    <>
                    <div onClick={() => handleOpen('right')} class="MuiBox-root css-ygagt">
                    <div class="MuiBox-root css-om8xn2">
                    <img src={wallet} height={'30px'} width={'30px'}/>
                        
                        </div><div class="MuiBox-root css-0"><p class="MuiTypography-root MuiTypography-body1 css-1mnj09s">Seamless Delivery Guarented!</p><p class="MuiTypography-root MuiTypography-body1 css-ucxyi3">To ensure a seamless process, kindly complete your <span>KYC verification</span> after placing your order</p></div><div class="MuiBox-root css-j14fc5"><button class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-rrlqo" tabindex="0" type="button"><svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="16" fill="#fff"></rect><path d="M11.332 16h9.333M16 11.333 20.667 16 16 20.667" stroke="#222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg><span class="MuiTouchRipple-root css-w0pj6f">
                    </span></button></div></div>
                       
                    </>
                )
            }
                         

           <Drawer placement={placement} open={open} onClose={() => setOpen(false)}>
    <Drawer.Header style={{position:'unset'}}>
        <Drawer.Title style={{ fontWeight: '700', flexGrow: 1 }}>
            <h2 style={{ fontSize: '15px', marginTop: "10px" ,textAlign:'center'}}>KYC Verification Timeline</h2>
        </Drawer.Title>
    </Drawer.Header>
    <Drawer.Body>
        <div className="content">
            <h5>Here are some general steps and typical timelines:</h5>
            <ul>
                <li><h6>Submission of Documents:</h6> Immediately after placing the order.</li>
                <li><h6>Initial Review:</h6> Few hours to a couple of days.</li>
                <li><h6>Detailed Verification:</h6> 1 to 2 days.</li>
                <li><h6>Approval/Completion:</h6> Another day or two.</li>
            </ul>
        </div>
    </Drawer.Body>
</Drawer>

        </div>
    );
};

export default SeamLesss