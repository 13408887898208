import React from "react";

const PrivacyPolicynew = () => {
    return (
    <>
            
            <div>
    <h3>How to Request Renty Furniture App Account Deletion?</h3>
    <p><strong>Last Updated On:</strong> 13 Aug, 2024</p>
    <p>We value your privacy and understand that there may be circumstances where you'd like to request the deletion of your account. We are committed to ensuring a straightforward and hassle-free process for our users. If you wish to delete your account, please follow the steps outlined below.</p>
    
    <h4>How to Request Account Deletion:</h4>
    <ul>
        <li><strong>Compose an Email:</strong> Open your email client and create a new message.</li>
        <li><strong>Recipient:</strong> Send the email to <a href="mailto:complain@rentyfurniture.com">complain@rentyfurniture.com</a>.</li>
        <li><strong>Subject:</strong> Please use the subject line "Renty Furniture Account Deletion Request."</li>
        <li><strong>Email Content:</strong> In the body of the email, kindly provide the following information:</li>
    </ul>
    
    <h4>Required Information:</h4>
    <ul>
        <li><strong>Your Full Name</strong></li>
        <li><strong>Username (if applicable)</strong></li>
        <li><strong>Email Address</strong> associated with your account</li>
        <li><strong>Phone Number</strong> associated with your account</li>
        <li><strong>Reason for Account Deletion:</strong> Please provide a brief explanation for your request. We appreciate your feedback, and this information can help us improve our services.</li>
    </ul>

    <p><strong>Send the Email:</strong> Once you have filled out the necessary information and reviewed it, send the email to <a href="mailto:complain@rentyfurniture.com">complain@rentyfurniture.com</a>.</p>
    
    <h4>Confirmation and Processing:</h4>
    <p>Upon receiving your account deletion request, our support team will confirm your identity and review the provided reason for deletion. We may contact you for further clarification if needed.</p>
    <p>Please note that account deletion requests are typically processed within 7 days, but this may vary depending on the volume of requests. Once your request has been processed, you will receive a confirmation email to your registered email address.</p>
    
    <h4>Important Information:</h4>
    <ul>
        <li><strong>Data Retention:</strong> We will securely delete all of your personal information and account data once your request is processed, in compliance with our privacy policy.</li>
        <li><strong>Access to Services:</strong> After your account is deleted, you will no longer have access to the Renty Furniture App and will need to re-register if you wish to use our services in the future.</li>
        <li><strong>Recovery:</strong> Account deletion is irreversible, and we will not be able to recover your account or data after the process is completed.</li>
    </ul>

    <p>We are dedicated to safeguarding your privacy and ensuring a smooth account deletion process. If you have any questions or encounter any issues during the process, please do not hesitate to contact our support team at <a href="mailto:support@rentyfurniture.com">support@rentyfurniture.com</a>.</p>
</div>

    
    </>
)
}

export default PrivacyPolicynew;
