const initialState = {
    data: {}
};


export const setCityPincodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_CITY_PINCODE_DATA":
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};
