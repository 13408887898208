import {
    DELETE_CARD_ITEM_BY_ID_REQUEST,
    DELETE_CARD_ITEM_BY_ID_SUCCESS,
    DELETE_CARD_ITEM_BY_ID_FAILURE,
} from "../../types";

import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";
import { fetchCartProducts } from "./getCartProducts"


export const deleteCardItemByIdRequest = () => {
    return {
        type: DELETE_CARD_ITEM_BY_ID_REQUEST,
    };
};
export const deleteCardItemByIdSuccess = (Data) => {
    return {
        type: DELETE_CARD_ITEM_BY_ID_SUCCESS,
        payload: Data,
    };
};

export const deleteCardItemByIdFailure = (Error) => {
    return {
        type: DELETE_CARD_ITEM_BY_ID_FAILURE,
        payload: Error,
    };
};

export const deleteCardItemById = (productId) => {
    const headers = {
        Accept: "Application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(deleteCardItemByIdRequest());
        axios
            .delete(url + `deleteCartItem/${productId}`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(deleteCardItemByIdSuccess(response.data));
                dispatch(fetchCartProducts())
            })
            .catch(function (error) {
                dispatch(deleteCardItemByIdFailure(error));
            });
    };
};
