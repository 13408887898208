import React from "react";
import { Drawer, ButtonToolbar, Button, IconButton, Placeholder } from "rsuite";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import AngleDownIcon from "@rsuite/icons/legacy/AngleDown";
import AngleUpIcon from "@rsuite/icons/legacy/AngleUp";
import like from "./like.png";

import "rsuite/dist/rsuite.min.css";
import { ThumbDown } from "@mui/icons-material";
const Condition = () => {
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const isMobile = window.innerWidth <= 768;

  const handleOpen = (key) => {
    setOpen(true);
    setPlacement(key);
  };
  return (
    <div>
      <style>
        {`
                    p{
                    font-family:Recline !important;
                    }
                     }
        .terms-conditions {
            font-size: 0.9em; /* Adjust font size here */
            margin: 20px;
        }
        h2 {
            font-size: 1.1em;
            color: #333;
        }
        ul {
            list-style-type: disc;
            margin-left: 20px;
        }
        li {
            margin-bottom: 10px;
        }
                    
                    `}
      </style>

      {isMobile ? (
        <>
          <div
            class="MuiBox-root css-dnzdvo"
            style={{
              border: "1px solid black",
              background: "aliceblue",
              marginTop: "-2px",
              borderRadius: "14px",
            }}
          >
            <div
              class="MuiBox-root css-u01ki0"
              onClick={() => handleOpen("bottom")}
            >
              <div class="MuiBox-root css-1kz6cvi">
                <div class="MuiBox-root css-12z0wuy">
                  <div
                    class="MuiBox-root css-1s0tc6b"
                    style={{ paddingTop: "" }}
                  >
                    <img alt="" src={like} class="loaded" />
                  </div>
                </div>
                <div class="MuiBox-root css-0">
                  <p class="MuiTypography-root MuiTypography-body1 css-10lfzr7">
                    Terms and Conditions:
                  </p>
                  <div class="MuiBox-root css-1kz6cvi">
                    <p
                      class="MuiTypography-root MuiTypography-body1 css-1ra57w1"
                      style={{ marginLeft: "25px" }}
                    >
                      Condition : As good as new{" "}
                      <span style={{ color: "blue" }}>:- Know More </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div onClick={() => handleOpen("right")}>
            Condition : As good as new{" "}
            <span style={{ color: "blue" }}>:- Know More </span>
          </div>
        </>
      )}
      <Drawer placement={placement} open={open} onClose={() => setOpen(false)}>
        <Drawer.Header style={{position:'unset'}}>
          <Drawer.Title style={{ fontWeight: "700" ,textAlign:'center'}}>
            Renty Furniture
          </Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <div class="terms-conditions">
            <ul>
              <h3>Product Condition</h3>
              <h5>Brand New</h5>
              <li>
                Brand new products are packed directly from the company,
                ensuring perfect condition with no scratches, dents, or signs of
                wear and tear.
              </li>
              <h5>Refurbished Products</h5>
              <li>
                All our products are expertly restored to their original
                condition at our state-of-the-art refurbishment centre.
              </li>
              <li>
                For a few products like appliances and pre-lam furniture, please
                expect minor scratches or dents which won&#39;t affect the
                aesthetics or functionality of the product.
              </li>
            </ul>
          </div>
        </Drawer.Body>
      </Drawer>
    </div>
  );
};

export default Condition;
