import React, { useEffect } from 'react'

function Cancellation_return() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div style={{marginTop:'60px'}}>
          
<h3>Payment and Refund Policy</h3>
    <ol>
        <li><strong>Upfront Rental Payment:</strong> Subscribers are required to make an upfront rental payment to book a subscription plan on the Company's website. This amount is refundable only if the subscription or order is canceled before or at the time of delivery. Once the subscribed products are delivered, the payment is non-refundable, except if the subscriber has opted for the Flexi Cancellation Pass. Refunds under the Flexi Cancellation Pass are calculated according to the example provided in the Flexi Cancellation Pass section..</li>
        <li><strong>Billing Cycle::</strong> o	The billing cycle begins on the date the last item in a bundle is delivered to the subscriber’s address. This date plus one month constitutes the billing cycle..</li>
        <li><strong>Discount and Payment:</strong> Customers opting for the No Cost EMI method will receive an upfront discount equivalent to the interest charged by the bank. This ensures that the total EMI amount equals the total invoice amount of the No Cost EMI eligible products at the time of purchase. For Bank Credit Card payments, the offer applies to subscription plans selected in a single checkout where the total sale price (net of applicable discounts) is Rs. 5000 or more.
            <br/><strong>Example:</strong>
            <ul>
                <li>Cart Value: INR 40,000</li>
                <li>No Cost EMI Tenure: 6 Months</li>
                <li>Rate of Interest: 15% p.a.</li>
                <li>Upfront Discount: INR 1750</li>
                <li>Payment by Customer: INR 38,250</li>
                <li>Invoice Value: INR 38.250 (after discount)</li>
            </ul>
            <strong>Bank Statement Example:</strong>
            <ul>
                <li>EMI 1: INR 6,666 (Interest: INR 500, Principal: INR 6,166)</li>
                <li>EMI 2: INR 6,666 (Interest: INR 416, Principal: INR 6,250)</li>
                <li>EMI 3: INR 6,666 (Interest: INR 334, Principal: INR 6,334)</li>
                <li>EMI 4: INR 6,666 (Interest: INR 250, Principal: INR 6,418)</li>
                <li>EMI 5: INR 6,666 (Interest: INR 166, Principal: INR 6,500)</li>
                <li>EMI 6: INR 6,666 (Interest: INR 84, Principal: INR 6,584)</li>
                <li>Total: INR 40,000 (Interest: INR 1750, Principal: INR 38,250)</li>
            </ul>
        </li>
        <li><strong>Charges:</strong> RENTYFURNITURE does not charge for the No Cost EMI payment method. However, any additional charges such as GST or other taxes applied to the purchase will be levied by the bank issuing the credit or debit card. The bank's policies on EMI facilities, including any additional charges or cancellation fees, apply.</li>
        <li><strong>Bank Discretion:</strong> The extension of the EMI facility is at the discretion of the issuing bank. RENTYFURNITURE is not liable if the bank refuses to process the transaction.</li>
        <li><strong>Invoice and EMI Terms:</strong> The invoice will reflect the net value of the product purchased. The EMI will proceed according to the plan selected during checkout. Any delay or failure in EMI payments may incur additional interest or penalties by the bank, which will be the customer’s responsibility. RENTYFURNITURE is not liable for any additional charges.</li>
        <li><strong>Processing Fees:</strong> RENTYFURNITURE will not charge a processing fee for EMI options. Banks may impose processing or cancellation fees as per their policies, which are non-refundable even in case of order return or cancellation.</li>
        <li><strong>Cancellation and Refunds:</strong> The No Cost EMI program will continue as is even if cancellations or returns occur. Customers are responsible for managing the No Cost EMI program with their credit card issuer. The program is available only in India and requires the cardholder to be at least 18 years old.</li>
        <li><strong>Order Cancellation:</strong> RENTYFURNITURE reserves the right to cancel orders in cases of fraudulent activity, including duplicate accounts or multiple orders. In such cases, the order will not be reinstated. The decision of RENTYFURNITURE will be final and binding.</li>
        <li><strong>Bank Services:</strong> RENTYFURNITURE does not warrant or represent the services offered by any bank, including the EMI facility. By availing of this offer, customers agree that RENTYFURNITURE is not liable for any issues related to bank services or benefits.</li>
        <li><strong>Flexi-Cancellation Pass:</strong> If a customer purchases a Flexi-Cancellation Pass, they can cancel their subscription during the tenure and receive a refund of the outstanding principal amount post-termination. Any NC-EMI charges incurred after the subscription ends will not be covered by the company. Amount to be refunded will be the principal outstanding at termination or the amount eligible for refund assuming no NC-EMI, minus interest charged after termination. Subscribers should settle the loan with their banker to avoid future interest or other charges.</li>
   
      
    </ol>
        </div>
    )
}

export default Cancellation_return