
import {
    getAllldisocuntReq,
    getAllldisocuntSucccess,
    getAllldisocuntFail,
} from "../types";


import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const GetAlldisscountttReqqqq = () => {
    return {
        type: getAllldisocuntReq,
    };
};
export const getAlldicsundtttttsuccedss = (Data) => {
    return {
        type: getAllldisocuntSucccess,
        payload: Data,
    };
};

export const GetAllldisocunttttfail = (Error) => {
    return {
        type: getAllldisocuntFail,
        payload: Error,
    };
};




export const GETALLDISCOUNTCOUPEN = () => {
    const headers = {
        "Content-Type": "Application/json",
        'Authorization': basicAuth,
    };
    const addressId = localStorage.getItem('customerId')
    return (dispatch) => {
        dispatch(GetAlldisscountttReqqqq());
        axios
            .get(url + `getAllDiscountAndCouponsList`,
                {
                    headers: headers,
                })
            .then(function (response) {
                localStorage.setItem("message", "getFilterByProductSuccessfully")
                
                dispatch(getAlldicsundtttttsuccedss(response.data));
            })
            .catch(function (error) {
                localStorage.setItem("message", "getFilterByProductFailed")
                dispatch(GetAllldisocunttttfail(error));
            });
    };
};
