// NoInternetPage.js
import React, { useEffect, useState } from 'react';

const NoInternetPage = () => {
  
  // If the user is offline, show a no internet connection message
    return (
      <div style={styles.container}>
        <h1 style={styles.title}>No Internet Connection</h1>
        <p style={styles.message}>It looks like you're offline. Please check your connection and try again.</p>
      </div>
    );

  // If the user is online, just return null or render the main content
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f8d7da',
    color: '#721c24',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
  },
  title: {
    fontSize: '36px',
    margin: '20px 0',
  },
  message: {
    fontSize: '18px',
    marginBottom: '20px',
  }
};

export default NoInternetPage;
