import {
    UpdateCartItemsBybrowserIdCustomerIdReq,
    UpdateCartItemsBybrowserIdCustomerIdSucesss,
    UpdateCartItemsBybrowserIdCustomerIdFail,
} from "../../../actions/types";
const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};

export const UpdateCartItemsBybrowserIdCustomerId = (state = initialState, action) => {
    switch (action.type) {
        case UpdateCartItemsBybrowserIdCustomerIdReq:
            return {
                ...state,
                loading: true,
            };
        case UpdateCartItemsBybrowserIdCustomerIdSucesss:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "successfully fatched"
            };
        case UpdateCartItemsBybrowserIdCustomerIdFail:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
        default:
            return state;
    }
};
