

import {
    GetaddressByAddeessIdReq,
    GetaddressByAddeessIdSuccc,
    GetaddressByAddeessIdFAIL,
} from "../types";

import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const getAddressbyAdddrsssiDrEQ = () => {
    return {
        type: GetaddressByAddeessIdReq,
    };
};
export const getAddressbyAdddrsssiDrSUCC = (Data) => {
    return {
        type: GetaddressByAddeessIdSuccc,
        payload: Data,
    };
};

export const getAddressbyAdddrsssiFAIL = (Error) => {
    return {
        type: GetaddressByAddeessIdFAIL,
        payload: Error,
    };
};



export const GETADDRSSSBYADDRESSID = () => {
    const headers = {
        "Content-Type": "Application/json",
        'Authorization': basicAuth,
    };
    const addressId = localStorage.getItem('addressIsssd')
    return (dispatch) => {
        dispatch(getAddressbyAdddrsssiDrEQ());
        axios
            .get(url + `getAddressById/${addressId}`,
                {
                    headers: headers,
                })
            .then(function (response) {
                localStorage.setItem("message", "getFilterByProductSuccessfully")
                
                dispatch(getAddressbyAdddrsssiDrSUCC(response.data));
            })
            .catch(function (error) {
                localStorage.setItem("message", "getFilterByProductFailed")
                dispatch(getAddressbyAdddrsssiFAIL(error));
            });
    };
};
