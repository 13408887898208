import React,{useEffect} from 'react'

function Document_Requred() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div class="about-us-area pt-100 pb-50" bis_skin_checked="1" style={{marginTop:"100px"}}>
                <div class="container" bis_skin_checked="1">
                    <div class="row align-items-center flex-row-reverse" bis_skin_checked="1">
                        <div class="col-lg-6"
                            style={{ marginBottom: "37px" }} bis_skin_checked="1">
                            <div style={{ background: "#fff", border: "1px solid #bababa", borderRadius: "10px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", padding: "20px" }} bis_skin_checked="1" >
                                <h4>
                                    <i class="pe-7s-add-user" style={{ color: "rgb(245, 99, 88)", fontWeight: "bold" }}></i> Self Employed
                                </h4>
                                <b>
                                    Mandatory Documents
                                </b>
                                <ul class="list-number kyc-list">
                                    <li>Pan Card</li>
                                    <li>Selfie</li>
                                    <li>GST number</li>
                                </ul>
                                <p><span class="rm-green__text">
                                    Note:
                                </span>
                                    While we appreciate you sharing your documents, please note that Renty Furniture reserves the right to confirm or decline the order on a case by case basis.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6" style={{ marginBottom: "37px" }} bis_skin_checked="1">
                            <div style={{ background: "#fff", border: "1px solid #bababa", borderRadius: "10px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", padding: "20px" }} bis_skin_checked="1">
                                <h4>
                                    <i class="pe-7s-network" style={{ color: "rgb(245, 99, 88)", fontWeight: "bold" }}></i> Working Professional
                                </h4>
                                <b>
                                    Mandatory Documents
                                </b>
                                <ul class="list-number kyc-list">
                                    <li>Pan Card</li>
                                    <li>Selfie</li>
                                    <li>Company name and Official Email id</li>
                                </ul>
                                <p><span class="rm-green__text">
                                    Note:
                                </span>
                                    While we appreciate you sharing your documents, please note that Renty Furniture reserves the right to confirm or decline the order on a case by case basis.
                                </p>
                            </div>
                        </div>


                        <div class="col-lg-6" style={{ marginBottom: "37px" }} bis_skin_checked="1">

                            <div style={{ background: "#fff", border: "1px solid #bababa", borderRadius: "10px", padding: "20px", maxHeight: "534px", minHeight: "490px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", padding: "20px" }} bis_skin_checked="1">

                                <h4>
                                    <i class="pe-7s-exapnd2" style={{ color: "rgb(245, 99, 88)", fontWeight: "bold" }}></i> Students
                                </h4>

                                <b>
                                    Mandatory Documents
                                </b>
                                <ul class="list-number kyc-list">
                                    <li>Govt id card</li>
                                    <li>Profile pic</li>
                                    <li>Bank statement</li>
                                    <li>College name</li>
                                </ul>
                                <b>
                                    Additional Documents
                                </b>
                                <p> We might also ask for the following docs in some cases

                                </p>
                                <ul class="list-number kyc-list">
                                    <li>Parents Bank Statement</li>
                                    <li>Parents Govt id</li>
                                </ul>
                                <p>* 6 months Bank Statement might be asked to be uploaded in some cases </p>
                                <p><span class="rm-green__text">
                                    Note:
                                </span>
                                    While we appreciate you sharing your documents, please note that Renty Furniture reserves the right to confirm or decline the order on a case by case basis.
                                </p>

                            </div>

                        </div>

                        <div class="col-lg-6" style={{ marginBottom: "37px" }} bis_skin_checked="1">
                            <div style={{ background: "#fff", border: "1px solid #bababa", borderRadius: "10px", padding: "20px", maxHeight: "534px", minHeight: "490px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", padding: "20px" }} bis_skin_checked="1">
                                <h4>
                                    <i class="pe-7s-paper-plane" style={{ color: "rgb(245, 99, 88)", fontWeight: "bold" }}></i> Freelance
                                </h4>

                                <b>
                                    Mandatory Documents
                                </b>
                                <ul class="list-number kyc-list">
                                    <li>Pan Card</li>
                                    <li>Selfie</li>
                                    <li>Bank statement</li>
                                </ul>
                                <p><span class="rm-green__text">
                                    Note:
                                </span>
                                    While we appreciate you sharing your documents, please note that Renty Furniture reserves the right to confirm or decline the order on a case by case basis.
                                </p>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default Document_Requred