import {
    GET_CUSTOMER_BY_PHONE_NUMBER_REQUEST,
    GET_CUSTOMER_BY_PHONE_NUMBER_SUCCESS,
    GET_CUSTOMER_BY_PHONE_NUMBER_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const fetchCustomerByNumberRequest = () => {
    return {
        type: GET_CUSTOMER_BY_PHONE_NUMBER_REQUEST,
    };
};
export const fetchCustomerByNumberSuccess = (Data) => {
    return {
        type: GET_CUSTOMER_BY_PHONE_NUMBER_SUCCESS,
        payload: Data,
    };
};

export const fetchCustomerByNumberFailure = (Error) => {
    return {
        type: GET_CUSTOMER_BY_PHONE_NUMBER_FAILURE,
        payload: Error,
    };
};

export const fetchCustomerByNumber = (number) => {
    console.log(number)
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchCustomerByNumberRequest());
        axios
            .get(url + `getCustomerByPhonenumber/${number}`, {
                headers: headers,
            })
            .then(function (response) {

                if (response.data !== "customer Not present") {
                    localStorage.setItem("message", "CustomerExist")

                    dispatch(fetchCustomerByNumberSuccess(response.data));
                }
                else {
                    localStorage.setItem("message", "CustomerNotExist")
                    localStorage.setItem("number",number)


                    dispatch(fetchCustomerByNumberFailure(response.data));
                }
            })
            .catch(function (error) {
                dispatch(fetchCustomerByNumberFailure(error));
                localStorage.setItem("message", "CustomerNotExist")
            });
    };
};
