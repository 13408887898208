import { combineReducers } from "redux";
//Auth
import { sendOtpSmsReducer } from "./auth/login"
import { newUserRegistrationReducer } from "./auth/registration"
//Customer
import { getCustomerByNumberReducer } from "./customer/getCustomerByNumber"
import { getCustomerByIdReducer } from "./customer/getCustomerById"
//Products
import { getProductsByFilterReducer ,getProductsByFilterHomeReducer} from "./products/getProductsByFilter"
import { getDiscountProductsReducer } from "./products/getDiscountProducts"
import { getBestRatingProductsReducer } from "./products/bestRatingProducts"
import { getCartProductsReducer } from "./products/cart/getCartProducts"
import { addToCartProductReducer } from "./products/cart/addToCartProduct"
import { getSearchProductReducer } from "./products/productSearch"
import { getAllOrdersByCustomerIdReducer } from "./products/orders/getAllOrdersByCustomerId"
import { getWishListReducer } from "./products/wishList/getWishList"
import { getProductByIdReducer } from "./products/getProductById"
import { addWishListProductReducer } from "./products/wishList/addCustomerWishList"
import { deleteWishListByCustomerIdReducer } from "./products/wishList/deleteWishListByCustomerId"
import { deleteCardItemByIdReducer } from "./products/cart/deleteCardItemById"
//category
import { getAllMainCategoryReducer } from "./products/mainCategory/getAllMainCategory"
import { getAllMainCategoryDropdownReducer } from "./products/mainCategory/getAllMainCategoryDropdown"
//sub category
import { getAllSubCategoryReducer } from "./products/subCategory/subCategory"
import { getSubCategoryDropdownByIdReducer } from "./products/subCategory/getAllSubCategoryDropdown"
//super category
import { getAllSuperCategoryReducer } from "./products/superCategory/superCategory"
import { getSuperCategoryDropdownByIdReducer } from "./products/superCategory/getAllSuperCategoryDropdown"
//Banners
import { getCrouselBannersReducer } from "./banners/crouselBanners"
import { getCenterBannersReducer } from "./banners/centerBanners"
import { getMiddleBannersReducer } from "./banners/middleBanner"
import { getBottomBannersReducer } from "./banners/bottomBanner"
//Pages
import { getAboutUsReducer } from "./pages/about"
import { getAllBlogsReducer } from "./pages/blogs"
import { getCancellationAndReturnReducer } from "./pages/cancellationsAndReturn"
import { getAllFaQReducer } from "./pages/faq"
import { getPrivacyAndPolicyReducer } from "./pages/privacyPolicy"
import { getRantalTermsAndConditionReducer } from "./pages/rentalTermsAndCondition"
//City
import { getAllCityReducer } from "./city/getAllCity"
import { setCityPincodeReducer } from "./city/setCityPinCode"


//set Main Category Data
import { setMainCateogryDataReducer } from "./products/setMainCategoryData"
// search products
import { getProductsSearchReducer } from "./products/getProductsbysearch";
// mostproducts
import { getmMostOrderProductsReducer } from "./products/getmostorderProducts";

import {getProductsbyDiscountIDhReducer} from './products/getrProductsByDiscount'

import { AddProductOrder } from "./products/orders/addorder";
import { UpdateCartQuantity } from "./products/updateCartItem";

import { AddaddressReducer } from "./products/addAddress";

import { GetaddresByCustomerId } from "./products/getcustomerAddressById";

import { UpdateAddresByCustomerID } from "./products/updateCustomerAddressById";

import { AddpaymentReducer } from "./products/addpayment";

import { ActiveRentalPlans } from "./products/orders/activeSub";

import { UpdateQunatity } from "./products/updateQuantity";
import { Durationreducer } from "./products/setduration";
import { generateOderByid } from "./products/orders/generateOrderId";
import { getInvoice } from "./products/orders/getInvoice";
import { getReciptsbSuccefull } from "./products/orders/getRecipts";

import { updateUserProfile } from "./auth/updateuser";
import { getcanccel } from "./products/orders/getAlcancel";
import { UpdateCartItemsBybrowserIdCustomerId } from "./products/cart/updateCartItermByBrwoserId";
import { getAlladdressBycustomerId } from "./products/getAlladdressBycustomerId";
import { GetProductListingredcuer } from "./products/productslistReducer";
import { GetAllPincodeByCityIDREDCUER } from "./products/getAllpincodeByCityIdReducer";

import { GetRelatableProductsReducer } from "./products/getrelatbaleproducReducer";
import { GETADDRESSBYADDRESSID } from "./products/getADDESSBYAdressIdReducer";
import { setAddressIdReducer } from "./adressIdreducer";
import { getAllproductRedcuer } from "./products/getallproducts.js";
import { GetAlldiscountCopenReducer } from "./products/getAlldiscountCopenReducer.js";
import { GetdiscountByIdReducer } from "./products/orders/getDiscountBydiscountId.js";
import { cancelrequestofrentalplanreducer } from "./products/cancenrentalplanreducer.js";
import { generateSignatureReducer } from "./products/cart/generateSignature.js";
import { SendOtpAdharReducer } from "./products/cart/sendOtpReducer.js";
import { VerifiOtpRedcucer } from "./products/cart/verifyotpreducer.js";
import { VerifiOtpPanRedcucer } from "./products/cart/verifyotpPanReducer.js";
import { UpdateRentalAgreementReducer } from "./products/orders/updateRentalAgreemntReudcer.js";
import { getAgrrementByOrderIdReducer } from "./products/orders/getRentagreementReducer.js";
import { RenewOrderReducer } from "./products/renewOrderReducer.js";
export const rootReducer = combineReducers({
    //Auth
    sendOtpSms: sendOtpSmsReducer,
    newUserRegistration: newUserRegistrationReducer,
    //Customer
    getCustomerByNumber: getCustomerByNumberReducer,
    getCustomerById: getCustomerByIdReducer,
    //Products
    getProductsByFilter: getProductsByFilterReducer,
    getDiscountProducts: getDiscountProductsReducer,
    getBestRatingProducts: getBestRatingProductsReducer,
    getSearchProduct: getSearchProductReducer,
    getAllOrdersByCustomerId: getAllOrdersByCustomerIdReducer,
    getProductById: getProductByIdReducer,
    addWishListProduct: addWishListProductReducer,
    deleteWishListByCustomerId: deleteWishListByCustomerIdReducer,

    //category
    getAllMainCategory: getAllMainCategoryReducer,
    getAllMainCategoryDropdown: getAllMainCategoryDropdownReducer,
    //Set Main Category Data
    setMainCateogryData: setMainCateogryDataReducer,
    //sub category
    getAllSubCategory: getAllSubCategoryReducer,
    getSubCategoryDropdownById: getSubCategoryDropdownByIdReducer,
    //super category
    getAllSuperCategory: getAllSuperCategoryReducer,
    getSuperCategoryDropdownById: getSuperCategoryDropdownByIdReducer,
    //cart
    getCartProducts: getCartProductsReducer,
    addToCartProduct: addToCartProductReducer,
    deleteCardItemById: deleteCardItemByIdReducer,
    //wishList
    getWishList: getWishListReducer,
    //Banners
    getCrouselBanners: getCrouselBannersReducer,
    getCenterBanners: getCenterBannersReducer,
    getMiddleBanners: getMiddleBannersReducer,
    getBottomBanners: getBottomBannersReducer,
    //Pages
    getAboutUs: getAboutUsReducer,
    getAllBlogs: getAllBlogsReducer,
    getCancellationAndReturn: getCancellationAndReturnReducer,
    getAllFaQ: getAllFaQReducer,
    getPrivacyAndPolicy: getPrivacyAndPolicyReducer,
    getRantalTermsAndCondition: getRantalTermsAndConditionReducer,
    //city
    getAllCity: getAllCityReducer,
    setCityPincode: setCityPincodeReducer,
    // searchproducts
   getProductsSearch:getProductsSearchReducer,
   //    getmostorderproduct
    MostOrderProducts:getmMostOrderProductsReducer,
    // discount
    discountProducts:getProductsbyDiscountIDhReducer,
    // orderadd
    AddProductOrder:AddProductOrder,
    // quantityadd
    UpdateCartQuantity:UpdateCartQuantity,
    // addresadd
    addressAdd:AddaddressReducer,
    // customeradd
    customerAddres:GetaddresByCustomerId,
    // addresscustomer
    UpdateAddresByCustomerID:UpdateAddresByCustomerID,
    // payment
    AddpaymentReducer:AddpaymentReducer,
    // ActiveRentalPlans
    ActiveRentalPlans:ActiveRentalPlans,
    // getProductsByFilterHomeReducer
    getProductsByFilterHomeReducer:getProductsByFilterHomeReducer,
    // UpdateQunatity
    UpdateQunatity:UpdateQunatity,
    Durationreducer:Durationreducer,
    generateOderByid:generateOderByid,
    getReciptsbSuccefull:getReciptsbSuccefull,
    getInvoice:getInvoice,
    getcanccel:getcanccel,
    updateUserProfile:updateUserProfile,
    UpdateCartItemsBybrowserIdCustomerId:UpdateCartItemsBybrowserIdCustomerId,
    getAlladdressBycustomerId:getAlladdressBycustomerId,
    GetProductListingredcuer:GetProductListingredcuer,
    GetAllPincodeByCityIDREDCUER:GetAllPincodeByCityIDREDCUER,
    GetRelatableProductsReducer:GetRelatableProductsReducer,
    GETADDRESSBYADDRESSID:GETADDRESSBYADDRESSID,
    setAddressIdReducer:setAddressIdReducer,
    getAllproductRedcuer:getAllproductRedcuer,
    GetAlldiscountCopenReducer:GetAlldiscountCopenReducer,
    GetdiscountByIdReducer:GetdiscountByIdReducer,
    cancelrequestofrentalplanreducer:cancelrequestofrentalplanreducer,
    generateSignatureReducer:generateSignatureReducer,
    SendOtpAdharReducer:SendOtpAdharReducer,
    VerifiOtpRedcucer:VerifiOtpRedcucer,
    VerifiOtpPanRedcucer:VerifiOtpPanRedcucer,
    UpdateRentalAgreementReducer:UpdateRentalAgreementReducer,
    getAgrrementByOrderIdReducer:getAgrrementByOrderIdReducer,
    RenewOrderReducer:RenewOrderReducer
})