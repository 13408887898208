

import {
    UpdateAgreementRequest,
    UpdateAgreementSucesss,
    UpdateAgreementFail,
} from "../../../actions/types";
const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};

export const UpdateRentalAgreementReducer = (state = initialState, action) => {
    switch (action.type) {
        case UpdateAgreementRequest:
            return {
                ...state,
                loading: true,
            };
        case UpdateAgreementSucesss:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "successfully added"
            };
        case UpdateAgreementFail:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
            
            
        default:
            return state;
    }
};
