

import {
    GET_MOST_ORDER_PRODUCTBY_REQUEST,
    GET_MOST_ORDER_PRODUCTBY_SUCCESS,
    GET_MOST_ORDER_PRODUCTBY_FAILURE,
} from "../types";



import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const fetchMostOrderProductsByFilterRequest = () => {
    return {
        type: GET_MOST_ORDER_PRODUCTBY_REQUEST,
    };
};
export const fetchMostOrderProductsByFilterSuccess = (Data) => {
    return {
        type: GET_MOST_ORDER_PRODUCTBY_SUCCESS,
        payload: Data,
    };
};

export const fetchMostOrderProductsByFilterFailure = (Error) => {
    return {
        type: GET_MOST_ORDER_PRODUCTBY_FAILURE,
        payload: Error,
    };
};



export const fetchMostOrderProductsByFilter = () => {
    const headers = {
        "Content-Type": "Application/json",
        'Authorization': basicAuth,
    };

    //Dynamic Object creatingprod
    return (dispatch) => {
        dispatch(fetchMostOrderProductsByFilterRequest());
        axios
            .get(url + `getMostOrderedProducts`, 
            {
                headers: headers,
            })
            .then(function (response) {
                localStorage.setItem("message", "getFilterByProductSuccessfully")
                dispatch(fetchMostOrderProductsByFilterSuccess(response.data));
            })
            .catch(function (error) {
                localStorage.setItem("message", "getFilterByProductFailed")
                dispatch(fetchMostOrderProductsByFilterFailure(error));
            });
    };
};
