import {
    Update_Address_Request,
    Update_Address_Success,
    Update_Address_Fail,
} from "../types";
import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const AddaddressByCustomerIDRequest = () => {
    return {
        type: Update_Address_Request,
    };
};
export const AddaddressByCustomerIDSuccess = (Data) => {
    return {
        type: Update_Address_Success,
        payload: Data,
    };
};

export const AddaddressByCustomerIDFail = (Error) => {
    return {
        type: Update_Address_Fail,
        payload: Error,
    };
};

export const AddaddressByCustomerID = (data) => {
    const headers = {
        Accept: "Application/json",
        'Authorization': basicAuth,
    };
    const customerId=localStorage.getItem('customerId')
    const newDat={
            "name": data?.name,
            "street": data?.street,
            "stateName": data?.stateName,
            "postCode": data?.postCode,
            "country": data?.country,
            "selectCity": {
                "cityId": data?.selectCity?.cityId
            },
           "passCustomerId":customerId
    }
    console.log(newDat,'newDatnewDatnewDatnewDatnewDatnewDat')
 
    return (dispatch) => {
        dispatch(AddaddressByCustomerIDRequest());
        axios
            .post(url + `addAddress`, newDat, {
                headers: headers,
            })
            .then(function (response) {
                localStorage.setItem('addressId',response?.data?.addressId)
                console.log(response?.data,'responseresponseresponse')
                localStorage.setItem("message", "addToCartProductsuccuss")
                console.log(response.data,'response.dataresponse.data')
                dispatch(AddaddressByCustomerIDSuccess(response.data));
            })
            .catch(function (error) {
                localStorage.setItem("message", "addToCartProductFailed")
                dispatch(AddaddressByCustomerIDFail(error));
            });
    };
};
