
import validator from "validator";
//import { ExposureTwoTone } from "@material-ui/icons";
var strongRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);
///var postalcodeexp = new RegExp('^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/');

// ------------------------------------   VALIDATING EMAIL INPUT  ------------------------------------**//
export const emailValidate = (email, field = "") => {
  let error = {};
  if (!email) {
    error.email = `Please Enter ${field === "" ? "This field" : field}`;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    error.email = `${field === "" ? "This field" : field} is not valid`;
  } else {
    error.email = null;
  }

  return error.email;
};

// ------------------------------------   VALIDATING NUMBER INPUT  ------------------------------------**//
export const numberValidate = (input, field) => {
  let error = {};
  if (input === null || input === undefined || input === "") {
    error.input = `Please Enter ${field}`;
  } else if (isNaN(input)) error.input = `${field} is invalid`;
  else error.input = null;

  return error.input;
};

// ------------------------------------   VALIDATING STRING INPUT  ------------------------------------**//
export const stringValidate = (stringinput, field = "") => {
  let error = {};
  if (!stringinput || stringinput === "") {
    error.stringinput = `Please Enter ${field === "" ? "This" : field}`;
  } else if (stringinput.length === 0) {
    error.stringinput = `Please Select ${field}`;
  } else {
    error.stringinput = null;
  }

  return error.stringinput;
};


// ------------------------------------   VALIDATING PASSWORD INPUT  ------------------------------------**//
export const passwordValidate = (passwordinput) => {
  let error = {};
  const match = passwordinput.match(strongRegex);

  if (!passwordinput) error.passwordinput = `Please Enter Password`;
  else if (match === null)
    error.passwordinput = `Password must contain 8 characters including one or more capital letters, small letters, special charaters and a numeric value`;
  else {
    error.passwordinput = null;
  }

  return error.passwordinput;
};

//---------------------------------- --- ** VALIDATING SELECTION INPUTS  ** -------------------------------//
export const selectinputValidate = (selectinput, field = "") => {
  const error = {};

  if (
    selectinput === null ||
    selectinput === undefined ||
    selectinput.length === 0
  ) {
    error.selectinput = `Please Select ${field}`;
  } else if (selectinput === "*" || selectinput === "")
    error.selectinput = `Please Select ${field}`;
  else if (selectinput === "0") error.selectinput = null;
  else error.selectinput = null;

  return error.selectinput;
};

//---------------------------------- --- ** VALIDATING USER CONTACT DETAILS  ** -------------------------------//
export const contactNumberValidation = (numinput, field = "") => {
  const error = {};

  if (!numinput)
    error.numinput = `Please Enter ${field === "" ? "This" : field}`;
  else if (!numinput || numinput === "") {
    error.numinput = `Please Enter ${field === "" ? "This" : field}`;
  } else if (numinput.length === 0) {
    error.numinput = `Please Enter ${field === "" ? "This" : field}`;
  } else if (!/^[0-9-+() ]*$/i.test(numinput)) {
    error.numinput = `Please enter a valid ${field}`;
  }

  // else if(isNaN(numinput)) error.numinput = `${field === '' ? "This" : field } must be number input.`

  // else if(numinput.length < 10) error.numinput = `${field === '' ? "This Field" : field } must be ten digits long.`

  // else if(numinput.length > 10) error.numinput = `${field === '' ? "This Field" : field } must be ten digits long.`
  else error.numinput = null;

  return error.numinput;
};

//---------------------------------- --- ** VALIDATING OTP  ** -------------------------------//
export const otprValidation = (numinput, field = "") => {
  const error = {};

  if (!numinput)
    error.numinput = `Please Enter ${field === "" ? "This" : field}`;
  else if (!numinput || numinput === "") {
    error.numinput = `Please Enter ${field === "" ? "This" : field}`;
  } else if (numinput.length === 0) {
    error.numinput = `Please Enter ${field === "" ? "This" : field}`;
  } else if (!/^[0-9-+() ]*$/i.test(numinput)) {
    error.numinput = `Please enter a valid ${field}`;
  }

  else if(isNaN(numinput)) error.numinput = `${field === '' ? "This" : field } must be number input.`

  else if(numinput.length < 6) error.numinput = `${field === '' ? "This Field" : field } must be six digits long.`

  else if(numinput.length > 6) error.numinput = `${field === '' ? "This Field" : field } must be six digits long.`
  else error.numinput = null;

  return error.numinput;
};

//---------------------------------- --- ** VALIDATING USER POSTAL CODE DETAILS  ** -------------------------------//
export const postalCodeValidation = (code, field) => {
  var postalCodeExpr = new RegExp(
    "^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$"
  );
  const error = {};

  if (!code) error.code = `Please enter ${field}`;
  else if (!postalCodeExpr.test(code))
    error.code = `Please enter a valid ${field}`;
  else error.code = null;
  return error.code;
};



/////--------------------date validation--------------------------////
export const validateDate = (value) => {
  const error = {};
  if (validator.isDate(value)) {
    error.date = null;
  } else {
    error.date = `Invalid Date`;
  }
  return error.date;
};

///--------------------------------//
export const currencyFormat = (num) => {
  if (
    num !== "" &&
    num !== undefined &&
    num.length !== 0 &&
    typeof num !== "undefined"
  ) {
    const areDoller = num.toString().includes("$");
    if (areDoller) {
      num = num.toString().replace("$", "");
    }
    const areThereAnyCommas = num.toString().includes(",");
    if (areThereAnyCommas) {
      // num = parseFloat(num).toFixed(0).replace(/\,/g, '')
      return "$" + parseFloat(num).toFixed(2); //.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
      if (num.toString().length > 3) {
        return (
          "$" +
          parseFloat(num)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        );
      } else return "$" + parseFloat(num).toFixed(2);
    }
  } else {
    return 0;
  }
};

export const quantityFormat = (num) => {
  if (
    num !== "" &&
    num !== undefined &&
    num.length !== 0 &&
    typeof num !== "undefined"
  ) {
    const areThereAnyCommas = num.toString().includes(",");
    if (areThereAnyCommas) {
      // num = parseFloat(num).toFixed(0).replace(/\,/g, '')

      return num; //.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
      if (num.toString().length > 3) {
        return parseFloat(num)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      } else return num;
    }
  } else {
    return 0;
  }
};

export const SequenceValidation = (numinput, field = "") => {
  const error = {};

  if (!numinput)
    error.numinput = `Please Enter ${field === "" ? "This" : field}`;
  else if (!numinput || numinput === "") {
    error.numinput = `Please Enter ${field === "" ? "This" : field}`;
  } else if (numinput.length === 0) {
    error.numinput = `Please Enter ${field === "" ? "This" : field}`;
  } else if (!/^[0-9-+() ]*$/i.test(numinput)) {
    error.numinput = `Please enter a valid ${field}`;
  } else if (numinput == 0 || numinput < 0) {
    error.numinput = `Sequence No must be greater then 0! `;
  } else error.numinput = null;

  return error.numinput;
};
// ------------------------------------   VALIDATING FILE INPUT  ------------------------------------**//
export const fileValidate = (stringinput, field = "") => {
  let error = {};
  if (!stringinput || stringinput === "") {
    error.stringinput = `Please Enter ${field === "" ? "This" : field}`;
  } else if (stringinput.length === 0) {
    error.stringinput = `Please Select ${field}`;
  } else {
    error.stringinput = null;
  }

  return error.stringinput;
};
