import {
    Update_Address_Request,
    Update_Address_Success,
    Update_Address_Fail,
} from "../../actions/types";


const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};
export const AddaddressReducer = (state = initialState, action) => {
    switch (action.type) {
        case Update_Address_Request:
            return {
                ...state,
                loading: true,
            };
        case Update_Address_Success:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "successfully addded"
            };
        case Update_Address_Fail:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
        default:
            return state;
    }
};