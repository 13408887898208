// -----------------------------------------------Login------------------------------------------------
export const POST_LOGIN_OTP_REQUEST = "POST_LOGIN_OTP_REQUEST";
export const POST_LOGIN_OTP_SUCCESS = "POST_LOGIN_OTP_SUCCESS";
export const POST_LOGIN_OTP_FAILURE = "POST_LOGIN_OTP_FAILURE";

export const GET_LOGIN_OTP_REQUEST = "GET_LOGIN_OTP_REQUEST";
export const GET_LOGIN_OTP_SUCCESS = "GET_LOGIN_OTP_SUCCESS";
export const GET_LOGIN_OTP_FAILURE = "GET_LOGIN_OTP_FAILURE";

export const REGISTRATION_NEW_USER_REQUEST = "REGISTRATION_NEW_USER_REQUEST";
export const REGISTRATION_NEW_USER_SUCCESS = "REGISTRATION_NEW_USER_SUCCESS";
export const REGISTRATION_NEW_USER_FAILURE = "REGISTRATION_NEW_USER_FAILURE";


// -----------------------------------------------PRODUCTS------------------------------------------------
export const POST_AGENT_REQUEST = "POST_AGENT_REQUEST";
export const POST_AGENT_SUCCESS = "POST_AGENT_SUCCESS";
export const POST_AGENT_FAILURE = "POST_AGENT_FAILURE";

export const GET_PRODUCTS_BY_FILTER_REQUEST = "GET_PRODUCTS_BY_FILTER_REQUEST";
export const GET_PRODUCTS_BY_FILTER_SUCCESS = "GET_PRODUCTS_BY_FILTER_SUCCESS";
export const GET_PRODUCTS_BY_FILTER_FAILURE = "GET_PRODUCTS_BY_FILTER_FAILURE";

export const GET_PRODUCT_BY_ID_REQUEST = "GET_PRODUCT_BY_ID_REQUEST";
export const GET_PRODUCT_BY_ID_SUCCESS = "GET_PRODUCT_BY_ID_SUCCESS";
export const GET_PRODUCT_BY_ID_FAILURE = "GET_PRODUCT_BY_ID_FAILURE";

export const GET_BEST_RATING_PRODUCTS_REQUEST = "GET_BEST_RATING_PRODUCTS_REQUEST";
export const GET_BEST_RATING_PRODUCTS_SUCCESS = "GET_BEST_RATING_PRODUCTS_SUCCESS";
export const GET_BEST_RATING_PRODUCTS_FAILURE = "GET_BEST_RATING_PRODUCTS_FAILURE";

export const GET_DISCOUNT_PRODUCTS_REQUEST = "GET_DISCOUNT_PRODUCTS_REQUEST";
export const GET_DISCOUNT_PRODUCTS_SUCCESS = "GET_DISCOUNT_PRODUCTS_SUCCESS";
export const GET_DISCOUNT_PRODUCTS_FAILURE = "GET_DISCOUNT_PRODUCTS_FAILURE";

export const GET_SEARCH_PRODUCT_REQUEST = "GET_SEARCH_PRODUCT_REQUEST";
export const GET_SEARCH_PRODUCT_SUCCESS = "GET_SEARCH_PRODUCT_SUCCESS";
export const GET_SEARCH_PRODUCT_FAILURE = "GET_SEARCH_PRODUCT_FAILURE";

//orders
export const GET_ALL_ORDERS_BY_CUSTOMER_ID_REQUEST = "GET_ALL_ORDERS_BY_CUSTOMER_ID_REQUEST";
export const GET_ALL_ORDERS_BY_CUSTOMER_ID_SUCCESS = "GET_ALL_ORDERS_BY_CUSTOMER_ID_SUCCESS";
export const GET_ALL_ORDERS_BY_CUSTOMER_ID_FAILURE = "GET_ALL_ORDERS_BY_CUSTOMER_ID_FAILURE";

//cart

export const GET_CART_PRODUCTS_REQUEST = "GET_CART_PRODUCTS_REQUEST";
export const GET_CART_PRODUCTS_SUCCESS = "GET_CART_PRODUCTS_SUCCESS";
export const GET_CART_PRODUCTS_FAILURE = "GET_CART_PRODUCTS_FAILURE";
export const ClearCartProduct = "ClearCartProduct";


export const ADD_TO_CART_PRODUCTS_REQUEST = "ADD_TO_CART_PRODUCTS_REQUEST";
export const ADD_TO_CART_PRODUCTS_SUCCESS = "ADD_TO_CART_PRODUCTS_SUCCESS";
export const ADD_TO_CART_PRODUCTS_FAILURE = "ADD_TO_CART_PRODUCTS_FAILURE";

export const DELETE_CARD_ITEM_BY_ID_REQUEST = "DELETE_CARD_ITEM_BY_ID_REQUEST";
export const DELETE_CARD_ITEM_BY_ID_SUCCESS = "DELETE_CARD_ITEM_BY_ID_SUCCESS";
export const DELETE_CARD_ITEM_BY_ID_FAILURE = "DELETE_CARD_ITEM_BY_ID_FAILURE";

export const GET_WISHLIST_PRODUCTS_REQUEST = "GET_WISHLIST_PRODUCTS_REQUEST";
export const GET_WISHLIST_PRODUCTS_SUCCESS = "GET_WISHLIST_PRODUCTS_SUCCESS";
export const GET_WISHLIST_PRODUCTS_FAILURE = "GET_WISHLIST_PRODUCTS_FAILURE";


export const ADD_TO_WISHLIST_PRODUCT_REQUEST = "ADD_TO_WISHLIST_PRODUCT_REQUEST";
export const ADD_TO_WISHLIST_PRODUCT_SUCCESS = "ADD_TO_WISHLIST_PRODUCT_SUCCESS";
export const ADD_TO_WISHLIST_PRODUCT_FAILURE = "ADD_TO_WISHLIST_PRODUCT_FAILURE";


export const DELETE_WISHLIST_PRODUCT_BY_ID_REQUEST = "DELETE_WISHLIST_PRODUCT_BY_ID_REQUEST";
export const DELETE_WISHLIST_PRODUCT_BY_ID_SUCCESS = "DELETE_WISHLIST_PRODUCT_BY_ID_SUCCESS";
export const DELETE_WISHLIST_PRODUCT_BY_ID_FAILURE = "DELETE_WISHLIST_PRODUCT_BY_ID_FAILURE";

// -----------------------------------------------Banners------------------------------------------------

export const GET_CROUSEL_BANNER_REQUEST = "GET_CROUSEL_BANNER_REQUEST";
export const GET_CROUSEL_BANNER_SUCCESS = "GET_CROUSEL_BANNER_SUCCESS";
export const GET_CROUSEL_BANNER_FAILURE = "GET_CROUSEL_BANNER_FAILURE";

export const GET_CENTER_BANNER_REQUEST = "GET_CENTER_BANNER_REQUEST";
export const GET_CENTER_BANNER_SUCCESS = "GET_CENTER_BANNER_SUCCESS";
export const GET_CENTER_BANNER_FAILURE = "GET_CENTER_BANNER_FAILURE";

export const GET_MIDDLE_BANNER_REQUEST = "GET_MIDDLE_BANNER_REQUEST";
export const GET_MIDDLE_BANNER_SUCCESS = "GET_MIDDLE_BANNER_SUCCESS";
export const GET_MIDDLE_BANNER_FAILURE = "GET_MIDDLE_BANNER_FAILURE";

export const GET_BOTTOM_BANNER_REQUEST = "GET_BOTTOM_BANNER_REQUEST";
export const GET_BOTTOM_BANNER_SUCCESS = "GET_BOTTOM_BANNER_SUCCESS";
export const GET_BOTTOM_BANNER_FAILURE = "GET_BOTTOM_BANNER_FAILURE";

// -----------------------------------------------Customer------------------------------------------------

export const GET_CUSTOMER_BY_ID_REQUEST = "GET_CUSTOMER_BY_ID_REQUEST";
export const GET_CUSTOMER_BY_ID_SUCCESS = "GET_CUSTOMER_BY_ID_SUCCESS";
export const GET_CUSTOMER_BY_ID_FAILURE = "GET_CUSTOMER_BY_ID_FAILURE";

export const GET_CUSTOMER_BY_PHONE_NUMBER_REQUEST = "GET_CUSTOMER_BY_PHONE_NUMBER_REQUEST";
export const GET_CUSTOMER_BY_PHONE_NUMBER_SUCCESS = "GET_CUSTOMER_BY_PHONE_NUMBER_SUCCESS";
export const GET_CUSTOMER_BY_PHONE_NUMBER_FAILURE = "GET_CUSTOMER_BY_PHONE_NUMBER_FAILURE";


// -----------------------------------------------Pages------------------------------------------------

export const GET_ALL_BLOGS_REQUEST = "GET_ALL_BLOGS_REQUEST";
export const GET_ALL_BLOGS_SUCCESS = "GET_ALL_BLOGS_SUCCESS";
export const GET_ALL_BLOGS_FAILURE = "GET_ALL_BLOGS_FAILURE";

export const GET_CANCELLATION_AND_RETURN_REQUEST = "GET_CANCELLATION_AND_RETURN_REQUEST";
export const GET_CANCELLATION_AND_RETURN_SUCCESS = "GET_CANCELLATION_AND_RETURN_SUCCESS";
export const GET_CANCELLATION_AND_RETURN_FAILURE = "GET_CANCELLATION_AND_RETURN_FAILURE";

export const GET_FAQ_REQUEST = "GET_FAQ_REQUEST";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAILURE = "GET_FAQ_FAILURE";

export const GET_PRIVACY_POLICY_REQUEST = "GET_PRIVACY_POLICY_REQUEST";
export const GET_PRIVACY_POLICY_SUCCESS = "GET_PRIVACY_POLICY_SUCCESS";
export const GET_PRIVACY_POLICY_FAILURE = "GET_PRIVACY_POLICY_FAILURE";

export const GET_RENTAL_TERMS_AND_CONDITION_REQUEST = "GET_RENTAL_TERMS_AND_CONDITION_REQUEST";
export const GET_RENTAL_TERMS_AND_CONDITION_SUCCESS = "GET_RENTAL_TERMS_AND_CONDITION_SUCCESS";
export const GET_RENTAL_TERMS_AND_CONDITION_FAILURE = "GET_RENTAL_TERMS_AND_CONDITION_FAILURE";

export const GET_ABOUT_US_REQUEST = "GET_ABOUT_US_REQUEST";
export const GET_ABOUT_US_SUCCESS = "GET_ABOUT_US_SUCCESS";
export const GET_ABOUT_US_FAILURE = "GET_ABOUT_US_FAILURE";

// -----------------------------------------------City------------------------------------------------

export const GET_ALL_CITY_REQUEST = "GET_ALL_CITY_REQUEST";
export const GET_ALL_CITY_SUCCESS = "GET_ALL_CITY_SUCCESS";
export const GET_ALL_CITY_FAILURE = "GET_ALL_CITY_FAILURE";

// ------------------------------------------------------Category-----------------------------------------------------

//Main Category
export const POST_MAIN_CATEGORY_REQUEST = "POST_MAIN_CATEGORY_REQUEST";
export const POST_MAIN_CATEGORY_SUCCESS = "POST_MAIN_CATEGORY_SUCCESS";
export const POST_MAIN_CATEGORY_FAILURE = "POST_MAIN_CATEGORY_FAILURE";

export const GET_ALL_MAIN_CATEGORY_REQUEST = "GET_ALL_MAIN_CATEGORY_REQUEST";
export const GET_ALL_MAIN_CATEGORY_SUCCESS = "GET_ALL_MAIN_CATEGORY_SUCCESS";
export const GET_ALL_MAIN_CATEGORY_FAILURE = "GET_ALL_MAIN_CATEGORY_FAILURE";

export const GET_MAIN_CATEGORY_BY_ID_REQUEST = "GET_MAIN_CATEGORY_BY_ID_REQUEST";
export const GET_MAIN_CATEGORY_BY_ID_SUCCESS = "GET_MAIN_CATEGORY_BY_ID_SUCCESS";
export const GET_MAIN_CATEGORY_BY_ID_FAILURE = "GET_MAIN_CATEGORY_BY_ID_FAILURE";

export const DELETE_MAIN_CATEGORY_BY_ID_REQUEST = "DELETE_MAIN_CATEGORY_BY_ID_REQUEST";
export const DELETE_MAIN_CATEGORY_BY_ID_SUCCESS = "DELETE_MAIN_CATEGORY_BY_ID_SUCCESS";
export const DELETE_MAIN_CATEGORY_BY_ID_FAILURE = "DELETE_MAIN_CATEGORY_BY_ID_FAILURE";

export const UPDATE_MAIN_CATEGORY_BY_ID_REQUEST = "UPDATE_MAIN_CATEGORY_BY_ID_REQUEST";
export const UPDATE_MAIN_CATEGORY_BY_ID_SUCCESS = "UPDATE_MAIN_CATEGORY_BY_ID_SUCCESS";
export const UPDATE_MAIN_CATEGORY_BY_ID_FAILURE = "UPDATE_MAIN_CATEGORY_BY_ID_FAILURE";

export const GET_MAIN_CATEGORY_DROPDOWN_BY_ID_REQUEST = "GET_MAIN_CATEGORY_DROPDOWN_BY_ID_REQUEST";
export const GET_MAIN_CATEGORY_DROPDOWN_BY_ID_SUCCESS = "GET_MAIN_CATEGORY_DROPDOWN_BY_ID_SUCCESS";
export const GET_MAIN_CATEGORY_DROPDOWN_BY_ID_FAILURE = "GET_MAIN_CATEGORY_DROPDOWN_BY_ID_FAILURE";

//Sub Category
export const GET_ALL_SUB_CATEGORY_REQUEST = "GET_ALL_SUB_CATEGORY_REQUEST";
export const GET_ALL_SUB_CATEGORY_SUCCESS = "GET_ALL_SUB_CATEGORY_SUCCESS";
export const GET_ALL_SUB_CATEGORY_FAILURE = "GET_ALL_SUB_CATEGORY_FAILURE";

export const GET_SUB_CATEGORY_BY_ID_REQUEST = "GET_SUB_CATEGORY_BY_ID_REQUEST";
export const GET_SUB_CATEGORY_BY_ID_SUCCESS = "GET_SUB_CATEGORY_BY_ID_SUCCESS";
export const GET_SUB_CATEGORY_BY_ID_FAILURE = "GET_SUB_CATEGORY_BY_ID_FAILURE";

export const POST_SUB_CATEGORY_REQUEST = "POST_SUB_CATEGORY_REQUEST";
export const POST_SUB_CATEGORY_SUCCESS = "POST_SUB_CATEGORY_SUCCESS";
export const POST_SUB_CATEGORY_FAILURE = "POST_SUB_CATEGORY_FAILURE";

export const UPDATE_SUB_CATEGORY_BY_ID_REQUEST = "UPDATE_SUB_CATEGORY_BY_ID_REQUEST";
export const UPDATE_SUB_CATEGORY_BY_ID_SUCCESS = "UPDATE_SUB_CATEGORY_BY_ID_SUCCESS";
export const UPDATE_SUB_CATEGORY_BY_ID_FAILURE = "UPDATE_SUB_CATEGORY_BY_ID_FAILURE";

export const DELETE_SUB_CATEGORY_BY_ID_REQUEST = "DELETE_SUB_CATEGORY_BY_ID_REQUEST";
export const DELETE_SUB_CATEGORY_BY_ID_SUCCESS = "DELETE_SUB_CATEGORY_BY_ID_SUCCESS";
export const DELETE_SUB_CATEGORY_BY_ID_FAILURE = "DELETE_SUB_CATEGORY_BY_ID_FAILURE";

export const GET_SUB_CATEGORY_DROPDOWN_BY_ID_REQUEST = "GET_SUB_CATEGORY_DROPDOWN_BY_ID_REQUEST";
export const GET_SUB_CATEGORY_DROPDOWN_BY_ID_SUCCESS = "GET_SUB_CATEGORY_DROPDOWN_BY_ID_SUCCESS";
export const GET_SUB_CATEGORY_DROPDOWN_BY_ID_FAILURE = "GET_SUB_CATEGORY_DROPDOWN_BY_ID_FAILURE";

//Super Category

export const GET_ALL_SUPER_CATEGORY_REQUEST = "GET_ALL_SUPER_CATEGORY_REQUEST";
export const GET_ALL_SUPER_CATEGORY_SUCCESS = "GET_ALL_SUPER_CATEGORY_SUCCESS";
export const GET_ALL_SUPER_CATEGORY_FAILURE = "GET_ALL_SUPER_CATEGORY_FAILURE";

export const GET_SUPER_CATEGORY_BY_ID_REQUEST = "GET_SUPER_CATEGORY_BY_ID_REQUEST";
export const GET_SUPER_CATEGORY_BY_ID_SUCCESS = "GET_SUPER_CATEGORY_BY_ID_SUCCESS";
export const GET_SUPER_CATEGORY_BY_ID_FAILURE = "GET_SUPER_CATEGORY_BY_ID_FAILURE";

export const POST_SUPER_CATEGORY_REQUEST = "POST_SUPER_CATEGORY_REQUEST";
export const POST_SUPER_CATEGORY_SUCCESS = "POST_SUPER_CATEGORY_SUCCESS";
export const POST_SUPER_CATEGORY_FAILURE = "POST_SUPER_CATEGORY_FAILURE";

export const UPDATE_SUPER_CATEGORY_BY_ID_REQUEST = "UPDATE_SUPER_CATEGORY_BY_ID_REQUEST";
export const UPDATE_SUPER_CATEGORY_BY_ID_SUCCESS = "UPDATE_SUPER_CATEGORY_BY_ID_SUCCESS";
export const UPDATE_SUPER_CATEGORY_BY_ID_FAILURE = "UPDATE_SUPER_CATEGORY_BY_ID_FAILURE";

export const DELETE_SUPER_CATEGORY_BY_ID_REQUEST = "DELETE_SUPER_CATEGORY_BY_ID_REQUEST";
export const DELETE_SUPER_CATEGORY_BY_ID_SUCCESS = "DELETE_SUPER_CATEGORY_BY_ID_SUCCESS";
export const DELETE_SUPER_CATEGORY_BY_ID_FAILURE = "DELETE_SUPER_CATEGORY_BY_ID_FAILURE";

export const GET_SUPER_CATEGORY_DROPDOWN_BY_ID_REQUEST = "GET_SUPER_CATEGORY_DROPDOWN_BY_ID_REQUEST";
export const GET_SUPER_CATEGORY_DROPDOWN_BY_ID_SUCCESS = "GET_SUPER_CATEGORY_DROPDOWN_BY_ID_SUCCESS";
export const GET_SUPER_CATEGORY_DROPDOWN_BY_ID_FAILURE = "GET_SUPER_CATEGORY_DROPDOWN_BY_ID_FAILURE";

// ------------------------------------------------------Products-----------------------------------------------------

export const GET_ALL_PRODUCTS_REQUEST = "GET_ALL_PRODUCTS_REQUEST";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILURE = "GET_ALL_PRODUCTS_FAILURE";

export const GET_PRODUCTS_BY_ID_REQUEST = "GET_PRODUCTS_BY_ID_REQUEST";
export const GET_PRODUCTS_BY_ID_SUCCESS = "GET_PRODUCTS_BY_ID_SUCCESS";
export const GET_PRODUCTS_BY_ID_FAILURE = "GET_PRODUCTS_BY_ID_FAILURE";

export const POST_PRODUCTS_REQUEST = "POST_PRODUCTS_REQUEST";
export const POST_PRODUCTS_SUCCESS = "POST_PRODUCTS_SUCCESS";
export const POST_PRODUCTS_FAILURE = "POST_PRODUCTS_FAILURE";

export const UPDATE_PRODUCTS_BY_ID_REQUEST = "UPDATE_PRODUCTS_BY_ID_REQUEST";
export const UPDATE_PRODUCTS_BY_ID_SUCCESS = "UPDATE_PRODUCTS_BY_ID_SUCCESS";
export const UPDATE_PRODUCTS_BY_ID_FAILURE = "UPDATE_PRODUCTS_BY_ID_FAILURE";

export const DELETE_PRODUCTS_BY_ID_REQUEST = "DELETE_PRODUCTS_BY_ID_REQUEST";
export const DELETE_PRODUCTS_BY_ID_SUCCESS = "DELETE_PRODUCTS_BY_ID_SUCCESS";
export const DELETE_PRODUCTS_BY_ID_FAILURE = "DELETE_PRODUCTS_BY_ID_FAILURE";



export const GET_PRODUCTBY_SEARCH_REQUEST = "GET_PRODUCTBY_SEARCH_REQUEST";
export const GET_PRODUCTBY_SEARCH_SUCCESS = "GET_PRODUCTBY_SEARCH_SUCCESS";
export const GET_PRODUCTBY_SEARCH_FAILURE = "GET_PRODUCTBY_SEARCH_FAILURE";



export const GET_MOST_ORDER_PRODUCTBY_REQUEST = "GET_MOST_ORDER_PRODUCTBY_REQUEST";
export const GET_MOST_ORDER_PRODUCTBY_SUCCESS = "GET_MOST_ORDER_PRODUCTBY_SUCCESS";
export const GET_MOST_ORDER_PRODUCTBY_FAILURE = "GET_MOST_ORDER_PRODUCTBY_FAILURE";

// getproductsBydiscount



export const GET_Discount_PRODUCTBY_REQUEST = "GET_Discount_PRODUCTBY_REQUEST";
export const GET_Discount_PRODUCTBY_SUCCESS = "GET_Discount_PRODUCTBY_SUCCESS";
export const GET_Discount_PRODUCTBY_FAILURE = "GET_Discount_PRODUCTBY_FAILURE";



export const Post_Order_Request = "Post_Order_Request";
export const Post_Order_Success = "Post_Order_Success";
export const Post_Order_Failure = "Post_Order_Failure";


// updateCartItems

export const Update_Cart_Items_Request = "Update_Cart_Items_Request";
export const Update_Cart_Items_Success = "Update_Cart_Items_Success";
export const Update_Cart_Items_Fail = "Update_Cart_Items_Fail";



export const Update_Address_Request = "Update_Address_Request";
export const Update_Address_Success = "Update_Address_Success";
export const Update_Address_Fail = "Update_Address_Fail";




export const GetCustomreAddressByIdRequest = "GetCustomreAddressByIdRequest";
export const GetCustomreAddressByIdSuccess = "GetCustomreAddressByIdSuccess";
export const GetCustomreAddressByIdFailure = "GetCustomreAddressByIdFailure";



export const UpdateAddessByCustimerRequestID = "UpdateAddessByCustimerRequestID";
export const UpdateAddessByCustimerSuccessID = "UpdateAddessByCustimerSuccessID";
export const UpdateAddessByCustimerFailureID = "UpdateAddessByCustimerFailureID";



export const addPaymentRequest = "addPaymentRequest";
export const addPaymentSuccess = "addPaymentSuccess";
export const addPaymentFail = "addPaymentFail";



export const CLEAR_ADD_PRODUCT_ORDER = 'CLEAR_ADD_PRODUCT_ORDER';



export const AllActiveSubScriptionRequest = "AllActiveSubScriptionRequest";
export const AllActiveSubScriptionSuccess = "AllActiveSubScriptionSuccess";
export const AllActiveSubScriptionFailure = "AllActiveSubScriptionFailure";




export const GetAllProductsHomeRequest = "GetAllProductsHomeRequest";
export const GetAllProductsHomeSucccess = "GetAllProductsHomeSucccess";
export const GetAllProductsHomeFailure = "GetAllProductsHomeFailure";






export const UpdateAddToCartReq = "UpdateAddToCartReq";
export const UpdateAddToCartSuccess = "UpdateAddToCartSuccess";
export const UpdateAddToCartFailure = "UpdateAddToCartFailure";


export const ClearUpdateQunatityState= "ClearUpdateQunatityState";

export const SET_Duration = 'SET_Duration';





export const AddrecieptRequest = "AddrecieptRequest";
export const AddrecieptSuccess = "AddrecieptSuccess";
export const AddrecieptFailure = "AddrecieptFailure";







export const createOrderIdRequest = "createOrderIdRequet";
export const createOrderIdSuceess = "createOrderIdSuceess";
export const createOrderIdFail = "createOrderIdFail";


export const getReieptDataReq = "getReieptDataReq";
export const getReieptDatasuccess = "getReieptDatasuccess";
export const getReieptDataFail = "getReieptDataFail";



export const getInvoiceReq = "getInvoiceReq";
export const getInvoiceSuccess = "getInvoiceSuccess";
export const getInvoiceFail = "getInvoiceFail";



export const getAllCanPlanReq = "getAllCanPlanReq";
export const getAllCanPlanSucess = "getAllCanPlanSucess";
export const getAllCanPlanFail = "getAllCanPlanFail";




export const UpdateProfleReq = "UpdateProfleReq";
export const UpdateProfleSucesss = "UpdateProfleSucesss";
export const UpdateProflefail = "UpdateProflefail";




export const UpdateCartItemsBybrowserIdCustomerIdReq = "UpdateCartItemsBybrowserIdCustomerIdReq";
export const UpdateCartItemsBybrowserIdCustomerIdSucesss = "UpdateCartItemsBybrowserIdCustomerIdSucesss";
export const UpdateCartItemsBybrowserIdCustomerIdFail = "UpdateCartItemsBybrowserIdCustomerIdFail";


export const getAlladressBycustomerIdTRequets = "getAlladressBycustomerIdTRequets";
export const getAlladressBycustomerIdTSuccess = "getAlladressBycustomerIdTSuccess";
export const getAlladressBycustomerIdTFail = "getAlladressBycustomerIdTFail";




export const GetProductListingRequest = "GetProductListingRequest";
export const GetProductListingRequestSuccess = "GetProductListingRequestSuccess";
export const GetProductListingRequesFail = "GetProductListingRequesFail";



export const GetAllpincodeByCityIdReq = "GetAllpincodeByCityIdReq";
export const GetAllpincodeByCityIdSuccess = "GetAllpincodeByCityIdSuccess";
export const GetAllpincodeByCityIdFail = "GetAllpincodeByCityIdFail";



export const GetRelatableProductsBySubCityIdReq = "GetRelatableProductsBySubCityIdReq";
export const GetRelatableProductsBySubCityIdSucc = "GetRelatableProductsBySubCityIdSucc";
export const GetRelatableProductsBySubCityIdFail = "GetRelatableProductsBySubCityIdFail";


export const GetaddressByAddeessIdReq = "GetaddressByAddeessIdReq";
export const GetaddressByAddeessIdSuccc = "GetaddressByAddeessIdSuccc";
export const GetaddressByAddeessIdFAIL = "GetaddressByAddeessIdFAIL";

export const setAdddressId='setAddressID'


export const GETALLRPDUCRREQQESREWQ = "GETALLRPDUCRREQQESREWQ";
export const GETALLRPDUCRREQQESRSUCCESSS = "GETALLRPDUCRREQQESRSUCCESSS";
export const GETALLRPDUCRREQQESRFAILLLLLL = "GetaddressByAddeessIdFAIL";


export const getAllldisocuntReq = "getAllldisocuntReq";
export const getAllldisocuntSucccess = "getAllldisocuntSucccess";
export const getAllldisocuntFail = "getAllldisocuntFail";


export const getDiscountByIdRequest = "getDiscountByIdRequest";
export const getDiscountByIdsucccesss = "getDiscountByIdsucccesss";
export const getDiscountByIdFaillll = "getDiscountByIdFaillll";
export const getDiscountclear = "getDiscountclear";




export const UpdatRentalPlanDisactiveRequest = "UpdatRentalPlanDisactiveRequest";
export const UpdatRentalPlanDisactiveSuccesss = "UpdatRentalPlanDisactiveSuccesss";
export const UpdatRentalPlanDisactivefAIL = "UpdatRentalPlanDisactivefAIL";



export const canceltheplanRequest = "canceltheplanRequest";
export const canceltheplanScuesssss = "canceltheplanScuesssss";
export const canceltheplanFail = "canceltheplanFail";



export const generateSignatureRequest = "generateSignatureRequest";
export const generatesignatureSuceesss = "generatesignatureSuceesss";
export const generateSignatureFald = "generateSignatureFald";



export const SendOtpAdgharRequest = "SendOtpAdgharRequest";
export const SendOtpAdgharSuccesss = "SendOtpAdgharSuccesss";
export const SendOtpAdgharFail = "SendOtpAdgharFail";




export const verifyOtpRequest = "verifyOtpRequest";
export const verifyOtpSuccessss = "verifyOtpSuccessss";
export const verifyOtpFail = "verifyOtpFail";



export const verifyOtppanRequest = "verifyOtppanRequest";
export const verifyOtppanSuccessss = "verifyOtppanSuccessss";
export const verifyOtppanFail = "verifyOtppanFail";


export const UpdateAgreementRequest = "UpdateAgreementRequest";
export const UpdateAgreementSucesss = "UpdateAgreementSucesss";
export const UpdateAgreementFail = "UpdateAgreementFail";





export const GetAggrementDataByOrderIdRequest = "GetAggrementDataByOrderIdRequest";
export const GetAggrementDataByOrderIdSuccess = "GetAggrementDataByOrderIdSuccess";
export const GetAggrementDataByOrderIdFail = "GetAggrementDataByOrderIdFail";





export const RenewOrderReq = "RenewOrderReq";
export const RenewOrderSuccesss = "RenewOrderSuccesss";
export const RenewOrderFails = "RenewOrderFails";





export const getProductListingFiltererviceRequest = "getProductListingFiltererviceRequest";
export const getProductListingFiltererviceSuccess = "getProductListingFiltererviceSuccess";
export const getProductListingFiltererviceSFail = "getProductListingFiltererviceSFail";


