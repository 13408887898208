import React, { useEffect, useState } from "react";
import { Drawer, ButtonToolbar, Button, IconButton, Placeholder } from "rsuite";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import AngleDownIcon from "@rsuite/icons/legacy/AngleDown";
import AngleUpIcon from "@rsuite/icons/legacy/AngleUp";
import { useSelector } from "react-redux";
import "rsuite/dist/rsuite.min.css";
import {
  ArrowForward,
  CloudCircle,
  LockClock,
  ThumbDown,
} from "@mui/icons-material";
import {
  GETDISCOUNTbyDiscountId,
  clearDiscountData,
} from "../../../actions/products/orders/getDiscountById";
import { useDispatch } from "react-redux";
import discount from "../../assets/discount.png";
import Swal from "sweetalert2";
const OfferAndDisCountDetail = () => {
  const coupons = useSelector(
    (state) => state?.GetAlldiscountCopenReducer?.data
  );

  const coupendatata = Array.isArray(coupons) ? coupons : [];

  const coupentDiscountPrice = useSelector(
    (state) => state?.GetdiscountByIdReducer?.data
  );
  const [coupen, setCoupen] = useState("");

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const isMobile = window.innerWidth <= 768;

  const handleOpen = (key) => {
    setOpen(true);
    setPlacement(key);
  };
  let minrequiremnt = 3000;
 
  const handleCoupen = async (Coupen) => {
    console.log(Coupen, 'CoupenCoupenCoupenCoupen');

    try {
        // Set the coupon code to clipboard
        await navigator.clipboard.writeText(Coupen);
        
        // Optionally, dispatch your discount action and show success message
        // localStorage.setItem("coupen", Coupen);
        // dispatch(GETDISCOUNTbyDiscountId(Coupen));
        
        Swal.fire({
            title: "Coupon",
            text: "Coupon Code Copied Successfully!",
            icon: "success",
        });

        setOpen(false);
    } catch (err) {
        console.error("Failed to copy coupon to clipboard: ", err);
        Swal.fire({
            title: "Error",
            text: "Failed to copy coupon to clipboard.",
            icon: "error",
        });
    }
};


  const handleRemoveCoupon = () => {
    dispatch(clearDiscountData());
  };
  const [error, setrorr] = useState();

  const ApplyCoupeb = (e) => {
    e.preventDefault();
    if (coupen == "") {
      return setrorr("Please Enter Valid Coupen Code");
    }
    localStorage.setItem("coupen", coupen);
    dispatch(GETDISCOUNTbyDiscountId(coupen));
    Swal.fire({
      title: "Coupon",
      text: "Coupon Applied successfully ",
      icon: "success",
    });
    setOpen(false);
  };

  return (
    <div>
      <style>
        {`
                    #input-offer-code{
                    border:1px solid black !important;
                    }
         .MuiTypography-body1 {
    line-height: 28px;
}
            .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium.css-azjc9l{
            display:flex !Important;
            }
.NKKKKK {
    border: 2px solid whitesmoke !important;
    margin-bottom: 5px !important;
}
            `}
      </style>

      <>
        {isMobile ? (
          <>
            <div onClick={() => handleOpen("bottom")}>
            <span style={{ color: "white" }}>
                {
                  coupendatata?.filter((item) => item.couponStatus === true)
                    .length
                }{" "}
                Available
                <ArrowForward />
              </span>
            </div>
          </>
        ) : (
          <div onClick={() => handleOpen("right")}>
          
              <span style={{ color: "white" }}>
                {
                  coupendatata?.filter((item) => item.couponStatus === true)
                    .length
                }{" "}
                Available
                <ArrowForward />
              </span>
          </div>
        )}
      </>

      <Drawer placement={placement} open={open} onClose={() => setOpen(false)}>
        <Drawer.Header >
          <Drawer.Title style={{ fontWeight: "700" }}>
            {" "}
            Offer and Discount{" "}
          </Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body style={{ padding: "40px" }}>
          <div
            style={{ width: "70%", margin: "AUTO" }}
            class="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-outlined MuiInputAdornment-sizeMedium css-azjc9l"
          >
            {/* <input
              onChange={(e) => setCoupen(e.target.value)}
              id="input-offer-code"
              style={{
                width: "80%",
                border: "1px solid grey",
                paddingBottom: "10px",
                border: "",
              }}
              placeholder="Enter Coupon Code"
              type="text"
              class=""
            /> */}

            {/* <button
              style={{
                position: "relative",
                top: "",
                left: "4px",
                backgroundColor: "rgb(245, 99, 88)",
                borderRadius: "6px",
                color: "white",
                padding: "8px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              onClick={ApplyCoupeb}
              class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1tzsp8a"
              tabindex="0"
              type="button"
            >
              APPLY
            </button> */}
          </div>
          <p
            style={{
              color: "red",
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              fontSize: "15px",
            }}
          >
            {error}
          </p>

          <fieldset
            aria-hidden="true"
            class="MuiOutlinedInput-notchedOutline css-igs3ac"
          >
            <legend class="css-ihdtdm">
              <span class="notranslate"></span>
            </legend>
          </fieldset>
          {coupendatata
            ?.filter((item) => item.couponStatus === true)
            ?.map((item) => {
              return (
                <>
                  <style>
                    {`
                                        
                                     #responsiveDiv {
            background: url(https://assets.furlenco.com/s3-furlenco-images/grogu/OfferIcon.svg) no-repeat;
            width: 70%;
            margin: auto;
            
        }
    
                           div#responsiveDiv {
    border: 3px solid whitesmoke !important;
    margin-bottom: 9px;
}             .NKKKKK {
                                    width: 67%;
                                    margin: auto;

                                    }
                            
                                    
                                        
                                        `}
                  </style>
                  <div
                    className="NKKKKK"
                    style={{
                      background:
                        "url(https://assets.furlenco.com/s3-furlenco-images/grogu/OfferIcon.svg)",
                      backgroundRepeat: "no-repeat",
                      width: "70%",
                      margin: "auto",
                    }}
                  >
                    <div class="MuiBox-root css-1n4o34b mt-5">
                      <div class="MuiBox-root css-14hsy4n">
                        <p
                          class="MuiTypography-root MuiTypography-body1 css-9mr42z"
                          style={{
                            fontSize: "12px",
                            display: "flex",
                            justifyContent: "center",
                            gap: "4px",
                            color: "white",
                            fontWeight: "700",
                            marginBottom: "0px",
                            padding: "5px",
                            background: "rgb(254, 175, 168)",
                            borderRadius: "6px",
                            width: "37%",
                          }}
                        >
                          Minimum Cost {item?.minimumCartValue}
                          <path
                            d="M18.0716 4.92941C16.1828 3.04037 13.6714 2 11 2C8.32864 2 5.81724 3.04037 3.92845 4.92941C2.04 6.81809 1 9.32918 1 12C1 14.6708 2.04 17.1819 3.92845 19.0706C5.81724 20.9596 8.32864 22 11 22C13.6714 22 16.1828 20.9596 18.0716 19.0706C19.96 17.1819 21 14.6708 21 12C21 9.32918 19.96 6.81809 18.0716 4.92941Z"
                            fill="#940924"
                          ></path>{" "}
                        </p>
                      </div>
                      <div style={{display:'flex',justifyContent:'space-between'}}>
                      <div class="MuiBox-root css-1s1gfiq">
                        <img
                          src={discount}
                          style={{ width: "30px", height: "30px" }}
                        />

                        <div class="MuiBox-root css-58lsyh">
                          <p class="MuiTypography-root MuiTypography-body1 css-elwuio">
                            {item.tittle} {item?.discountPercentage}% off
                          </p>
                          <div class="" style={{fontSize:'12px',width:'',lineHeight:'22px'}}>
                                                        {item?.description}
                                                    </div>
                          <p class="MuiTypography-root MuiTypography-body1 css-1frsiv7">
                            Use Coupon code : {item?.couponCode}
                          </p>
                        </div>
                    
                      </div>
                      <div className="button">
                          <button
                            className=""
                            style={{
                              background: "rgb(245, 99, 88)",
                              padding: "10px",
                              borderRadius: "4px",
                              color: "white",
                            }}
                            onClick={() => handleCoupen(item?.couponCode)}
                          >
                            Copy Coupon
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </Drawer.Body>
      </Drawer>
    </div>
  );
};

export default OfferAndDisCountDetail;
