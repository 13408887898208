import {
    ADD_TO_WISHLIST_PRODUCT_REQUEST,
    ADD_TO_WISHLIST_PRODUCT_SUCCESS,
    ADD_TO_WISHLIST_PRODUCT_FAILURE,
} from "../../../actions/types";

const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};
export const addWishListProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_WISHLIST_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_TO_WISHLIST_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "successfully"
            };
        case ADD_TO_WISHLIST_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
        default:
            return state;
    }
};
