import React from 'react';

export  const LoadingComponent = () => {
  return (

    <>
    <style>
        {`
        .loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(245, 99, 88);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-spinner {
  width: 80px;
  height: 80px;
  animation: rotate 1.5s linear infinite;
}

.loading-spinner circle {
  fill: none;
  stroke: #61dafb;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-dasharray: 283; /* 2 * π * r */
  stroke-dashoffset: 75; /* Offset to create a loading effect */
  animation: dash 1.5s ease-in-out infinite;
}

.loading-text {
  color: #ffffff;
  font-size: 18px;
  margin-top: 15px;
}

/* Animations */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 75;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 75;
  }
}

        
        `}
    </style>
    <div className="loading-overlay">
      <svg
        className="loading-spinner"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <circle cx="50" cy="50" r="45" />
      </svg>
      <p className="loading-text">Processing your payment...</p>
    </div></>
  );
};


export  const OrderLoading = () => {
    return (
  
      <>
      <style>
          {`
          .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(245, 99, 88);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .loading-spinner {
    width: 80px;
    height: 80px;
    animation: rotate 1.5s linear infinite;
  }
  
  .loading-spinner circle {
    fill: none;
    stroke: #61dafb;
    stroke-width: 5;
    stroke-linecap: round;
    stroke-dasharray: 283; /* 2 * π * r */
    stroke-dashoffset: 75; /* Offset to create a loading effect */
    animation: dash 1.5s ease-in-out infinite;
  }
  
  .loading-text {
    color: #ffffff;
    font-size: 18px;
    margin-top: 15px;
  }
  
  /* Animations */
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dashoffset: 75;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 75;
    }
  }
  
          
          `}
      </style>
      <div className="loading-overlay">
        <svg
        style={{color:'white'}}
          className="loading-spinner"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
        >
          <circle cx="50" cy="50" r="45" />
        </svg>
        <p className="loading-text">Now Your Order is Creating...</p>
      </div></>
    );
  };
  
  

  export const  SuccessMessageComponent = () => {
    return (
      <>
        <style>
          {`
          .success-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 128, 0, 0.8); /* Green background */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 1000;
            color: white;
          }
  
          .success-icon {
            width: 80px;
            height: 80px;
            animation: bounce 1.5s infinite;
          }
  
          .success-text {
            font-size: 24px;
            margin-top: 20px;
            text-align: center;
          }
  
          /* Animation for the icon */
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-15px);
            }
            60% {
              transform: translateY(-7px);
            }
          }
          `}
        </style>
        <div className="success-overlay">
          <svg
            className="success-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path fill="white" d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 22c-5.522 0-10-4.478-10-10s4.478-10 10-10 10 4.478 10 10-4.478 10-10 10zm5.707-14.293l-6 6-2.707-2.707-1.414 1.414 4.121 4.121 7.071-7.071-1.414-1.414z"/>
          </svg>
          <p className="success-text" style={{color:'white'}}>Your order has been successfully created!</p>
        </div>
      </>
    );
  };
  