import {
  SET_Duration
} from "../types"



    export const SetDuraion = (selectedTenure,updatedPrice) => {
        console.log(updatedPrice,'selectedTenureselectedTenure')

        let duration;
        switch (selectedTenure) {
          case "ThreeMonth":
            duration = 3;
            break;
          case "SixMonth":
            duration = 6;
            break;
          case "NineMonth":
            duration = 9;
            break;
          case "TwelveMonth":
          case "PerMonth":
            duration = 12;
            break;
          case "eighteenMonth":
            duration = 18;
            break;
          default:
            duration = "";
            break;
        }
      
        return {
          type: SET_Duration,
          payload: duration,
          tenure:selectedTenure,
          updatedPrice:updatedPrice
        };
      };