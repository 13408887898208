import {
    ADD_TO_WISHLIST_PRODUCT_REQUEST,
    ADD_TO_WISHLIST_PRODUCT_SUCCESS,
    ADD_TO_WISHLIST_PRODUCT_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";
export const addWishListProductRequest = () => {
    return {
        type: ADD_TO_WISHLIST_PRODUCT_REQUEST,
    };
};
export const addWishListProductSuccess = (Data) => {
    return {
        type: ADD_TO_WISHLIST_PRODUCT_SUCCESS,
        payload: Data,
    };
};

export const addWishListProductFailure = (Error) => {
    return {
        type: ADD_TO_WISHLIST_PRODUCT_FAILURE,
        payload: Error,
    };
};

export const addWishListProduct = (productId) => {
    const headers = {
        Accept: "Application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(addWishListProductRequest);
        axios
            .post(url + `addCustomerWishList`, {
                "customer": {
                    "customerId": localStorage.getItem('customerId')
                },
                "passProduct": {
                    "productId": productId
                }
            }, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(addWishListProductSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(addWishListProductFailure(error));
            });
    };
};
