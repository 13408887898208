import {
    GET_WISHLIST_PRODUCTS_REQUEST,
    GET_WISHLIST_PRODUCTS_SUCCESS,
    GET_WISHLIST_PRODUCTS_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";
export const fetchGetWishlistRequest = () => {
    return {
        type: GET_WISHLIST_PRODUCTS_REQUEST,
    };
};
export const fetchGetWishlistSuccess = (Data) => {
    return {
        type: GET_WISHLIST_PRODUCTS_SUCCESS,
        payload: Data,
    };
};

export const fetchGetWishlistFailure = (Error) => {
    return {
        type: GET_WISHLIST_PRODUCTS_FAILURE,
        payload: Error,
    };
};

export const fetchGetWishlist = (id) => {
    const newID=localStorage.getItem('customerId')
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    const cityId=localStorage.getItem('cityId')
    return (dispatch) => {
        dispatch(fetchGetWishlistRequest);

        axios
            .get(url + `getAllCustomerWishListByCustomerId/${newID}?cityId=${cityId}`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchGetWishlistSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchGetWishlistFailure(error));
            });
    };
};
