import {
    getDiscountByIdRequest,
    getDiscountByIdsucccesss,
    getDiscountByIdFaillll,
    getDiscountclear
} from "../../types";
import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";



export const GetDiscountBYidReqqqqq = () => {
    return {
        type: getDiscountByIdRequest,
    };
};
export const getDiscountBYiDsuccessss = (Data) => {
    return {
        type: getDiscountByIdsucccesss,
        payload: Data,
    };
};

export const getDisocuntBTIdDDFAIL = (Error) => {
    return {
        type: getDiscountByIdFaillll,
        payload: Error,
    };
};


export const GETDISCOUNTbyDiscountId = (data) => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(GetDiscountBYidReqqqqq());
        axios
            .get(url + `getDiscountAndCouponByCouponCode/${data}`, {
                headers: headers,
            })
            .then(function (response) {

                dispatch(getDiscountBYiDsuccessss(response.data));
            })
            .catch(function (error) {
                dispatch(getDisocuntBTIdDDFAIL(error));
            });
    };
};

export const clearDiscountData = () => ({
    type: getDiscountclear
});