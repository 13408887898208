import {
    GetAllpincodeByCityIdReq ,
    GetAllpincodeByCityIdSuccess,
    GetAllpincodeByCityIdFail,
} from "../types";


import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const FetAllPincodeByCityIdReq = () => {
    return {
        type: GetAllpincodeByCityIdReq,
    };
};
export const fetchAllPincodeByCityIdSuccess = (Data) => {
    return {
        type: GetAllpincodeByCityIdSuccess,
        payload: Data,
    };
};

export const FetchAllPincodeByCityIdFAAIL = (Error) => {
    return {
        type: GetAllpincodeByCityIdFail,
        payload: Error,
    };
};

export const GetAllPincodeByCityIdAction = (id) => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(FetAllPincodeByCityIdReq);
        axios
            .get(url + `getAllPinByCityId/${id}`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchAllPincodeByCityIdSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(FetchAllPincodeByCityIdFAAIL(error));
            });
    };
};
