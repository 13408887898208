import {
    canceltheplanRequest,
    canceltheplanScuesssss,
    canceltheplanFail,
} from "../types";

import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const canceltheplanRequestuest = () => {
    return {
        type: canceltheplanRequest,
    };
};
export const canceltheplanScuesssssfun = (Data) => {
    return {
        type: canceltheplanScuesssss,
        payload: Data,
    };
};

export const canceltheplanFailfun = (Error) => {
    return {
        type: canceltheplanFail,
        payload: Error,
    };
};

export const handelCancelTHErERENTANREQUEST = (id) => {
    const customerId=localStorage.getItem('customerId')
    const cartId=localStorage.getItem('cartId')
    
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(canceltheplanRequestuest());
        axios
         .post(url + `cancelRentalPlanById/${id}`,{},{
                headers: headers,
            })   
            .then(function (response) {
    
                // localStorage.setItem("message", "searchProductuccuss")
                dispatch(canceltheplanScuesssssfun(response.data));
            })
            .catch(function (error) {
                // localStorage.setItem("message", "searchProductFailed")
                dispatch(canceltheplanFailfun(error));
            });
    };
};
