import React, { useState, useEffect, useCallback, useRef } from 'react';
import "../style/css/loading.css"
//Fetch API
import { fetchAllMainCategory } from "../actions/products/mainCategory/getAllMainCategory"
//Redux
import { connect } from "react-redux";
function TestingPage(props) {
    const { mainCategoryData, handleGetAllMainCategory } = props
    // useEffect(() => {
    //     handleGetAllMainCategory()
    // }, [])
    const [count, setCount] = useState(0);

    const handleClick = useCallback(() => {
        setCount(count + 1);
    }, [count]);
    const inputRef = useRef();

    useEffect(() => {
        // Focus on the input element when the component mounts
        inputRef.current.focus();
    }, []);
    return (
        <>
            {/* <main>
                <div class="preloader">
                    <div class="preloader__square"></div>
                    <div class="preloader__square"></div>
                    <div class="preloader__square"></div>
                    <div class="preloader__square"></div>
                </div>
                <div class="status">Loading<span class="status__dot">.</span><span class="status__dot">.</span><span class="status__dot">.</span></div>
            </main> */}
            <div style={{ display: "grid", alignItems: "center" }}>
                {/* <p>Count: {count}</p>
                <button onClick={handleClick}>Increment Count</button> */}
                <input ref={inputRef} type="text" width={30}/>
            </div>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        mainCategoryData: state.getAllMainCategory.data,
        loading: state.getAllMainCategory.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetAllMainCategory: () => dispatch(fetchAllMainCategory())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestingPage);
