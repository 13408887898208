import {
    GetRelatableProductsBySubCityIdReq,GetRelatableProductsBySubCityIdSucc,GetRelatableProductsBySubCityIdFail} from "../../actions/types";



const initialState = {
    loading: true,
    data: [],
    error: "",
    message: ""
};
export const GetRelatableProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GetRelatableProductsBySubCityIdReq:
            return {
                ...state,
                loading: true,
                error: "",
                message: ""
            };
        case GetRelatableProductsBySubCityIdSucc:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: action.payload
            };
        case GetRelatableProductsBySubCityIdFail:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: action.payload
            };
        default:
            return state;
    }
};
