import React,{useEffect} from 'react'

function Rental_Terms_And_Conditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
      
    <div class="container">
    <h1>Terms of Use – RENTYFURNITURE</h1>
    
    <p>This website is owned and operated by Day Dream Future Reality Infratech Pvt Ltd (the "Company"), a company duly registered under the Companies Act, 1956, with its registered address at 17018 Pine Tower, Mahagun Mywoods, Greater Noida West, Noida 201301, U.P., India. By using our website, you agree to comply with and be bound by the following terms, conditions, and policies ("Terms of Use"). Please review these Terms of Use carefully. If you do not agree to these terms, you should not use the website, review information, or subscribe to any furniture, soft furnishings, or home décor ("Transaction") from this website.</p>
    
    <p><strong>"We," "Our," "Us," "RENTYFURNITURE," or "Service Provider"</strong> refers to Day Dream Future Reality Infratech Pvt Ltd and its successors and assignees. <strong>"Website" or "Site"</strong> refers to <a href="http://www.RENTYFURNITURE.com">www.RENTYFURNITURE.com</a> and any mobile applications ("App") developed by Day Dream Future Reality Infratech Pvt Ltd. <strong>"You," "Your," or "Yourself"</strong> refers to the Prospect, Customer, or their representative, and any other user accessing the Site.</p>

    <h2>GENERAL</h2>
    <p>By using <a href="http://www.RENTYFURNITURE.com">http://www.RENTYFURNITURE.com</a> (the "Website" or "App"), you agree to the terms and conditions outlined in these Terms of Use. This agreement constitutes the entire and exclusive agreement between you and us, overriding all previous or contemporaneous agreements, representations, warranties, and understandings regarding the Website, its content, and any products or services provided through it.</p>
    
    <p>These Terms of Use may be updated periodically without specific notice to you. The most recent version will be posted on the Website, and you should review these Terms of Use before using the Website each time.</p>
    
    <p>Your use of the Website and its services is governed by these Terms of Use and any applicable policies referenced herein. If you make a transaction on the Website, you will be subject to the relevant policies applicable to that transaction. By using the Website, you are entering into a contract with the Company, and these terms and policies constitute your binding obligations with us.</p>
    
    <p>For the purposes of these Terms of Use, <strong>"You" or "User"</strong> refers to any individual or entity who has agreed to become a subscriber on the Website by providing information via computer systems. While browsing the Website does not require registration, completing the registration process is mandatory to subscribe to our offerings.</p>
    
    <p>When you use the Website or communicate with us through emails or other forms of data, you consent to electronic communication. We may communicate with you via email or other electronic means.</p>
    
    <p>These Terms of Use apply solely to the Website and the App.</p>

    <h2>LEGAL NOTICE</h2>
    <p>All textual, graphical, and other content on this Website, unless otherwise stated by the Company, is the property of the Company. The content, organization, graphics, design, compilation, magnetic translation, digital conversion, and related aspects of the Website are protected by applicable copyrights, trademarks, and other proprietary rights, including but not limited to intellectual property rights. Copying, redistributing, using, or publishing any part of the Website, except as expressly permitted hereunder, is strictly prohibited. Viewing the Website does not grant you ownership rights to any content, document, or other materials.</p>
    
    <p>You may view, copy, print, and use content from the Website solely for your personal, non-commercial use, provided that:</p>
    <ul>
        <li>The content is used for informational purposes only.</li>
        <li>No text, graphics, or other content is modified in any way.</li>
        <li>Graphics are not used, copied, or distributed separately from accompanying text.</li>
        <li>The content is not used in a manner that is offensive or disparages the Company, its products, employees, or management.</li>
    </ul>
    
    <p>Nothing in this statement implies or grants any license or right to use any patent, copyright, trademark, or other intellectual property of the Company or any third party, except as expressly provided herein.</p>
    
    <p>We reserve the right, at our sole discretion, to edit or delete any documents, information, or other content on the Website. Trademarks mentioned on the Website may be unregistered trademarks under common law or registered trademarks of the Company under Indian Law.</p>

    <h2>USE OF WEBSITE</h2>
    <p>You shall not use any "deep-link," "page-scrape," "robot," "spider," or other automatic devices, programs, algorithms, or methodologies, or any similar or equivalent manual processes to access, acquire, copy, or monitor any part of the Website or its Content. Additionally, you shall not reproduce or circumvent the navigational structure or presentation of the Website or any Content to obtain or attempt to obtain any materials, documents, or information through means not intentionally made available through the Website. We reserve the right to block any such activities.</p>
    
    <p>You shall not probe, scan, or test the vulnerability of the Website or any network connected to it, nor breach any security or authentication measures. You may not reverse lookup, trace, or seek to trace any information on other users or visitors of the Website, including accounts not owned by you, to its source. You also agree not to exploit the Website, services, or information to reveal any information, including personal identification or details, other than your own as provided by the Website.</p>
    
    <p>You agree not to disparage or defame the Company, nor encourage others to do so. "Disparage" includes comments or statements made in any medium that could adversely affect the Company's business conduct, plans, prospects, or reputation. You will not publish, post, or release any material, make speeches, give interviews, or make public statements about the Company, its operations, clients, employees, products, or services without prior written consent from the Company.</p>
    
    <p>You may not forge headers or manipulate identifiers to disguise the origin of any message or transmission sent to us through the Website. Impersonation or misrepresentation of any individual or entity is also prohibited.</p>
    
    <p>You may not use the Website or its content for any unlawful purpose or in violation of these Terms of Use, nor solicit or perform any illegal activity or any activity that infringes the Company’s rights.</p>
    
    <p>We partner with Microsoft Clarity and Microsoft Advertising to analyze your interactions with our website through behavioral metrics, heatmaps, and session replays to enhance and market our products/services. We use first and third-party cookies and other tracking technologies to gather data on product/service popularity and online activity. This data is also used for site optimization, fraud detection, and advertising purposes. For more information on how Microsoft collects and uses your data, please refer to the <a href="https://privacy.microsoft.com/en-us">Microsoft Privacy Statement</a>.</p>
    
    <p>Additionally, we use Moengage to understand how you interact with our apps and website to improve our products and advertising. By using our site, you consent to the collection and analysis of this behavioral data by Moengage. For further details, please visit the <a href="https://www.moengage.com/privacy-policy/">Moengage Privacy Policy</a>.</p>

    <h2>SECURITY POLICY</h2>
    <p>At RENTYFURNITURE, we are committed to leveraging the latest technologies and best practices to maintain a highly secure environment for our customers. However, we recognize that no technology is infallible, and therefore, we disclaim any liability for defects or errors in our network and systems. We continuously monitor and assess our technology and design for potential vulnerabilities. If you identify a security issue on our Website or App, please contact us at <a href="mailto:security@RENTYFURNITURE.com">security@RENTYFURNITURE.com</a>. We value your input and will work diligently to address any issues promptly. We also acknowledge and appreciate the contributions of security researchers who help us enhance the security of RENTYFURNITURE.</p>

    <h2>DISCLAIMER AND LIMITS</h2>
    <p>We make reasonable efforts to ensure the accuracy and integrity of the information and materials provided on this Website, but we are not responsible for misprints, outdated information, technical or pricing inaccuracies, typographical errors, or other mistakes that may appear. All information and materials are subject to change without notice. The information and services are provided "as is" and "as available," with all warranties, express or implied, disclaimed. This includes, but is not limited to, disclaiming any implied warranties of merchantability or fitness for a particular purpose. The information and services may contain bugs, errors, or other limitations, and we, along with our affiliated parties, will not be liable for any damages arising from your use of or inability to use this Website or its content. This includes direct, indirect, special, incidental, or consequential damages, such as those related to loss of business or profits.</p>
    
    <p>The exclusion of damages described above is a fundamental element of the agreement between us and you. No advice or information obtained from us through this Website, whether oral or written, will create any warranty, representation, or guarantee not expressly stated in these terms of use. We also disclaim any responsibility or liability for damages caused by viruses contained within any electronic files or documents.</p>
    
    <p>Our maximum liability under any circumstances will be limited to the purchase price you paid for any products, services, or information.</p>
    
    <p>We consider the protection of your privacy a high priority. Your personal information is valuable to us, and we handle it in accordance with the Information Technology Act, 2000, and its Rules. Our Privacy Policy is available at <a href="https://www.RENTYFURNITURE.com/privacy-policy">https://www.RENTYFURNITURE.com/privacy-policy</a>. If you do not agree with how we process or use your information as outlined in our Privacy Policy, please refrain from using our Website.</p>
    
    <p>The Company may collect personal data when you register with RENTYFURNITURE or browse the Website. This data may be used to build a customer profile and includes:</p>
    <ul>
        <li>Identifiers such as Name, Alias, Postal Address, Username, Password, Email Address, Aadhaar number (UID), Permanent Account Number (PAN)</li>
        <li>Characteristics of protected classifications under law such as age and gender</li>
        <li>Commercial information such as records of personal property</li>
    </ul>
    
    <p>References to any third-party products, services, or offerings by trade name, trademark, or manufacturer do not imply endorsement or recommendation by the Company.</p>

    <h2>PRODUCT DESCRIPTION</h2>
    <p>We do not guarantee that the product descriptions or other content on this Website is accurate, complete, reliable, current, or error-free, and we assume no liability in this regard. RENTYFURNITURE makes no representations or warranties about the accuracy of the images displayed on the Website; these images do not reflect the actual quality of the products. Users should not infer product quality based solely on the images. Please note that prices displayed on our Website or app are subject to change without prior notice. Technical issues, typographical errors, or updates to product information may cause price variations. We reserve the right to adjust prices at any time based on our business needs.</p>
    
    <p>The terms and conditions related to delivery, payment, and other matters will be governed by the Subscription Plan selected by the Customer. Any feedback or data submitted by users, such as ideas, comments, suggestions, or questions about Company products or services or the Website content, will be considered non-confidential. The Company has no obligation regarding such information and may freely reproduce, use, disclose, and distribute it without limitation. Additionally, the Company may use any ideas, concepts, know-how, or techniques contained in such feedback for any purpose, including developing, manufacturing, and marketing new products.</p>
    
    <p>We reserve the right, and you authorize us, to use and assign all information regarding your use of the Website and any information you provide in accordance with our Privacy Policy.</p>

    <h2>PAYMENTS</h2>
    <p>You represent and warrant that, if you are making payments to us:</p>
    <ul>
        <li>Any credit, debit, net banking, check, or other payment information you provide is accurate and complete.</li>
        <li>Charges incurred will be honored by your credit card, debit card, or banking company.</li>
        <li>You will pay the charges at the posted prices, including any applicable taxes. You also agree to comply with our <a href="#payment-refund-policy">PAYMENT AND REFUND POLICY</a>.</li>
    </ul>
    
    <p>RENTYFURNITURE is not responsible for any loss or damage arising directly or indirectly from:</p>
    <ul>
        <li>Lack of authorization for transactions.</li>
        <li>Exceeding the limit agreed upon with your bank.</li>
        <li>Payment issues or declines for any reason.</li>
    </ul>
    
    <p>All payments must be made in Indian Rupees, as acceptable in the Republic of India. We do not facilitate payments in any other currency. By making a payment, you authorize RENTYFURNITURE and/or its service providers to collect, process, facilitate, and remit payments. You understand and agree that the payment facility provided by RENTYFURNITURE is not a banking or financial service but a facilitator offering electronic, automated online payment, cash on delivery, and collection services using existing banking infrastructure and credit card payment gateway networks. RENTYFURNITURE and its service providers are not acting as trustees or fiduciaries with respect to your payments.</p>
    
    <p>Refunds, if applicable, will be issued via a link provided by the Company, which can be redeemed through any payment platform of your choice, as permitted. For electronic payments, refunds will be processed using NEFT, RTGS, or other approved online banking/electronic funds transfer systems, in compliance with our <a href="#payment-refund-policy">PAYMENT AND REFUND POLICY</a>. You may initiate a chargeback request according to the terms of your debit or credit card scheme or bank account. Please note that chargeback requests are processed by your card provider or bank, not by us, and we bear no liability for this process. We reserve the right to review your account and payment history in case of a chargeback request to detect fraud. If we suspect fraudulent activity, we may terminate, block, or suspend your account or subscription immediately.</p>
    
    <p>For your convenience, you may save your credit, debit, ATM, or POS card details for future payments. We use 128-bit encryption technology and PCI-DSS certified environments to ensure the security of your payment information. This information will be used to facilitate future payments only with your permission.</p>
    
    <p>You can choose to authorize automatic payments. By doing so, you irrevocably authorize us to instruct your bank or financial institution to handle such transactions as requested on the site. You agree to be bound by the terms and conditions of your bank or financial institution, with our terms prevailing in case of conflict.</p>
    
    <p>RENTYFURNITURE will not be liable for any damages, claims, or losses resulting from processing or failure to process a payment transaction, or any delay beyond our control. You agree that we are not liable for any unauthorized use of your credit card or other payment methods by third parties in connection with the site. You waive the right to dispute any payment made into your account and will bear all associated costs.</p>

    <h2>No Cost EMI Terms and Conditions</h2>
    <ol>
        <li><strong>Eligibility:</strong> No Cost EMI purchases can only be made using credit or debit cards issued by select banks, through the No Cost EMI payment option on the Payment Page. This method is not available for payments made via net banking or other payment methods introduced by RENTYFURNITURE.</li>
        <li><strong>Availability:</strong> No Cost EMI is applicable only for select tenures determined by RENTYFURNITURE, and is available based on the credit and debit card issuing banks' terms, which may change from time to time.</li>
        <li><strong>Discount and Payment:</strong> Customers opting for the No Cost EMI method will receive an upfront discount equivalent to the interest charged by the bank. This ensures that the total EMI amount equals the total invoice amount of the No Cost EMI eligible products at the time of purchase. For Bank Credit Card payments, the offer applies to subscription plans selected in a single checkout where the total sale price (net of applicable discounts) is Rs. 5000 or more.
            <br/><strong>Example:</strong>
            <ul>
                <li>Cart Value: INR 40,000</li>
                <li>No Cost EMI Tenure: 6 Months</li>
                <li>Rate of Interest: 15% p.a.</li>
                <li>Upfront Discount: INR 1750</li>
                <li>Payment by Customer: INR 38,250</li>
                <li>Invoice Value: INR 38.250 (after discount)</li>
            </ul>
            <strong>Bank Statement Example:</strong>
            <ul>
                <li>EMI 1: INR 6,666 (Interest: INR 500, Principal: INR 6,166)</li>
                <li>EMI 2: INR 6,666 (Interest: INR 416, Principal: INR 6,250)</li>
                <li>EMI 3: INR 6,666 (Interest: INR 334, Principal: INR 6,334)</li>
                <li>EMI 4: INR 6,666 (Interest: INR 250, Principal: INR 6,418)</li>
                <li>EMI 5: INR 6,666 (Interest: INR 166, Principal: INR 6,500)</li>
                <li>EMI 6: INR 6,666 (Interest: INR 84, Principal: INR 6,584)</li>
                <li>Total: INR 40,000 (Interest: INR 1750, Principal: INR 38,250)</li>
            </ul>
        </li>
        <li><strong>Charges:</strong> RENTYFURNITURE does not charge for the No Cost EMI payment method. However, any additional charges such as GST or other taxes applied to the purchase will be levied by the bank issuing the credit or debit card. The bank's policies on EMI facilities, including any additional charges or cancellation fees, apply.</li>
        <li><strong>Bank Discretion:</strong> The extension of the EMI facility is at the discretion of the issuing bank. RENTYFURNITURE is not liable if the bank refuses to process the transaction.</li>
        <li><strong>Invoice and EMI Terms:</strong> The invoice will reflect the net value of the product purchased. The EMI will proceed according to the plan selected during checkout. Any delay or failure in EMI payments may incur additional interest or penalties by the bank, which will be the customer’s responsibility. RENTYFURNITURE is not liable for any additional charges.</li>
        <li><strong>Processing Fees:</strong> RENTYFURNITURE will not charge a processing fee for EMI options. Banks may impose processing or cancellation fees as per their policies, which are non-refundable even in case of order return or cancellation.</li>
        <li><strong>Cancellation and Refunds:</strong> The No Cost EMI program will continue as is even if cancellations or returns occur. Customers are responsible for managing the No Cost EMI program with their credit card issuer. The program is available only in India and requires the cardholder to be at least 18 years old.</li>
        <li><strong>Order Cancellation:</strong> RENTYFURNITURE reserves the right to cancel orders in cases of fraudulent activity, including duplicate accounts or multiple orders. In such cases, the order will not be reinstated. The decision of RENTYFURNITURE will be final and binding.</li>
        <li><strong>Bank Services:</strong> RENTYFURNITURE does not warrant or represent the services offered by any bank, including the EMI facility. By availing of this offer, customers agree that RENTYFURNITURE is not liable for any issues related to bank services or benefits.</li>
        <li><strong>Flexi-Cancellation Pass:</strong> If a customer purchases a Flexi-Cancellation Pass, they can cancel their subscription during the tenure and receive a refund of the outstanding principal amount post-termination. Any NC-EMI charges incurred after the subscription ends will not be covered by the company. Amount to be refunded will be the principal outstanding at termination or the amount eligible for refund assuming no NC-EMI, minus interest charged after termination. Subscribers should settle the loan with their banker to avoid future interest or other charges.</li>
   
      
    </ol>

<h2>Payment and Refund Policy</h2>
    <ol>
        <li><strong>Upfront Rental Payment:</strong> Subscribers are required to make an upfront rental payment to book a subscription plan on the Company's website. This amount is refundable only if the subscription or order is canceled before or at the time of delivery. Once the subscribed products are delivered, the payment is non-refundable, except if the subscriber has opted for the Flexi Cancellation Pass. Refunds under the Flexi Cancellation Pass are calculated according to the example provided in the Flexi Cancellation Pass section..</li>
        <li><strong>Billing Cycle::</strong> o	The billing cycle begins on the date the last item in a bundle is delivered to the subscriber’s address. This date plus one month constitutes the billing cycle..</li>
        <li><strong>Discount and Payment:</strong> Customers opting for the No Cost EMI method will receive an upfront discount equivalent to the interest charged by the bank. This ensures that the total EMI amount equals the total invoice amount of the No Cost EMI eligible products at the time of purchase. For Bank Credit Card payments, the offer applies to subscription plans selected in a single checkout where the total sale price (net of applicable discounts) is Rs. 5000 or more.
            <br/><strong>Example:</strong>
            <ul>
                <li>Cart Value: INR 40,000</li>
                <li>No Cost EMI Tenure: 6 Months</li>
                <li>Rate of Interest: 15% p.a.</li>
                <li>Upfront Discount: INR 1750</li>
                <li>Payment by Customer: INR 38,250</li>
                <li>Invoice Value: INR 38.250 (after discount)</li>
            </ul>
            <strong>Bank Statement Example:</strong>
            <ul>
                <li>EMI 1: INR 6,666 (Interest: INR 500, Principal: INR 6,166)</li>
                <li>EMI 2: INR 6,666 (Interest: INR 416, Principal: INR 6,250)</li>
                <li>EMI 3: INR 6,666 (Interest: INR 334, Principal: INR 6,334)</li>
                <li>EMI 4: INR 6,666 (Interest: INR 250, Principal: INR 6,418)</li>
                <li>EMI 5: INR 6,666 (Interest: INR 166, Principal: INR 6,500)</li>
                <li>EMI 6: INR 6,666 (Interest: INR 84, Principal: INR 6,584)</li>
                <li>Total: INR 40,000 (Interest: INR 1750, Principal: INR 38,250)</li>
            </ul>
        </li>
        <li><strong>Charges:</strong> RENTYFURNITURE does not charge for the No Cost EMI payment method. However, any additional charges such as GST or other taxes applied to the purchase will be levied by the bank issuing the credit or debit card. The bank's policies on EMI facilities, including any additional charges or cancellation fees, apply.</li>
        <li><strong>Bank Discretion:</strong> The extension of the EMI facility is at the discretion of the issuing bank. RENTYFURNITURE is not liable if the bank refuses to process the transaction.</li>
        <li><strong>Invoice and EMI Terms:</strong> The invoice will reflect the net value of the product purchased. The EMI will proceed according to the plan selected during checkout. Any delay or failure in EMI payments may incur additional interest or penalties by the bank, which will be the customer’s responsibility. RENTYFURNITURE is not liable for any additional charges.</li>
        <li><strong>Processing Fees:</strong> RENTYFURNITURE will not charge a processing fee for EMI options. Banks may impose processing or cancellation fees as per their policies, which are non-refundable even in case of order return or cancellation.</li>
        <li><strong>Cancellation and Refunds:</strong> The No Cost EMI program will continue as is even if cancellations or returns occur. Customers are responsible for managing the No Cost EMI program with their credit card issuer. The program is available only in India and requires the cardholder to be at least 18 years old.</li>
        <li><strong>Order Cancellation:</strong> RENTYFURNITURE reserves the right to cancel orders in cases of fraudulent activity, including duplicate accounts or multiple orders. In such cases, the order will not be reinstated. The decision of RENTYFURNITURE will be final and binding.</li>
        <li><strong>Bank Services:</strong> RENTYFURNITURE does not warrant or represent the services offered by any bank, including the EMI facility. By availing of this offer, customers agree that RENTYFURNITURE is not liable for any issues related to bank services or benefits.</li>
        <li><strong>Flexi-Cancellation Pass:</strong> If a customer purchases a Flexi-Cancellation Pass, they can cancel their subscription during the tenure and receive a refund of the outstanding principal amount post-termination. Any NC-EMI charges incurred after the subscription ends will not be covered by the company. Amount to be refunded will be the principal outstanding at termination or the amount eligible for refund assuming no NC-EMI, minus interest charged after termination. Subscribers should settle the loan with their banker to avoid future interest or other charges.</li>
   
      
    </ol>

    <h1>Payment and Refund Policy</h1>

<h2>Upfront Rental Payment</h2>
<p>Subscribers are required to make an upfront rental payment to book a subscription plan on the Company's website. This amount is refundable only if the subscription or order is canceled before or at the time of delivery. Once the subscribed products are delivered, the payment is non-refundable, except if the subscriber has opted for the Flexi Cancellation Pass. Refunds under the Flexi Cancellation Pass are calculated according to the example provided in the Flexi Cancellation Pass section.</p>

<h2>Billing Cycle</h2>
<ul>
    <li>The billing cycle begins on the date the last item in a bundle is delivered to the subscriber’s address. This date plus one month constitutes the billing cycle.</li>
    <li>For instance, if "Bundle 1" (an Allen bed and a mattress) is ordered on July 19th, with the bed delivered on July 24th and the mattress on July 29th, the subscription cycle for "Bundle 1" starts on July 29th and ends on the 28th of the following month.</li>
    <li>If "Bundle 1" and "Bundle 2" (a Flex 3-seater couch) are ordered on July 19th and delivered on July 24th and July 29th respectively, the billing cycle for "Bundle 1" will be from July 24th to August 23rd, and for "Bundle 2" from July 29th to August 28th.</li>
</ul>

<h2>Payment Terms</h2>
<ul>
    <li>Payments for bundles/items must be made or deducted (in case of autopay) before the expiry of the bundle/items. Late payments will incur a charge of 2% of the outstanding amount or Rs. 150 per month, whichever is higher, until the overdue amount and any penalties are paid.</li>
    <li>In the case of non-payment, refusal to return assets, or loss of assets, RENTYFURNITURE reserves the right to use a third-party collection agency. The customer's information may be shared with the agency for payment follow-ups. Additionally, if payments are missed for a month, RENTYFURNITURE may terminate the subscription and initiate asset retrieval.</li>
</ul>

<h2>ECS/NACH Service</h2>
<ul>
    <li>Subscribers can enroll in the ECS/NACH service for automatic payment management. By signing up for this service, you authorize Day Dream Future Reality Infratech Pvt Ltd to collect rental dues from your bank account as per the mandate form. This authorization remains in effect until the rental agreement is terminated.</li>
    <li>You are responsible for ensuring sufficient funds in your account to cover rental payments. Failure to do so may result in late payment fees and additional charges. Day Dream Future Reality Infratech Pvt Ltd is not liable for any charges imposed by your bank for this service.</li>
</ul>

<h2>NACH/ECS/Direct Debit Terms</h2>
<ul>
    <li>By signing the NACH mandate form, you confirm that the provided details are accurate and agree to participate in NACH/ECS/Direct Debit/Standing Instructions (SI) according to the platform’s terms and conditions.</li>
    <li>In case of dishonor of ECS/Standing Instructions, a fee of ₹500 will be imposed. Your bank may levy additional charges, which RENTYFURNITURE will not cover.</li>
    <li>Day Dream Future Reality Infratech Pvt Ltd is not responsible for delays or failures in debiting your bank account due to reasons beyond its control.</li>
</ul>

<h2>Authorization and Charges</h2>
<ul>
    <li>By subscribing to NACH/ECS/Direct Debit/SI, you agree to comply with the terms communicated by Day Dream Future Reality Infratech Pvt Ltd and your bank. You authorize the company and its agents to verify and execute the mandate form on your behalf.</li>
    <li>You also authorize your bank to debit your account for charges related to mandate verification, registration, transactions, returns, or other fees associated with NACH/ECS/Direct Debit/SI.</li>
</ul>

<h2>Sufficient Funds</h2>
<p>Ensure sufficient funds are available in your account on the due date of the Auto Debit transaction. Day Dream Future Reality Infratech Pvt Ltd aims to debit your account on the invoice date. Any delays will result in transactions being debited at a later time.</p>

<h2>Responsibility</h2>
<p>Day Dream Future Reality Infratech Pvt Ltd or its authorized agents are responsible for initiating Auto Debit transactions. The company and its sponsor bank or NPCI are not liable for any bank charges debited due to NACH transactions.</p>

<h2>Legal Recourse</h2>
<p>In the event of dishonor of electronic funds transfer instructions/NACH Auto Debit mandates due to insufficient funds, Day Dream Future Reality Infratech Pvt Ltd reserves the right to exercise all available legal remedies as per Section 138 of the Negotiable Instruments Act, 1881.</p>

<h1>Promotional Offer Terms and Conditions</h1>

<h2>Availability</h2>
<p>RENTYFURNITURE periodically offers promotions for various rental and UNLMTD products. Once an offer expires, it will not be reopened or extended under any circumstances.</p>

<h2>User Responsibility</h2>
<p>Customers must make timely payments during the promotional period to benefit from the offer. RENTYFURNITURE will not reinstate or extend an expired offer.</p>

<h2>Offer Amendments</h2>
<p>RENTYFURNITURE reserves the right to modify or alter any current or future offer at any time without prior notice.</p>

<h2>Finality of Offer on App</h2>
<p>The terms and conditions of an offer as displayed on the RENTYFURNITURE app at the time of offer are final and binding.</p>

<h2>No Reopening of Offers</h2>
<p>RENTYFURNITURE is under no obligation to reopen a closed or expired offer. Once an offer period ends, it will not be reinstated, and no exceptions will be made.</p>

<h2>Loss of Opportunity</h2>
<p>If a customer misses the opportunity to take advantage of an offer due to non-payment or other reasons, the responsibility for the missed opportunity lies solely with the customer.</p>

<h2>No Offer Revision</h2>
<p>RENTYFURNITURE will not revise, reopen, or extend offers beyond their originally specified duration.</p>
<p>These terms and conditions are strict and non-negotiable. Users are encouraged to review offer details carefully and ensure timely payments to avail themselves of the benefits during the promotional period. RENTYFURNITURE will not entertain requests for offer revision or reopening once the offer has ended.</p>

<h1>Documentation and Verification Policy</h1>

<h2>Credit Information</h2>
<p>The Subscriber authorizes the Company to obtain credit information from Credit Information Companies (e.g., Recordant Private Limited) to assess their creditworthiness and offer personalized products.</p>

<h2>Personal and Financial Data</h2>
<p>The Subscriber must provide accurate personal and financial information, including credit scores, in accordance with applicable data protection laws. The Subscriber consents to the Company processing, transferring, and sharing this data with branches, subsidiaries, affiliates, legal and regulatory authorities, and designated third parties.</p>

<h2>Data Usage</h2>
<p>The collected data will be used to assess the Subscriber’s eligibility for offers, comply with legal requirements (such as KYC), provide services, manage the Subscriber’s relationship with the Company, conduct statistical and risk analysis, and ensure compliance with applicable laws.</p>

<h1>B2C: Business to Customer</h1>

<h2>Documentation and Verification</h2>
<p>All prospective customers of RENTYFURNITURE must comply with our documentation and verification policy. Customers are required to provide the following documents when placing an order for their desired products:</p>
<ul>
    <li>RENTYFURNITURE reserves the right to cancel any order based on internal policies related to documentation and field verification.</li>
    <li>Additional documents may be requested on a case-by-case basis and will be communicated via email and SMS.</li>
    <li>Orders from customers residing in Paying Guest accommodations, hostels, hotels, exhibitions, or makeshift premises will not be entertained as these are considered temporary requirements.</li>
</ul>

<h2>For Employed/Salaried Professionals/Self-Employed Professionals</h2>
<ul>
    <li>PAN Number issued by the Income Tax Department of India.</li>
    <li>Permanent Address Proof (Aadhar/DL/Passport/Voter ID).
        <ul>
            <li>The name on the PAN card should match the order name.</li>
            <li>The name on the Permanent Address Proof should match the order name and PAN card name.</li>
            <li>If the registered contact number is not linked with Aadhar or other documents, customers need to upload an original image of the document.</li>
        </ul>
    </li>
</ul>

<h2>For Students</h2>
<ul>
    <li>PAN Number issued by the Income Tax Department of India.</li>
    <li>Permanent Address Proof (Aadhar/DL/Passport/Voter ID).
        <ul>
            <li>The name on the PAN card should match the order name.</li>
            <li>The name on the Permanent Address Proof should match the order name and PAN card name.</li>
            <li>If the registered contact number is not linked with Aadhar or other documents, customers need to upload an original image of the document.</li>
        </ul>
    </li>
    <li>Proof of Permanent Address of Working Parent/Guardian.</li>
    <li>Contact Number of Working Parent/Guardian.</li>
</ul>

<h2>For Foreign Nationals</h2>
<ul>
    <li>Copy of Visa.</li>
    <li>Copy of Passport.</li>
    <li>HR Letter/Rental Agreement/Recent Utility Bill (Delivery Address Proof).</li>
    <li>Local Company/Business Reference.</li>
    <li>Foreign nationals with Employment visas are the only ones eligible for our services.</li>
    <li>Visa should have a validity of more than six months.</li>
    <li>Documents will be collected offline.</li>
</ul>

<h2>For NRIs (Non-Resident Indians)/POIs (Persons of Indian Origin)</h2>
<ul>
    <li>Visa/PIO Card/OCI Card.</li>
    <li>Passport.</li>
    <li>HR Letter/Rental Agreement/Recent Utility Bill (Delivery Address Proof).</li>
    <li>Local Company/Business Reference.</li>
</ul>

<h1>B2B: Business to Business</h1>

<h2>Documentation</h2>
<p>Documentation for B2B orders will be collected offline, including the contract.</p>

<h2>Security Deposit</h2>
<p>A refundable security deposit based on the order value will be required, ranging from 3 to 9 months, at the discretion of RENTYFURNITURE.</p>

<h1>Tax Deducted at Source (TDS)/TDS Certificate</h1>

<h2>Responsibility</h2>
<ul>
    <li>Customers are responsible for deducting TDS where applicable and must notify RENTYFURNITURE in writing. Form 16A must be submitted in a timely manner for proper crediting.</li>
    <li>Form 16A should be shared within 15 days of the due date for the respective quarter.</li>
    <li>In case of discrepancies in income or TDS, RENTYFURNITURE reserves the right to communicate with the customer for rectification, and the customer is responsible for making appropriate revisions.</li>
</ul>

<h1>Goods and Services Tax (GST) Input Credit</h1>

<h2>Application of GST</h2>
<p>Day Dream Future Reality Infratech Pvt Ltd applies GST on all supplies. For GST-registered customers, an E-invoice will be issued, allowing them to claim Input Tax Credit.</p>

<h2>Non-GST Registered Customers</h2>
<p>Non-GST registered customers or those who haven’t provided GSTIN during onboarding will not receive Input Tax Credit. Customers should inform the company within one month of GSTR-2B generation if they have not received the Input Tax Credit.</p>

<h1>Documentation for Various Entity Types</h1>

<h2>Sole Proprietors</h2>
<ul>
    <li>PAN Card of the business entity.</li>
    <li>Address Proof of the proprietor (DL/Aadhar/Voter ID/Passport).</li>
    <li>GST Certificate/Sales and Income Tax Returns/Registration Documents.</li>
    <li>Delivery Address Proof.</li>
    <li>3 Months Undated Cheques.</li>
</ul>

<h2>Partnership Firms</h2>
<ul>
    <li>PAN Card copy of the firm.</li>
    <li>Registered Partnership Deed.</li>
    <li>Power of Attorney for business transactions.</li>
    <li>GST Certificate/Sales and Income Tax Returns/Registration Documents.</li>
    <li>ID/Address Proof of main partners and Power of Attorney holders.</li>
    <li>Delivery Address Proof.</li>
    <li>3 Months Undated Cheques.</li>
</ul>

<h2>Limited Liability Partnerships (LLP)</h2>
<ul>
    <li>PAN Card copy of the LLP.</li>
    <li>LLP Agreement.</li>
    <li>Incorporation Document and DPIN of designated partners.</li>
    <li>Certificate of Registration issued by the ROC.</li>
    <li>Resolution to avail furniture on rent and Power of Attorney if applicable.</li>
    <li>Government Issued Permanent Address Proof of authorized person.</li>
    <li>GST Certificate/Registration Documents.</li>
    <li>Current Address Proof.</li>
    <li>3 Months Undated Cheques.</li>
</ul>

<h2>Private Limited Companies (PVT LTD)</h2>
<ul>
    <li>PAN Card of the company.</li>
    <li>Certificate of Incorporation/Commencement of Business and DIN.</li>
    <li>Memorandum & Articles of Association.</li>
    <li>Authorization Letter and Power of Attorney if applicable.</li>
    <li>GST Certificate/Sales and Income Tax Returns/Registration Documents.</li>
    <li>ID and Address Proof of authorized signatories.</li>
    <li>List of directors with contact numbers.</li>
    <li>Delivery/Mailing Address Proof.</li>
    <li>3 Months Undated Cheques.</li>
</ul>

<h2>Trusts/Clubs/Associations</h2>
<ul>
    <li>Certificate of Registration.</li>
    <li>Power of Attorney for business transactions.</li>
    <li>ID and Address Proof of authorized signatories.</li>
    <li>Document listing trustees, beneficiaries, and those holding Power of Attorney.</li>
    <li>GST Certificate/Sales and Income Tax Returns/Registration Documents.</li>
    <li>Resolution of the managing body.</li>
    <li>Declaration of Trust/Bye-Laws.</li>
    <li>Additional Address Proof if applicable.</li>
    <li>3 Months Undated Cheques.</li>
</ul>

<h1>Cancellation of Subscription</h1>
<ul>
    <li>1-Month Subscription Plan: Comes with a minimum commitment of 3 months. Early cancellation requires payment for the full initial 3-month period.</li>
    <li>6-Month, 9-Month, 12-Month or 18-Month Plans: Committed for the entire duration with no refunds for early cancellation. The customer must pay the full rental amount for the entire period.</li>
    <li>Post-Initial Subscription: The subscription can be renewed with predefined plans (1, 3, 6, 9, 12, or 18 months) or terminated by raising a request on the RENTYFURNITURE app. If not renewed, the subscription defaults to a 1-month plan at full rent without discounts.</li>
    <li>Example: If the subscription for the single door Fridge (monthly rent ₹800) is not renewed by the expiry date, it will convert to a 1-month plan at ₹800. For instance, if the subscription ends on March 31 and the new cycle starts on April 1, the rent for February will be ₹800.</li>
</ul>

<h1>Refund Policy</h1>
<ul>
    <li>Refund Processing: To process a refund, subscribers must provide their name and relevant details as requested by RENTYFURNITURE. Refunds will be issued to the original payment method, which may include any banking channel. At our discretion, refunds may also be processed via alternative methods such as NEFT or through a refund claim link.</li>
    <li>Refund Eligibility: Refunds will only be issued in the following cases:
        <ul>
            <li>Termination of the subscription by the customer or RENTYFURNITURE after the initial subscription plan, provided there are no outstanding dues payable by the customer.</li>
            <li>Rental payments are non-refundable except when the Flexi Cancellation Pass, as detailed in the Flexi Cancellation Pass Refund Policy, is applicable.</li>
        </ul>
    </li>
</ul>
<h1>Delivery Policy</h1>
        <ul>
            <li><strong>Serviceable Locations:</strong> We deliver to specific locations within Bengaluru, Mumbai, Pune, Delhi, Gurugram, Noida, Chennai, and Hyderabad. Delivery may be restricted to serviceable pin codes at our discretion. Delivery fees may apply in certain cases, and customers will be notified accordingly. Delivery status can be tracked using the RENTYFURNITURE App.</li>
            <li><strong>Delivery and Setup:</strong> Furniture is delivered and set up by our in-house RENTYFURNITURE team. Customers will be contacted to schedule delivery. It is advisable to accept delivery during the first two attempts, as subsequent attempts will prioritize other customers.</li>
            <li><strong>Delivery Timeframe:</strong> Our team ("champs") will wait a maximum of 15 minutes at the delivery location. If no one is available to receive the order, our champs will proceed to the next location on their route. A new delivery date will be scheduled if the initial delivery cannot be completed. We aim to provide a smooth delivery process while ensuring minimal disruption to our schedule.</li>
        </ul>

        <h1>Quality Checks During Delivery</h1>
        <ul>
            <li><strong>Customer Verification:</strong> Customers should inspect and verify the quality and details of each item at the time of delivery.</li>
            <li><strong>Responsibility:</strong> The customer is responsible for ensuring that all items are in good condition upon delivery.</li>
            <li><strong>Reporting Issues:</strong> Any issues or discrepancies should be reported to the delivery personnel immediately and recorded by contacting our customer support team.</li>
        </ul>

        <h1>RENTYFURNITURE Care Program</h1>
        <ul>
            <li><strong>Program Details:</strong> We offer a Furniture Care Program on a monthly chargeable basis to insure your furniture against damages from normal wear and tear, up to a maximum of ₹70,000. This program covers accidental damages and general wear and tear.</li>
            <li><strong>Enrolment:</strong> Specific inclusions and exclusions apply, detailed in the General Inclusions and Exclusions section. Enroll in this program when placing your order for comprehensive protection against common damages.</li>
        </ul>

        <h1>Flexi Cancellation Pass Refund Policy</h1>
        <ul>
            <li><strong>Eligibility:</strong> Customers who have opted for the Flexi Cancellation Pass during order renewals are eligible for a refund of the base plan price only.</li>
            <li><strong>Example Calculation:</strong>
                <ul>
                    <li>For a 12-month plan at a 70% discount:</li>
                    <li>Total rent without discount: ₹18,000</li>
                    <li>Discounted price: ₹12,600</li>
                    <li>Refund calculation if returning products after 6 months:</li>
                    <li>Refund per month: ₹12,600 / 12 months = ₹1050</li>
                    <li>Refund for 6 months: ₹1050 × 6 = ₹6,300</li>
                </ul>
            </li>
            <li><strong>Terms and Changes:</strong> Refund eligibility and calculation terms may change without notice. The example provided is for illustrative purposes and may not reflect the exact amount. Review the terms periodically for updates. If you do not agree with these terms, refrain from signing up for the Flexi Cancellation Pass.</li>
            <li><strong>Timeframe and Non-Refundable Charges:</strong>
                <ul>
                    <li>Rental payments are non-refundable except with the Flexi Cancellation Pass.</li>
                    <li>Refunds are based on the subscription plan selected and are not available for value-added services or taxes.</li>
                </ul>
            </li>
            <li><strong>Refund Eligibility Terms:</strong>
                <ul>
                    <li><strong>Tenure and Usage Categories:</strong>
                        <ul>
                            <li>6 Months: Refund if usage is 3 months or less; prorated refund for more than 3 months.</li>
                            <li>9 Months: Refund if usage is 6 months or less; prorated refund for more than 6 months.</li>
                            <li>12 Months: Refund if usage is 6 months or less; prorated refund for more than 6 months.</li>
                        </ul>
                    </li>
                    <li><strong>Refund Calculation:</strong> Prorated refunds are based on the number of unused months from the final pick-up date.</li>
                    <li><strong>Refund Request Process:</strong> Contact our customer support team with required details and documentation. Refunds will be processed using the original payment method when possible, or via an alternative method at our discretion.</li>
                    <li><strong>Exceptions and Limitations:</strong> No refunds for cancellations due to service violations or illegal activities. Additional fees, charges, and non-recurring expenses are non-refundable.</li>
                    <li><strong>Dispute Resolution:</strong> For errors or issues with refunds, contact our customer support team.</li>
                    <li><strong>Changes to Terms:</strong> We may update these refund terms at any time without notice. Users should regularly check for updates.</li>
                    <li><strong>Flexi Cancellation Pass Exceptions:</strong> Refunds through the Flexi Cancellation Pass are not available for Rent to Own and Terminate to Own options.</li>
                </ul>
            </li>
            <li><strong>Refund Eligibility for Upfront:</strong>
                <table border="1" cellpadding="5" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Tenure</th>
                            <th>Usage</th>
                            <th>Refund</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>6 Months</td>
                            <td>&#8804; 3 months</td>
                            <td>3 Months</td>
                        </tr>
                        <tr>
                            <td>6 Months</td>
                            <td>&gt; 3 months</td>
                            <td>Prorate months</td>
                        </tr>
                        <tr>
                            <td>9 Months</td>
                            <td>&#8804; 6 months</td>
                            <td>3 Months</td>
                        </tr>
                        <tr>
                            <td>9 Months</td>
                            <td>&gt; 6 months</td>
                            <td>Prorate months</td>
                        </tr>
                        <tr>
                            <td>12 Months</td>
                            <td>&#8804; 6 months</td>
                            <td>6 Months</td>
                        </tr>
                        <tr>
                            <td>12 Months</td>
                            <td>&gt; 6 months</td>
                            <td>Prorate months</td>
                        </tr>
                    </tbody>
                </table>
            </li>
            <li><strong>Non-Refundable Charges:</strong> Any value-added service charges, as well as taxes, are non-refundable and will not be factored into the refund calculation.</li>
            <li><strong>Agreement to Refund Policy:</strong> By signing up for the Flexi Cancellation Pass and making an upfront payment for our services, you agree to adhere to this Refund Policy. Your payment signifies that you have read, understood, and accepted these Refund Eligibility Terms and Conditions. It is your responsibility to periodically review these terms for any updates or changes. If you do not agree with these terms, please refrain from making any upfront payments for our services. For any questions or concerns, please contact our Customer Support team.</li>
        </ul>

        <h1>General Inclusions and Exclusions</h1>
        <ul>
            <li><strong>Coverage:</strong> The Company's coverage for damages to furniture and appliances includes normal wear and tear, scratches and dents, liquid spills, food stains, cracks, tears, and damages caused by bugs and fungus. However, we do not cover damages caused or worsened by sharp objects, hand or power tools, cigarette butts, or other forms of abuse. Damages resulting from burglary or theft of the product(s) are also excluded. It is the Customer’s responsibility to properly care for rented furniture and appliances to avoid damages not covered by this policy.</li>
            <li><strong>Abusive Damage:</strong> In the event of abusive damage to our furniture, we reserve the right to recover the full price of the asset from the Customer. This policy is in place to ensure the protection of our assets and maintain the quality of our service. Abusive damage is defined as damage that is intentionally or negligently inflicted on the furniture, beyond normal wear and tear.</li>
            <li><strong>Natural Wear and Tear:</strong> Furniture is subject to natural wear and tear, which is defined as damage resulting from the normal use of the furniture over time. Our coverage includes this type of damage, as well as any accidental damage.</li>
            <li><strong>Exceptions:</strong> Furniture and appliances are not covered for damages caused by natural disasters, vandalism, and other external factors beyond normal usage. Special care must be taken to avoid these situations as they are not covered by our policies.</li>
        </ul>

        <h1>Repairs and Service</h1>
        <ul>
            <li><strong>Repair Services:</strong> We provide repair services for furniture and appliances covered under our rental agreement. Repairs are conducted by trained professionals to ensure high-quality service.</li>
            <li><strong>Service Requests:</strong> Customers must report any issues or required repairs to our customer support team as soon as they arise. We will assess the situation and arrange for necessary repairs or replacements.</li>
            <li><strong>Charges:</strong> Repair services covered by our policies are provided at no additional cost. However, any repairs required due to abusive damage or neglect will incur charges to the customer.</li>
        </ul>

        <h1>Replacement Policy</h1>
        <ul>
            <li><strong>Replacement Conditions:</strong> Furniture and appliances may be replaced if they are deemed unserviceable after a reasonable number of repair attempts. Replacement items will be similar in type and quality.</li>
            <li><strong>Customer Requests:</strong> Customers can request a replacement if the item is consistently problematic or unserviceable. Replacement requests must be made through our customer support team.</li>
            <li><strong>Charges:</strong> Replacement is provided at no additional cost if it falls under our coverage. Charges may apply for replacements due to negligence or misuse.</li>
        </ul>

        <h1>Additional Policies</h1>
        <ul>
            <li><strong>Security Deposit:</strong> A security deposit may be required at the time of rental. This deposit is refundable subject to terms and conditions, and will be adjusted based on the condition of the returned items.</li>
            <li><strong>Late Fees:</strong> Late fees may apply if rental items are returned after the agreed rental period without prior notice.</li>
            <li><strong>Usage Restrictions:</strong> Rented items should be used according to their intended purpose and in accordance with care instructions provided.</li>
        </ul>
        <h1>RENTYFURNITURE Policies and Terms</h1>
        
        <h2>Delivery Policy</h2>
        <ul>
            <li><strong>Delivery Timeframe:</strong> Products are delivered within 14 days. The delivery status can be checked on the App. Confirmation of the delivery date is sent via message and email. Multiple delivery attempts are made; it’s advisable to accept delivery during the first two attempts to avoid delays, as subsequent attempts may be prioritized for other customers. Delivery contacts will be provided on the day of delivery. If the customer is absent, the next delivery attempt will be scheduled as soon as possible. Acceptable recipients include the customer, spouse, parents, or relatives who have inspected the furniture.</li>
        </ul>

        <h2>Quality Checks During Delivery</h2>
        <ul>
            <li><strong>Verification:</strong> Customers are requested to check and verify the quality and details of each piece of furniture during delivery.</li>
        </ul>

        <h2>Post Delivery</h2>
        <ul>
            <li><strong>Returns and Replacements:</strong> If the customer is not satisfied with the product for valid reasons during setup or post-delivery, the specific item can be returned, and a replacement will be provided free of charge.</li>
            <li><strong>Satisfaction Guarantee:</strong> If unsatisfied with the product for valid reasons, customers can return the item for a replacement or swap at no additional cost.</li>
            <li><strong>Swap Timeframe:</strong> Swaps must be initiated within 5 days of the first delivery. Swaps requested after this period are not permitted. Customers are allowed one swap within this 5-day window.</li>
            <li><strong>Curation Period:</strong> Swaps can only be made during the 5-day Curation period following the first delivery. Users may swap items based on their preferences during this timeframe.</li>
            <li><strong>Stock Availability:</strong> Swaps are subject to current stock availability. Users must choose from available items.</li>
            <li><strong>No Obligation to Reopen Swaps:</strong> RENTYFURNITURE is not obligated to reopen swaps due to stock unavailability. Users should make selections within the Curation period.</li>
            <li><strong>Lost Opportunities:</strong> If users do not swap within the Curation period, they bear responsibility for any lost opportunities.</li>
            <li><strong>Governing Terms:</strong> These terms govern the delivery and swapping of products. Users should make informed decisions during the Curation period. RENTYFURNITURE aims to provide a satisfactory experience but cannot guarantee stock availability beyond this period.</li>
            <li><strong>B2B Customers:</strong> The UNLMTD Website does not cater to B2B customers or establishments at present.</li>
            <li><strong>KYC Registration:</strong> Failure to complete KYC registration will result in automatic rejection. Orders from customers in Paying Guest accommodations, Hostels, and Hotels are not accepted.</li>
        </ul>

        <h2>Subscription Termination and Refund Policy</h2>
        <ul>
            <li><strong>Termination Initiation:</strong> Customers may initiate the termination of their subscription at any time during the subscription period.</li>
            <li><strong>Refund Policy (Without Flexi):</strong> Generally, no refunds are provided if the subscription is terminated before the scheduled end date.</li>
            <li><strong>Refund Policy (With Flexi):</strong> For customers who have purchased the chargeable Flexi option during renewal, refunds will be processed based on the chosen tenure as follows:
                <ul>
                    <li><strong>6-Month Tenure:</strong>
                        <ul>
                            <li><strong>Within 50% of Tenure:</strong> If cancellation is initiated within the first 3 months and products are physically returned, a refund of 50% of the amount paid will be issued.</li>
                            <li><strong>After 50% of Tenure:</strong> If termination occurs after the first 3 months, the refund will be calculated on a prorated monthly basis.</li>
                        </ul>
                    </li>
                    <li><strong>12-Month Tenure:</strong>
                        <ul>
                            <li><strong>Within 50% of Tenure:</strong> If cancellation is initiated within the first 6 months and products are physically returned, a refund of 50% of the amount paid will be issued.</li>
                            <li><strong>After 50% of Tenure:</strong> If termination occurs after the first 6 months, the refund will be calculated on a prorated monthly basis.</li>
                        </ul>
                    </li>
                    <li><strong>18-Month Tenure:</strong>
                        <ul>
                            <li><strong>Before 6 Months:</strong> For example, if a customer terminates their subscription at the end of the 2nd month, the total amount is Rs. 18,000. The prorated monthly rate is Rs. 1,000. The customer will receive a refund of Rs. 12,000, covering the remaining 12 months.</li>
                            <li><strong>After 6 Months:</strong> If termination occurs after 6 months, for instance, at 10 months and 1 day, the refund will be Rs. 1,000, which represents the 12th month's amount.</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li><strong>Refund Calculation Example:</strong> This example demonstrates how the refund amount is determined based on the prorated rate and the timing of subscription termination. Note that amounts are illustrative.</li>
            <li><strong>Monthly Prorated Basis:</strong> Refunds are not applicable for usage extending beyond the last full month of the subscription. For example, if a customer uses the service for 11 months and 1 day, no refund will be issued for the 12th month.</li>
            <li><strong>Terms and Conditions:</strong> These terms govern subscription termination and refunds. Customers can opt for the Flexi option to be eligible for potential refunds, with criteria based on the chosen subscription tenure. RENTYFURNITURE aims to provide clarity and fairness in its refund process.</li>
        </ul>

        <h2>Personal Information</h2>
        <ul>
            <li><strong>Data Collection:</strong> The Platform collects and uses personal information as described in our Privacy Policy. This may include data used to build a customer profile, such as:
                <ul>
                    <li><strong>Identifiers:</strong> Name, Alias, Postal Address, Username, Password, Email Address, Aadhaar Number (UID), Permanent Account Number (PAN)</li>
                    <li><strong>Protected Classifications:</strong> Race, Religion, Sex/Gender, Sexual Orientation</li>
                    <li><strong>Commercial Information:</strong> Products purchased, obtained, or considered</li>
                    <li><strong>Internet and Interaction Data:</strong> Browsing history, search history, geolocation data, interaction with websites, apps, or ads, and audio, electronic, visual, or similar information</li>
                    <li><strong>Inferences:</strong> Preferences, characteristics, predispositions, behavior, intelligence, or aptitudes used to create a consumer profile</li>
                </ul>
            </li>
        </ul>

        <h2>Products and Services</h2>
        <ul>
            <li><strong>Products and Services:</strong> The Platform offers products such as furniture, soft furnishings, and home décor under the brand “RENTYFURNITURE” (“Products”), as well as services including shipping, delivery, and installation (“Services”). By selecting a Product and making a payment, you submit an offer to purchase based on the terms provided on the Platform. The Company retains sole discretion to accept or reject your offer. If your offer is rejected after payment, the Company will refund the full amount to the original payment source within 10 working days.</li>
            <li><strong>Product Descriptions and Pricing:</strong> Product descriptions and pricing are displayed on the Platform. Prices may vary due to technical issues, typographical errors, or discrepancies. The Company reserves the right to correct any errors or modify product details, including prices. In such cases, the Company may contact you for further instructions or cancel the order, refunding any processed payments within 10 working days.</li>
            <li><strong>Images of Products:</strong> Images of Products on the Platform may differ from actual products. The Company does not guarantee the accuracy of these images and they should not be relied upon for the product’s quality.</li>
        </ul>

        <h2>Delivery</h2>
        <ul>
            <li><strong>Shipping and Delivery:</strong> The Company provides shipping and delivery to areas it services, with no charge unless otherwise notified.</li>
            <li><strong>Address Verification:</strong> Shipping addresses and pin codes will be verified for serviceability before payment is processed. If an order cannot be serviced, you may provide an alternative address within the serviceable area.</li>
            <li><strong>Delivery Timeline:</strong> The Company will attempt to deliver Products within the promised timeline. You will be updated via call, SMS, or email about your order status. For inquiries, contact customercare@RENTYFURNITURE.com. Delivery may be delayed due to factors beyond the Company’s control.</li>
            <li><strong>Delivery Attempts:</strong> The Company will make up to two delivery attempts. If delivery cannot be completed after these attempts, the Company reserves the right to cancel the order.</li>
        </ul>

        <h2>Returns, Refunds, and Cancellations</h2>
        <ul>
            <li><strong>Returns:</strong> Products cannot be returned once delivered. However, they are covered under the warranty as specified in the warranty clause.</li>
            <li><strong>Cancellations:</strong> Orders can be canceled before shipment for a full refund. Orders cannot be canceled once dispatched.</li>
            <li><strong>Refunds:</strong> Refunds, if applicable, will be processed based on the merit of the claim. Approved refunds will be issued within 10 working days to the original payment method.</li>
        </ul>

        <h2>Taxes</h2>
        <ul>
            <li><strong>Responsibility:</strong> You are responsible for all fees, costs, and charges associated with purchasing Products and Services, including applicable taxes such as Goods & Service Tax.</li>
        </ul>

        <h2>Payment</h2>
        <ul>
            <li><strong>Payment Processor:</strong> The Company uses a third-party payment processor ("Payment Processor") to handle payments. Payments are subject to the Payment Processor's terms, conditions, and privacy policies. The Company is not liable for errors or issues at the Payment Processor’s end. By placing an order, you authorize the Payment Processor to charge your selected payment method.</li>
            <li><strong>Chargebacks:</strong> You may initiate a chargeback through your Payment Method provider for fraudulent transactions. The Company is not responsible for chargeback processing and reserves the right to review your account for fraud. The Company may suspend or terminate your account if fraud is suspected.</li>
            <li><strong>Unauthorized Transactions:</strong> The Company is not liable for any issues related to unauthorized transactions, exceeded limits, or payment declines.</li>
            <li><strong>Currency:</strong> All payments on the Platform must be made in Indian Rupees.</li>
        </ul>

        <h2>Warranty</h2>
        <ul>
            <li><strong>Coverage:</strong> Products are covered under the manufacturer’s warranty, with specific details available on the respective product pages on the Platform. For warranty-related issues, please contact customercare@RENTYFURNITURE.com. General warranty terms can be found in section 25.9.</li>
        </ul>

        <h2>Intellectual Property Rights</h2>
        <ul>
            <li><strong>Ownership:</strong> All intellectual property rights, including trademarks, copyrights, designs, and other related rights in the Platform, Products, and/or Services, are the exclusive property of the Company, its affiliates, or licensors (“RENTYFURNITURE IP”).</li>
            <li><strong>License:</strong> You are granted a limited, non-exclusive, non-transferable, and non-sublicensable license to access and use the Platform in accordance with these Terms. All other rights are reserved by the Company.</li>
            <li><strong>Usage:</strong> You may use and display RENTYFURNITURE IP only for personal use, specifically for identifying Products, making purchases, and processing returns and refunds. This usage is subject to the limited, revocable, personal, non-exclusive, and non-transferable license granted upon registration.</li>
            <li><strong>Modifications:</strong> The Company may modify the Platform, its features, or any related Products or Services at its discretion, including the possibility of discontinuing access to certain features or the entire Platform. The Company may also impose usage limits without prior notice.</li>
        </ul>

        <h2>Usage Restrictions</h2>
        <ul>
            <li><strong>Prohibitions:</strong> While using the Platform, you shall not:
                <ul>
                    <li>Copy, translate, disassemble, decompile, reverse-engineer, distribute, publish, display, perform, modify, upload, or create derivative works from any part of the Platform or any materials provided by the Company.</li>
                    <li>Upload, input, transmit, or otherwise make available any content that:
                        <ul>
                            <li>Is illegal, fraudulent, deceptive, or violates privacy or other rights;</li>
                            <li>Is promotional or constitutes spam without prior written consent from the Company;</li>
                            <li>Is offensive, discriminatory, or harmful;</li>
                            <li>Infringes on intellectual property rights;</li>
                            <li>Contains harmful software;</li>
                            <li>Is personally identifiable information of others without proper rights;</li>
                            <li>Is not legally or contractually permissible.</li>
                        </ul>
                    </li>
                    <li>Use Company trademarks or logos in a way that suggests association or endorsement by the Company.</li>
                    <li>Disrupt or interfere with the Platform's proper functioning or its network connections.</li>
                    <li>Overburden the Platform or its infrastructure.</li>
                    <li>Access materials not publicly available through the Platform.</li>
                    <li>Violate Platform security or attempt unauthorized access.</li>
                    <li>Use automated tools to access or monitor the Platform.</li>
                    <li>Probe or test the Platform’s vulnerability or security measures.</li>
                    <li>Make defamatory or negative statements about the Company or its affiliates.</li>
                    <li>Forge headers or impersonate individuals or entities.</li>
                    <li>Use the Platform for unlawful purposes or to solicit illegal activities.</li>
                </ul>
            </li>
        </ul>

        <h2>Terms and Termination</h2>
        <ul>
            <li><strong>Account Closure:</strong> If you do not agree to these Terms, you may close your User Account. Such termination does not affect rights and obligations from prior use.</li>
            <li><strong>Company Termination:</strong> The Company may terminate or suspend these Terms and your User Account with or without notice.</li>
            <li><strong>Reasons for Termination:</strong> Termination or suspension may occur if:
                <ul>
                    <li>You breach these Terms, Privacy Policy, or other applicable terms;</li>
                    <li>Required by law;</li>
                    <li>The Company decides to discontinue the Platform or your access.</li>
                </ul>
            </li>
        </ul>

        <h2>Disclaimer</h2>
        <ul>
            <li>Except as provided under the RENTYFURNITURE service warranty, the Company disclaims all other warranties, including implied warranties of merchantability and fitness for a particular purpose. The Platform is provided "as is" without warranty of any kind. The Company is not liable for errors, interruptions, or issues related to the Platform. The Company is not responsible for data loss or unauthorized use of your User Account.</li>
        </ul>

        <h2>Indemnity and Limitation of Liability</h2>
        <ul>
            <li><strong>Indemnification:</strong> You agree to indemnify the Company against losses or claims arising from your use of the Platform in violation of these Terms or applicable law.</li>
            <li><strong>Additional Rights:</strong> This indemnification is in addition to other rights available to the Company.</li>
            <li><strong>Compensation:</strong> Payments for indemnity are not penalties but compensation for the Company's losses.</li>
            <li><strong>Limitation:</strong> The Company is not liable for special, incidental, consequential, or indirect damages, or for damages beyond the amount paid by you for any Product or Service related to the claim.</li>
        </ul>

        <h2>Confidentiality</h2>
        <ul>
            <li><strong>Obligations:</strong> You shall keep all Confidential Information confidential and use it only as necessary to perform obligations under these Terms. Confidential Information includes information designated as confidential or that should reasonably be understood as confidential.</li>
            <li><strong>Feedback:</strong> The Company has no obligation to keep suggestions or feedback confidential. By providing such, you grant the Company a perpetual, irrevocable, royalty-free license to use, modify, and commercially exploit them.</li>
        </ul>

        <h2>Force Majeure</h2>
        <ul>
            <li>The Company is not liable for failures or delays due to causes beyond its control, including natural disasters, war, or network failures.</li>
        </ul>

        <h2>Governing Law</h2>
        <ul>
            <li>These Terms are governed by Indian law. Any legal action shall be brought in Bangalore, and you consent to the jurisdiction of the courts there.</li>
        </ul>

        <h2>Dispute Resolution</h2>
        <ul>
            <li><strong>Resolution Methods:</strong> Disputes shall be resolved through negotiation or, if necessary, arbitration under the Arbitration and Conciliation Act, 1996.</li>
            <li><strong>Arbitrator:</strong> The Company may appoint a sole arbitrator. Arbitration will occur in Bangalore, in English.</li>
        </ul>

        <h2>Notifications</h2>
        <ul>
            <li>The Company may send notifications via email or on the Platform. You consent to receive electronic communications, which will satisfy legal requirements for written communication.</li>
        </ul>

        <h2>Security Policy</h2>
        <ul>
            <li>The Company takes security seriously but acknowledges that no system is perfect. If you discover a security issue, please report it to customercare@RENTYFURNITURE.com. The Company appreciates contributions from security researchers.</li>
        </ul>

        <h2>Third Party Websites</h2>
        <ul>
            <li>The Platform may link to third-party websites. The Company is not responsible for their content or accuracy, and links do not imply endorsement.</li>
        </ul>

        <h2>Information and Press Releases</h2>
        <ul>
            <li>Information about the Company on the Platform is provided as of the date prepared. The Company has no obligation to update this information.</li>
        </ul>

        <h2>Customer Care</h2>
        <ul>
            <li>For queries or feedback, contact the customer care team at customercare@RENTYFURNITURE.com.</li>
        </ul>

        <h2>Grievance Redressal</h2>
        <ul>
            <li>If your query remains unresolved after the initial escalation, you may escalate the issue further to our Grievance Officer:
                <ul>
                    <li><strong>Name:</strong> Mr. Uma Shankar (“Grievance Redressal Officer”)</li>
                    <li><strong>Contact Information:</strong> customercare@RENTYFURNITURE.com</li>
                </ul>
            </li>
            <li>The Grievance Redressal Officer will initiate an inquiry within 48 (forty-eight) hours of receiving the complaint and will endeavour to resolve it promptly, but in any case, within 1 (one) month from the date of receipt of the complaint.</li>
        </ul>
        <h2>Miscellaneous</h2>
        <ul>
            <li><strong>Notice:</strong> Any notice you wish to serve on the Company must be sent to the address provided in the Terms or as updated by the Company on its website from time to time.</li>
            <li><strong>Assignment:</strong> The Company may assign any of its rights under these Terms to any person or entity. You may assign your User Account and these Terms to a third party only with the Company’s consent.</li>
            <li><strong>Amendments:</strong> The Company may modify or update these Terms at its sole discretion. It is your responsibility to review these Terms periodically. When the Company makes material changes, it will update the “Version Date” at the top of this page. The Company is not responsible for notifying you of these changes. Your continued use of the Platform after changes constitute your acceptance of the new Terms. If you do not agree with any of these Terms or any future Terms, do not use or continue to access the Platform. Except as provided in this paragraph, these Terms may not be amended or waived without the Company’s express written authorization.</li>
            <li><strong>Survival:</strong> Termination of these Terms will not affect provisions that by their nature are intended to survive such termination.</li>
            <li><strong>Severability:</strong> If any provision of these Terms is held invalid or excessively broad by a court of competent jurisdiction, the remainder of these Terms and the application of such provision to persons or circumstances other than those held invalid shall not be affected.</li>
            <li><strong>Waiver:</strong> No term or provision of these Terms will be deemed waived unless the waiver is in writing and signed by the party against whom it is sought to be enforced. A waiver of any default or breach at any instance does not constitute a continuing waiver of the same or any subsequent breach.</li>
            <li><strong>Relationship:</strong> The relationship between the parties is on a principal-to-principal basis. Nothing in these Terms will be construed as creating a partnership, agency, or employment relationship.</li>
            <li><strong>Entire Agreement:</strong> These Terms, along with the Privacy Policy, constitute the entire agreement between the parties concerning the subject matter and supersede all other oral or written proposals, correspondence, or agreements.</li>
        </ul>
    <style>
  {
    `
       body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            margin: 20px;
            color: #333;
        }
        h1 {
            font-size: 24px;
            color: #0044cc;
            border-bottom: 2px solid #0044cc;
            padding-bottom: 5px;
            margin-bottom: 20px;
        }
        h2 {
            font-size: 20px;
            color: #0044cc;
            margin-top: 20px;
            margin-bottom: 10px;
        }
        h3 {
            font-size: 18px;
            color: #0044cc;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        ul {
            margin: 0;
            padding-left: 20px;
        }
        li {
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 20px;
        }
    
    `
  }
</style>
</div>
  )
}

export default Rental_Terms_And_Conditions