import {
    AllActiveSubScriptionRequest,
    AllActiveSubScriptionSuccess,
    AllActiveSubScriptionFailure,
} from "../../../actions/types";

const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};
export const ActiveRentalPlans = (state = initialState, action) => {
    switch (action.type) {
        case AllActiveSubScriptionRequest:
            return {
                ...state,
                loading: true,
            };
        case AllActiveSubScriptionSuccess:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "successfully fatched"
            };
        case AllActiveSubScriptionFailure:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
        default:
            return state;
    }
};
