import React from 'react'
import { Link } from 'react-router-dom'
import "../../style/css/forMobileTandQ.css"
function ForMobileTandQ() {
    return (
        <div className='container' >
            <div className='row'>
                <div className='col-12'>
                    <div className='styleDiv'>
                        <Link to="/contact-us" style={{ textDecoration: "none" }}>
                            <h1 className='tandqheading'>Contact</h1>
                        </Link>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='styleDiv'>
                        <Link to="/FAQ" style={{ textDecoration: "none" }}>
                            <h1 className='tandqheading'>FAQs</h1>
                        </Link>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='styleDiv'>
                        <Link to="/rental_terms_and_conditions" style={{ textDecoration: "none" }}>
                            <h1 className='tandqheading'>Rental Term & Conditions</h1>
                        </Link>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='styleDiv'>
                        <Link to="/Cancellation_return" style={{ textDecoration: "none" }}>
                            <h1 className='tandqheading'>Cancellation & Return</h1>
                        </Link>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='styleDiv'>
                        <Link to="/blogs" style={{ textDecoration: "none" }}>
                            <h1 className='tandqheading'>Blogs</h1>
                        </Link>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='styleDiv'>
                        <Link to="/document-required" style={{ textDecoration: "none" }}>
                            <h1 className='tandqheading'>Documents Required</h1>
                        </Link>
                    </div>

                </div>
                <div className='col-12'>
                    <div className='styleDiv'>
                        <Link to="/Privacy_Policy" style={{ textDecoration: "none" }}>
                            <h1 className='tandqheading'>Privacy Policy</h1>
                        </Link>
                    </div>
                </div>
                <div className='col-12' style={{ marginBottom: "80px" }}>
                    <div className='styleDiv'>
                        <Link to="/shipping_policy" style={{ textDecoration: "none" }}>
                            <h1 className='tandqheading'>Shipping Policy</h1>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForMobileTandQ