import react from 'react'


const PaymentPlan = () => {
    return (
       <>
       
       </>
    )
}

export default PaymentPlan