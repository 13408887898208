import React, { useState } from 'react';
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import Sidebar from './sidebar';
import Profile from "./Profile"
import Active_Subscription_Items from "./Active_Subscription_Items"
import All_Request from "./All_Request"
import Close_Subscription_Items from "./Close_Subscription_Items"
import Invoice from "./Invoice"
import Reciepts from "./Reciepts"
import "../../../style/css/main.css"
import PrivateRoute from '../../../utils/privateRoutes';
import VerifiedKyc from './kycverified';
function Main() {

    return (
        <div >
            <div className="container-fluid" style={{ marginTop: "80px" }}>
                <div className="row">
                    <div className="col-12 col-md-3 mt-4">
                        <Sidebar />
                    </div>
                    <div className="col-12 col-md-9 mt-4">
                        <Routes>
                            <Route path="/" element={<Outlet />} />
                            <Route path="profile" element={<Profile />} />
                            <Route path="active_subscription_items" element={<Active_Subscription_Items />} />
                            <Route path="all_request" element={<All_Request />} />
                            <Route path="close_subscriptions_items" element={<Close_Subscription_Items />} />
                            <Route path="invoice" element={<Invoice />} />
                            <Route path="reciepts" element={<Reciepts />} />
                            <Route path="kycverified" element={<VerifiedKyc/>} />

                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main