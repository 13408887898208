import {
    GET_PRODUCT_BY_ID_REQUEST,
    GET_PRODUCT_BY_ID_SUCCESS,
    GET_PRODUCT_BY_ID_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";

export const fetchProductByIdRequest = () => {
    return {
        type: GET_PRODUCT_BY_ID_REQUEST,
    };
};
export const fetchProductByIdSuccess = (Data) => {
    return {
        type: GET_PRODUCT_BY_ID_SUCCESS,
        payload: Data,
    };
};

export const fetchProductByIdFailure = (Error) => {
    return {
        type: GET_PRODUCT_BY_ID_FAILURE,
        payload: Error,
    };
};

export const fetchProductById = (name) => {
    const cityId=localStorage.getItem('cityId')
    const custumerId=localStorage.getItem('customerId')
    const encodedProductName = decodeURIComponent(name);
    console.log(encodedProductName,'encodedProductNameencodedProductNameencodedProductName')
 const prodyId=localStorage.getItem('productName')
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
        "ngrok-skip-browser-warning" : "skip-browser-warning",
    };
    return (dispatch) => {
        dispatch(fetchProductByIdRequest());
        axios
            .get(`${url}getProductByNameAndCityId/${prodyId}?cityId=${cityId}${custumerId ? `&customerId=${custumerId}` : ''}`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchProductByIdSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchProductByIdFailure(error));
            });
    };
};
