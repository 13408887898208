


import {
    createOrderIdRequest,
    createOrderIdSuceess,
    createOrderIdFail,
} from "../../types";


import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";

export const CreateOrderIdBYrEQ = () => ({
    type: createOrderIdRequest,
  });

export const CreateOrderIdBYSucess = (data) => {
    return {
        type: createOrderIdSuceess,
        payload:data
    };
};
export const CreateOrderIdBYrFail = (Data) => {
    return {
        type: createOrderIdFail,
        payload: Data,
    };
};



export const CreateOrderId = () => {
    const headers = {
        Accept: "application/json",
        'Authorization': basicAuth,
    };

 // Assuming pdfFile is the PDF file object

    return (dispatch) => {
        dispatch(CreateOrderIdBYrEQ());
        axios
            .get(url + `generatePreDefinedOrderId`, {
                headers: headers,
            })
            .then(function (response) {
                   console.log(response?.data,'orderID')
                localStorage.setItem('neworderId',response?.data)
                dispatch(CreateOrderIdBYSucess(response.data));
            })
            .catch(function (error) {
                dispatch(CreateOrderIdBYrFail(error));
            });
    };
};


