import React, { useState } from 'react';
import { Drawer, Button } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import piggyallter from './pigywallet.png'

const banksData = {
    HDFC: [
        { months: 3, interest: 'No-Cost', emi: 5933, overall: 17799 },
        { months: 6, interest: 'No-Cost', emi: 2967, overall: 17799 },
        { months: 9, interest: 'No-Cost', emi: 1978, overall: 17799 },
        { months: 12, interest: '15% PA*', emi: 1706, overall: 20469 },
        { months: 18, interest: '15% PA*', emi: 1137, overall: 20469 },
    ],
    Axis: [
        { months: 3, interest: 'No-Cost', emi: 5933, overall: 17799 },
        { months: 6, interest: 'No-Cost', emi: 2967, overall: 17799 },
        { months: 9, interest: 'No-Cost', emi: 1978, overall: 17799 },
        { months: 12, interest: '15% PA*', emi: 1706, overall: 20469 },
        { months: 18, interest: '15% PA*', emi: 1137, overall: 20469 },
    ],
};

const NoEmi = ({updatedPrice}) => {
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const isMobile = window.innerWidth <= 768;

    const handleOpen = key => {
        setOpen(true);
        setPlacement(key);
    };

    return (
        <div>
            <style>
                {`
                    p {
                        font-family: Recline !important;
                    }
                    .accordion-item {
                        margin-bottom: 10px;
                    }
                    .accordion-header {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        padding: 10px;
                        background: #f1f1f1;
                    }
                    .accordion-body {
                        padding: 10px;
                        border: 1px solid #f1f1f1;
                    }
                    .editable-table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .editable-table th, .editable-table td {
                        border: 1px solid #ddd;
                        padding: 8px;
                    }
                    .editable-table th {
                        background-color: #f2f2f2;
                    }
                    .dialog-container {
                        width: 300px;
                        padding: 20px;
                        border: 1px solid #ccc;
                        border-radius: 5px;
                    }
                    .dialog-footer {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;
                    }
                        h5{
                        font-size:15px;
                        }
                `}
            </style>
            {isMobile ? (
                <div className="MuiBox-root css-dnzdvo" style={{background:'aliceblue',marginTop:'-2px',borderRadius:'8px'}}>
                    <div className="MuiBox-root css-u01ki0" onClick={() => handleOpen('bottom')}>
                        <div className="MuiBox-root css-1kz6cvi">
                            <div className="MuiBox-root css-12z0wuy">
                                <div className="MuiBox-root css-1s0tc6b" style={{ paddingTop: "" }}>
                                    <img alt="" src={piggyallter} className="loaded" />
                                </div>
                            </div>
                            <div className="MuiBox-root css-0">
                                <p className="MuiTypography-root MuiTypography-body1 css-10lfzr7">No Cost EMI:</p>
                                <div className="MuiBox-root css-1kz6cvi">
                                    <p className="MuiTypography-root MuiTypography-body1 css-1ra57w1" style={{ marginLeft: '25px' }}> starting at ₹{updatedPrice}/mo</p>
                                </div>
                            </div>
                        </div>
                        <div className="MuiBox-root css-0">
                            <div className="MuiBox-root css-0">
                                <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="rgb(245, 99, 88)"></circle>
                                    <path d="m8.333 6.666 2.917 2.917-2.917 2.916" stroke="#fff" strokeWidth="1.05" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="MuiBox-root css-0"></div>
                </div>
            ) : <>
            <div className="MuiBox-root css-dnzdvo" style={{background:'white',marginTop:'10px'}}>
                    <div className="MuiBox-root css-u01ki0" onClick={() => handleOpen('right')}>
                        <div className="MuiBox-root css-1kz6cvi">
                            <div className="MuiBox-root css-12z0wuy">
                                <div className="MuiBox-root css-1s0tc6b" style={{ paddingTop: "" }}>
                                    <img alt="" src={piggyallter} className="loaded" />
                                </div>
                            </div>
                            <div className="MuiBox-root css-0">
                                <p className="MuiTypography-root MuiTypography-body1 css-10lfzr7">No Cost EMI:</p>
                                <div className="MuiBox-root css-1kz6cvi">
                                    <p className="MuiTypography-root MuiTypography-body1 css-1ra57w1" style={{ marginLeft: '25px' }}> starting at ₹{updatedPrice}/mo</p>
                                </div>
                            </div>
                        </div>
                        <div className="MuiBox-root css-0">
                            <div className="MuiBox-root css-0">
                                <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="rgb(245, 99, 88)"></circle>
                                    <path d="m8.333 6.666 2.917 2.917-2.917 2.916" stroke="#fff" strokeWidth="1.05" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="MuiBox-root css-0"></div>
                </div>
            </>}
            <Drawer placement={placement} open={open} onClose={() => setOpen(false)}>
                <Drawer.Header style={{position:'unset'}}>
                    <Drawer.Title style={{ fontWeight: '700',textAlign:'center' }}>EMI Plans</Drawer.Title>
                    <Drawer.Actions>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                    </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body>
                    <div className="MuiBox-root css-106v1ri">
                        <p className="MuiTypography-root MuiTypography-body1 css-1xz8zkz" style={{fontSize:'15px'}}>Find the perfect EMI option from below and use the same mode to pay at cart while placing the order.</p>
                    </div>
                    <div style={{fontSize:'15px'}}>
                        {Object.keys(banksData).map((bank, index) => (
                            <AccordionItem key={index} style={{fontSize:'15px'}} bankName={bank} emiData={banksData[bank]} />
                        ))}
                    </div>
            <p class="MuiTypography-root MuiTypography-body1 css-19lwdf6">*Other major Banks:Axis Bank, Kotak Bank, HSBC, IndusInd, Yes Bank, Bank of Baroda, Standard Chartered, Ratnakar Bank Ltd. Emi options available, Please check for information at cart.</p>

                </Drawer.Body>
            </Drawer>
        </div>
    );
};

const AccordionItem = ({ bankName, emiData }) => {
    const [isActive, setIsActive] = useState(false);
    const [editedData, setEditedData] = useState(emiData);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editingRow, setEditingRow] = useState(null);

    const handleToggle = () => {
        setIsActive(!isActive);
    };

    const handleEditClick = (rowIndex) => {
        setEditingRow(editedData[rowIndex]);
        setDialogOpen(true);
    };

    const handleSave = () => {
        const updatedData = editedData.map((item, index) =>
            index === editingRow.index ? editingRow : item
        );
        setEditedData(updatedData);
        setDialogOpen(false);
    };

    return (
        <div className="accordion-item">
            <style>
                {

                    `
                    .editable-table th {
    background-color: #f2f2f2;
    font-size: 11px;
}
    .editable-table th, .editable-table td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 12px;
}
                    `
                }
            </style>
            <div className="accordion-header" onClick={handleToggle}>
                <h5>{bankName} Bank EMI Options</h5>
                <span>{isActive ? '▲' : '▼'}</span>
            </div>
            {isActive && (
                <div className="accordion-body">
                    <table className="editable-table">
                        <thead>
                            <tr>
                                <th>Months</th>
                                <th>Interest</th>
                                <th>Monthly EMI</th>
                                <th>Overall Cost</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody style={{ overflow: 'scroll' }}>
                            {editedData.map((row, index) => (
                                <tr key={index}>
                                    <td className="js__editable">{row.months}</td>
                                    <td className="js__editable">{row.interest}</td>
                                    <td className="js__editable">{row.emi}</td>
                                    <td className="js__editable">{row.overall}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(index)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {dialogOpen && (
                        <dialog open className="dialog-container">
                            <div className="dialog-header">
                                <h2>Edit Details</h2>
                            </div>
                            <div className="dialog-content">
                                <form id="dialogForm">
                                    <div className="form-group">
                                        <label htmlFor="months" >Months:</label>
                                        <input type="number" id="months" name="months" value={editingRow.months} onChange={(e) => setEditingRow({ ...editingRow, months: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="interest">Interest:</label>
                                        <input type="text" id="interest" name="interest" value={editingRow.interest} onChange={(e) => setEditingRow({ ...editingRow, interest: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="emi">Monthly EMI:</label>
                                        <input type="number" id="emi" name="emi" value={editingRow.emi} onChange={(e) => setEditingRow({ ...editingRow, emi: e.target.value })} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="overall">Overall Cost:</label>
                                        <input type="number" id="overall" name="overall" value={editingRow.overall} onChange={(e) => setEditingRow({ ...editingRow, overall: e.target.value })} required />
                                    </div>
                                </form>
                            </div>
                            <div className="dialog-footer">
                                <button className="js__dialog-save" onClick={handleSave}>Save</button>
                                <button className="js__dialog-close" onClick={() => setDialogOpen(false)}>Cancel</button>
                            </div>
                        </dialog>
                    )}
                </div>
            )}

        </div>
    );
};

export default NoEmi;
