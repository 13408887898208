import React, { useState, useEffect, useRef } from "react";
import Logo from "../../style/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import "../../style/css/productListing.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
//API Function
import { fetchProductsByFilter } from "../../actions/products/getProductsByFilter";
import { fetchAllMainCategory } from "../../actions/products/mainCategory/getAllMainCategory";
import {
  fetchAllMainCategoryDropdownRequest,
  fetchAllMainCategoryDropdown,
} from "../../actions/products/mainCategory/getAllMainCategoryDropdown";
import { fetchAllSubCategoryDropdown } from "../../actions/products/subCategory/getAllSubCategoryDropdown";
import { fetchAllSuperCategoryDropdown } from "../../actions/products/superCategory/getAllSuperCategoryDropdown";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import TuneIcon from "@mui/icons-material/Tune";
//Redux
import { connect, useDispatch, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
//Icons & Utils
import { useNavigate, useParams, NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ArrowForward, ArrowRight, FilterList } from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CategoryIcon from "@mui/icons-material/Category";
import { BiCurrentLocation } from "react-icons/bi";
import { SlLike } from "react-icons/si";
import { BiLike } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import { LiaSearchSolid } from "react-icons/lia";
import { FaHome } from "react-icons/fa";
import { BiSolidTimer } from "react-icons/bi";
import { ImProfile } from "react-icons/im";
import TestingPage from "../testingPage";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { Form, FormCheck } from "react-bootstrap";
import { getALLPRODUCTSAction } from "../../actions/products/getALLLPRODUCTSaction";
import google from "./google.png";
import outofstock from "./outofstock.png";
import { Skeleton } from "@mui/material";

function ProductListing(props) {
  const location = useLocation();

  const categoryNamsssse = location.state;
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow2 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);

  const {
    setMainCategoryData,
    handleGetProducts,
    ProductFilterData,
    loading,
    handleFetchAllMainCategory,
    mainCategoryData,
    error,
    handleFetchAllMainCategoryDropdown,
    getAllMainCategoryDropdownData,
    handleFetchAllSubCategoryDropdown,
    getSubCategoryDropdownByIdData,
    // handleFetchAllSuperCategoryDropdown,
    getSuperCategoryDropdownByIdData,
    mainCategoryDataPreSelected,
    setCityPincodeData,
    userSelectedPincode,
  } = props;
  console.log(getSubCategoryDropdownByIdData, "getSubCategoryDropdownByIdData");

  const isMobile = window.innerWidth <= 768;
  const navigate = useNavigate();
  const [productsLoading, setProductsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setProductsLoading(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  // Initialize the state with the retrieved keyword or an empty string

  const options = ["Sofas", "Beds", "Others"];
  // const priceOptions = ["250", "251 - 500", "501 - 750", "751 - 1000"]
  const sortOptions = [
    "Featured",
    "Name A-Z",
    "Name Z-A",
    "Price Low to High",
    "Price High to Low",
  ];
  let minPrice = Infinity; // Initialize minPrice with Infinity to ensure any value will be smaller
  let maxPrice = -Infinity; // Initialize maxPrice with -Infinity to ensure any value will be larger
  const producstttttt = useSelector(
    (state) => state?.getAllproductRedcuer?.data
  );
  console.log(producstttttt, "producstttttt");

  const [sortedProductData, setSortedProductData] = useState([]);

  const [sortedProductforPriceRange, setSortedProductDataForPrice] = useState(
    []
  );

  const generatePriceOptions = (twelveMonths) => {
    console.log(twelveMonths, "twelveMonths");

    // Filter out non-numeric values and find min/max
    const validPrices = twelveMonths.filter(
      (price) => !isNaN(price) && price >= 0
    );

    if (validPrices.length === 0) {
      console.log("No valid prices found.");
      return [];
    }

    const minPrice = Math.min(...validPrices);
    const maxPrice = Math.max(...validPrices);

    const priceOptions = new Set(); // Use a Set to ensure uniqueness
    let start = Math.floor(minPrice / 300) * 300; // Start from the nearest lower multiple of 300

    // Generate ranges up to maxPrice
    while (start < maxPrice) {
      const end = Math.min(start + 300, maxPrice);
      priceOptions.add(`${start} - ${end}`);
      start += 300; // Move to the next range
    }

    // If maxPrice is exactly on a boundary, add it as a separate range
    if (maxPrice % 300 === 0) {
      priceOptions.add(`${maxPrice - 300} - ${maxPrice}`);
    }

    // Handle the case with only one valid price
    if (validPrices.length === 1) {
      const singlePrice = validPrices[0];
      const rangeStart = Math.floor(singlePrice / 300) * 300;
      const rangeEnd = rangeStart + 300; // Create a range around the single price
      priceOptions.add(`${rangeStart} - ${rangeEnd}`);
    }

    return Array.from(priceOptions); // Convert Set to array for rendering
  };

  // Usage in collecting unique price options from all products
  const priceOptionsSet = new Set();

  if (Array.isArray(sortedProductforPriceRange)) {
    const twelveMonths = sortedProductforPriceRange
      .filter((product) => product.status === true) // Exclude products with status false
      .map((product) => product.twelveMonth || 0); // Collect all twelveMonth values

    const priceOptions = generatePriceOptions(twelveMonths); // Get unique price options
    priceOptions.forEach((option) => priceOptionsSet.add(option)); // Add each option to the Set
  }

  const priceOptionsArray = Array.from(priceOptionsSet); // Convert Set to array for rendering

  // Defining State
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [superAnchor, setSuperAnchor] = useState(null);
  const [superFilters, setSuperFilters] = useState([]);
  const [priceRangeE, setPriceRangeE] = useState(null);
  const [selectedPriceRange, setSelectedPriceRange] = useState([]);
  const [sortE, setSortE] = useState(null);
  const [selectedSortE, setSelectedSortE] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedSortOption, setSelectedSortOption] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { keyword } = useParams();
  const [filterData, setFilterData] = useState({
    categoryName: "",
    subCategoryNames: [],
    minPrice: 0,
    maxPrice: 0,
    keyword: "",
    pincode: userSelectedPincode?.pincode
      ? userSelectedPincode?.pincode
      : 201301,
  });

  //Sub Category Functionality or Logic
  const SubCategoryOpen = Boolean(anchorEl);
  const SubCategoryId = SubCategoryOpen ? "filter-dropdown" : undefined;
  const handleOpenSubCategoryClick = (event) => {
    setAnchorEl(event.currentTarget);
    const popoverElement = document.querySelector(
      ".css-3bmhjh-MuiPaper-root-MuiPopover-paper"
    );

    // Check if the element exists
    if (popoverElement) {
      // Hide the element by setting its display property to 'none'
      popoverElement.style.display = "none";
    }
  };
  const handleSubCategoryClose = () => {
    setAnchorEl(null);
  };
  const [selectedSubCategory, setSelectedSubCategory] = useState([]); // Define selectedSubCategory state
  const [selectedsuperSubCategory, setselectedsuperSubCategory] = useState([]); // Define selectedSubCategory state

  //Sub-Category Checkbox Selection
  const handleSubCategoryCheckboxChange = (filter) => {
    console.log(filter, "filerrrrrrrrrr");
    console.log(selectedSubCategory, "selectedSubCategory");

    const isChecked = selectedSubCategory.some(
      (item) => item.value === filter.value
    );

    if (isChecked) {
      // If the checkbox is already checked, remove it from the selectedSubCategory array
      setSelectedSubCategory(
        selectedSubCategory.filter((item) => item.value !== filter.value)
      );
    } else {
      // If the checkbox is not checked, add it to the selectedSubCategory array
      setSelectedSubCategory([...selectedSubCategory, filter]);
    }

    // Selecting Sub category Id
    const subCategoryId = filter.value;

    // Call the function to fetch all super categories based on the selected sub category
    // handleFetchAllSuperCategoryDropdown(subCategoryId);

    // Update selectedFilters state with the selected filter
    const updatedFilters = selectedFilters.includes(filter)
      ? selectedFilters.filter((item) => item.value !== filter.value)
      : [...selectedFilters, filter];

    // Map updatedFilters to extract labels
    const labelArray = updatedFilters.map((item) => item.label);

    // Update filterData with the new list of subCategoryNames and reset minPrice and maxPrice
    setFilterData({
      ...filterData,
      subCategoryNames: labelArray,
      minPrice: 0,
      maxPrice: 0,
    });

    // Update selectedFilters state with the updatedFilters list
    setSelectedFilters(updatedFilters);
  };

  useEffect(() => {
    // handleFetchAllSuperCategoryDropdown()
  }, [getSubCategoryDropdownByIdData]);

  //Super Category Functionality or Logic
  const SuperCategoryOpen = Boolean(superAnchor);
  const SuperCategoryId = SuperCategoryOpen ? "filter-dropdown" : undefined;
  const handleOpenSuperCategoryClick = (event) => {
    setSuperAnchor(event.currentTarget);
    setSuperCategoryOpen(!superCategoryOpen);
  };
  const handleSuperCategoryClose = () => {
    setSuperAnchor(null);
  };

  //Super-Category Checkbox Selection
  const handleSuperCategoryCheckboxChange = (filter) => {
    // Toggle the selection state of the filter
    if (selectedsuperSubCategory.includes(filter)) {
      // If the filter is already selected, remove it from the selection
      setselectedsuperSubCategory(
        selectedsuperSubCategory.filter((item) => item !== filter)
      );
    } else {
      // If the filter is not selected, add it to the selection
      setselectedsuperSubCategory([...selectedsuperSubCategory, filter]);
    }

    // Update superFilters state based on the selection
    const updatedFilters = superFilters.includes(filter)
      ? superFilters.filter((item) => item !== filter) // Remove filter if already in superFilters
      : [...superFilters, filter]; // Add filter if not in superFilters

    // Map updatedFilters to extract labels
    const labelArray = updatedFilters.map((item) => item.label);

    // Update filterData with the new list of supperCategoryNames
    setFilterData({
      ...filterData,
      supperCategoryNames: labelArray,
    });

    // Update superFilters state with the updatedFilters list
    setSuperFilters(updatedFilters);
  };

  // Price Range Functionality or logic

  const PriceRangeOpen = Boolean(priceRangeE);
  const priceRangeId = PriceRangeOpen ? "price-range-dropdown" : undefined;
  const handleOpenPriceRangeClick = (event) => {
    setPriceRangeE(event.currentTarget);
    setPriceRangeOpen(!priceRangeOpen);
  };

  const handlePriceRangeClose = () => {
    setPriceRangeE(null);
  };
  const handlePriceRangeCheckboxChange = (filter) => {
    // Split the string into an array using the '-' delimiter
    const [min, max] = filter
      .split("-")
      .map((value) => parseInt(value.trim(), 10));
    const updatedFilters = selectedPriceRange.includes(filter) ? [] : [filter];

    // const updatedFilters = selectedPriceRange.includes(filter)
    //     ? selectedPriceRange.filter((item) => item !== filter)
    //     : [...selectedPriceRange, filter];

    setSelectedPriceRange(updatedFilters);
    setFilterData({
      ...filterData,

      minPrice: min,
      maxPrice: max,
    });
  };
  // useEffect(() => {
  //     if (filterData.categoryName) {
  //         localStorage.setItem('cat', filterData.categoryName);
  //     }
  // // }, [filterData]);
  // useEffect(() => {
  //   // Clear the 'cat' key from localStorage when navigating to the homepage
  //   localStorage.removeItem("cat");
  // }, []);

  useEffect(() => {
    // Clear the 'cat' key from localStorage when navigating to the homepage
    localStorage.setItem("pincode", filterData?.pincode);
  }, [filterData?.pincode]);

  // useEffect(() => {
  //     const handleNavigation = () => {
  //         // Clear the 'cat' key from localStorage when navigating to the home page
  //         localStorage.removeItem('cat');
  //     };

  //     // Listen for the component to unmount and navigate to the home page
  //     return () => {
  //         handleNavigation();
  //     };
  // }, []);

  //Sort Functionality or logic
  const sortopenss = Boolean(sortE);
  const sortId = sortopenss ? "sort-dropdown" : undefined;
  const handleOpenSortClick = (event) => {
    setSortE(event.currentTarget);
    setSortOpen(!sortOpen);
  };

  const handleSortClose = () => {
    setSortE(null);
  };
  const handleSortCheckboxChange = (filter) => {
    setSelectedSortOption(filter);
    const updatedFilters = selectedSortE.includes(filter) ? [] : [filter];

    // const updatedFilters = selectedSortE.includes(filter)
    //     ? selectedSortE.filter((item) => item !== filter)
    //     : [...selectedSortE, filter];

    setSelectedSortE(updatedFilters);
  };

  // product-details
  useEffect(() => {
    window.scrollTo(0, 0);
    handleGetProducts(filterData, keyword);
    handleFetchAllMainCategory();
    handleFetchAllMainCategoryDropdown();
  }, []);

  //Handle Set MainCategory Preselected Data from the home page
  useEffect(() => {
    console.log(
      "mainCategoryDataPreSelected before update:",
      mainCategoryDataPreSelected
    );

    // Assuming mainCategoryDataPreSelected is correctly set from the homepage
    if (mainCategoryDataPreSelected) {
      console.log("Updating category data:", mainCategoryDataPreSelected);
      localStorage.setItem("selectedId", mainCategoryDataPreSelected.id);

      setSelectedCard(mainCategoryDataPreSelected.id);
      handleFetchAllSubCategoryDropdown(mainCategoryDataPreSelected.id);
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        categoryName: mainCategoryDataPreSelected.categoryName,
      }));
    }
  }, [mainCategoryDataPreSelected]);

  const calculateDiscouunt = (twelveMonth, perMonth) => {
    // Calculate the total price for 18 months without discount
    const discountPercentage = ((perMonth - twelveMonth) / perMonth) * 100;
    // Return the discount percentage for 18 months
    return discountPercentage.toFixed(2);
  };

  const handleCardClick = (index, id, categoryName) => {
    console.log(categoryNamsssse, "categoryNamssssecategoryNamsssse");
    setSelectedCard(id);
    localStorage.setItem("selectedId", id);
    handleFetchAllSubCategoryDropdown(id);
    localStorage.setItem("cat", categoryName);
    setMainCategoryData({
      index: index,
      id: id,
      categoryName: categoryName,
    });
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      categoryName: categoryName,
      maxPrice: 0,
      minPrice: 0,
    }));
    console.log(categoryName);
    setFilterData({
      ...filterData,
      subCategoryNames: [],
      maxPrice: 0,
      minPrice: 0,
    });
    setSelectedSortE([]); // Assuming setSelectedSortE is your state updater function
    setSelectedSortOption("Sort by")
  };
  useEffect(() => {
    // Check if categoryName has changed
    if (filterData?.minPrice) {
      return;
    } else if (filterData?.maxPrice) {
      return;
    } else if (filterData?.categoryName) {
      setSortedProductDataForPrice(sortedProductData);
    }
  }, [
    filterData.categoryName,
    filterData?.minPrice,
    filterData?.maxPrice,
    sortedProductData,
  ]); // Dependencies

  useEffect(() => {
    localStorage.setItem("cat", filterData?.categoryName);
  }, [categoryNamsssse, filterData?.categoryName]);

  useEffect(() => {
    console.log(filterData, "filterData before API call");

    handleGetProducts(filterData, keyword);

    console.log(filterData, "filterData after API call");
  }, [keyword, filterData]);

  //Sort Function
  var minPrice2 = -Infinity;
  var mixPrice2 = -Infinity;

  useEffect(() => {
    // Perform sorting logic whenever selectedSortOption or ProductFilterData changes
    if (
      selectedSortOption &&
      Array.isArray(ProductFilterData) &&
      ProductFilterData.length !== 0
    ) {
      const sortedData = [...ProductFilterData];
      if (selectedSortOption === "Name A-Z") {
        sortedData.sort((a, b) => a.productTitle.localeCompare(b.productTitle));
      } else if (selectedSortOption === "Name Z-A") {
        sortedData.sort((a, b) => b.productTitle.localeCompare(a.productTitle));
      } else if (selectedSortOption === "Price Low to High") {
        const newValue = ProductFilterData?.map((item) => item?.twelveMonth);
        minPrice2 = Math.min(minPrice, newValue);
        mixPrice2 = Math.max(mixPrice2, newValue);
        sortedData.sort((a, b) => a.twelveMonth - b.twelveMonth);
      } else if (selectedSortOption === "Price High to Low") {
        sortedData.sort((a, b) => b.twelveMonth - a.twelveMonth);
      }
      setSortedProductData(sortedData);
    } else {
      // If no sorting option is selected, set the original unsorted data
      if (Array.isArray(ProductFilterData) && ProductFilterData.length !== 0) {
        setSortedProductData([...ProductFilterData]);
      } else {
        setSortedProductData([]);
      }
    }
  }, [selectedSortOption, ProductFilterData]);

  const [hoveredIndices, setHoveredIndices] = useState(
    Array(sortedProductData.length).fill(null)
  );

  const handleHover = (index) => {
    const newHoveredIndices = [...hoveredIndices];
    newHoveredIndices[index] = index;
    setHoveredIndices(newHoveredIndices);
  };
  const handleProductDetail = (item) => {
    const formattedProductName1 = item?.productTitle?.replace(/\s+/g, '-'); // Replace all spaces with hyphens

    navigate(`/product-details/${formattedProductName1}`);
    // window.location.reload();

  };

  const [futureDate, setFutureDate] = useState();

  const mydata = (days) => {
    const currentDate = new Date();
    const futureDate = new Date(
      currentDate.getTime() + days * 24 * 60 * 60 * 1000
    );
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    var forDate = futureDate.toLocaleDateString("en-GB", options);
    return forDate;
  };
  const [subCategoryOpen, setSubCategoryOpen] = useState(false);

  const handleSubCategoryClick = () => {
    setSubCategoryOpen(!subCategoryOpen);
  };

  const [superCategoryOpen, setSuperCategoryOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleCloseDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSuperCategoryOpen(false);
    }
  };
  const dropdownRef2 = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleCloseDropdown);
    return () => {
      document.removeEventListener("mousedown", handleCloseDropdown);
    };
  }, []);

  const handleCloseDropdown2 = (event) => {
    if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
      setSubCategoryOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleCloseDropdown2);
    return () => {
      document.removeEventListener("mousedown", handleCloseDropdown2);
    };
  }, []);
  const dispatch = useDispatch();

  const [priceRangeOpen, setPriceRangeOpen] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  // useEffect(() => {
  //   dispatch(getALLPRODUCTSAction());
  // }, [dispatch]);

  const handleMouseEnter = () => {
    setSubCategoryOpen(true);
  };

  const handleMouseLeave = () => {
    setSubCategoryOpen(false);
  };

  const handleMouseEnter2 = () => {
    setPriceRangeOpen(true);
  };

  const handleMouseLeave2 = () => {
    setPriceRangeOpen(false);
  };
  const handleMouseEnter3 = () => {
    setSortOpen(true);
  };

  const handleMouseLeave3 = () => {
    setSortOpen(false);
  };

  // Retrieve selected category and card index from localStorage on component mount
  useEffect(() => {
    const savedCategoryName = localStorage.getItem("cat");
    const savedCategoryIndex = localStorage.getItem("selectedCardIndex");

    if (savedCategoryName && savedCategoryIndex !== null) {
      setSelectedCard(parseInt(savedCategoryIndex));
      setFilterData((prev) => ({
        ...prev,
        categoryName: savedCategoryName,
      }));
    }
  }, []);

  return ProductFilterData.legnth != 0 ? (
    <div style={{ marginTop: "5%" }}>
      {isMobile ? (
        <>
          <style>
            {`
                                .headerssss{
                                display:none;
                                }
                                    .MuiBox-root.css-1492rz {
    border: 1px solid grey !important;
}
                                    `}
          </style>
          <div class="MuiBox-root css-72yisn" bis_skin_checked="1">
            <div class="MuiBox-root css-1s315wo" bis_skin_checked="1">
              <div
                class="MuiBox-root css-qdo4dw"
                style={{ margin: "0px" }}
                bis_skin_checked="1"
              >
                <div class="MuiBox-root css-1w45noj" bis_skin_checked="1">
                  {Array.isArray(mainCategoryData) &&
                    mainCategoryData
                      ?.filter((item) => item.status !== false)
                      ?.map((item, index) => (
                        <div
                          key={index}
                          onClick={() =>
                            handleCardClick(
                              index,
                              item.categoryId,
                              item.categoryName
                            )
                          }
                        >
                          {Number(selectedCard) === Number(item.categoryId) ? (
                            <div
                              className="MuiBox-root css-ocf0bf"
                              bis_skin_checked="1"
                            >
                              <div
                                className="MuiBox-root css-15ro776"
                                bis_skin_checked="1"
                              >
                                <svg
                                  width="12"
                                  height="12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10 3 4.5 8.5 2 6"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <p
                                className="MuiTypography-root MuiTypography-body1 css-heejq2"
                                style={{ textOverflow: "ellipsis" }}
                              >
                                {item.categoryName}
                              </p>
                            </div>
                          ) : (
                            <div
                              className={`MuiBox-root css-1492rz`}
                              bis_skin_checked="1"
                            >
                              <p className="MuiTypography-root MuiTypography-body1 css-h6pkw8">
                                {item.categoryName}
                              </p>
                            </div>
                          )}
                        </div>
                      ))}
                </div>

                {isMobile && (
                  <>
                    <style>
                      {`
                                                        button.MuiButtonBase-root.MuiButton-root.MuiButton-outline-primary.MuiButton-outline-primaryPrimary.MuiButton-sizeMedium.MuiButton-outline-primarySizeMedium.MuiButton-root.MuiButton-outline-primary.MuiButton-outline-primaryPrimary.MuiButton-sizeMedium.MuiButton-outline-primarySizeMedium.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
 
                                                            border: 1px solid grey !important;
    white-space: nowrap;
    color: rgb(119, 119, 119) !important;
    font-size: 12px;
    background-color: transparent;


}
    button.MuiButtonBase-root.MuiButton-root.MuiButton-outline-primary.MuiButton-outline-primaryPrimary.MuiButton-sizeMedium.MuiButton-outline-primarySizeMedium.MuiButton-root.MuiButton-outline-primary.MuiButton-outline-primaryPrimary.MuiButton-sizeMedium.MuiButton-outline-primarySizeMedium.css-1w1rijm-MuiButtonBase-root-MuiButton-root:hover {
 background:red;
}
                                                        `}
                    </style>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "",
                        gap: "10px",
                        overflow: "auto",
                        margin: "10px",
                      }}
                    >
                      {Array.isArray(getSubCategoryDropdownByIdData) &&
                      getSubCategoryDropdownByIdData.length !== 0 ? (
                        getSubCategoryDropdownByIdData.map((option) =>
                          // Check if status is true before rendering the option
                          option.status ? (
                            <div
                              key={option.id}
                              style={{
                                border: "1px solid grey",
                                borderRadius: "8px",
                              }}
                            >
                              <Button
                                variant="outline-primary" // Set default variant
                                onClick={() =>
                                  handleSubCategoryCheckboxChange(option)
                                }
                                style={{
                                  border: "1px solid grey", // Removed !important
                                  whiteSpace: "nowrap", // Prevent text wrapping
                                  color: selectedSubCategory.includes(option)
                                    ? "white"
                                    : "grey", // Set text color based on selection
                                  backgroundColor: selectedSubCategory.includes(
                                    option
                                  )
                                    ? "rgb(245, 99, 88)"
                                    : "transparent", // Set background color based on selection
                                }}
                              >
                                {option.label}
                              </Button>
                            </div>
                          ) : null
                        )
                      ) : (
                        <p>There are no subcategories available</p>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div class="" bis_skin_checked="1">
                <div class="" bis_skin_checked="1">
                  <div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        margin: "auto",
                        width: "95%",
                        gap: "10px",
                        marginBottom: "15px",
                      }}
                    >
                      {loading ? (
                        <>
                          {/* Skeleton loader for each product card */}
                          {Array.from({ length: 4 }).map((_, index) => (
                            <div
                              key={index}
                              style={{
                                padding: "16px",
                                border: "1px solid #ddd",
                              }}
                            >
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={140}
                              />
                              <Skeleton width="60%" />
                              <Skeleton width="30%" />
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          {Array.isArray(sortedProductData) &&
                          sortedProductData.length !== 0 ? (
                            sortedProductData.map(
                              (item, index) =>
                                // Check if the status is true before rendering the product
                                item.status === true && (
                                  <div
                                    key={index}
                                    className="MuiBox-root "
                                    bis_skin_checked="1"
                                    onClick={() => handleProductDetail(item)}
                                  >
                                    <div
                                      className="MuiBox-root css-w26f2k"
                                      bis_skin_checked="1"
                                    >
                                      <div
                                        className="MuiBox-root css-14c5dc4"
                                        bis_skin_checked="1"
                                      >
                                        {item?.inStockQuantity == 0 && (
                                          <>
                                            <div
                                              className=""
                                              style={{
                                                width: "20px",
                                                position: "relative",
                                              }}
                                            >
                                              <img
                                                src={outofstock}
                                                style={{
                                                  width: "100px",
                                                  position: "absolute",
                                                  top: "",
                                                  zIndex: "999",
                                                }}
                                              />
                                            </div>
                                          </>
                                        )}
                                        <div style={{ position: "relative" }}>
                                          {item?.stripText ? (
                                            <>
                                              <p
                                                className="MuiTypography-root MuiTypography-Small_Medium css-11je29n"
                                                style={{
                                                  backgroundColor: `${item?.stripColor}`,
                                                  position: "absolute",
                                                  top: "92px",
                                                  left: 0,
                                                  zIndex: 1, // Ensures the text is on top of the image
                                                  padding: "5px", // Optional: add some padding to the text
                                                }}
                                              >
                                                {item?.stripText}
                                              </p>
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {/* <div className=""
                                                                                style={{
                                                                                    width: '30px',
                                                                                    position: 'absolute',
                                                                                    top: 0,
                                                                                    right: '30px',
                                                                                    zIndex: 1,  // Ensures the text is on top of the image
                                                                                }}>
                                                                            </div> */}
                                        </div>
                                        <div
                                          className="MuiBox-root css-1om2adh"
                                          bis_skin_checked="1"
                                          style={{ paddingTop: "100%" }}
                                        >
                                          {item && item.productImagesPath && (
                                            <img
                                              src={item.productImagesPath[0]}
                                              alt=""
                                              data-srcset={
                                                item.productImagesPath[0]
                                              }
                                              sizes=""
                                              className="lazy loaded"
                                              style={{
                                                height: "100%",
                                                width: "100%",
                                                visibility: "visible",
                                              }}
                                              srcSet={item.productImagesPath[0]}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MuiBox-root css-ldca19"
                                      bis_skin_checked="1"
                                      style={{ width: "100%" }}
                                    >
                                      <p
                                        className=""
                                        style={{
                                          marginTop: "15px",
                                          fontSize: "11px",
                                          display: "-webkit-box",
                                          WebkitLineClamp: 2, // Limit to 2 lines
                                          WebkitBoxOrient: "vertical",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {item?.productTitle}
                                      </p>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "",
                                        }}
                                      >
                                        <p
                                          className="MuiTypography-root MuiTypography-Small_Medium"
                                          style={{
                                            background: "rgb(245, 99, 88)",
                                            padding: "2px",
                                            borderRadius: "5px",
                                            color: "white",
                                            fontSize: "10px",
                                          }}
                                        >
                                          -
                                          {calculateDiscouunt(
                                            item?.twelveMonth,
                                            item?.perMonth
                                          )}
                                          % off
                                        </p>
                                        <s
                                          style={{
                                            display: "",
                                            justifyContent: "end",
                                            position: "relative",
                                            left: "5px",
                                            top: "5px",
                                            fontSize: "11px",
                                            marginBottom: "4px",
                                          }}
                                        >
                                          ₹{item?.perMonth}/mo
                                        </s>
                                      </div>

                                      <p className="MuiTypography-root MuiTypography-body1 css-2kxnf0">
                                        ₹ {item?.twelveMonth}
                                      </p>
                                      <div
                                        className="MuiBox-root css-ov56kn"
                                        bis_skin_checked="1"
                                      >
                                        <p className="MuiTypography-root MuiTypography-body1 css-1ws68lr">
                                          ₹ {item.price / 10}
                                        </p>
                                      </div>
                                      <div
                                        className=""
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p className="MuiTypography-root MuiTypography-body1 css-1ko6fke">
                                          Get it by {mydata(item.availabeDay)}
                                        </p>

                                        <ArrowForward />
                                      </div>
                                    </div>
                                  </div>
                                )
                            )
                          ) : (
                            <p style={{ color: "red" }}>No products found</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {isMobile && (
                  <>
                    {/* 1 */}
                    <Offcanvas
                      placement="bottom"
                      style={{
                        width: "100%",
                        height: "50%",
                        borderRadius: "10px",
                      }}
                      show={show1}
                      onHide={handleClose1}
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Price Range</Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <Dropdown
                          show={!priceRangeOpen}
                          onToggle={setPriceRangeOpen}
                          id={priceRangeId}
                        >
                          <Dropdown.Menu className="sort">
                            <ul
                              style={{ padding: "16px", listStyleType: "none" }}
                            >
                              {Array.isArray(priceOptionsArray) &&
                                priceOptionsArray?.map((option) => (
                                  <li
                                    key={option}
                                    style={{
                                      marginBottom: "8px",
                                      cursor: "pointer", // To indicate the whole li is clickable
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      handlePriceRangeCheckboxChange(option)
                                    } // Single click handler on li
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      checked={selectedPriceRange.includes(
                                        option
                                      )}
                                      onChange={() =>
                                        handlePriceRangeCheckboxChange(option)
                                      } // Ensures the checkbox toggles correctly
                                      onClick={(e) => e.stopPropagation()} // Prevents the click from bubbling to the li
                                      label={option}
                                      style={{
                                        marginRight: "8px",
                                        pointerEvents: "none",
                                      }} // Prevent checkbox from intercepting events
                                    />
                                  </li>
                                ))}
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Offcanvas.Body>
                    </Offcanvas>

                    {/* 2 */}
                    <Offcanvas
                      placement="bottom"
                      style={{
                        width: "100%",
                        height: "55%",
                        borderRadius: "10px",
                      }}
                      show={show}
                      onHide={handleClose}
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Sort</Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <Dropdown
                          show={!sortOpen}
                          onToggle={setSortOpen}
                          id={sortId}
                        >
                          <Dropdown.Menu>
                            <ul
                              style={{ padding: "16px", listStyleType: "none" }}
                            >
                              {sortOptions.map((option) => (
                                <li
                                  key={option}
                                  style={{
                                    marginBottom: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleSortCheckboxChange(option)
                                  } // Make the entire item clickable
                                >
                                  <Form.Check
                                    type="checkbox"
                                    checked={selectedSortE.includes(option)}
                                    onChange={() =>
                                      handleSortCheckboxChange(option)
                                    }
                                    label={option}
                                    style={{ pointerEvents: "none" }} // Disable the default checkbox click
                                  />
                                </li>
                              ))}
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Offcanvas.Body>
                    </Offcanvas>
                    <div class="MuiBox-root css-8atqhb" bis_skin_checked="1">
                      <div className="Moreflex">
                        <div
                          class="MuiBox-root css-na2bnx"
                          bis_skin_checked="1"
                          style={{ gap: "0px" }}
                        >
                          <div
                            class="MuiBox-root css-1fs6avd"
                            bis_skin_checked="1"
                            style={{ background: "" }}
                          >
                            <div
                              class="MuiBox-root css-1kjzbsf"
                              bis_skin_checked="1"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                background: "rgb(245, 99, 88)",
                                padding: "18px",
                              }}
                              onClick={handleShow}
                            >
                              <SwapVertIcon
                                className="item"
                                style={{
                                  height: "30px",
                                  width: "",
                                  color: "white",
                                }}
                              />
                              <div>
                                <div
                                  className="item"
                                  style={{ color: "white" }}
                                >
                                  Filter
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="MuiBox-root css-1fs6avd"
                            bis_skin_checked="1"
                          >
                            <div
                              class="MuiBox-root css-1kjzbsf"
                              bis_skin_checked="1"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                background: "rgb(245, 99, 88)",
                                padding: "18px",
                              }}
                              onClick={handleShow2}
                            >
                              <TuneIcon
                                style={{
                                  height: "25px",
                                  width: "20px",
                                  color: "white",
                                }}
                              />
                              <div>
                                <div
                                  className="item"
                                  style={{ color: "white" }}
                                >
                                  Price
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div class="MuiBox-root css-c88jtm" bis_skin_checked="1">
                <div class="MuiBox-root css-0" bis_skin_checked="1">
                  <div
                    class="MuiBox-root css-1gwz7tv"
                    bis_skin_checked="1"
                  ></div>
                </div>
                <div class="MuiBox-root css-0" bis_skin_checked="1">
                  <div
                    class="MuiBox-root css-1gwz7tv"
                    bis_skin_checked="1"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          class="MuiContainer-root MuiContainer-axWidthXl css-1epolx4"
          bis_skin_checked="1"
        >
          <div class="MuiBox-root css-0" bis_skin_checked="1">
            <div class="MuiBox-root css-qbi8c0" bis_skin_checked="1">
              <div class="MuiBox-root css-qbi8c0" bis_skin_checked="1">
                {mainCategoryData
                  ?.filter((item) => item.status !== false)
                  ?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        handleCardClick(
                          index,
                          item.categoryId,
                          item.categoryName
                        )
                      }
                    >
                      {Number(selectedCard) === Number(item.categoryId) ? (
                        <div
                          className="MuiBox-root css-ocf0bf"
                          bis_skin_checked="1"
                        >
                          <div
                            className="MuiBox-root css-15ro776"
                            bis_skin_checked="1"
                          >
                            <svg
                              width="12"
                              height="12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 3 4.5 8.5 2 6"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <p className="MuiTypography-root MuiTypography-body1 css-heejq2">
                            {item.categoryName}
                          </p>
                        </div>
                      ) : (
                        <div
                          className={`MuiBox-root css-1492rz`}
                          bis_skin_checked="1"
                        >
                          <p className="MuiTypography-root MuiTypography-body1 css-h6pkw8">
                            {item.categoryName}
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div class="MuiBox-root css-jxqdoq" bis_skin_checked="1">
              <div
                class="MuiBox-root css-12h61o9"
                bis_skin_checked="1"
                style={{ gap: "0px" }}
              >
                <div
                  class=""
                  bis_skin_checked="1"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <p class="MuiTypography-root MuiTypography-body1 css-1awhple">
                    FILTERS{" "}
                  </p>
                  <div class="MuiBox-root css-iuzuyl" bis_skin_checked="1">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <div>
                            <Dropdown
                              ref={dropdownRef2}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              show={subCategoryOpen}
                            >
                              <Dropdown.Toggle
                                id="dropdown-subcategory"
                                aria-expanded={subCategoryOpen}
                              >
                                Sub-Category
                              </Dropdown.Toggle>
                              <Dropdown.Menu show={subCategoryOpen}>
                                {Array.isArray(
                                  getSubCategoryDropdownByIdData
                                ) &&
                                getSubCategoryDropdownByIdData.length !== 0 ? (
                                  getSubCategoryDropdownByIdData.map((option) =>
                                    option.status ? (
                                      <div
                                        key={option.id}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          cursor: "pointer",
                                          padding: "5px",
                                        }}
                                        onClick={() =>
                                          handleSubCategoryCheckboxChange(
                                            option
                                          )
                                        }
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          checked={selectedSubCategory.includes(
                                            option
                                          )}
                                          onChange={() =>
                                            handleSubCategoryCheckboxChange(
                                              option
                                            )
                                          }
                                          onClick={(e) => e.stopPropagation()} // Stop event propagation to prevent double toggle
                                          style={{ marginRight: "8px" }}
                                        />
                                        <span>{option.label}</span>
                                      </div>
                                    ) : null
                                  )
                                ) : (
                                  <Dropdown.Item disabled>
                                    There are no subcategories available
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                            <div>
                                                                <Dropdown ref={dropdownRef}>
                                                                    <Dropdown.Toggle
                                                                        aria-expanded={superCategoryOpen}
                                                                        onClick={handleOpenSuperCategoryClick}
                                                                        endIcon={<FilterAltIcon />}
                                                                        className='css-1x9pq6u'
                                                                    >
                                                                        Super Category
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu show={superCategoryOpen}>
                                                                        <ul style={{ listStyleType: 'none' }}>
                                                                            {Array.isArray(getSuperCategoryDropdownByIdData) && getSuperCategoryDropdownByIdData.length !== 0 &&
                                                                                getSuperCategoryDropdownByIdData.map((option) => (
                                                                                    option.status === false ? null :
                                                                                        <li key={option.id} style={{ marginBottom: '8px' }}>
                                                                                            <Form.Check
                                                                                                type="checkbox"
                                                                                                id={`super-category-checkbox-${option.id}`}
                                                                                                label={option.label}
                                                                                                checked={selectedsuperSubCategory.includes(option)} // Ensure that selectedSubCategory includes the current option
                                                                                                onChange={() => handleSuperCategoryCheckboxChange(option)}
                                                                                            />
                                                                                        </li>
                                                                                ))}
                                                                        </ul>
                                 {Array.isArray(getSuperCategoryDropdownByIdData)&& getSuperCategoryDropdownByIdData.length!==0?(<></>):(<>
                                    <Dropdown.Item disabled>There are no super categories available</Dropdown.Item>
                                 
                                 </>)}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div> */}
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <div>
                            <Dropdown
                              ref={dropdownRef}
                              show={priceRangeOpen}
                              onMouseEnter={handleMouseEnter2}
                              onMouseLeave={handleMouseLeave2}
                            >
                              <Dropdown.Toggle
                                id="dropdown-subcategory"
                                variant="outlined"
                                endIcon={<FilterAltIcon />}
                                style={{ color: "white" }}
                              >
                                Price Range
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="sort">
                                <ul
                                  style={{
                                    padding: "16px",
                                    listStyleType: "none",
                                  }}
                                >
                                  {Array.isArray(priceOptionsArray) &&
                                    priceOptionsArray?.map((option) => (
                                      <li
                                        key={option}
                                        style={{
                                          marginBottom: "8px",
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        onClick={() =>
                                          handlePriceRangeCheckboxChange(option)
                                        } // Click event on the entire li (text + checkbox)
                                      >
                                        <input
                                          type="checkbox"
                                          style={{
                                            marginRight: "8px", // To add space between checkbox and label
                                            cursor: "pointer",
                                          }}
                                          checked={selectedPriceRange.includes(
                                            option
                                          )}
                                          onChange={() =>
                                            handlePriceRangeCheckboxChange(
                                              option
                                            )
                                          }
                                          onClick={(e) => e.stopPropagation()} // Prevent checkbox click from triggering the li click twice
                                        />
                                        <span style={{ cursor: "pointer" }}>
                                          {option}
                                        </span>
                                      </li>
                                    ))}
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <div>
                            <Dropdown
                              ref={dropdownRef}
                              show={sortOpen}
                              onMouseEnter={handleMouseEnter3}
                              onMouseLeave={handleMouseLeave3}
                            >
                              <Dropdown.Toggle
                                id="dropdown-subcategory"
                                aria-describedby={sortId}
                                endIcon={<CategoryIcon />}
                              >
                                {selectedSortOption
                                  ? selectedSortOption
                                  : "Sort By"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <ul
                                  style={{
                                    padding: "16px",
                                    listStyleType: "none",
                                  }}
                                >
                                  {/* Remove duplicates using Set */}
                                  {[...new Set(sortOptions)].map((option) => (
                                    <li
                                      key={option}
                                      style={{
                                        display: "flex",
                                        marginBottom: "8px",
                                        cursor: "pointer", // Change cursor to pointer for better UX
                                      }}
                                      onClick={() =>
                                        handleSortCheckboxChange(option)
                                      } // Make the entire li clickable
                                    >
                                      <Form.Check
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          marginRight: "8px", // Adds space between checkbox and label
                                        }}
                                        type="checkbox"
                                        checked={selectedSortE.includes(option)}
                                        onChange={() =>
                                          handleSortCheckboxChange(option)
                                        } // Handle checkbox change
                                        onClick={(e) => e.stopPropagation()} // Prevent double triggering
                                      />
                                      <span>{option}</span>{" "}
                                      {/* Label for the option */}
                                    </li>
                                  ))}
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)", // 3 boxes per row
                    gap: "10px", // Space between boxes
                    margin: "auto",
                    width: "95%",
                  }}
                >
                  {Array.from({ length: 4 }).map((_, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "16px",
                        border: "1px solid #ddd",
                        display: "flex",
                        flexDirection: "column", // Stack the skeleton vertically
                        alignItems: "flex-start", // Align items to the start (left)
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={140}
                      />
                      <Skeleton width="60%" />
                      <Skeleton width="30%" />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="MuiBox-root css-6djc" bis_skin_checked="1">
                  {Array.isArray(sortedProductData) &&
                  sortedProductData.length !== 0 ? (
                    sortedProductData.map(
                      (item, index) =>
                        // Check if the status is true before rendering the product
                        item.status === true && (
                          <div
                            key={index}
                            className="MuiBox-root css-18q7liu  singleproduct"
                            bis_skin_checked="1"
                            onClick={() => handleProductDetail(item)}
                          >
                            <div
                              className="MuiBox-root css-w26f2k"
                              bis_skin_checked="1"
                            >
                              <div
                                className="MuiBox-root css-14c5dc4"
                                bis_skin_checked="1"
                                style={{ position: "relative" }}
                              >
                                {console.log(
                                  item?.inStockQuantity,
                                  "item?.inStockQuantityitem?.inStockQuantity"
                                )}
                                {item?.inStockQuantity == 0 && (
                                  <>
                                    <div
                                      className=""
                                      style={{
                                        width: "20px",
                                        position: "relative",
                                      }}
                                    >
                                      <img
                                        loading="lazy"
                                        src={outofstock}
                                        style={{
                                          width: "100px",
                                          position: "absolute",
                                          top: "",
                                          zIndex: "999",
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                                {item && item.productImagesPath && (
                                  <div style={{ position: "relative" }}>
                                    {item?.stripText ? (
                                      <>
                                        <p
                                          className="MuiTypography-root MuiTypography-Small_Medium css-11je29n"
                                          style={{
                                            backgroundColor: `${item?.stripColor}`,
                                            position: "absolute",
                                            top: "70%",
                                            left: 0,
                                            zIndex: 1, // Ensures the text is on top of the image
                                            padding: "5px", // Optional: add some padding to the text
                                          }}
                                        >
                                          {item?.stripText}
                                        </p>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    <div
                                      className=""
                                      style={{
                                        width: "30px",
                                        position: "absolute",
                                        top: 0,
                                        right: "30px",
                                        zIndex: 1, // Ensures the text is on top of the image
                                      }}
                                    ></div>
                                    <div
                                      className="MuiBox-root css-1om2adh"
                                      bis_skin_checked="1"
                                      style={{ paddingTop: "100%" }}
                                    >
                                      <img
                                        src={item.productImagesPath[0]}
                                        alt=""
                                        data-srcset={item.productImagesPath[0]}
                                        sizes=""
                                        className="lazy loaded"
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                          visibility: "visible",
                                        }}
                                        srcSet={item.productImagesPath[0]}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div
                              className="MuiBox-root css-ldca19"
                              bis_skin_checked="1"
                              style={{ width: "100%" }}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "",
                                  alignItems: "center",
                                  margin: "0px",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2, // Limit to 2 lines
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                className=""
                              >
                               {item?.productTitle }

                              </p>
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  gap: "6px",
                                  alignItems: "center",
                                  color: "grey",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p className="MuiTypography-root MuiTypography-Small_Medium css-11je29n">
                                  -
                                  {calculateDiscouunt(
                                    item?.twelveMonth,
                                    item?.perMonth
                                  )}
                                  % off
                                </p>
                                <p className="">
                                  <s>₹{item?.perMonth}/mo</s>
                                </p>
                              </div>
                              <p className="MuiTypography-root MuiTypography-body1 css-2kxnf0">
                                ₹ {item?.twelveMonth}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ displya: "" }}>
                                  <div
                                    className="MuiBox-root css-ov56kn"
                                    bis_skin_checked="1"
                                  >
                                    <p className="MuiTypography-root MuiTypography-body1 css-1ws68lr">
                                      ₹ {item.price / 10}
                                    </p>
                                  </div>
                                  <p className="MuiTypography-root MuiTypography-body1 css-1ko6fke">
                                    Get it by {mydata(item.availabeDay)}
                                  </p>
                                </div>
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "end",
                                  }}
                                >
                                  <ArrowForward />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    )
                  ) : (
                    <p style={{ color: "red" }}>No products found</p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  ) : (
    ""
  );
}

const mapStateToProps = (state, ownProps) => {
  console.log("stategetProductsByFilter -------->", state.getProductsByFilter);
  return {
    ProductFilterData: state.getProductsByFilter.data,
    error: state.getProductsByFilter.error,
    loading: state.getProductsByFilter.loading,
    mainCategoryData: state.getAllMainCategory.data,
    getAllMainCategoryDropdownData: state.getAllMainCategoryDropdown.data,
    getSubCategoryDropdownByIdData: state.getSubCategoryDropdownById.data,
    getSuperCategoryDropdownByIdData: state.getSuperCategoryDropdownById.data,
    mainCategoryDataPreSelected: state.setMainCateogryData.data,
    userSelectedPincode: state.setCityPincode.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetProducts: (filterData, keyword) =>
      dispatch(fetchProductsByFilter(filterData, keyword)),
    handleFetchAllMainCategory: () => dispatch(fetchAllMainCategory()),
    handleFetchAllMainCategoryDropdown: () =>
      dispatch(fetchAllMainCategoryDropdown()),
    handleFetchAllSubCategoryDropdown: (id) =>
      dispatch(fetchAllSubCategoryDropdown(id)),
    setMainCategoryData: (data) =>
      dispatch({ type: "SET_MAIN_CATEGORY_DATA", payload: data }),
    // handleFetchAllSuperCategoryDropdown: (id) => dispatch(fetchAllSuperCategoryDropdown(id)),
    setCityPincodeData: (data) =>
      dispatch({ type: "SET_CITY_PINCODE_DATA", payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListing);
