import {
    UpdateAddToCartReq,
    UpdateAddToCartSuccess,
    UpdateAddToCartFailure,
    ClearUpdateQunatityState
} from "../types";

import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
import Swal from "sweetalert2";
export const UpdateAddToCartRequest = () => {
    return {
        type: UpdateAddToCartReq,
    };
};
export const UpdateAddToCartSuccessfun = (Data) => {
    return {
        type: UpdateAddToCartSuccess,
        payload: Data,
    };
};

export const UpdateAddToCartFailurefun = (Error) => {
    return {
        type: UpdateAddToCartFailure,
        payload: Error,
    };
};

export const UpdateAddToCartQuantity = (data) => {
    const customerId=localStorage.getItem('customerId')
    const cartId=localStorage.getItem('cartId')

console.log(data,'datadata')
    
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(UpdateAddToCartRequest());
        axios
            .put(url + `updateCartItemById/${cartId}`,  {
                "quantity": data?.isRenewlPlan ? 1 : data?.quantity, // Set quantity to 1 if isRenewlPlan is true
                "duration": data.duration,
                "cartPaymentStatus": false,
                "cartValue":data?.cartValue||1,

                "custumerid": customerId,
                "passProduct": {
                    "productId": data.productId
                },
                "deleted": false
            },{
                headers: headers,
            })
            .then(function (response) {
                console.log(response,'responseresponseresponseresponse')
    
                // localStorage.setItem("message", "searchProductuccuss")
                dispatch(UpdateAddToCartSuccessfun(response.data));
            })
            .catch(function (error) {
                console.log(error,'errorerrorerror')
                if(error?.response?.data==="Product is Out of Stock!"){
                    Swal.fire({
                        title: 'OUT OF STOCK',
                        text: "Product is Out of Stock!",
                        icon: 'error',
                    });
                }else{
                    Swal.fire({
                        title: 'Renewal Product',
                        text: "This product is renewal you can't update",
                        icon: 'error',
                    });
                }
          
                // localStorage.setItem("message", "searchProductFailed")
                dispatch(UpdateAddToCartFailurefun(error));
            });
    };
};

export const ClearUpdateQunatitySrrtate = () => ({
    type: ClearUpdateQunatityState,
  });