import {
    getDiscountByIdRequest,
    getDiscountByIdsucccesss,
    getDiscountByIdFaillll,
    getDiscountclear
} from "../../../actions/types";

const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};


export const GetdiscountByIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case getDiscountByIdRequest:
            return {
                ...state,
                loading: true,
            };
        case getDiscountByIdsucccesss:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "successfully fatched"
            };
        case getDiscountByIdFaillll:
            return {
                ...state,
                loading: false,
                tdata: {},
                error: action.payload,
                message: "faild"
            };
            case getDiscountclear:
                return{
                ...state,
                data:[]
              }


        default:
            return state;
    }
};
