
import {
    GET_BOTTOM_BANNER_REQUEST,
    GET_BOTTOM_BANNER_SUCCESS,
    GET_BOTTOM_BANNER_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const fetchBottomBannerRequest = () => {
    return {
        type: GET_BOTTOM_BANNER_REQUEST,
    };
};
export const fetchBottomBannerSuccess = (Data) => {

    return {
        type: GET_BOTTOM_BANNER_SUCCESS,
        payload: Data,
    };
};

export const fetchBottomBannerFailure = (Error) => {
    return {
        type: GET_BOTTOM_BANNER_FAILURE,
        payload: Error,
    };
};

export const fetchBottomBanner = (data) => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchBottomBannerRequest);
        axios
        .get(url + `getAllFooterBanners`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchBottomBannerSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchBottomBannerFailure(error));
            });
    };
};
