

import {
    GetAggrementDataByOrderIdRequest,
    GetAggrementDataByOrderIdSuccess,
    GetAggrementDataByOrderIdFail,
} from "../../../actions/types";
const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};

export const getAgrrementByOrderIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case GetAggrementDataByOrderIdRequest:
            return {
                ...state,
                loading: true,
            };
        case GetAggrementDataByOrderIdSuccess:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "successfully added"
            };
        case GetAggrementDataByOrderIdFail:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
            
            
        default:
            return state;
    }
};
