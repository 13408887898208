import { data } from "jquery";
import {
    Post_Order_Request,
    Post_Order_Success,
    Post_Order_Failure,
    CLEAR_ADD_PRODUCT_ORDER
} from "../../../actions/types";
const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};

export const AddProductOrder = (state = initialState, action) => {
    switch (action.type) {
        case Post_Order_Request:
            return {
                ...state,
                loading: true,
            };
        case Post_Order_Success:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "successfully added"
            };
        case Post_Order_Failure:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
            case CLEAR_ADD_PRODUCT_ORDER:
                return{
                    ...state,
                    data:[],
                    loading: false

                } 
            
        default:
            return state;
    }
};
