import {
    GET_MAIN_CATEGORY_DROPDOWN_BY_ID_REQUEST,
    GET_MAIN_CATEGORY_DROPDOWN_BY_ID_SUCCESS,
    GET_MAIN_CATEGORY_DROPDOWN_BY_ID_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../auth/baseUrl";
import { basicAuth } from "../../auth/basicAuth";
export const fetchAllMainCategoryDropdownRequest = () => {
    return {
        type: GET_MAIN_CATEGORY_DROPDOWN_BY_ID_REQUEST,
    };
};
export const fetchAllMainCategoryDropdownSuccess = (Data) => {
    return {
        type: GET_MAIN_CATEGORY_DROPDOWN_BY_ID_SUCCESS,
        payload: Data,
    };
};

export const fetchAllMainCategoryDropdownFailure = (Error) => {
    return {
        type: GET_MAIN_CATEGORY_DROPDOWN_BY_ID_FAILURE,
        payload: Error,
    };
};

export const fetchAllMainCategoryDropdown = () => {
    const headers = {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        'Authorization': basicAuth,
    };

    return (dispatch) => {
        dispatch(fetchAllMainCategoryDropdownRequest);
        axios
            .get(url + "getAllMainCategory", {
                headers: headers,
            })
            .then(function (response) {
                const fetchedOptions = response.data.map(option => {
                    return { value: option.categoryId, label: option.categoryName };
                });
                dispatch(fetchAllMainCategoryDropdownSuccess(fetchedOptions));
            })
            .catch(function (error) {
                dispatch(fetchAllMainCategoryDropdownFailure(error));
            });
    };
};
