import {
    POST_LOGIN_OTP_REQUEST,
    POST_LOGIN_OTP_SUCCESS,
    POST_LOGIN_OTP_FAILURE,
} from "../../actions/types";

const initialState = {
    loading: false,
    data: [],
    error: "",
    message: ""
};
export const sendOtpSmsReducer = (state = initialState, action) => {
    switch (action.type) {
        case POST_LOGIN_OTP_REQUEST:
            return {
                ...state,
                loading: true,
                error: "",
                message: ""
            };
        case POST_LOGIN_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: action.payload
            };
        case POST_LOGIN_OTP_FAILURE:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "faild"
            };
        default:
            return state;
    }
};
