

import {
    GetCustomreAddressByIdRequest,
    GetCustomreAddressByIdSuccess,
    GetCustomreAddressByIdFailure,
} from "../types";



import axios from "axios";
import { url } from "../auth/baseUrl";
import { basicAuth } from "../auth/basicAuth";
export const fetchCustomreAddressByIdRequest = () => {
    return {
        type: GetCustomreAddressByIdRequest,
    };
};
export const fetchCustomreAddressByIdSuccess = (Data) => {
    return {
        type: GetCustomreAddressByIdSuccess,
        payload: Data,
    };
};

export const fetchCustomreAddressByIdFailure = (Error) => {
    return {
        type: GetCustomreAddressByIdFailure,
        payload: Error,
    };
};



export const GetAddeessByCustomerId = () => {
    const headers = {
        "Content-Type": "Application/json",
        'Authorization': basicAuth,
    };
    const addressId = localStorage.getItem('customerId')
    return (dispatch) => {
        dispatch(fetchCustomreAddressByIdRequest());
        axios
            .get(url + `getCustomerById/${addressId}`,
                {
                    headers: headers,
                })
            .then(function (response) {
                
                dispatch(fetchCustomreAddressByIdSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchCustomreAddressByIdFailure(error));
            });
    };
};
